/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentFactoryResolver, ComponentRef, Injectable, Injector, Renderer2, RendererFactory2, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { appletComponentMap } from '../components/applet-component-map';
import { InputApplication } from '../components/input-application';
import { ListApplication } from '../components/list-application';
import { ApplicationInformation } from './application-information.service';
import { ApplicationRefFacade } from "./application-ref.facade";

@Injectable()
export class CanvasLayoutRenderService {

    private renderer: Renderer2;

    constructor(rendererFactory: RendererFactory2,
        private injector: Injector,
        private componentFactory: ComponentFactoryResolver,
        private domSanitizer: DomSanitizer,
        private applicationInformation: ApplicationInformation,
        private applicationRefFacade: ApplicationRefFacade) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    getResponsivePanelCommands(tag: string, commands: Record<string, LayoutCommand[]>)
        : Record<string, LayoutCommand[]> {
        const breakPoints = {};
        for (const breakPoint in commands) {
            breakPoints[breakPoint] = this.getNaturalCommands(tag, commands[breakPoint]);
        }
        return breakPoints;
    }

    updateShellCanvasLayout(
        containerNode: HTMLElement,
        headerNode: ChildNode,
        leftPanelNode: ChildNode,
        rightPanelNode: ChildNode,
        footerNode: ChildNode,
    ): void {
        if (!containerNode) return;
        if (leftPanelNode)
            this.renderer.insertBefore(containerNode, leftPanelNode, containerNode.firstChild);
        if (headerNode)
            this.renderer.insertBefore(containerNode, headerNode, containerNode.firstChild);
        if (rightPanelNode)
            this.appendChild(containerNode, rightPanelNode);
        if (footerNode)
            this.appendChild(containerNode, footerNode);
    }

    removeNodeByNodeName(containerNode: HTMLElement, nodeName: string): void {
        nodeName = nodeName.toUpperCase();
        const filteredChildren = _.filter(containerNode.childNodes, node => node.nodeName == nodeName);
        filteredChildren.forEach(oldChild => this.removeChild(containerNode, oldChild));
    }

    appendChild(containerNode: HTMLElement, canvasNode: ChildNode): void {
        if (!containerNode || !canvasNode) return;
        this.renderer.appendChild(containerNode, canvasNode);
    }

    removeChild(containerNode: HTMLElement, oldChild: HTMLElement | ChildNode): void {
        if (!containerNode || !oldChild) return;
        this.renderer.removeChild(containerNode, oldChild);
    }

    createElement<T>(nodeName: string): T {
        return this.renderer.createElement(nodeName);
    }

    render(canvasId: string, commands: LayoutCommand[],
        appletLookup: Record<string, any>, context: Record<string, any>,
    ): ChildNode {
        if (!Array.isArray(commands) || commands.length <= 0) return null;
        const nodes = this.createFromCommands(canvasId, commands, appletLookup, context);
        return nodes[0];
    }

    cleanApplicationRef(canvasId: string): void {
        this.applicationRefFacade.destroyCanvasComponentRefs(canvasId);
    }

    private getNaturalCommands(tag: string, commands: LayoutCommand[])
        : LayoutCommand[] {
        const panelCommands = [];
        let startFound = false;
        for (const command of commands) {
            if ("colStart" == command.cmd && tag == command.tag) startFound = true;
            if (startFound) panelCommands.push(command);
            if ("colEnd" == command.cmd && tag == command.tag) break;
        }
        return panelCommands;
    }

    private createFromCommands(canvasId: string, commands: LayoutCommand[],
        appletLookup: Record<string, any>, context: Record<string, any>,
    ): NodeListOf<ChildNode> {
        const root = this.createElement<HTMLDivElement>("div");
        const parents = [root];
        let last = 0;
        let uniqueAppletId = 0;
        commands.forEach(command => {
            switch (command.cmd) {
                case 'html': {
                    this.appendChildren(parents[last], command);
                    break;
                }
                case "colEnd": {
                    parents.pop();
                    last--;
                    break;
                }
                case "applet": {
                    const appletRef = this.createAppletElement(uniqueAppletId, command, appletLookup, context);
                    if (appletRef) {
                        this.applicationRefFacade.updateCanvasComponentRefs(canvasId, appletRef);
                        uniqueAppletId++;
                        const div: HTMLDivElement = this.createElement("div");
                        div.appendChild(appletRef.location.nativeElement);
                        parents[last].appendChild(div);
                    }
                    break;
                }
                case "colStart": {
                    const element = this.createStartElement(command);
                    parents[last].appendChild(element);
                    parents.push(element);
                    last++;
                    break;
                }
                default:
                    console.log("Missing support for command ", command.cmd);
                    break;
            }
        });
        return root.childNodes;
    }

    private appendChildren(parent: any, command: LayoutCommand) {
        try {
            const auxContainer = this.createStartElement(command);
            auxContainer.innerHTML = this.domSanitizer.sanitize(SecurityContext.HTML, command.content);
            auxContainer.childNodes.forEach(node => this.renderer.appendChild(parent, node));
        } catch (e) {
            console.log(e);
        }
    }

    private setClasses(element: any, command: LayoutCommand): void {
        if (!command.classAsIs) {
            this.renderer.addClass(element, "clearfix");
        }
        if (command.class) {
            const classes = command.class.split(' ');
            classes.forEach(classStr => {
                if (_.isEmpty(classStr)) return;
                this.renderer.addClass(element, classStr);
            });
        }
    }

    private setAttributes(element: any, command: LayoutCommand): void {
        if (command.id) {
            this.renderer.setAttribute(element, "id", command.id);
        }
        if (command.attributes) {
            const attributes = command.attributes.split(' ');
            attributes.forEach(attribute => {
                const attributeParts = attribute.split('=');
                if (attributeParts.length <= 1) return;
                const attributeName = attributeParts[0];
                const attributeValue = attributeParts[1].replace(/'/g, "");
                this.renderer.setAttribute(element, attributeName, attributeValue);
            });
        }
    }

    private getCommandStyle(command: LayoutCommand): string {
        let styleValue = "";
        if (command.float || command.width || command.style) {
            if (command.float)
                styleValue += 'float: ' + command.float + ';';
            if (command.width)
                styleValue += 'width: ' + command.width + ';';
            if (command.style)
                styleValue += command.style;
        }
        return styleValue;
    }

    private setStyles(element: any, style: string): void {
        if (!style) return;
        this.renderer.setAttribute(element, "style", style);
    }

    private getAppletObject(command: LayoutCommand, appletLookup: Record<string, any>, sequence: number)
        : Applet {
        const applet = appletLookup[command.id];
        if (_.isNil(applet)) return null;
        const appletName = this.getAppletNameFromCommand(command);
        applet.rid = 'CP_' + appletName + '_' + sequence;
        applet.template = this.snakeCase(applet.template);
        applet.style = this.getCommandStyle(command);
        applet.class = command.class;
        applet.attributes = command.attributes;
        applet.config = {};
        const appInfo = this.applicationInformation.getAppInfo(applet.name);
        // Prevent tab applications to be recompiled since it would recompile its children applications
        const isTabbedApp = appInfo.tabApplications && appInfo.tabApplications.length > 0;
        applet.hasBreakPointOverrides = (!appInfo.hasBreakPointOverrides || isTabbedApp) ? false : true;
        if (!this.applicationInformation.isInputApp(applet.name)) applet.hasBreakPointOverrides = false;
        return { ...appInfo, ...applet };
    }

    private getAppletNameFromCommand(command: LayoutCommand) {
        return command.id.replace(/\./g, '');
    }

    private snakeCase(name: string): string {
        const SNAKE_CASE_REGEXP = /[A-Z]/g;
        const separator = '-';
        return name.replace(SNAKE_CASE_REGEXP, (letter, pos) => (pos ? separator : '') + letter.toLowerCase());
    }

    private createAppletElement(uniqueAppletId: number, command: LayoutCommand,
        appletLookup: Record<string, any>, context: Record<string, any>): ComponentRef<InputApplication | ListApplication> {

        const applet = this.getAppletObject(command, appletLookup, uniqueAppletId);
        if (_.isNil(applet)) {
            console.error(command.id, "metadata could not be found!");
            return;
        }

        const componentTemplate = _.capitalize(applet.template);
        const componentTheme = componentTemplate + IX_Theme.themeName;
        const componentType = appletComponentMap[componentTheme] || appletComponentMap[componentTemplate];
        if (_.isNil(componentType)) {
            console.error(applet.name, "component could not be found!");
            return;
        }

        const appletFactory = this.componentFactory.resolveComponentFactory<InputApplication | ListApplication>(componentType);
        const appletRef = appletFactory.create(this.injector);

        appletRef.instance.applet = applet;
        appletRef.instance.context = context;
        appletRef.instance.cssClass = applet.class || "";

        const appletNode = appletRef.location.nativeElement;
        this.setAttributes(appletNode, command);

        // applet rid is used by conditional format implementation
        // override id for applet angular components
        this.renderer.setAttribute(appletNode, "id", (applet.rid + 'wrapper'));
        this.renderer.setAttribute(appletNode, "data-applet", applet.name);
        if (command.detectChanges) appletRef.hostView.detectChanges(); // Testing purposes only
        return appletRef;
    }

    private createStartElement(command: LayoutCommand): any {
        const element = this.createElement(command.tag ? command.tag : "div");
        const style = this.getCommandStyle(command);
        this.setStyles(element, style);
        this.setClasses(element, command);
        this.setAttributes(element, command);
        this.createScriptInjection(element, command);
        return element;
    }

    private createScriptInjection(element: any, command: LayoutCommand) {
        if (command.scripts) {
            const script = this.createElement<HTMLScriptElement>("script");
            this.renderer.setAttribute(script, "type", "text/javascript");
            script.text = command.scripts.split("|").join(" ");
            this.renderer.appendChild(element, script);
        }
    }
}
