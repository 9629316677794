/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import _ from 'lodash';
import { WorkflowConfigurationService } from './workflow-configuration.service';
import { DynamicReplacementService } from './dynamic-replacement.service';
import { TranslateFacadeService } from './translate-facade.service';
import UtilityFunctions from '../utility.functions';
import { MetaTagsService } from './metatags.service';

@Injectable()
export class ThemeService {

    constructor(private translateFacadeService: TranslateFacadeService,
        private workflowConfigurationService: WorkflowConfigurationService,
        private dynamicReplacementService: DynamicReplacementService,
        private metaTagsService: MetaTagsService) {
    }

    getThemeProperty(name): ThemeProperty {
        return _.find(IX_Theme.properties, (item) => item.PropertyName.EqualsIgnoreCase(name));
    }

    getThemePropertyIfMatching(name, value1) {
        const theme = IX_Theme;
        name = name.toLowerCase();
        value1 = value1.toLowerCase();
        return _.find(theme?.properties, (item) =>
            item.PropertyName.EqualsIgnoreCase(name)
            && item.Value1.EqualsIgnoreCase(value1)
        );
    }

    getThemeDetail(detailName) {
        return _.isNil(IX_Theme.details[detailName]) ? null : IX_Theme.details[detailName];
    }

    isThemePropertyEnabled(name: string): boolean {
        const property = this.getThemePropertySafe(name);
        return !_.isNil(property.Value1) && this.isTruthy(property.Value1);
    }

    isThemePropertyDisabled(name: string): boolean {
        const property = this.getThemePropertySafe(name);
        return !_.isNil(property.Value1) && this.isFalsy(property.Value1);
    }

    setWarnUserOnExternalLinks() {
        const isEnabled = this.isWarnUserOnExternalLinksEnabled();
        if (isEnabled) {
            const _warnOptions = this.getWarnUserOnExternalLinksOptions();
            const _warnUserOnExternalLinks = UtilityFunctions.warnUserOnExternalLinks(_warnOptions);
            _warnUserOnExternalLinks.unregister();
            _warnUserOnExternalLinks.register();
        }
    }

    isWarnUserOnExternalLinksEnabled() {
        const property = this.getThemeProperty("WarnUserOnExternalLinks");
        return !_.isEmpty(property);
    }

    getWarnUserOnExternalLinksOptions() {
        const property = this.getThemeProperty("WarnUserOnExternalLinks");
        const exclusions = [];
        if (property.Value2 && !_.isEmpty(property.Value2)) {
            const themeExclusions = property.Value2.split(',');
            themeExclusions.forEach(function (exclusion) {
                exclusions.push("/" + exclusion + "/gi")
            });
        }
        const warnOptions = {
            exclude: exclusions,
            message: _.isEmpty(property.Value1) ? "" : property.Value1
        };
        return warnOptions;
    }

    private isTruthy(value) {
        if (_.isNil(value)) return false;
        value = value.toString().toLowerCase().trim();
        return _.includes(["true", "yes", "y"], value);
    }

    private isFalsy(value) {
        if (_.isNil(value)) return false;
        value = value.toString().toLowerCase().trim();
        return _.includes(["false", "no", "n"], value);
    }

    private getThemePropertySafe(name): any {
        return this.getThemeProperty(name) || {};
    }

    private isThemeDetailEnabled(detailName) {
        const value = this.getThemeDetail(detailName);
        return !_.isNil(value) && this.isTruthy(value);
    }

    getGridColors($scope, showGridColorsByPercentageColumn, grdColrByPcntRemainingColor, lightenGridColorByPercentage, showGridColorsByPercentageConvertToNumber) {
        const property = this.getThemeProperty("DataGridColors");
        if (_.isEmpty(property?.Value1)) return;
        const colorsList = property.Value1.split('|');
        $scope.gridColors = colorsList;
        $scope.showGridColorsByPercentageColumn = showGridColorsByPercentageColumn;
        $scope.grdColrByPcntRemainingColor = grdColrByPcntRemainingColor;
        $scope.lightenGridColorByPercentage = lightenGridColorByPercentage;
        $scope.showGridColorsByPercentageConvertToNumber = showGridColorsByPercentageConvertToNumber;
    }

    isTrackDirtyStateDisabled() {
        return this.isThemeDetailEnabled('DisableTrackDirtyState');
    }

    setCssClassForList($element: any): void {
        const property = this.getThemeProperty("#List.CssClass");
        if (_.isEmpty(property?.Value1)) return;
        $element?.addClass(property.Value1);
    }

    getExcelExportIcon(defaultIconPath: string): string {
        const exportImgSrc = this.getThemeDetail("ExportToExcelIcon");
        const defaultImageName = "Blue_Excel_btn.jpg";
        if (exportImgSrc) {
            return defaultIconPath + exportImgSrc;
        }
        return defaultIconPath + defaultImageName;
    }

    getMinColumnWidth(minWidthValInList) {
        if (!minWidthValInList) {
            const minWidthDefaultVal = this.getThemeProperty("MinColumnWidthDefaultValue");
            if (minWidthDefaultVal) {
                minWidthValInList = minWidthDefaultVal.Value1;
            } else {
                minWidthValInList = "20";
            }
        }
        // tslint:disable-next-line: radix
        return parseInt(minWidthValInList);
    }

    textToShowOnNoData(listNoDataTextId, noDataString) {
        const translatedNoDataText = this.translateFacadeService.translateOrDefault(listNoDataTextId, noDataString);
        if (this.dynamicReplacementService.hasReplacementValue(translatedNoDataText)) {
            if (noDataString.indexOf("{ThemeProperty:") > -1) {
                const noDataTextVal = noDataString.substring(15, noDataString.length - 1);
                const replacedText = this.getThemeProperty(noDataTextVal) ? this.getThemeProperty(noDataTextVal).Value1 : "No Data";
                return this.translateFacadeService.getTranslation(replacedText);
            }
        } else {
            return translatedNoDataText;
        }
    }

    /**
     * Currently supports one occurrence of theme property in the translated string.
     */
    textToShow(translationTextId, textValue, defaultText) {
        const translatedText = this.translateFacadeService.translateOrDefault(translationTextId, textValue);
        if (!this.dynamicReplacementService.hasReplacementValue(translatedText)) {
            return translatedText;
        }

        const themePropRegex = /{ThemeProperty:(.*)}/;
        if (!themePropRegex.test(textValue)) {
            return defaultText;
        }

        const themePropMatches = themePropRegex.exec(textValue);
        const themePropName = themePropMatches[1];
        const prop = this.getThemeProperty(themePropName);
        const replacedText = prop ? prop.Value1 : defaultText;
        const tpTranslated = this.translateFacadeService.getTranslation(replacedText);
        return textValue.replace(themePropRegex, tpTranslated);
    }

    getWorkflowPageChangeScript() {
        const property = this.getThemeProperty("WorkflowPageChangeScript");
        let workflowPageChangeScript = _.isNil(property) || _.isEmpty(property.Value1) ? "" : property.Value1;

        // GDPR - if consent banner app is null, use default GA injection to avoid P-Tier breaking change. [SEE: VSTS #57552]
        const googleAnalyticsAccountId = this.getThemeDetail("GoogleAnalyticsAccountId");
        const consentBannerApplication = this.getThemeDetail("ConsentBannerApplication");
        if (googleAnalyticsAccountId && !consentBannerApplication) {
            workflowPageChangeScript += "window.dataLayer = window.dataLayer || []; \r\n"
            workflowPageChangeScript += "var gtag =  gtag || function () { window.dataLayer.push(arguments); };\r\n"
            workflowPageChangeScript += "gtag('config', '" + googleAnalyticsAccountId + "', {'page_path': $scope.step});";
        }

        return workflowPageChangeScript;
    }

    isRecordUserNavEventWithNoThrottleWaitEnabled() {
        return this.isThemePropertyEnabled("RecordUserNavEventWithNoThrottleWait");
    }

    appendSEOTags(tagsObject, tagName, tagValue, dynamic?, imgPath?) {
        const tagNames = {
            "title": ["title", "og:title"],
            "description": ["description", "og:description"],
            "image": ["og:image"],
            "keywords": ["keywords"],
            "type": ["og:type"],
            "url": ["og:url"]
        }

        if (!dynamic && tagName.toLowerCase() === "image" && imgPath) {
            if (tagValue.indexOf('/') !== 0) {
                tagValue = imgPath;
            }
        }

        // eslint-disable-next-line no-prototype-builtins
        if (tagNames.hasOwnProperty(tagName)) {
            tagNames[tagName].forEach((item) => tagsObject[item] = tagValue);
            return tagsObject;
        }

        tagsObject[tagName] = tagValue;
        return tagsObject;
    }

    addMetaTagCode(metaTagImagePath) {
        let tagsObject = {};

        const metaTagDescription = this.getThemeDetail("MetaTagDescription");
        if (metaTagDescription)
            tagsObject = this.appendSEOTags(tagsObject, "description", metaTagDescription);

        const defaultPageTitle = this.getThemeDetail("DefaultPageTitle");
        if (defaultPageTitle)
            tagsObject = this.appendSEOTags(tagsObject, "title", defaultPageTitle);

        const metaTagImage = this.getThemeDetail("MetaTagImage");
        if (metaTagImage)
            tagsObject = this.appendSEOTags(tagsObject, "image", metaTagImage, null, metaTagImagePath);

        const metaTagKeywords = this.getThemeDetail("MetaTagKeyWords");
        if (metaTagKeywords)
            tagsObject = this.appendSEOTags(tagsObject, "keywords", metaTagKeywords);

        tagsObject = this.appendSEOTags(tagsObject, "type", "website");

        this.metaTagsService.setDefaultTags(tagsObject);
    }

    getPlaidClientName() {
        const plaidClientName = this.getThemeProperty("PlaidClientName");
        return _.isEmpty(plaidClientName) ? "this application" : plaidClientName.Value1;
    }

    private isAllowShowAllInListsEnabled() {
        return this.isThemePropertyEnabled("AllowShowAllInLists");
    }

    setAllowShowAllInList($scope) {
        const pager = $scope.gridProperties.pager;
        if (_.isNil(pager) || !pager.showPageSizeSelector) return;
        pager.showPageSizeSelector = this.isAllowShowAllInListsEnabled();
    }

    bindGridPagerViewAllEvents(dataGrid) {
        const isAllowShowAllInListsEnabled = this.isAllowShowAllInListsEnabled();
        if (!isAllowShowAllInListsEnabled) return;
        const pager = dataGrid.option('pager');
        if (_.isNil(pager) || !pager.showPageSizeSelector) return;
        const pagerController = dataGrid.getView('pagerView')._getPager();
        if (pagerController) {
            pagerController.off('optionChanged');
            pagerController.on('optionChanged', (e) => {
                if (e.name === 'pageSize') {
                    window.setTimeout(() => {
                        this.updatePagerViewAllText(e);
                    }, 0);
                }
            });
        }
    }


    updatePagerViewAllText(e) {
        const el = e.element.find('.dx-page-size');
        if (el.length > 0) {
            $(el[el.length - 1]).addClass('showAllDataButton');
            el[el.length - 1].setAttribute('data-before', 'View All');
            el[el.length - 1].style.color = 'transparent';
        }
    }

    bindGridPagerRenderCompletedEvent(e) {
        const dataGrid = e.component;
        const isAllowShowAllInListsEnabled = this.isAllowShowAllInListsEnabled();
        if (!isAllowShowAllInListsEnabled) return;
        const pager = dataGrid.option('pager');
        if (_.isNil(pager) || !pager.showPageSizeSelector) return;
        dataGrid.getView('pagerView')
            .renderCompleted.add(() => {
                this.updatePagerViewAllText(e);
            });
    }

    private getCatapultFooterApplication() {
        let footerApp = this.getThemeDetail('FooterApplication');
        if (!_.isNil(footerApp) && footerApp.contains("|")) {
            footerApp = footerApp.split('|');
            footerApp = _.first(footerApp.filter(function (app) { return _.startsWith(app, "v4"); }));
            footerApp = _.last(footerApp.split("v4:"));
        }
        return footerApp;
    }

    getThemeApplications() {
        const workflowConfig = this.workflowConfigurationService.getConfiguration();
        const overrideApps = _.isEmpty(workflowConfig.themeOverrideCommandMap) ? [] : _.keys(workflowConfig.themeOverrideCommandMap);
        const apps = [this.getThemeDetail("HeaderApplication"),
        this.getCatapultFooterApplication(),
        this.getThemePropertySafe("LeftPanelApplication").Value1,
        this.getThemePropertySafe("RightPanelApplication").Value1]
        return _.filter(_.concat(apps, overrideApps), function (app) {
            return !_.isNil(app);
        });
    }

    getCanvasCellHeight() {
        const CanvasCellHeight = 5;
        return CanvasCellHeight;
    }

    getCanvasResponsiveWith() {
        const canvasResponsiveStrategy = this.getThemePropertySafe('CanvasResponsiveStrategy').Value1 || "";
        if (window['string'].Compare('ZoomResize', canvasResponsiveStrategy, true) === 0) {
            return window.innerWidth;
        }
        return window.outerWidth || window.innerWidth;
    }

    isResponsiveGridLayoutEnabled() {
        return this.isThemePropertyEnabled('IsResponsiveGridLayoutEnabled');
    }

    getResponsiveBreakpoints() {
        const breakpoints = {
            'ExtraLarge': 1600,
            'Large': 1200,
            'Medium': 992,
            'Small': 768
        };
        const overrideBreakpoints: string = this.getThemeDetail('ResponsiveBreakpoints');
        if (!_.isNil(overrideBreakpoints)) {
            const parts = overrideBreakpoints.split(',');
            parts.forEach(function (part) {
                const config = part.split(':');
                breakpoints[_.first(config)] = +_.last(config);
            });
        }
        return Object.freeze(breakpoints);
    }

    getResponsiveBreakpointWidth(sizeName) {
        return this.getResponsiveBreakpoints()[sizeName] || 0;
    }

    getAddThisCode() {
        const addThisUserIdColumn = this.getThemeDetail('AddThisUserId');
        const addThisApiUrlColumn = this.getThemeDetail('AddThisApiUrl');

        if (addThisUserIdColumn && addThisApiUrlColumn) {
            $('document').ready(function () {
                const rets = IX_SetSocialSharingButtons(window);
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = addThisApiUrlColumn + addThisUserIdColumn;
                const configScript = document.createElement('script');
                window.addthis_config = {};
                window.addthis_config.services_compact = rets['vals'];
                window.addthis_config.username = addThisUserIdColumn;

                $('body').append(configScript);
                $('body').append(script);
            });
        }
    }

    setAddThisWidget() {
        const addThisUserIdColumn = this.getThemeDetail('AddThisUserId');
        if (addThisUserIdColumn) {
            const _addThisWidget = setInterval(function () {
                if (window.addthis) {
                    if (window.addthis.layers && window.addthis.layers.refresh) {
                        window.addthis.layers.refresh();
                    }
                    window.addthis.update();
                    if (window.addthis.layers.onpage) {
                        clearInterval(_addThisWidget);
                    }
                }
            }, 50);
        }
    }

    preventGridGroupCellHiding() {
        const preventGridGroupCellHiding = this.getThemeProperty("PreventGridGroupCellHiding");
        if (preventGridGroupCellHiding) {
            return true;
        } else {
            return false;
        }
    }

    getPersonalizationIcon(imagePath) {
        const personalizedIcon = this.getThemeDetail("PersonalizationIcon")
        return (_.isString(personalizedIcon) && personalizedIcon.length > 0)
            ? ("/images/Buttons/" + personalizedIcon)
            : imagePath;
    }

    setUserModifiedFixedColumns($scope) {
        if (this.isThemePropertyEnabled("PreventManualFixedColumns")) {
            $scope?.gridProperties?.columns?.forEach((col) => col.allowFixing = false);
        }
    }

    setColumnChooserMode($scope) {
        if (_.isNil($scope.gridProperties)
            || _.isNil($scope.gridProperties.columnChooser)
            || _.isNil($scope.gridProperties.columnChooser.enabled)
            || !$scope.gridProperties.columnChooser.enabled
            || $scope.gridProperties.columnChooser.mode !== "DropDown") return;
        const calmColumnChooserEnabled = this.isThemePropertyEnabled("CalmColumnChooser");
        if (!calmColumnChooserEnabled) return;
        $scope.gridProperties.columnChooser.mode = "CalmDropDown";
    }

    private getVisibilityMap(tabAppName) {
        const visibilityMap = {};
        for (let i = 0; i < window.IX_Theme.properties.length; i++) {
            const property = window.IX_Theme.properties[i];
            if (_.isNil(property)
                || _.isNil(property.PropertyName)
                || _.isNil(property.Value1)
                || _.isNil(property.Value2)) continue;
            const propertyName = property.PropertyName.toLowerCase().trim();
            const themeTabAppName = property.Value1.toLowerCase().trim();
            const isTabPageProperty = propertyName === "tabpage.hide" || propertyName === "tabpage.show";
            if (!isTabPageProperty || tabAppName !== themeTabAppName) continue;
            const tabName = property.Value2.toLowerCase().trim();
            visibilityMap[tabName] = propertyName === "tabpage.show";
        }
        return visibilityMap;
    }

    setTabVisibility(tabAppName, tabItems) {
        if (_.isNil(tabAppName) || !_.isArray(tabItems)) return;
        tabAppName = tabAppName.toLowerCase().trim();
        const visibilityMap = this.getVisibilityMap(tabAppName);
        tabItems.forEach(function (tab) {
            if (!tab.id) return;
            const tabName = tab.id.toLowerCase().trim();
            tabName.split('|').forEach(function (appName) {
                const visibility = visibilityMap[tabName] || visibilityMap[appName];
                tab.visible = _.isNil(visibility) ? tab.visible : visibility;
            });
        });
    }

    getDefaultRadioButtonInfoIcon() {
        const infoIcon = this.getThemeDetail("DefaultRadioButtonInfoIcon");
        const badInfoIcon = this.isThemeDetailEnabled("DefaultRadioButtonInfoIcon"); // Bad metadata generation -> TRUE
        return (badInfoIcon || _.isNil(infoIcon))
            ? "/App_Themes/Default/images/BB2_InfoHoverIcon.png"
            : infoIcon;
    }

    getEnforceDateFieldMaskInput() {
        const enforceFieldMask = this.getThemeDetail("EnforceDateFieldMaskInput");
        return this.isTruthy(enforceFieldMask) || null;
    }

    setEnforceDateFieldMaskInput(dxOptions) {
        const enforceFieldMask = this.getEnforceDateFieldMaskInput();
        dxOptions.useMaskBehavior = _.isNil(enforceFieldMask)
            ? _.isNil(dxOptions.useMaskBehavior)
                ? null
                : dxOptions.useMaskBehavior
            : enforceFieldMask;
    }

    setDoNotHideTooltipsOnScroll(config) {
        config.preventOnHiding = true;
        const doNotHideTooltip = this.getThemeProperty("DoNotHideTooltipsOnScroll");
        if (_.isNil(doNotHideTooltip)) return;
        config.preventOnHiding = this.isTruthy(doNotHideTooltip.Value1);
    }

    private getRestrictHiddenButAvailableFields(appName) {
        const fieldsMap = {};
        let fields = [];
        for (let i = 0; i < window.IX_Theme.properties.length; i++) {
            const property = window.IX_Theme.properties[i];
            if (_.isNil(property)
                || _.isNil(property.PropertyName)
                || _.isNil(property.Value1)
                || _.isNil(property.Value2)) continue;
            const propertyName = property.PropertyName.toLowerCase().trim();
            const themeAppName = property.Value1.toLowerCase().trim();
            if (propertyName !== "hiddenbutavailablefields.restricttosubset" || appName !== themeAppName) continue;
            fields = property.Value2.trim().split(',');
            break;
        }
        fields.forEach(function (field) {
            fieldsMap[field.toLowerCase()] = !0;
        });
        return fieldsMap;
    }

    private getAppNameFromGridProperties(gridProperties) {
        const attrs = gridProperties.elementAttr || {};
        return attrs["data-appName"] || null;
    }

    setRestrictHiddenButAvailableFields($scope) {
        if (_.isNil($scope) || _.isNil($scope.gridProperties)) return;
        const dxConfig = $scope.gridProperties;
        let appName = this.getAppNameFromGridProperties($scope.gridProperties);
        if (_.isNil(appName) || _.isNil(dxConfig) || !_.isArray(dxConfig.columns)) return;
        appName = appName.toLowerCase().trim();
        const fieldNameMap = this.getRestrictHiddenButAvailableFields(appName);
        if (_.isEmpty(fieldNameMap)) return;
        dxConfig.columns.forEach(function (column) {
            const fieldName = (column.dataField || "").toLowerCase().trim();
            const condition = (column.condition || "").toLowerCase().trim();
            if (condition === "hiddenbutavailableifenabled") {
                column.showInColumnChooser = !_.isNil(fieldNameMap[fieldName]);
            }
        });
    }

    private getLisFieldConditionOverrides(appName) {
        const fieldsMap = {};
        for (let i = 0; i < window.IX_Theme.properties.length; i++) {
            const property = window.IX_Theme.properties[i];
            if (_.isNil(property)
                || _.isNil(property.PropertyName)
                || _.isNil(property.Value1)
                || _.isNil(property.Value2)) continue;
            const propertyName = property.PropertyName.toLowerCase().trim();
            const themeAppName = property.Value1.toLowerCase().trim();
            if (propertyName !== "#list.fieldcondition" || appName !== themeAppName) continue;
            const parts: string[] = property.Value2.trim().split('|');
            if (parts.length !== 2) continue;
            let fieldName = _.first(parts).toLowerCase().trim();
            const condition = _.last(parts);
            if (_.isNil(fieldName) || _.isNil(condition)) continue;
            fieldName = fieldName.toLowerCase().trim();
            fieldsMap[fieldName] = condition;
        }
        return fieldsMap;
    }

    setListFieldConditionOverride($scope, dxColumns) {
        if (_.isNil($scope)
            || _.isNil($scope.applet)
            || _.isNil($scope.applet.name)
            || !_.isArray(dxColumns)) return;
        const appName = $scope.applet.name.toLowerCase().trim();
        const fieldConditionMap = this.getLisFieldConditionOverrides(appName);
        if (_.isEmpty(fieldConditionMap)) return;
        dxColumns.forEach(function (column) {
            const fieldName = (column.dataField || "").toLowerCase().trim();
            if (_.isNil(fieldConditionMap[fieldName])) return;
            column.condition = fieldConditionMap[fieldName];
        });
    }

    private getCanvasAppletCommandOverrides() {
        const overridesMap = {};
        for (let i = 0; i < window.IX_Theme.properties.length; i++) {
            const property = window.IX_Theme.properties[i];
            if (_.isNil(property)
                || _.isNil(property.PropertyName)
                || _.isNil(property.Value1)
                || _.isNil(property.Value2)) continue;
            const propertyName = property.PropertyName.toLowerCase().trim();
            if (propertyName !== "#canvasappletcommandoverride") continue;
            const parts: string[] = property.Value2.trim().split('|');
            if (parts.length !== 2) continue;
            const fieldName = _.first(parts);
            const linkAppName = _.last(parts);
            if (_.isNil(fieldName) || _.isNil(linkAppName)) continue;
            const themeAppName = property.Value1.toLowerCase().trim();
            overridesMap[themeAppName] = {
                fieldName: fieldName.toLowerCase().trim().replace("#application.", ""),
                linkAppName: linkAppName
            };
        }
        return overridesMap;
    }

    private overrideCanvasAppletCommands(overrideInfo, commands) {
        if (!_.isArray(commands)) return;
        commands.forEach(function (command) {
            if (_.isNil(command.cmd) || command.cmd !== 'applet') return;
            const cmdAppName = command.id.toLowerCase().trim();
            if (cmdAppName !== overrideInfo.fieldName) return;
            command.id = overrideInfo.linkAppName;
        });
    }

    // private deprecatedCanvasAppletCommandOverrides() {
    //     if (_.isNil(canvas)
    //         || _.isNil(canvas.config)
    //         || _.isNil(canvas.layout)) return;
    //     var overridesMap = this.getCanvasAppletCommandOverrides();
    //     var stepName = canvas.name.toLowerCase().trim();
    //     if (_.isEmpty(overridesMap)) return;
    //     if (_.isEmpty(overridesMap[stepName])) {
    //         console.log("Research required", overridesMap);
    //     } else {
    //         var overrideInfo = overridesMap[stepName];
    //         var layout = canvas.config.layout.toLowerCase().trim();
    //         if (layout === 'responsive') {
    //             for (var layoutSize in canvas.layout[layout]) {
    //                 var commands = canvas.layout[layout][layoutSize];
    //                 this.overrideCanvasAppletCommands(overrideInfo, commands);
    //             }
    //         } else {
    //             var commands = canvas.layout[layout];
    //             this.overrideCanvasAppletCommands(overrideInfo, canvas.layout[layout]);
    //         }
    //     }
    // }

    setCanvasAppletCommandOverrides(canvas) {
        const workflowConfig = this.workflowConfigurationService.getConfiguration();
        this.applyCanvasLayoutCommandOverrides(canvas, workflowConfig.themeOverrideCommandMap);
        //this.deprecatedCanvasAppletCommandOverrides();
    }

    private applyCanvasLayoutCommandOverrides(canvas, themeOverrideCommandMap) {
        if (_.isNil(canvas) || _.isNil(canvas.config)
            || _.isNil(canvas.config.layout)
            || _.isNil(themeOverrideCommandMap)) return;
        // Case 1: Overrides are part of the step application
        // Case 1a: Replace responsive break points
        if (canvas.config.layout === "responsive") {
            this.applyStepResponsiveOverrides(canvas, themeOverrideCommandMap);
        }
    }

    private applyStepResponsiveOverrides(canvas, themeOverrideCommandMap) {
        const layout = canvas.config.layout;
        if (layout !== "responsive"
            || _.isNil(themeOverrideCommandMap[canvas.id])) return;
        const canvasTheme = themeOverrideCommandMap[canvas.id].canvas;
        if (_.isNil(canvasTheme)
            || _.isNil(canvasTheme.config)
            || canvas.config.layout !== canvasTheme.config.layout
            || _.isNil(canvas.layout[layout])
            || _.isNil(canvasTheme.layout[layout])) return;
        const workflowName = canvas.parentName;
        const themeName = canvasTheme.parentName;
        for (const layoutSize in canvasTheme.layout[layout]) {
            if (_.isNil(canvas.layout[layout][layoutSize])) continue;
            canvas.layout[layout][layoutSize] = canvasTheme.layout[layout][layoutSize];
            this.updateCanvasCommandClass(canvas.layout[layout][layoutSize], workflowName, themeName);
        }
    }

    private updateCanvasCommandClass(commands, workflowName, themeName) {
        if (!_.isArray(commands) || _.isNil(workflowName) || _.isNil(themeName)) return;
        workflowName = workflowName.replace(/\./g, "-");
        commands.forEach(function (command) {
            if (_.isNil(command.class)) return;
            command.class = command.class.replace(themeName, workflowName);
        });
    }

    setGridLoadPanelFromTheme($scope, textName) {
        const themeProperty = this.getThemePropertyIfMatching("GridLoadingPanelText", textName);
        if (themeProperty.Value2) {
            $scope.gridProperties.loadPanel.text = themeProperty.Value2;
        }
    }

    showSortingIndexesFromTheme($scope) {
        if (_.isNil($scope.gridProperties)
            || _.isNil($scope.gridProperties.sorting)
            || _.isNil($scope.gridProperties.sorting.mode)
            || $scope.gridProperties.sorting.mode == "none") return;
        const showSortIndexes = this.isThemePropertyEnabled("ShowSortingIndexes");
        $scope.gridProperties.sorting.showSortIndexes = showSortIndexes;
    }

    setAppLoadingShimmerFromTheme($scope, textName) {
        const themeProperty = this.getThemePropertyIfMatching("AppLoadingShimmer", textName);
        if (themeProperty.Value2) {
            // TODO: use DomSanitizer
            $scope.shimmerHtml = themeProperty.Value2;
        }
    }

    setBodyCssClass() {
        const themeName = 'IX_' + (IX_Theme.themeName.replace(/\s/g, ""));
        const customClasses = (this.getThemePropertySafe("BodyCssClass").Value1 || '').split(" ");
        const body = document.getElementsByTagName('body')[0];
        // IE11 supported classList methods
        body.classList.add(themeName);
        customClasses.forEach((className) => {
            if (_.isNil(className) || className === "") return;
            body.classList.add(className);
        });
    }

    getCustomAlertInfo() {

        const nameThemeProperty = this.getThemeProperty("CustomAlertApp.Name");
        const widthThemeProperty = this.getThemeProperty("CustomAlertApp.Width");
        const heightThemeProperty = this.getThemeProperty("CustomAlertApp.Height");
        const titleThemeProperty = this.getThemeProperty("CustomAlertApp.Title");
        const classThemeProperty = this.getThemeProperty("CustomAlertApp.Class");

        if (_.isNil(nameThemeProperty) || _.isEmpty(nameThemeProperty.Value1))
            return null;

        return {
            appName: nameThemeProperty.Value1,
            width: widthThemeProperty ? widthThemeProperty.Value1 : "500px",
            height: heightThemeProperty ? heightThemeProperty.Value1 : "400px",
            title: titleThemeProperty ? titleThemeProperty.Value1 : "Alert",
            class: classThemeProperty ? classThemeProperty.Value1 : ""
        };
    }

    getCustomSessionTimeOutAlertInfo() {
        const propPrefix = "CustomSessionTimeOutApp.";
        // Gets properties from the current theme properties.
        const nameThemeProperty = this.getThemeProperty(propPrefix + "Name");

        // When null is returned no custom session time out alert is displayed. See, IX_enhanceAlert.
        if (nameThemeProperty == null || _.isEmpty(nameThemeProperty.Value1))
            return null;

        const widthThemeProperty = this.getThemeProperty(propPrefix + "Width");
        const heightThemeProperty = this.getThemeProperty(propPrefix + "Height");
        const classThemeProperty = this.getThemeProperty(propPrefix + "Class");
        return {
            appName: nameThemeProperty.Value1,
            width: widthThemeProperty ? widthThemeProperty.Value1 : "500px",
            height: heightThemeProperty ? heightThemeProperty.Value1 : "400px",
            class: classThemeProperty ? classThemeProperty.Value1 + " IX_enhanceTimeoutWindow" : "IX_enhanceTimeoutWindow",
            showTitle: false
        };
    }

    addWalkMeScripts() {
        const walkMeScript = this.getThemeProperty("WalkMeScript");
        if (_.isNil(walkMeScript)) return;
        const scriptCode = walkMeScript.Value1;
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.text = scriptCode;
        $('body').append(script);
    }
}
