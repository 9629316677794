/* eslint-disable @typescript-eslint/camelcase */
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import numeral from 'numeral';
import Sugar from 'sugar/date';
import { AppsFacade } from '../state/apps.facade';
import { AccessibilityService } from './accessibility.service';
import { ApplicationInformation } from './application-information.service';
import { DynamicReplacementService } from './dynamic-replacement.service';
import { StoreService } from './store-service';
import UtilityFunctions from '../utility.functions';
import { ApplicationRefFacade } from './application-ref.facade';
import { CacheManagerService } from './cachemanager.service';
import { ConditionalFormattingService } from './conditional-formatting.service';
import { TranslateFacadeService } from './translate-facade.service';
import { UtilListService } from './util-list.service';
import { UtilService } from './util.service';
import { AlertService } from './alert.service';
import { DataService } from './data.service';
import { AppEvent } from '../state/app-events.enum';
import { AppsConstantsFacade } from './apps-constants.facade';
import dxDataGrid from 'devextreme/ui/data_grid';
import { ListApplication } from '../components/list-application';
import { AppsEntity } from '../state/apps.models';

@Injectable()
export class HelpersService {

  $cacheManager: any;

  constructor(private storeService: StoreService,
    private applicationRefFacade: ApplicationRefFacade,
    private dynamicReplacementService: DynamicReplacementService,
    private applicationInformation: ApplicationInformation,
    private translateFacadeService: TranslateFacadeService,
    private utilService: UtilService,
    private accessibilityService: AccessibilityService,
    private conditionalFormattingService: ConditionalFormattingService,
    private cacheManagerService: CacheManagerService,
    private alertService: AlertService,
    private dataService: DataService,
    private appsConstantsFacade: AppsConstantsFacade
  ) { }

  jsonParse(jsonStr) {
    if (typeof jsonStr === 'undefined' || (jsonStr != null && jsonStr.toString().trim() === '')) return jsonStr;
    if (typeof jsonStr === 'object') return jsonStr;
    if (!jsonStr.isJSON()) return jsonStr;
    const map = JSON.parse(jsonStr);
    if (map.targetFieldsToClear === null) {
      map.targetFieldsToClear = [];
    }
    return map;
  }

  isFieldMap(fieldMap) {
    const properties = ['sourceApp', 'targetApp', 'sourceFields', 'targetFields', 'directive'];
    // eslint-disable-next-line no-prototype-builtins
    return properties.some((property) => fieldMap.hasOwnProperty(property));
  }

  getFieldMapBase(map, appName?) {
    if (typeof map === 'string' && map.trim().length > 0) {
      const aux = {
        sourceApp: appName,
        sourceFields: [],
        targetFields: [],
        targetFieldsToClear: [],
        targetApp: '',
      };
      let tmp = map.split(',');
      aux.targetApp = tmp[0];

      if (typeof tmp[1] === 'undefined') return aux;

      const flds = tmp[1].split('|');
      for (let f = 0; f < flds.length; f++) {
        tmp = flds[f].split('=');
        aux.sourceFields.push(tmp[1]);
        aux.targetFields.push(tmp[0]);
      }
      map = aux;
    }

    if (typeof map.sourceApp === 'undefined' || !map.sourceApp) map.sourceApp = appName;

    return map;
  }

  getComponentName(appName: string): string {
    return "CL" + appName.replace(/\./g, '').replace(/_/g, '');
  }

  getThemeProperty(name, _ixTheme) {
    return (
      _ixTheme &&
      _ixTheme.properties &&
      _.find(_ixTheme.properties, function (item) {
        return item.PropertyName === name;
      })
    );
  }

  private _getThemePropertyCache(prop) {
    this._setCacheManager();
    const key = '{ThemeProperty:' + prop + '}';
    if (this.$cacheManager.has(key)) return this.$cacheManager.get(key);

    const res = this.dynamicReplacementService.getThemePropertyReplacement(prop);
    return this.cacheManagerService.set(key, res.toString().toLowerCase() === 'true');
  }

  private _setCacheManager() {
    if (this.$cacheManager == null) {
      this.$cacheManager = this.cacheManagerService;
    }
  }

  shouldWaitForApplyFilterData(parameters) {
    if (parameters.length > 2 && !_.isNil(parameters[2])) return parameters[2].toString().toLowerCase() === 'yes';
    return false;
  }

  getScreenContextFields(s) {
    if (!this.applicationInformation.isListApp(s.applet.name)) return null;

    const fieldMap = {};
    for (const f in s.model) {
      if (s.model[f].asScreenContext) {
        fieldMap[f] = {};
      }
    }
    return _.isEmpty(fieldMap) ? null : fieldMap;
  }

  updateScreenContextFields(row, fields) {
    for (const f in fields) {
      fields[f] = { value: row[f], valid: !_.isNil(row[f]) };
    }
  }

  setLocales(locale) {
    locale = escape(locale);

    //fix for duplicate cookie being set with subdomain name and "." + subdomain name on chrome in mac
    //first is set on .net side and second is from here
    if (!(document.cookie.indexOf('IXCulture=') > -1 && locale === IX_GetCookieValue('IXCulture'))) {
      IX_SetCookieValue('IXCulture', locale, 'Session');
    }

    this._setNumeralLocale(locale);
    try {
      // Get language code without full culture and dynamically import locales as needed
      const langCode = locale.substring(0, 2);
      if (langCode == 'en') Sugar.Date.setLocale(locale); // Sugar lib does not have en locale
      else import(`sugar/locales/${langCode}`).then(Sugar.Date.setLocale(locale));
      import(`devextreme/dist/js/localization/dx.messages.${langCode}.js`).then(DevExpress.localization.locale(langCode));
      // eslint-disable-next-line no-empty
    } catch (e) { }
    document.documentElement.setAttribute('lang', locale);
  }

  private _setNumeralLocale(newLocale) {
    const normalizeLocale = this._getNormalizeNumeralLocale(newLocale);
    numeral.locale(normalizeLocale);
  }

  private _getNormalizeNumeralLocale(newLocale) {
    newLocale = newLocale.toLowerCase();
    if (_.isNil(numeral.locales[newLocale])) {
      newLocale = newLocale.split('-')[0];
      if (_.isNil(numeral.locales[newLocale])) {
        IX_Log('component', 'Locale is not supported by numeral', newLocale);
      }
    }
    return newLocale;
  }

  getParamValueFromQueryStringHash(paramName, url) {
    if (url == null) url = window.location.href;

    const parts = url.split('#');
    if (parts.length < 2) return null;

    let lastPart = parts[parts.length - 1];
    if (lastPart.indexOf('?') >= 0) {
      lastPart = lastPart.substring(lastPart.indexOf('?') + 1);
    }

    const params = lastPart.split('&');

    for (let i = 0; i < params.length; i++) {
      const currentParam = params[i];
      if (currentParam.length === 0) continue;

      const currentParamParts = currentParam.split('=');
      const currentParamName = currentParamParts[0];
      let currentParamValue = currentParamParts[1];

      currentParamValue = currentParamValue == null ? '' : currentParamValue;

      if (currentParamName === paramName) {
        return currentParamValue;
      }
    }
  }

  interceptRedirects(initialURL, chartData, idField, onItemClick, deferredPromise) {
    let urlCollection = '';
    const absolutePath = function (href) {
      const link = document.createElement('a');
      link.href = href;
      return link.href;
    };

    window.IX_interceptRedirect = function (url) {
      let absUrl;

      if (url.indexOf('.ashx') >= 0 || url.indexOf('.aspx') >= 0) absUrl = absolutePath(url);
      else absUrl = initialURL.substr(0, initialURL.indexOf('.aspx') + 5) + '#!' + url;

      urlCollection += absUrl + '|';
      return true;
    };

    const chartDataLength = chartData.length;
    let currentElement = 0;

    const callNextElement = function () {
      if (currentElement >= chartDataLength) {
        window.IX_interceptRedirect = void 0;
        const k = {
          isValid: true,
          message: '',
          urlCollection: urlCollection,
        };

        deferredPromise.resolve(k);

        return;
      }

      const r = chartData[currentElement][idField];
      currentElement++;
      onItemClick(r).then(callNextElement);
    };

    callNextElement();
  }

  isUrl(str: string): boolean {
    return RegExp('((http[s]?|ftp)://):?').test(str);
  }


  // TODO Move download events to better home
  publishOnDocumentDownloadEvent(url: string, documentName: string): AppsEntity {
    const baseUrl = window.location.origin; // Return the protocol, hostname and port number of a URL

    // Put the filename at the end of the URL, as needed for some mobile apps
    const fileComponent = documentName ? (url.includes('?') ? '&' : '?') + 'ExportFileName=' + encodeURIComponent(documentName) : '';
    const targeturl = (this.isUrl(url) ? url : (baseUrl + url)) + fileComponent;
    const eventState = {
      type: "downloadDocument",
			targeturl,
			documentName,
    };
    this.appsConstantsFacade.publishAppEvent(AppEvent.DocumentDownload, eventState);

    return {
        id: AppEvent.DocumentDownload,
        state: eventState
    };
  }



  publishOnBase64DownloadEvent(data: string, documentName: string): void {
		const eventData = {
			type: "downloadBase64Document",
			content: btoa(data),
			filesize: new Blob([data]).size, // Number of bytes, may be larger than number of characters
			documentName: documentName
		};
    this.appsConstantsFacade.publishAppEvent(AppEvent.Base64Download, eventData);
	};

  transformElement(jQElement, newTag) {
    // If the tags match then there is no need to transform it.
    if (jQElement[0].tagName === $(newTag)[0].tagName) {
      return;
    }

    const attrs = {};

    $.each(jQElement[0].attributes, function (index, attr) {
      attrs[attr.nodeName] = attr.nodeValue;
    });

    $(jQElement).replaceWith(function () {
      return $(newTag, attrs).append($(this).contents());
    });
  }

  setUpLocationChangeWatch($rootScope, $scope, warningText) {
  }


  refreshValidationGroupButtons(events, validationGroupName, isValid?) {
    throw new Error('Use subscribeToRefreshValidationGroupButtons on base-application');
  }

  makeSuperscriptAdaCompliant(cntnt, supStartInd, supEndInd) {
    return cntnt;
  }

  getTranslation(translateId) {
    if (_.isNil(translateId) || translateId === '') return '';
    return this.translateFacadeService.instant(translateId);
  }

  shouldEnableMobileColumnSettings() {
    return this._getThemePropertyCache('EnableMobileColumnSettings');
  }


  shouldIncludeAccruedInterestCashvalue() {
    const setting = this._getTenantSettingCache('CASHVALUE_INCLUDEACCRUEDINTEREST');
    return setting != null && this.isTruthy(setting);
  }

  private _getTenantSettingCache(setting) {
    this._setCacheManager();
    const key = '{Globals:TenantSetting:' + setting + '}';
    if (this.$cacheManager.has(key)) return this.$cacheManager.get(key);
    return null;
  }

  isTruthy(value) {
    value = value.toString().toLowerCase().trim();
    return _.includes(['true', 'yes', 'y'], value);
  }

  getMessageText(translateId: string): string {
    return this.translateFacadeService.getTranslation(translateId);
  }

  showMessage(s, message, callbackFunction?) {
    const aux = this.getTranslation(message);

    return new Promise((resolve, reject) => {
      const waitForUser = () => {
        if (typeof callbackFunction === 'function') {
          callbackFunction();
        }
        resolve(true);
      };

      if (this.shouldWaitForTranslationBeforeShowModal() && aux === message) {
        this.utilService.getTranslationWhenReady(message).then((_aux) => {
          if (aux.length) {
            this.alertService.enhanceAlert(this.getMessageText(_aux), false, waitForUser);
          } else {
            reject(false);
          }
        });
      } else {
        if (aux.length) {
          this.alertService.enhanceAlert(this.getMessageText(aux), false, waitForUser);
        } else {
          reject(false);
        }
      }
    });
  }

  shouldWaitForTranslationBeforeShowModal() {
    return this._getThemePropertyCache('WaitForTranslationBeforeShowModal');
  }

  shouldUseErrorListComponent() {
    return this.isTruthy(this._getThemePropertyCache('UseErrorListComponent') && $('.ic-error-list')[0] !== 'undefined');
  }


  // TODO : FIXME: Refactor not to scan through whole body
  whenAddedToDom(selector, target?) {
    return new Promise(function (resolve, reject) {
      target = target || document.body;

      // config object
      const config = {
        attributes: false,
        attributeOldValue: false,
        characterData: false,
        characterDataOldValue: false,
        childList: true,
        subtree: true,
      };

      // instantiating observer
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const observer = new MutationObserver(subscriber);

      function returnElement(element) {
        // stop observing
        observer.disconnect();
        // empties observer instance and returns records
        observer.takeRecords(); // -> MutationRecords
        resolve(element);
      }

      // subscriber function
      function subscriber(mutations) {
        mutations.forEach(function (mutation) {
          for (let i = 0; i < mutation.addedNodes.length; i++) {
            const $mutation = $(mutation.addedNodes[i]);
            if (!_.isString(selector) && selector.has($mutation).length > 0) {
              return returnElement(mutation);
            }
            if ($mutation.is(selector) || $mutation.has(selector).length > 0) {
              return returnElement(mutation);
            }
            const $childloop = $mutation.find(selector);
            if ($childloop.length > 0) {
              return returnElement($childloop.get(0));
            }
          }
          const $child = $(mutation.target).find(selector);
          if ($child.length > 0) {
            return returnElement($child.get(0));
          }
        });
      }

      // observing target
      observer.observe(target, config);
    });
  }

  private getGridInstance(listApp: string): any {
    return this.applicationRefFacade.getListDxDataGrid(listApp);
  }

  setOnContentReady(props, $scope: ListApplication, e, appsFacade: AppsFacade, utilListService: UtilListService) {

    if (props.childAppToClearGridState) {
      if (e.component.getCombinedFilter() || e.model.isFilterDirty) {
        e.model.isFilterDirty = true;

        appsFacade.resetAppState(props.childAppToClearGridState, false);

        const childGridIns = this.getGridInstance(props.childAppToClearGridState);
        if (childGridIns) {
          childGridIns.refresh();
        }
      }
    }

    const isGrouped = !!e.component.columnOption("groupIndex:0");
    if ($scope.gridProperties.showExpandCollapseBtnOnHeaderPanel && $scope.gridProperties.showExpandCollapseBtnOnHeaderPanel) {
      if (isGrouped) {
        $(e.element).find('.dx-header-row .dx-command-expand').first().html('<span class="fas fa-caret-up collapseAllBtn" style="font-size: 25px; font-family: FontAwesome;"></span><br>');
        $('.collapseAllBtn').click(function () { e.component.collapseAll(); });

        $(e.element).find('.dx-header-row .dx-command-expand').first().append('<span class="fas fa-caret-down expandAllBtn" style="font-size: 25px;font-family: FontAwesome;"></span>');
        $('.expandAllBtn').click(function () { e.component.expandAll(); });
      }
    } else {
      if (isGrouped) {
        $(e.element).find("[title=ExpandAll]").show();
        $(e.element).find("[title=CollapseAll]").show();
      } else {
        $(e.element).find("[title=ExpandAll]").hide();
        $(e.element).find("[title=CollapseAll]").hide();
      }
    }

    const dataGrid = e.component;
    let info;

    if (dataGrid.setCustomSummaryTotalText != null)
      dataGrid.setCustomSummaryTotalText();

    if (!_.isUndefined(props.selectionColumnPosition)) {
      dataGrid.columnOption('command:select', 'visibleIndex', props.selectionColumnPosition);
    }

    dataGrid.columnOption('command:expand', 'width', props.expandCollapseColumnWidth);

    if (!_.isUndefined($scope.showGridColorsByPercentageColumn) && !_.isUndefined($scope.gridColors)) {
      this.setDataGridColors(e, $scope);
    }

    if (!props.focusedRowOpt && !props.autoSelectOnContentReady && !$scope.gridProperties.itemIsSelectedByUser) {
      $scope.gridProperties.defaultFirstRowSelection = true;
      info = this.getListFirstRow(dataGrid);
      if (!$.isEmptyObject(info)) $scope.gridProperties.onCellClick(info);
      $scope.gridProperties.defaultFirstRowSelection = false;
    }

    if ($scope.gridProperties.hideGridOnColumnsNoData && dataGrid.option("dataSource")) {
      const isColumnDataNull = this.isColumnDataNull($scope, dataGrid);
      const canvasAppName = this.utilService.getAppCanvasReference($scope.applet.name);
      if (canvasAppName) {
        if (isColumnDataNull)
          $(canvasAppName).hide();
        else
          $(canvasAppName).show();
      }
    }

    const firstPresentation = e.element.find("[role='presentation']:first");
    if (firstPresentation.length > 0) {
      const rowCount = e.component.getVisibleRows().length;
      const colCount = e.component.getVisibleColumns().length;

      firstPresentation.attr("aria-rowcount", rowCount);
      firstPresentation.attr("aria-colcount", colCount);
      firstPresentation.attr("role", "grid");
    }

    const groupCellItems = e.element.find(".dx-group-row .dx-command-expand + td");
    if (groupCellItems.each != null) {
      groupCellItems.each(function () {
        const current = $(this);
        const groupDescription = current.text();
        if (_.isEmpty(groupDescription))
          return;

        const expandButton = current.parent().find(".dx-command-expand:not(:empty)");
        const expandButtonAriaLabel = expandButton.attr("aria-label");

        expandButton.attr("aria-label", expandButtonAriaLabel + " " + groupDescription);
      });
    }

    if ($scope.disableRowSelection != null) {
      utilListService.disableRowSelection($scope, e);
    }
    if (this.accessibilityService != null && _.get($scope, "gridProperties.filterRow.visible", false)) {
      this.accessibilityService.addAccessibilityToDxDataGridRowHeader(e);
    }

    this.checkRowFocusAfterContentReady(e);
    this.accessibilityService.addAccessibilityToDxDataGridOnContentReady(e);

    // The below line makes grid rendering slow and impacts performance.
    // setTimeout(() => dataGrid.resize(), 50);

    try {
      this.bindConditionalFormattingForDxDataGrid($scope, e);
    }
    catch (ex) {
      throw new Error("There was a problem binding conditional formats to a DxDataGrid row." + ex);
    }
  }

  getListFirstRow(dataGrid: dxDataGrid): Record<string, unknown> {
    let i = 0;
    let rowSelected = false;
    let info = {};
    const dataSource = dataGrid.getDataSource();
    const totalCount = (dataSource) ? dataSource.totalCount() : 1000;
    let rowElement;

    do {
      rowElement = dataGrid.getRowElement(i);
      rowElement = $(rowElement);

      if (_.isNil(rowElement) || rowElement.length < 1)
        break;

      if (rowElement.hasClass("dx-data-row") || rowElement.hasClass("dx-group-row")) {
        dataGrid.selectRowsByIndexes([i]);
        rowSelected = true;
        break;
      }

      i++;
    } while (i < totalCount);

    if (rowSelected) {
      const selectedData = dataSource.items();

      if (selectedData.length > 0) {
        let rowData;
        if (selectedData[0].items && selectedData[0].items.length > 0) {
          rowData = selectedData[0].items[0];
        } else if (selectedData[0].collapsedItems && selectedData[0].collapsedItems.length > 0) {
          rowData = this.search(selectedData[0].collapsedItems[0]);
        } else {
          rowData = selectedData[0];
        }
        info = {
          data: rowData,
          rowType: 'data'
        };
      }
    }
    return info;
  };

  private search(data) {
    if (data.items != null && typeof data.items !== 'undefined') {
      return this.search(data.items[0]);
    } else {
      return data;
    }
  };

  bindConditionalFormattingForDxDataGrid($scope, e) {
    if ($scope.conditionalFormats == null)
      return;

    const rows = e.element.find("tr.dx-data-row");
    for (let i = 0; i < rows.length; i++) {
      const currentRow = $(rows[i]);

      this.bindConditionalFormattingForDxDataGridRow(currentRow, $scope);
    }
  }

  bindConditionalFormattingForDxDataGridRow(row, $scope: ListApplication) {
    const cells = row.find("td");
    const formattedElements = []
    let formatter = row.data("formatter");
    formatter?.unbind();

    for (let i = 0; i < cells.length; i++) {
      const currentCell = $(cells[i]);
      const fieldName = currentCell.data("field");
      if (fieldName == null)
        continue;

      formattedElements.push({
        fieldName: fieldName,
        selector: currentCell.find(":first-child")
      });
    }

    if (formattedElements.length === 0)
      return;
    const formattingContext = {
      formattedElements: formattedElements,
      rules: $scope.conditionalFormats,
      getAppState: () => this.getAppState($scope.applet.name)
    };

    formatter = this.conditionalFormattingService.bind(formattingContext);
    formatter.run();
    row.data("formatter", formatter);
  }

  private getAppState(appName: string): Promise<any> {
    return this.storeService
      .getCurrentAppState(appName)
      .pipe(first())
      .toPromise();
  }

  checkRowFocusAfterContentReady(e) {
    if (e == null || e.component == null || e.component.option == null)
      return;

    if (e.component.option("setRowFocusIndex") != null) {
      const setRowFocusIndex = e.component.option("setRowFocusIndex");
      const firstRowElement = e.element.find(".dx-data-row")[setRowFocusIndex];

      setTimeout(function () {
        $(firstRowElement).find("[tabindex]").first().focus();
      }, 0);

      e.component.option("setRowFocusIndex", null);
    }
  }

  isColumnDataNull($scope, dataGrid) {
    const fieldNames = $scope.gridProperties.hideGridOnColumnsNoData.split(',');
    const dataRows = dataGrid.option("dataSource").items();

    let isColumnsDataNull = true;
    for (let i = 0; i < dataRows.length; i++) {
      const item = dataRows[i];
      if (_.isNil(item)) continue;
      for (let j = 0; j < fieldNames.length; j++) {
        const fieldName = fieldNames[j];
        if (item[fieldName] != null) {
          isColumnsDataNull = false;
          break;
        }
      }

      if (!isColumnsDataNull)
        break;
    }

    return isColumnsDataNull;
  };

  setDataGridColors(e, scope) {
    const rows = e.component.getVisibleRows();
    if (rows.length <= 0) return;

    let newBgColor;
    let colorIndex = -1;
    const gridElement = e.element[0];
    let lightenColorValue = 0;
    let nonGroupLightenColorValue;
    const remainingColorForGridRows = scope.grdColrByPcntRemainingColor && scope.grdColrByPcntRemainingColor !== "" ? scope.grdColrByPcntRemainingColor : undefined;
    const existingTableBgColor = remainingColorForGridRows ? remainingColorForGridRows : (gridElement.style.backgroundColor !== "" ? gridElement.style.backgroundColor : 'white');
    const groupColor = scope.gridColors;
    if (groupColor.length <= 0) return;

    _.forEach(rows, function (row) {
      let stringBuilder;
      const visibleColumns = e.component.getVisibleColumns();
      const gridWidth = e.element.width();
      let backgroundLengthInPixels,
        tableCellWidth,
        remainingCellBackgroundPercent,
        rowCovered = false,
        totalCellsWidthCovered = 0;
      if (row.rowType === 'group') {
        if (row.groupIndex === 0) {
          if (colorIndex >= groupColor.length - 1) {
            colorIndex = -1; // Reset index
            lightenColorValue = 0;
          }
          newBgColor = groupColor[++colorIndex];
        } else {
          lightenColorValue = lightenColorValue + scope.lightenGridColorByPercentage;
          newBgColor = UtilityFunctions.lightenColorByPercentage(groupColor[colorIndex], lightenColorValue);
        }

        _.forEach(row.summaryCells, function (summaryColumns) {
          _.forEach(summaryColumns, function (columns) {
            if (columns.column === scope.showGridColorsByPercentageColumn) {
              e.element.find('.dx-group-row').css('background', 'transparent');
              if (scope.showGridColorsByPercentageConvertToNumber) {
                backgroundLengthInPixels = parseFloat(columns.value) * gridWidth;
              } else {
                backgroundLengthInPixels = (columns.value / 100) * gridWidth;
              };
            }
          });
        });
      } else {
        if (row.dataIndex === 0) {
          nonGroupLightenColorValue = lightenColorValue + scope.lightenGridColorByPercentage;
          lightenColorValue = 0; // Reset Color Value
        }

        colorIndex = colorIndex === -1 ? 0 : colorIndex;  // When data is presented without groups
        newBgColor = UtilityFunctions.lightenColorByPercentage(groupColor[colorIndex], nonGroupLightenColorValue);

        if (scope.showGridColorsByPercentageConvertToNumber) {
          backgroundLengthInPixels = parseFloat(row.data[scope.showGridColorsByPercentageColumn]) * gridWidth;
        } else {
          backgroundLengthInPixels = (row.data[scope.showGridColorsByPercentageColumn] / 100) * gridWidth;
        };
      };

      //in order to apply background color to the row based on the percentage calculated
      //need to go thru each 'td' and apply background (applying backgroundGradient in the entire row does not work for safari table rows)

      const rowElement = e.component.getRowElement(row.rowIndex);

      _.forEach(visibleColumns, function (col, ind) {
        if (!rowCovered) {
          tableCellWidth = rowElement.find("td").eq(ind)[0].clientWidth;
          totalCellsWidthCovered = totalCellsWidthCovered + tableCellWidth;

          if (totalCellsWidthCovered <= backgroundLengthInPixels) {
            stringBuilder = [newBgColor, '100%', ',', existingTableBgColor, '0%', ')'].join(' ');
            rowElement.find("td").eq(ind).css('background', '-webkit-linear-gradient(left,' + stringBuilder);
          } else {
            remainingCellBackgroundPercent = ((backgroundLengthInPixels - (totalCellsWidthCovered - tableCellWidth)) / tableCellWidth) * 100;
            stringBuilder = [newBgColor, remainingCellBackgroundPercent + '%', ',', existingTableBgColor, '0%', ')'].join(' ');
            rowElement.find("td").eq(ind).css('background', '-webkit-linear-gradient(left,' + stringBuilder);
            rowCovered = true;
          }
          rowElement.css('background', '-moz-linear-gradient(left,' + stringBuilder);
          rowElement.css('background', '-ms-linear-gradient(left,' + stringBuilder);
          rowElement.css('background', 'linear-gradient(left,' + stringBuilder);
        }
      })

      _.forEach(row.cells, function (cell) {
        const exisintgStyle = cell.cellElement.attr('style'); // to not lose existing style and append new one
        const addStyle = "background-color: transparent !important"; // new style to be added
        stringBuilder = [exisintgStyle, addStyle].join('');
        cell.cellElement.css('cssText', stringBuilder);
      });
    });
  };

  setDirtyFields(appName, fieldName, value) {
    let isFieldDirty = false;
    if (value !== null) {
      isFieldDirty = this.dataService.isAppFieldDirty(appName, fieldName, value);
    }
    this.dataService.setAppDirtyField(appName, fieldName, isFieldDirty);
  }

  setAppStateDirty(appName) {
    this.dataService.setAppStateDirty(appName);
  }

}
