import { Injectable, Injector, ComponentFactoryResolver, ApplicationRef } from '@angular/core';
import { ModalGenericComponent } from './../components/modal-generic/modal-generic.component';
import { ModalConfirmComponent } from './../components/modal-confirm/modal-confirm.component';
import { ModalEmailViewerComponent } from './../components/modal-email-viewer/modal-email-viewer.component';
import { ModalIncomingCallComponent } from './../components/modal-incoming-call/modal-incoming-call.component';
import { Subscription } from 'rxjs';
import { UtilService } from './util.service';
import { HelpersService } from './helpers.service';
import { DeviceService } from './device-information.service';

@Injectable()
export class ModalService {


  constructor(private injector: Injector,
     private componentFactory: ComponentFactoryResolver, 
     private applicationRef: ApplicationRef,
     private utilService: UtilService,
     private helpersService: HelpersService,
     private deviceService: DeviceService) {}

  _openInstances = [];

  // Classes the signify the outermost element of a modal, which will be a direct child of <body>
  _modalClasses = ['.dx-popup', '.dx-popup-wrapper', '.IX_enhanceAlertWindow'];

  confirm(options: ModalConfirmOptions): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const $lastFocused = $(document.activeElement);

      const onHiding = (e) => {
        this.registerModalClose(e.element, $lastFocused);
      };

      const onShown = (e) => {
        this.registerModalOpen(e.element, e.component);
      };

      const factory = this.componentFactory.resolveComponentFactory(ModalConfirmComponent);
      const componentRef = factory.create(this.injector);

      componentRef.instance.options = options;
      componentRef.instance.onHiding = onHiding;
      componentRef.instance.onShown = onShown;

      const yesSub: Subscription = componentRef.instance.yes.subscribe(() => resolve(true));
      const noSub: Subscription = componentRef.instance.no.subscribe(() => resolve(false));
      componentRef.onDestroy(() => {
        yesSub.unsubscribe();
        noSub.unsubscribe();
      });

      this.applicationRef.attachView(componentRef.hostView);
      // TODO when should we detatch?
    });
  }

  incomingCall(options) {
    return new Promise((resolve, reject) => {
      const $lastFocused = $(document.activeElement);

      const acceptCall = () => {
        resolve(true);
        close();
      };

      const declineCall = () => {
        resolve(false);
        close();
      };

      const close = () => {
        this.applicationRef.detachView(componentRef.hostView);
        $lastFocused.focus();
      };

      const onHiding = () => {
        resolve(false);
      };

      const factory = this.componentFactory.resolveComponentFactory(ModalIncomingCallComponent);
      const componentRef = factory.create(this.injector);

      componentRef.instance.options = options;
      componentRef.instance.acceptCall = acceptCall;
      componentRef.instance.declineCall = declineCall;
      componentRef.instance.onHiding = onHiding;

      this.applicationRef.attachView(componentRef.hostView);
    });
  }

  generic(options: ModalGenericOptions): Promise<any> {
    return new Promise((resolve, reject) => {
      const actionTypes = ['onPrimary', 'onSecondary', 'onTertiary'];
      const actionFuncs = {};

      const onHiding = (e) => {
        resolve(false);
        $lastFocused.focus();
        this.registerModalClose(e.element, $lastFocused);
      };

      const onShown = (e) => {
        this.registerModalOpen(e.element, e.component);
      };

      const factory = this.componentFactory.resolveComponentFactory(ModalGenericComponent);
      const componentRef = factory.create(this.injector);

      componentRef.instance.options = options;
      componentRef.instance.onPrimary = actionFuncs['onPrimary'];
      componentRef.instance.onPrimary = actionFuncs['onSecondary'];
      componentRef.instance.onPrimary = actionFuncs['onTertiary'];
      componentRef.instance.onHiding = onHiding;
      //componentRef.instance.onShown = onShown; // This was not called but present on component when porting

      const $lastFocused = $(document.activeElement);

      if (options.actions) {
        actionTypes.map((at) => {
          if (options.actions[at]) {
            // Only if the primary/secondary/tertiary actions have been defined.
            actionFuncs[at] = (e) => {
              handleAction(at, e);
            };
          }
        });
      }

      const handleAction = (actionName, args) => {
        const clickHandler = options.actions[actionName];
        const action = clickHandler.func;
        if (typeof action === 'function') {
          action(args);
        }
        resolve(clickHandler.resolve);
        closeAction();
      };

      const closeAction = () => {
        //popupElement.remove();
        this.applicationRef.detachView(componentRef.hostView);
        componentRef.destroy();
        $lastFocused.focus();
      };

      this.applicationRef.attachView(componentRef.hostView);
    });
  }

  emailViewer(options) {
    return new Promise((resolve, reject) => {
      const $lastFocused = $(document.activeElement);

      const done = () => {
        resolve(true);
        this.applicationRef.detachView(componentRef.hostView);
        $lastFocused.focus();
      };

      const onHiding = () => resolve(false);

      const factory = this.componentFactory.resolveComponentFactory(ModalEmailViewerComponent);
      const componentRef = factory.create(this.injector);

      componentRef.instance.options = options;
      componentRef.instance.done = done;
      componentRef.instance.onHiding = onHiding;

      this.applicationRef.attachView(componentRef.hostView);
    });
  }

  registerModalOpen(element, dxPopupComponent?) {
    this._openInstances.push(element);

    if (this._openInstances.length === 1) {
      this.stopBodyScrolling();
    }

    this._hidePageFromScreenreader();

    IX_OnShownModalDialogSetUpADA(element, dxPopupComponent);
  }

  registerModalClose(element, modalTriggeringElement) {
    _.remove(this._openInstances, element);

    if (!this._openInstances.length) {
      this._resumeBodyScrolling();
      this._unhidePageFromScreenreader();
    }

    IX_OnHiddenModalDialogSetUpADA(modalTriggeringElement);
  }

  /**
   * For implementation of DevExtreme.dxPopup as modal.
   * Under the hood, DevExtreme uses dxToolbar to contain the close button.
   * DevExtreme gives us a <div> with a button role. iOS VoiceOver prefers a <button> or <a> element.
   * We must re-add any event handlers; click handler is provided for here.
   * @param {jQuery} dxPopupComponent DevExtreme dxPopup.component
   * @param {Function} clickCallback
   * @param {string} newName
   */
  toolbarCloseButtonADAFixup(dxPopupComponent, clickCallback, newName?: string) {
    const $content = $(dxPopupComponent.content());
    const existingDiv = $content.parent().find('.dx-closebutton');
    this.helpersService.transformElement(existingDiv, '<button></button>');
    const newButton = $content.parent().find('.dx-closebutton');
    newButton.css('background', 'none').css('width', 'inherit').css('height', 'inherit').css('margin', 'inherit').click(clickCallback);

    if (newName) {
      newButton.attr('name', newName);
    }

    // add custom aria-label
    let closeAriaLabel = dxPopupComponent.option('closeAriaLabel');
    if (!!closeAriaLabel && closeAriaLabel.length > 0) {
      const appName = dxPopupComponent.option('screenName');
      const popupCloseAriaLabelId = !!appName && appName.length > 0 ? appName + '.htmls.popupCloseAriaLabel' : '';
      closeAriaLabel = this.utilService.translateOrDefault(popupCloseAriaLabelId, closeAriaLabel);
      newButton.attr('aria-label', closeAriaLabel);
    }

    // It's not valid for a <button> to have role="button"
    newButton.removeAttr('role');
  }

  private _hidePageFromScreenreader() {
    // To trap screenreader focus in modal
    // This rather drastic action fixes a bug in which iOS VoiceOver fails to trap
    // focus inside the modal, instead allowing the user to swipe-navigate to the
    // page behind it, which should be inaccessible.
    this._getSiblingsOfModal().attr('aria-hidden', true);
    if (this.deviceService.IX_isIOS()) {
      if (!$('.IX_enhanceAlertWindow').length) {
        // This is achieved by making sure popup wrapper/etc are last child of body.
        const $popup = $('body').children(this._modalClasses.join()).detach();
        $popup.appendTo('body');
      }
    }
  }

  private _unhidePageFromScreenreader() {
    this._getSiblingsOfModal().attr('aria-hidden', null);
  }

  stopBodyScrolling() {
    if (!this.deviceService.IX_isMobile()) {
      //to prevent the screen flash when the scrollbar is removed
      const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
      if (scrollBarWidth > 0) {
        $('body').css('margin-right', scrollBarWidth);
      }
    }

    document.body.style.overflow = 'hidden';

    //Handle iOS bug: https://benfrain.com/preventing-body-scroll-for-modals-in-ios/
    document.body.addEventListener('touchmove', this._freezeViewPort);
  }

  private _resumeBodyScrolling() {
    document.body.style.overflow = 'auto';

    if (!this.deviceService.IX_isMobile()) {
      //to remove the right margin added while stopping body scroll to prevent screen flash
      $('body').css('margin-right', 0);
    }

    document.body.removeEventListener('touchmove', this._freezeViewPort);
  }

  private _freezeViewPort(event) {
    if (!_.find(this._openInstances, event.element)) {
      event.preventDefault();
      // confirm('touchmove cancelled');
    } else {
      // confirm('touchmove allowed');
    }
  }

  private _getSiblingsOfModal() {
    return $('body').children('div:not(' + this._modalClasses.join() + ')');
  }
}
