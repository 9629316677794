import { Injectable } from '@angular/core';
import _ from 'lodash';

interface FormattingContext {
  formattedElements: FormattedElement[];
  rules: any[];
  getAppState: Function;
}

interface FormattedElement {
  fieldName: string;
  selector: any;
}

/**
 * This service creates an isolated context for conditional formatting
 * used from within a list app to provide row-level conditional formatting.
 * 
 * Supported within this service:
 * 1. Immdiate formatting based on DevExtreme events. As opposed to app-level 
 *    state model updates.
 * 2. Unwrapped native DevExtreme components created by DxDataGrid on editable 
 *    mode.
 * 
 * Authors:
 * Please keep dependencies to a minimum, consumers of this 
 * service must pass a formattingContext with all that is needed to construct and   
 * run the formats. See 'bind' method.
 */

@Injectable()
export class ConditionalFormattingService {
  constructor() { }

  bind(formattingContext: FormattingContext) {
    const formatter = new DevExtremeFormatter(formattingContext.rules, formattingContext.getAppState);
    for (let i = 0; i < formattingContext.formattedElements.length; i++) {
      const current = formattingContext.formattedElements[i];
      formatter.bind(current.selector, current.fieldName);
    }
    return formatter;
  }
}

class DevExtremeFormatter {
  boundComponents = [];
  rules: any[];
  getAppState: Function;

  constructor(_rules, _getAppState) {
    this.rules = _rules;
    this.getAppState = _getAppState;
  }

  private unbind() {
    for (let i = 0; i < this.boundComponents.length; i++) {
      const current = this.boundComponents[i];

      if (current.instance != null) current.instance.off('valueChanged');
    }
    this.boundComponents = [];
  }

  bind(element, fieldName) {
    const boundComponent = {
      element: element,
      fieldName: fieldName,
      instance: this.getDxInstanceFromjQueryElement(element),
    };

    this.boundComponents.push(boundComponent);

    if (boundComponent.instance != null) {
      this.attachToComponentEvents(boundComponent.instance);
    }
  }

  private run() {
    this.runAllBoundComponents();
  }

  private runAllBoundComponents() {
    const appState = this.getAppState();

    this.updateStateWithDxComponents(appState);

    for (let i = 0; i < this.boundComponents.length; i++) {
      const current = this.boundComponents[i];

      if (!this.hasConditionalFormattingRules(current.fieldName, this.rules)) continue;

      if (current.instance != null) {
        this.applyRulesOnDxComponent(current.instance, current.fieldName, this.rules, appState);
      } else {
        this.applyRulesOnJQuerySelector(current.element, current.fieldName, this.rules, appState);
      }
    }
  }

  attachToComponentEvents(instance) {
    instance.on('valueChanged', (e) => this.runAllBoundComponents());
  }

  updateStateWithDxComponents(appState) {
    for (let i = 0; i < this.boundComponents.length; i++) {
      const current = this.boundComponents[i];

      if (current.instance != null) {
        let newValue = current.instance.option('value');
        newValue = this.convertToTypeDefinedInApplication(current.instance, newValue);

        appState[current.fieldName] = newValue;
      }
    }
  }

  convertToTypeDefinedInApplication(instance, newValue) {
    return this.applyBooleanLookUpValues(instance, newValue);
  }

  applyBooleanLookUpValues(instance, value) {
    if (typeof value !== 'boolean') return value;

    const yesValue = instance.option('ic.yesValue'),
      noValue = instance.option('ic.noValue');

    if (value === true && yesValue != null) return yesValue;
    if (value === false && noValue != null) return noValue;

    return value;
  }

  hasConditionalFormattingRules(fieldName, rules) {
    const rule = _.get(rules, 'fieldToRuleSet.CL_' + fieldName);
    return rule != null;
  }

  getDxInstanceFromjQueryElement(element) {
    if (element == null) throw new Error("'element' is required");

    const dxComponents = element.data('dxComponents');
    if (dxComponents == null) return null;

    const instance = element.data(dxComponents[0]);
    if (instance == null) throw new Error('Invalid DevExtreme instance');

    return instance;
  }

  applyRulesOnDxComponent(instance, fieldName, rules, appState) {
    const input = this.getInputFromDxInstance(instance),
      formatInfo = this.evaluateRulesAndGetFormats(rules, fieldName, appState);

    if (formatInfo == null) return;

    if (input.length > 0) {
      this.applyEvaluatedFormatsUsingCss(formatInfo, input);
    }

    this.applyEvaluatedFormatsUsingDxInstance(formatInfo, instance);
  }

  applyRulesOnJQuerySelector(selector, fieldName, rules, appState) {
    const formatInfo = this.evaluateRulesAndGetFormats(rules, fieldName, appState);
    if (formatInfo == null) return;

    this.applyEvaluatedFormatsUsingCss(formatInfo, selector);
  }

  applyEvaluatedFormatsUsingCss(formatInfo, selector) {
    for (let i = 0; i < formatInfo.apply.length; i++) {
      const info = formatInfo.apply[i];

      selector.css(info.format);
      if (!_.isEmpty(info.className)) {
        selector.addClass(info.className);
      }
    }
    for (let i = 0; i < formatInfo.unapply.length; i++) {
      const info = formatInfo.unapply[i];

      for (const entry in info.format) {
        selector.css(entry, '');
      }
      if (!_.isEmpty(info.className)) {
        selector.removeClass(info.className);
      }
    }
  }

  applyEvaluatedFormatsUsingDxInstance(formatInfo, instance) {
    const applyStyles = {
      display: function (value) {
        if (value === 'none') {
          instance.option('visible', false);
        }
      },
    },
      unapplyStyles = {
        display: function (value) {
          if (value === 'none') instance.option('visible', true);
        },
      };

    for (let i = 0; i < formatInfo.apply.length; i++) {
      const info = formatInfo.apply[i];

      for (const entry in info.format) {
        const func = applyStyles[entry];
        if (func != null) func(info.format[entry]);
      }

      if (!_.isEmpty(info.className)) {
        instance.$element().addClass(info.className);
      }
    }

    for (let i = 0; i < formatInfo.unapply.length; i++) {
      const info = formatInfo.unapply[i];

      for (const entry in info.format) {
        const func = unapplyStyles[entry];
        if (func != null) func(info.format[entry]);
      }

      if (!_.isEmpty(info.className)) {
        instance.$element().removeClass(info.className);
      }
    }
  }

  getInputFromDxInstance(instance) {
    if (instance == null) throw new Error("Argument 'instance' is required.");

    return instance.$element().find('input.dx-texteditor-input');
  }

  evaluateRulesAndGetFormats(appRules, fieldName, appState) {
    const ruleName = _.get(appRules, 'fieldToRuleSet.CL_' + fieldName + '.rule');
    if (ruleName == null) return;
    const fieldRules = _.get(appRules, 'ruleSets.' + ruleName);
    if (fieldRules == null) return;

    const applyFormats = [],
      unapplyFormats = [];
    for (let i = 0; i < fieldRules.length; i++) {
      const fieldRule = fieldRules[i];

      const result = fieldRule.condition.call(appState);

      (result ? applyFormats : unapplyFormats).push(fieldRule);
    }

    return {
      apply: applyFormats,
      unapply: unapplyFormats,
    };
  }
}
