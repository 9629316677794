/* eslint-disable @typescript-eslint/no-explicit-any */
import { DataPersistence } from '@nrwl/angular';
import { Actions, CreateEffectMetadata } from '@ngrx/effects';
import { from, Observable, of, concat } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppsEntity } from '../state/apps.models';
import * as AppsFeature from '../state/apps.reducer';
import * as AppsActions from '../state/apps.actions';
import * as StateActions from '../state/apps.tokens';
import { BaseCommand } from "./base-command";
import { CommandListCollectionService } from "./command-list-collection.service";
import { CacheManagerService } from '../services/cachemanager.service';

export abstract class BaseEffectCommand extends BaseCommand {

    /** Place holder for the effect set up for a given command. You must import
     * @see `createEffect` from '@ngrx/effects';
    */
    protected abstract effectCommandName$: Observable<any> & CreateEffectMetadata;
    protected abstract actions$: Actions;
    protected abstract dataPersistence: DataPersistence<AppsFeature.AppsPartialState>;
    protected abstract commandListCollectionService: CommandListCollectionService;
    protected abstract cacheManagerService: CacheManagerService;

    getEffectFetchId(action: any): string {
        const { commandListName, executionId } = this.getCommandConfig(action);
        return `${commandListName}-${executionId}`;
    }

    getCommandConfig(action: any): CommandConfig {
        const prevCommandConfig = action?.commandConfig ? action.commandConfig : action;
        const nextCommandConfig = _.cloneDeep(prevCommandConfig);
        nextCommandConfig.options.e = prevCommandConfig.options.e;
        return nextCommandConfig;
    }

    waitAndContinueNextActions(promise: Promise<any>, commandConfig: CommandConfig, setPrevValue?: boolean): Observable<any> {
        return from(promise)
            .pipe(
                switchMap(result => this.getNextActions(commandConfig, setPrevValue ? result : null))
            );
    }

    getNextActions(commandConfig: CommandConfig, prevValue?: any): Observable<any> {
        return this.appendNextActions([], commandConfig, prevValue);
    }

    appendNextActions(thisCommandActions: any[], commandConfig: CommandConfig, prevValue?: any): Observable<any> {
        const nextCommands = this.commandListCollectionService.getNextCommand(commandConfig, prevValue);
        return concat(from(thisCommandActions), of(nextCommands));
    }

    completeCommandList(commandListName: string, executionId: string): void {
        this.commandListCollectionService.completeCommandList(commandListName, executionId);
    }

    getStateAction(actionType: string, appName: string, payload: any): any {
        const action = {
            type: `[${appName}] ${actionType}`,
            appName,
            ...payload
        };
        return action;
    }

    getSetAppFilterAction(appName: string, listFilterDetails: DataSourceFilterDetails): any {
        return this.getStateAction(StateActions.SET_FILTERS, appName, { listFilterDetails });
    }

    getAppState(appName: string, appsStore: any): AppState {
        const appsState = this.getAppsState(appsStore);
        return appsState[appName]?.state || {};
    }

    getAppsState(appsStore: any): Record<string, AppsEntity> {
        const appState = _.cloneDeep(appsStore.apps.entities);
        const tmpName = '_variables';
        const variables = {
            workflow: appsStore.workflow ?? "",
            themeName: appsStore.themeName ?? ""
        };
        appState[tmpName] = this.createAppEntity(tmpName, variables);
        return appState;
    }

    getUpdateCommandStateActions(commandState: AppsEntity[], commandConfig: CommandConfig): any[] {
        const commandName = commandConfig.command;
        const commandListName = commandConfig.commandListName + '[' + commandConfig.currentIndex + ']';
        return this.getUpdateCommandStateAction(commandState, commandName, commandListName);
    }

    getResetAppActions(appName: string): any {
        const resetAction = this.createResetStateAction(appName);
        return [resetAction];
    }

    createResetStateAction(appName: string): any {
        return {
            type: `[${appName}] ${StateActions.RESET_STATE}`,
            appName,
            forceDelete: false
        };
    }

    createAppEntity(appName: string, appState: AppState): AppsEntity {
        const appEntity = {
            id: appName,
            state: appState
        };
        return appEntity;
    }

    updateAppEntityWith(appName: string, updateState: AppState, appsStore: any): AppsEntity {
        const appState = this.getAppState(appName, appsStore);
        _.mergeWith(appState, updateState);
        return this.createAppEntity(appName, appState);
    }

    getAppFieldValue(appName: string, fieldName: string, appsState): any {
        return appsState[appName] ? appsState[appName].state[fieldName] : undefined;
    }

    shouldWaitForTranslationBeforeShowModal(): boolean {
        return this.getThemePropertyCache("WaitForTranslationBeforeShowModal");
    }

    getThemePropertyCache(propertyName: string): boolean {
        const key = "{ThemeProperty:" + propertyName + "}";
        if (this.cacheManagerService.has(key))
            return this.cacheManagerService.get(key) as boolean;
        const property = this.getThemeProperty(propertyName);
        return this.cacheManagerService.set(key, "true".EqualsIgnoreCase(property.Value1));
    }

    getThemeProperty(propertyName: string): ThemeProperty {
        return _.find(IX_Theme.properties, (item) => item.PropertyName.EqualsIgnoreCase(propertyName));
    }

    private getUpdateCommandStateAction(commandState: AppsEntity[], commandName: string, commandListName: string): any[] {
        if (_.isEmpty(commandState)) return [];
        const updateAction = AppsActions.updateCommandState({ commandState, commandName, commandListName });
        return [updateAction];
    }
}
