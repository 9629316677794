import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './components/not-found.component';
import { RouteGuards } from './services/route-guards.service';
//#region routes definitions
const routes: Routes = [
  {
   path: 'rbcloginv4wfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'RBCLoginV4.WF.App' },
    loadChildren: () => import('./jig/RBCLoginV4.WF.App/RBCLoginV4.WF.App.module').then(m => m.RBCLoginV4WFAppModule)
  },
  {
   path: 'bb8v4loginwfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'BB8V4Login.WF.App' },
    loadChildren: () => import('./jig/BB8V4Login.WF.App/BB8V4Login.WF.App.module').then(m => m.BB8V4LoginWFAppModule)
  },
  {
   path: 'rbcv4wfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'RBCv4.WF.App' },
    loadChildren: () => import('./jig/RBCv4.WF.App/RBCv4.WF.App.module').then(m => m.RBCv4WFAppModule)
  },
  {
   path: '__rbcclientv4dsuat__',
   loadChildren: () => import('./jig/RBCClientV4DSUAT/RBCClientV4DSUAT.module').then(m => m.RBCClientV4DSUATModule)
  },
  {
   path: '__rbcclientv4ds__',
   loadChildren: () => import('./jig/RBCClientV4DS/RBCClientV4DS.module').then(m => m.RBCClientV4DSModule)
  },
  {
   path: '__rbcclientv4phnuat__',
   loadChildren: () => import('./jig/RBCClientV4PHNUAT/RBCClientV4PHNUAT.module').then(m => m.RBCClientV4PHNUATModule)
  },
  {
   path: '__rbcclientv4phnuatmobile__',
   loadChildren: () => import('./jig/RBCClientV4PHNUATMobile/RBCClientV4PHNUATMobile.module').then(m => m.RBCClientV4PHNUATMobileModule)
  },
  {
   path: '__rbcclientv4etuat__',
   loadChildren: () => import('./jig/RBCClientV4ETUAT/RBCClientV4ETUAT.module').then(m => m.RBCClientV4ETUATModule)
  },
  {
   path: '__rbcclientv4dsmobile__',
   loadChildren: () => import('./jig/RBCClientV4DSMobile/RBCClientV4DSMobile.module').then(m => m.RBCClientV4DSMobileModule)
  },
  {
   path: '__rbcclientv4wip__',
   loadChildren: () => import('./jig/RBCClientV4WIP/RBCClientV4WIP.module').then(m => m.RBCClientV4WIPModule)
  },
  {
   path: '__rbcclientv4phn__',
   loadChildren: () => import('./jig/RBCClientV4PHN/RBCClientV4PHN.module').then(m => m.RBCClientV4PHNModule)
  },
  {
   path: '__rbcclientv4dsuatmobileqa__',
   loadChildren: () => import('./jig/RBCClientV4DSUATMobileQA/RBCClientV4DSUATMobileQA.module').then(m => m.RBCClientV4DSUATMobileQAModule)
  },
  {
   path: '__rbcclientv4dsuatmobile__',
   loadChildren: () => import('./jig/RBCClientV4DSUATMobile/RBCClientV4DSUATMobile.module').then(m => m.RBCClientV4DSUATMobileModule)
  },
  {
   path: '__rbcclientv4phnuatmobileqa__',
   loadChildren: () => import('./jig/RBCClientV4PHNUATMobileQA/RBCClientV4PHNUATMobileQA.module').then(m => m.RBCClientV4PHNUATMobileQAModule)
  },
  {
   path: '__rbcclientv4phnmobile__',
   loadChildren: () => import('./jig/RBCClientV4PHNMobile/RBCClientV4PHNMobile.module').then(m => m.RBCClientV4PHNMobileModule)
  },
  {
   path: '__rbcclientv4etuatmobileqa__',
   loadChildren: () => import('./jig/RBCClientV4ETUATMobileQA/RBCClientV4ETUATMobileQA.module').then(m => m.RBCClientV4ETUATMobileQAModule)
  },
  {
   path: '__rbcclientv4etmobile__',
   loadChildren: () => import('./jig/RBCClientV4ETMobile/RBCClientV4ETMobile.module').then(m => m.RBCClientV4ETMobileModule)
  },
  {
   path: '__rbcclientv4etuatmobile__',
   loadChildren: () => import('./jig/RBCClientV4ETUATMobile/RBCClientV4ETUATMobile.module').then(m => m.RBCClientV4ETUATMobileModule)
  },
  {
   path: '__rbcclientv4et__',
   loadChildren: () => import('./jig/RBCClientV4ET/RBCClientV4ET.module').then(m => m.RBCClientV4ETModule)
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];
//#endregion
@NgModule({ imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })], exports: [RouterModule] })
export class AppRoutingModule {}
