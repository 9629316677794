import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import numeral from 'numeral';
import Sugar from 'sugar/date';
import { Guid } from 'guid-typescript';
import { TranslateFacadeService } from './translate-facade.service';
import { ApiClientService } from './api-client.service';
import { StoreService } from './store-service';
import { FieldFormatService } from './field-format.service';
import { WorkflowConfigurationService } from './workflow-configuration.service';
import { ApplicationInformation } from './application-information.service';
import { AppsConstantsFacade } from './apps-constants.facade';
import UtilityFunctions from '../utility.functions';
import { AppsConstants } from '../state/apps.constants';
import { AppsEntity } from '../state/apps.models';
import { DynamicReplacementService } from './dynamic-replacement.service';
import { AlertService } from './alert.service';

type ParsedUrl = {
    protocol: string,
    host: string,
    hostname: string,
    port: string,
    pathname: string,
    search: string,
    searchObject: Record<string, string>,
    hash: string,
};

@Injectable()
export class UtilService {

    currentStepName: string;
    currentBreakPoint: string;
    currentContextName: string;
    currentContextState: AppState;
    contextStateSubscription: Subscription;

    constructor(private domSanitizer: DomSanitizer,
        private storeService: StoreService,
        private fieldFormatService: FieldFormatService,
        private workflowConfigurationService: WorkflowConfigurationService,
        private applicationInformation: ApplicationInformation,
        private translate: TranslateFacadeService,
        private appsConstantsFacade: AppsConstantsFacade,
        private apiClientService: ApiClientService,
        private dynamicReplacementService: DynamicReplacementService,
        private alertService: AlertService) {
        this.currentContextState = {};
        this.appsConstantsFacade.step$.subscribe(stepName => this.currentStepName = stepName);
        this.appsConstantsFacade.breakPoint$.subscribe(breakPoint => this.currentBreakPoint = breakPoint);
        this.appsConstantsFacade.contextName$.subscribe(contextName => {
            this.currentContextName = contextName;
            this.contextStateSubscription?.unsubscribe();
            this.contextStateSubscription = null;
            this.setCurrentContextState();
        });
    }

    private setCurrentContextState() {
        if (this.contextStateSubscription) return;
        if (_.isEmpty(this.currentContextName))
            this.currentContextName = this.workflowConfigurationService.getConstants().contextName;
        if (!_.isEmpty(this.currentContextName)) {
            this.contextStateSubscription = this.storeService
                .getCurrentAppState(this.currentContextName)
                .subscribe(contextState => this.currentContextState = contextState);
        }
    }

    refreshIframe(iframeId: string): void {
        if (_.isEmpty(iframeId)) return;
        const iframe = document.getElementById(iframeId) as any;
        iframe.src += "";
    }

    publishEvent(event: AppsEntity): void {
        this.appsConstantsFacade.publishEvent(event);
    }

    runConditionalErrorButton(scope, error) {
        if (scope.gridProperties === null)
            return;

        const onErrorButtonConfiguration = scope.gridProperties.ic.onErrorButtons;
        if (onErrorButtonConfiguration === null)
            return;

        const buttonsCmdLstMap = {};
        for (const b in scope.buttons) {
            buttonsCmdLstMap[scope.buttons[b].fieldName] = scope.buttons[b].cmdLstName;
        }

        const errorCode = error.MainMessage.split("|")[1];
        const errorNumber = error.MainMessage.split("|")[2];

        const runButton = (errorButton) => {
            scope.onEventHandler(buttonsCmdLstMap[errorButton.button]);
        }

        onErrorButtonConfiguration.forEach((errorButton) => {
            switch (errorButton.errorType) {
                case "ErrorCode":
                    if (errorCode === errorButton.errorText) {
                        runButton(errorButton);
                    }
                    break;
                case "ErrorNumber":
                    if (errorNumber === errorButton.errorText) {
                        runButton(errorButton);
                    }
                    break;
                case "ErrorTextContains":
                    if (error.MainMessage.contains(errorButton.errorText)) {
                        runButton(errorButton);
                    }
            }
        });
    }

    reloadRole() {
        const config = {
            url: "/Membership/ExtPages/ilg.ashx?IX_RELOAD_ROLE=Y",
            type: "POST",
            cache: false
        };
        return this.apiClientService.makeRequest(config, void (0), false);
    }

    getFallbackImage(dataRow, dataId, seq, scopeConfig) {

        let fallbackImg = scopeConfig.fallbackImagesFolder + "/" + scopeConfig.fallbackImagesNamePattern;
        fallbackImg += (typeof scopeConfig.fallbackImagesLength !== "undefined" && scopeConfig.fallbackImagesLength !== "") ? (dataRow["Id"] % parseInt(scopeConfig.fallbackImagesLength)) : seq;
        fallbackImg += "." + scopeConfig.fallbackImagesExtension;
        return fallbackImg;
    }

    suppressValidationForBecomeUser($scope: any, fieldName: string, modelName: string): void {
        if (!IX_InBecomeUserMode() || !_.get($scope, 'applet.config.inputFields.' + fieldName)) {
            return;
        }
        if (modelName && $scope.applet.config.inputFields[fieldName][modelName]) {
            delete $scope.applet.config.inputFields[fieldName][modelName].dxValidator;
        } else {
            delete $scope.applet.config.inputFields[fieldName].dxValidator;
        }
    }

    waitForDataSource($scope, fieldName) {
        $scope[fieldName + 'Loaded'] = false;
        const _onDataSourceChanged = () => {
            $scope.$applyAsync(() => {
                $scope[fieldName + 'Loaded'] = true;
            });
            $scope.dataSources[fieldName].off('changed', _onDataSourceChanged);
        };
        $scope.dataSources[fieldName].on('changed', _onDataSourceChanged);
    }

    setRequestData(appObj, dataIn) {
        return this.apiClientService.setRequestData(appObj, dataIn);
    }

    setECDContext(ecdContext, _contextApps) {
        return this.apiClientService.setECDContext(ecdContext, _contextApps);
    }

    setEcdRequestContext(ecdRequest, ecdContext) {
        return this.apiClientService.setEcdRequestContext(ecdRequest, ecdContext);
    }

    makeRequest(config, request, rejectOnFail) {
        return this.apiClientService.makeRequest(config, request, rejectOnFail);
    }

    createOnEventHandlerSettings(dst, src) {
        dst._options = {
            setListContext: src.setListContext,
            eventHandler: src.eventHandler,
            cmdLstName: src.cmdLstName
        };
        return dst;
    }


    hasValidLabel($label, debug) {
        if ($label.length === 0) return false;
        return $label.is(":visible") || debug;
    }

    hasInputValue(_value) {
        return !!_value && (
            (_.isString(_value) && _value.trim().length > 0)
            || (_.isArray(_value) && _value.length > 0)
            || _.isNumber(_value)
        );
    }

    // add placeholder value to ariaLabel so screen reader can read it
    getAriaLabelWithPlaceholderText($target, ariaLabelText, labelText) {

        const $placeholder = $target.parent().find('div[data-dx_placeholder]');
        let placeholderText = $placeholder.attr('data-dx_placeholder') || '';
        // if input has value remove placeholderText, screen reader will read input value
        const inputValue = $target.val();
        const hasInputValue = this.hasInputValue(inputValue);
        const hasPlaceholder = this.hasInputValue(placeholderText);
        const hasAriaLabel = this.hasInputValue(ariaLabelText);
        const hasLabel = this.hasInputValue(labelText);
        let result = "";
        if (hasPlaceholder || hasAriaLabel) {
            if (hasAriaLabel) {
                result = ariaLabelText;
            } else if (hasLabel) {
                result = labelText;
            }
            placeholderText = hasInputValue ? '' : placeholderText;
            result = placeholderText.length > 0 ? result + ' ' + placeholderText : result;
        }
        return result;
    }

    updateADALabelAttributes($element, $target, ariaLabelText, appId, debug) {
        const $label = $element.parent().find("label");

        if (this.hasValidLabel($label, debug)) {
            const labelId = Guid.create();
            $label.attr("id", labelId);

            ariaLabelText = this.getAriaLabelWithPlaceholderText($target, ariaLabelText, $label.text());

            // if we have ariaLabelText or placeholderText use it so screen reader uses aria-label instead of <label> contents
            if (ariaLabelText && ariaLabelText.length > 0) {
                $target.attr("aria-label", ariaLabelText);
            } else {
                $target.attr("aria-labelledby", labelId);
            }
        } else {
            ariaLabelText = this.getAriaLabelWithPlaceholderText($target, ariaLabelText, null);

            $target.attr("aria-label", ariaLabelText);
        }
    }

    createADALabelAttributes(options) {
        setTimeout(() => {
            options = { ...options }
            const $app = $('#' + options.appId);
            let describedById = options.cId + '_ariaDescribedBy';
            // Number of items that start with the same aria describedby id.
            const itemsWithDescribedById = $("[aria-describedby^='" + describedById + "']").length;

            /* Prevents against repeated "id" by appending an '_' and a number at the end of it. */
            if (itemsWithDescribedById > 0)
                describedById = describedById + "_" + itemsWithDescribedById;

            if (options.ariaDescribedBy && options.ariaLabelText !== options.ariaDescribedBy) {
                const $aux = $("<div style='display:none;'/>")
                    .attr("id", describedById)
                    .text(options.ariaDescribedBy);
                $app.append($aux);
                options.target.attr("aria-describedby", describedById);
            }

            this.updateADALabelAttributes(options.element, options.target, options.ariaLabelText, options.appId, (options.debug || options.useFieldLabelAsAriaLabel));

            if (!_.isEmpty(options.target.attr("aria-describedby"))) {
                options.target.removeAttr("aria-label");
            }
        }, 0);
    }

    getValuesFromUrl(routeParams) {
        const urlContext = { context: {} };
        for (const param in routeParams) {
            if (this.isFieldAppReferenced(param)) {
                const urlInfo = this.getAppReferenced(param);
                if (_.isNil(urlContext.context[urlInfo.appName])) {
                    urlContext.context[urlInfo.appName] = {};
                }
                urlContext.context[urlInfo.appName][urlInfo.fieldName] = routeParams[param];
            }
        }
        return urlContext;
    }

    getConditionalFormatRulesFieldMask(defaultFieldMask, conditionalFormats, data, fieldName, rowIndex?) {
        let returnFieldMask = defaultFieldMask;
        const getFieldMask = (target, field, ruleMatched, row) => {
            returnFieldMask = _.get(ruleMatched, 'format.fieldMask', returnFieldMask);
        };
        const _fieldName = fieldName.startsWith("CL_") ? fieldName : ("CL_" + fieldName);
        const runAllRules = true;
        const dataNormalized = _.mapValues(data, (item) => _.get(item, 'value', item));
        IX_ConditionalFormatExecuteRules(null, conditionalFormats, dataNormalized, getFieldMask, _fieldName, rowIndex, runAllRules);
        return returnFieldMask;
    }

    getConditionalFormatRulesContext(appName: string, appState: any): any {

        this.setCurrentContextState();

        const appObj: any = {};
        appObj.IXAppName = appName;
        appObj.IXCurrentWFStep = this.currentStepName ? this.currentStepName : appName;

        _.forEach(appState, (value, fieldName) => appObj[fieldName] = value);
        // Merge context fields into appObj with prefix to match TE conditional mapping format
        _.forEach(this.currentContextState, (value, key) => appObj['Context_' + key] = value);

        return appObj;
    }

    // TODO - refactor such that appObj is clear what is being passed
    updateConditionalFormatRulesContext(appName: string, appState: any): Promise<any> {

        const appObj = this.getConditionalFormatRulesContext(appName, appState);

        // TODO Move data source count to list-application and get reference to app
        // appObj.IXDataSourceCount = (field) => {

        //     //Note that appName is sometimes the scope of the app, not the name (the calls to this function are inconsistent)
        //     let appScope;
        //     if (typeof appName === "string")
        //         appScope = this.getApplicationScope(appName);
        //     else
        //         appScope = appName;

        //     const ds = appScope.dataSources[field];

        //     if (_.isNil(ds))
        //         return 0;

        //     if (!ds.isLoaded())
        //         return -1;

        //     const items = ds.items();
        //     if (_.isNil(items)) {
        //         if (!_.isNil(ds.length))
        //             return ds.length;
        //         else
        //             return 0;
        //     }
        //     else {
        //         return items.length;
        //     }

        // }

        return Promise.resolve(appObj);
    }

    hasValidValue(value) {
        return value !== void (0) && value !== null && value !== "";
    }

    getCacheKeyForECDG(request: any): string {
        const hashCode = _.isEmpty(request) ? "" : JSON.stringify(request).IXhashCode();
        return request.ApplicationName + request.ServerCallType + hashCode + "_ecdg";
    }

    getFieldNameFromConfig(config) {
        let fieldName = "";
        const index = "CL_".length;
        if (!_.isNil(config) && !_.isNil(config.icClassName)) {
            fieldName = config.icClassName.substr(index);
        }
        return fieldName;
    }

    isFieldAppReferenced(fieldName) {
        return fieldName.indexOf('.') !== -1;
    }

    getAppReferenced(fieldNameStr): AppReference {
        const aux = fieldNameStr.split(".");
        return {
            appName: aux[0].replace(/_/g, '.'),
            fieldName: aux[1]
        };
    }

    getAppsInFieldMap(fieldMap: any, sourceAppName: string, targetAppName: string): Record<string, boolean> {

        const apps = {};

        apps[sourceAppName] = true;
        apps[targetAppName] = true;

        if (fieldMap && fieldMap.sourceFields && fieldMap.targetFields && fieldMap.sourceFields.length > 0
            && fieldMap.targetFields.length > 0 && fieldMap.sourceFields.length === fieldMap.targetFields.length) {

            let isTargetFieldAppReference, isSourceFieldAppReference, targetAppTmp, sourceAppTmp;

            for (let i = 0; i < fieldMap.sourceFields.length; i++) {
                isTargetFieldAppReference = this.isFieldAppReferenced(fieldMap.targetFields[i]);
                isSourceFieldAppReference = this.isFieldAppReferenced(fieldMap.sourceFields[i]);
                if (isTargetFieldAppReference && !isSourceFieldAppReference) {
                    targetAppTmp = this.getAppReferenced(fieldMap.targetFields[i]);
                    apps[targetAppTmp.appName] = true;
                } else if (!isTargetFieldAppReference && isSourceFieldAppReference) {
                    sourceAppTmp = this.getAppReferenced(fieldMap.sourceFields[i]);
                    apps[sourceAppTmp.appName] = true;

                } else if (isTargetFieldAppReference && isSourceFieldAppReference) {
                    targetAppTmp = this.getAppReferenced(fieldMap.targetFields[i]);
                    sourceAppTmp = this.getAppReferenced(fieldMap.sourceFields[i]);
                    apps[sourceAppTmp.appName] = true;
                    apps[targetAppTmp.appName] = true;
                }
            }

            apps[fieldMap.sourceApp] = true;
            apps[fieldMap.targetApp] = true;
        }

        return apps;
    }

    getTrustedString(str: string): string {
        return this.domSanitizer.sanitize(SecurityContext.HTML, str);
    }

    getTranslationWhenReady(message: string): Promise<string> {
        if (_.isNil(message) || message === '') {
            return Promise.resolve('');
        }
        return this.translate.get(message).toPromise();
    };

    getTranslation(translateId: string): string {
        if (_.isNil(translateId) || translateId === '') return '';
        return this.translate.instant(translateId).toString();
    }

    getBaseImagePath(): string {
        const aspNETTheme = IX_Theme.details.ASPNETTheme || 'Default';
        return '/App_Themes/' + aspNETTheme;
    }

    removeImagePathFrontSlash(imagePath): string {
        return '/' === _.first(imagePath) ? imagePath.substr(1) : imagePath;
    }

    addImagePathFrontSlash(imagePath): string {
        const slash = '/' !== _.first(imagePath) ? '/' : '';
        return slash + imagePath;
    }

    getThemeImageOverrides() {
        if (_.isNil(IX_Theme.imageOverrideMap)) {
            const imageOverrideMap = {};
            const imageOverrides = IX_Theme.imageOverrides || {};
            for (const imagePath in imageOverrides) {
                const imagePathLower = imagePath.toLowerCase();
                imageOverrideMap[imagePathLower] = imageOverrides[imagePath];
            }
            IX_Theme.imageOverrideMap = Object.freeze(imageOverrideMap);
        }
        return IX_Theme.imageOverrideMap;
    }

    getThemeImageOverrideOrDefault(imageUrl): string {
        const _themeImageOverrides = this.getThemeImageOverrides();
        let imagePath = imageUrl.replace("/images/", "");
        imagePath = this.removeImagePathFrontSlash(imagePath);
        const imagePathLower = imagePath.toLowerCase();
        imagePath = _.isNil(_themeImageOverrides[imagePathLower]) ? imagePath : _themeImageOverrides[imagePathLower];
        imagePath = this.addImagePathFrontSlash(imagePath);
        return "/images" + imagePath;
    }

    setASPNETThemePath(imagePath: string): string {
        imagePath = this.getThemeImageOverrideOrDefault(imagePath);
        return this.getBaseImagePath() + imagePath;
    }

    translateImageOrDefault(translateId: string, defaultStr: string): string {
        let translation = defaultStr;
        if (_.isNil(translateId)) return translation;
        const imageTranslation = this.getTranslation(translateId);
        if (imageTranslation !== translateId) {
            translation = this.setASPNETThemePath(imageTranslation);
        }
        return translation;
    }

    translateOrDefault(translateId: string, defaultStr: string): string {
        let translation = defaultStr;
        if (_.isNil(translateId)) return translation;
        translation = this.getTranslation(translateId);
        if (translation === translateId) {
            translation = defaultStr;
        }
        return translation;
    }

    updateComponentTranslationProperties(options, translateId): void {
        const ariaLabelTranslationId = translateId + '.ariaLabel';
        const placeholderTranslationId = translateId + '.placeholder';
        options.ariaLabelText = this.translateOrDefault(ariaLabelTranslationId, options.ariaLabelText);
        options.placeholder = this.translateOrDefault(placeholderTranslationId, options.placeholder);
        options.ariaDescribedBy = this.translateOrDefault(translateId + '.ariaDescribedBy', void (0));
    }

    getAppContext(s, skipFirst = false) {
        if (!_.isNil(s.context))
            return s;
        if (!skipFirst && !_.isNil(s.context) && !_.isNil(s.applet))
            return s;
        return this.getAppContext(s.$parent);
    }

    convertRawDataArrayFromDSFormat($s, rawData, arrayName = "rawData") {
        $s[arrayName].clear();
        for (let i = 1; i < rawData.length; i++) {
            const singleObject = rawData[i];
            $s[arrayName][i - 1] = {};

            for (let j = 0; j < singleObject.length; j++) {
                $s[arrayName][i - 1][rawData[0][j]] = singleObject[j];
            }
        }
    }

    getDataSetFromArray(listData) {
        if (!listData || !listData.length) {
            return;
        }

        const dataSet = [];
        const keys = Object.keys(listData[0]);
        dataSet.push(keys);
        listData.forEach(function (row) {
            const dataRow = [];
            keys.forEach(function (key) { dataRow.push(row[key]); });
            dataSet.push(dataRow);
        });

        return dataSet;
    }

    groupArray(table, groupFields, aggregationFields, secLevelField?, $s?, securityNameField?, securitySymbolField?, idField?) {

        if (!groupFields || !groupFields.length || !table || !table.length) return [];

        const createGroupAggreation = (record) => {
            const aggregation = {};
            for (const sk in aggregationFields) {
                if (record[aggregationFields[sk]] !== null) {
                    aggregation[aggregationFields[sk]] = {
                        type: "sum",
                        calculatedVal: +record[aggregationFields[sk]]
                    };
                }
                else {
                    aggregation[aggregationFields[sk]] = {
                        type: "sum",
                        calculatedVal: +0
                    };
                }
            }
            return aggregation;
        };

        const updateGroupAggreation = (group, record) => {

            for (const field in group.aggregations) {
                switch (group.aggregations[field].type) {
                    case "sum":
                        group.aggregations[field].calculatedVal += record[field];
                        break;
                };
            }
        };

        const createGroupInfo = (groupMap, record, fieldName, secLevelField, securityNameField, securitySymbolField, idField) => {
            let currentGroup = groupMap[record[fieldName]];
            if (currentGroup) {
                if (secLevelField !== undefined && securityNameField !== undefined) {
                    if (record[secLevelField] !== undefined && record[secLevelField] !== null && record[secLevelField] !== "") {

                        if (record[securityNameField] !== undefined && record[securityNameField] !== null && record[securityNameField] !== "") {
                            currentGroup.group.securitiesArray.push({ 'Id': record[idField], 'securityName': record[securityNameField], 'securitySymbol': (securitySymbolField !== undefined ? record[securitySymbolField] : '') });
                            currentGroup.group.level2Sec = true;
                        }
                    }
                }

                currentGroup.group.items.push(record);
                updateGroupAggreation(currentGroup.group, record);
            }
            else {
                currentGroup = {
                    field: fieldName,
                    group: {
                        key: record[fieldName],
                        count: record.__groupCount || 1,
                        items: [record],
                        aggregations: createGroupAggreation(record),
                        level2Sec: secLevelField !== undefined && securityNameField !== undefined ? (record[secLevelField] !== undefined && record[secLevelField] !== null && record[secLevelField] !== '' && record[securityNameField] !== undefined && record[securityNameField] !== null && record[securityNameField] !== '' ? true : false) : ''
                    }
                };
                if (secLevelField !== undefined && securityNameField !== undefined) {
                    currentGroup.group.securitiesArray = [];
                    if (record[secLevelField] !== undefined && record[secLevelField] !== null && record[secLevelField] !== "") {
                        if (record[securityNameField] !== undefined && record[securityNameField] !== null && record[securityNameField] !== "") {
                            currentGroup.group.securitiesArray.push({ 'Id': record[idField], 'securityName': record[securityNameField], 'securitySymbol': (securitySymbolField !== undefined ? record[securitySymbolField] : '') });
                        }
                    }
                }

            }
            return currentGroup;
        }

        const _groupItems = (groupMap, groupField, records, secLevelField?, securityNameField?, securitySymbolField?, idField?) => {
            for (let r = 0; r < records.length; r++)
                groupMap[records[r][groupField]] = createGroupInfo(groupMap, records[r], groupField, secLevelField, securityNameField, securitySymbolField, idField);
        }

        const transverseGroupTree = (groupMap, groupField, secLevelField?, securityNameField?, securitySymbolField?, idField?) => {
            for (const k in groupMap) {
                if (!groupMap[k].subGroups) {
                    groupMap[k].subGroups = {};
                    _groupItems(groupMap[k].subGroups, groupField, groupMap[k].group.items, secLevelField, securityNameField, securitySymbolField, idField);
                } else {
                    transverseGroupTree(groupMap[k].subGroups, groupField);
                }
            }
        }

        const groupTree = {}, levels = groupFields.length;

        _groupItems(groupTree, groupFields[0], table, secLevelField, securityNameField, securitySymbolField, idField);

        if (levels) {
            for (let i = 1; i < levels; i++) {
                transverseGroupTree(groupTree, groupFields[i], i + 1);
            }
        }

        return groupTree;
    }

    createProxyFactory(obj, path) {
        return {
            getValue: (function (obj, path) {
                return function () {
                    return _.get(obj, path, void (0));
                }
            }(obj, path)),

            setValue: (function (obj, path) {
                return function (value) {
                    return _.set(obj, path, value);
                }
            }(obj, path)),
        };
    }


    createECDHeader(appId, parentAppId, operation, commandPath, fetchDynamicData?) {
        return this.apiClientService.createECDHeader(appId, parentAppId, operation, commandPath, fetchDynamicData);
    }

    getPopupCanvasApplets(canvasId) {
        let currentStepName;
        this.appsConstantsFacade.step$.subscribe(stepName => currentStepName = stepName)
        return this.applicationInformation.getStepApplets(currentStepName, [canvasId]);
    }

    updateOverridePopupsInWorkFlowContext(popups) {
        for (const popupKey in popups) {
            const popupItem = popups[popupKey];
            popupItem.canvas.applets = this.getPopupCanvasApplets(popupItem.canvas.id);
            this.setPopupMethods(popupItem);
        }
    }

    setPopupMethods(popupItem) {
        popupItem.popup.onShown = (e) => IX_PopUpOnShownAddClass(e);
    }

    updatePopupsInWorkFlowContext($s) {
        if ($s.context._popups) {
            for (const pu in $s.applet.config.popups) {
                if (!$s.context._popups[pu]) {
                    $s.context._popups[pu] = $s.applet.config.popups[pu];
                    const canvasId = $s.applet.config.popups[pu].canvas.id;
                    $s.context._popups[pu].canvas.applets = this.getPopupCanvasApplets(canvasId);
                    this.setPopupMethods($s.context._popups[pu]);
                }
            }
        }
    }

    updateDynamicObjectWithAppFieldFormats($s) {
        for (const _do in $s.dynamicObjs) {
            $s.dynamicObjs[_do].rid = $s.applet.rid;
            $s.dynamicObjs[_do].appName = $s.applet.name;

            // If the app being used is an icAppTemplate, include the template name
            if ($s.applet.originalName)
                $s.dynamicObjs[_do].originalName = $s.applet.originalName;
        }
    }

    createApplicationModelCopies($s) {
        if (!_.isNil($s.applet) && this.applicationInformation.isInputApp($s.applet.name)) {
            $s.modelDRs = { ...$s.model };
            $s.modelDefaultValues = { ...$s.model };
        }
    }

    getModelDefaultValueOrDefault(scope, fieldName, defaultValue) {
        if (!_.isNil(scope) && !_.isNil(scope.modelDefaultValues) &&
            !_.isNil(scope.modelDefaultValues[fieldName]) &&
            !_.isNil(scope.modelDefaultValues[fieldName].value)) {
            defaultValue = scope.modelDefaultValues[fieldName].value;
        }
        return defaultValue;
    }

    createApplicationProperties($s) {
        $s.applet.config.formats = this.applicationInformation.getFormats($s.applet.originalName || $s.applet.name);
        $s.applet.config.fieldNamesToUppercase = this.applicationInformation.getFieldNamesToUppercase($s.applet.name);
        $s.applet.config.filterCasing = this.applicationInformation.getFilterCasings($s.applet.name);
        $s.fields = $s.applet.config.inputFields;
        $s.buttons = $s.applet.config.buttons;
        $s.images = $s.applet.config.images;
        $s.toolTips = $s.applet.config.toolTips;
        $s.dynamicObjs = $s.applet.config.dynamicObjs;
    }

    getTextDynamicObjsTranslationId(appName, property, field) {
        const noOverridden = ['tableTitle', 'screenDisplayLabel'];
        if (_.find(noOverridden, function (it) { return it === field; })) {
            return appName + '.' + property + '.' + field;
        }
        return this.getTextTranslationId(appName, property, field);
    }

    getTextTranslationId(appName, property, field) {
        const namespace = this.getTranslationNameSpace(appName);
        return namespace + '.' + property + '.' + field;
    }

    getTranslationNameSpace(appName, excludeBreakPoint?) {
        let namespace = appName;
        if (excludeBreakPoint) return namespace;
        const appInfo = this.applicationInformation.getAppInfo(appName);
        if (_.isNil(appInfo)) return namespace;
        //TODO: check if test.app[x] values should be normalize
        if (appInfo.hasBreakPointOverrides)
            namespace = appName + '.' + this.currentBreakPoint;
        return namespace;
    }

    createTranslatorIdAdapter(appName, property) {
        const adapters = {};
        adapters['dynamicObjs'] = {
            getTranslationId: this.getTextTranslationId,
            updateTranslation: true,
            setTranslation: function (s, property, field, translation) {
                s[property][field].config = translation;
            }
        };
        adapters['fields'] = {
            getTranslationId: this.getTextTranslationId,
            updateTranslation: false,
            setTranslation: () => { }
        };
        const _defaultAdapter = {
            getTranslationId: this.getTextTranslationId,
            updateTranslation: false,
            setTranslation: () => { }
        };
        if (adapters[property]) {
            if ('dynamicObjs' === property && this.applicationInformation.isListApp(appName)) {
                adapters[property].getTranslationId = this.getTextDynamicObjsTranslationId;
            }
            return adapters[property];
        }
        return _defaultAdapter;
    }

    // in base-application.ts
    // getDxValidatorPath(config) {
    //     const maxDepth = 5;
    //     const queue = [{
    //         obj: config,
    //         path: "",
    //         level: 1
    //     }];
    //     while (queue.length) {
    //         const item = queue.shift();
    //         if (!_.isObject(item.obj)) continue;
    //         if (!_.isNil(item.obj.dxValidator)) return item.path;
    //         const keys = Object.keys(item.obj);
    //         _.each(keys, function (key) {
    //             if (_.isObject(item.obj[key]) && item.level < maxDepth)
    //                 queue.push({
    //                     obj: item.obj[key],
    //                     path: item.path.length ===  0 ? key : item.path + "." + key,
    //                     level: item.level + 1
    //                 });
    //         });
    //     }
    //     return null;
    // }

    getAppNameForTranslation(applet) {
        return applet.translationIdPrefix ? applet.translationIdPrefix : applet.name;
    }

    // in base-application.ts
    // createApplicationPropertiesTranslations($s) {
    //     const properties = ['buttons', 'dynamicObjs', 'fields', 'toolTips'];
    //     const appName = this.getAppNameForTranslation($s.applet);
    //     for (let i = 0; i < properties.length; i++) {
    //         const property = properties[i];
    //         for (const field in $s[property]) {
    //             const adapter = this.createTranslatorIdAdapter(appName, property);
    //             const translationId = adapter.getTranslationId(appName, property, field);
    //             if (adapter.updateTranslation) {
    //                 const translation = $translate.instant(translationId).toString();
    //                 if (translation !== translationId) {
    //                     adapter.setTranslation($s, property, field, translation);
    //                 }
    //             }
    //             $s[property][field].translationId = translationId;
    //             $s[property][field].rid = $s.applet.rid;
    //         }
    //     }
    //     for (const field in $s.fields) {
    //         const path = _getDxValidatorPath($s.fields[field]);
    //         if (_.isNil(path)) continue;
    //         const config = (path ===  "") ? $s.fields[field] : _.get($s.fields[field], path);
    //         const isValid = this.applicationInformation.isValidationGroupValid(config.dxValidator.validationGroup);
    //         _.forEach(config.dxValidator.validationRules, function (rule) {
    //             rule.enabled = (!isValid && rule.type ===  "required") ? false : rule.enabled;
    //             if (_.isString(rule.message)) {
    //                 const namespace = _getTranslationNameSpace(appName);
    //                 rule.message = _translateOrDefault(namespace + '.fields.' + field + ".validation", rule.message);
    //             }
    //         })
    //     }
    //     for (const image in $s.images) {
    //         $s.images[image] = _setASPNETThemePath($s.images[image]);
    //     }
    // }

    // TODO still in use - refactored out of in put and list apps?
    // createApplicationScopeProperties($s) {
    //     this.createApplicationProperties($s);
    //     this.createApplicationPropertiesTranslations($s);
    //     this.updatePopupsInWorkFlowContext($s);
    //     this.updateDynamicObjectWithAppFieldFormats($s);
    //     this.setValidationGroupButtons($s);
    // }

    // in base-application.ts
    // setValidationGroupButtons($scope) {
    //     const groupButtons = [];
    //     const validationGroupMap = this.applicationInformation.getValidationGroupMap();
    //     _.forEach($scope.buttons, (button) => {
    //         if (_.isNil(button.validationGroupName)) return;
    //         const groupName = button.validationGroupName.toLowerCase();
    //         const groupFlags = validationGroupMap[groupName];
    //         if (!_.isNil(groupFlags) && (groupFlags.hasFields || groupFlags.hasComponents)) {
    //             groupButtons.push(groupName + "|" + button.icClassName);
    //         } else {
    //             delete button.disabled;
    //             delete button.icClassName;
    //             delete button.validationGroupName;
    //         }
    //     });
    //     if (!_.isEmpty(groupButtons)) {
    //         $scope.validationGroupButtons = groupButtons;
    //     }
    // }

    // in base-command
    // getIcUrl(url) {
    //     let o = {}, p, x, y,
    //         returnUrlRegex = new RegExp("\\?(ReturnURL\\=.+)\\#", "i");
    //     o.url = url;
    //     o.prefix = '?#!';
    //     url = url.replace(returnUrlRegex, '?#');
    //     url = url.replace(o.prefix, '#');
    //     p = url.split('?');
    //     if (p.length ===  2 || p.length ===  3) {
    //         x = p[0].split('/');
    //         o.folder = x[1];
    //         o.page = x[2] && x[2].replace('#', '').replace('!', ''); // remove trailing "!" if present
    //         if (x.length > 3) {
    //             o.wf = x[4];
    //             y = p[1].split('s=');
    //             o.step = y[1];
    //         }
    //         if (p.length ===  3) {
    //             x = p[1].split('/');
    //             o.wf = x[x.length - 1];
    //             y = p[2].split('s=');
    //             o.step = y[1];
    //         }
    //     }
    //     return o;
    // }

    parseUrl(url: string): ParsedUrl {
        const parser = document.createElement('a');
        const searchObject = {};
        parser.href = url;
        // Convert query string to object
        const queries = parser.search.replace(/^\?/, '').split('&');
        for (let i = 0; i < queries.length; i++) {
            const split = queries[i].split('=');
            searchObject[split[0]] = split[1];
        }
        return {
            protocol: parser.protocol,
            host: parser.host,
            hostname: parser.hostname,
            port: parser.port,
            pathname: parser.pathname,
            search: parser.search,
            searchObject: searchObject,
            hash: parser.hash
        };
    }

    getQueryUrl(url) {
        return url.replace(window.location.origin, '');
    }

    processCanvasResult(data) {

        if (!data) {
            alert(IX_getCommandFromMessageList("CANNOT-LOAD-APPLET-LIBRARY"));
            return [];
        }

        // Fix categories. Change from string to object with id, name, description
        if (data && data.categories) {
            for (let i = 0; i < data.categories.length; i++) {
                const cat = data.categories[i];
                if (typeof (cat) === "string") {
                    data.categories[i] = { name: cat, id: cat, description: "" };
                }
            }
        }

        // Fix applets category -> categories
        if (data && data.applets) {
            for (let i = 0; i < data.applets.length; i++) {
                const app = data.applets[i];
                if (app.category) {
                    app.categories = app.category;
                    delete app.category;
                }

                // Fix up name to be same as application as lots of code relies on that fact
                if (app.name !== app.application) {
                    app.title = app.name;
                    app.name = app.application;
                }

                app.config = app.config || {};
                if (app.template === 'cms') {
                    app.config.imageUploadParams = { 'appletId': app.id, 'action': 'UploadImage' };
                    app.config.imageUploadURL = '/iXingPages/Canvas.ashx';
                    app.config.imageManagerLoadUrl = '/iXingPages/Canvas.ashx';
                    app.config.imageManagerLoadParams = { 'appletId': app.id, 'action': 'LoadImages' };
                    app.config.imageManagerDeleteUrl = '/iXingPages/Canvas.ashx';
                    app.config.imageManagerDeleteParams = { 'appletId': app.id, 'action': 'DeleteImage' };
                }
            }
        }
        // Enable drag & drop within the library
        data.config = data.config || {};
        data.config.useDragDrop = true;

        return data;
    }

    refreshCanvas(request) {
        return this.apiClientService.makeCanvasRequest(request)
            .then((result) => {
                return this.processCanvasResult(result);
            });
    }

    // TODO address what to do here!
    // getWorkContextScope() {
    //     const element = _.isNil(window.AngularMigrationEnabled) ? "[ng-view]" : document.body;
    //     return angular.element(element).scope();
    // }

    // getApplicationScope(appName) {
    //     appName = this.applicationInformation.getAppClassName(appName);
    //     appName = document.getElementsByClassName(appName);
    //     return angular.element(appName).isolateScope();
    // }

    isNumeral(it) {
        if (_.isBoolean(it) || Array.isArray(it) || Infinity === it)
            return false;
        if (_.isNil(it))
            return true;
        if (!isNaN(it))
            return true;
        const n = numeral(it),
            res = _.isNumber(n.value());
        return res;
    }

    isSelectedFromListSAYT($scope, options) {
        if (!_.get(options, 'rule.fieldName')) {
            return true;
        }
        const appName = $scope.applet.name;
        const fieldName = options.rule.fieldName;

        const element = $('[data-app="' + appName + '"] .dx-autocomplete[model="model.' + fieldName + '"]');
        if (!element.length) {
            return true;
        }

        const component = element.dxAutocomplete('instance');
        if (!component) {
            return true;
        }

        return component.option('icSelectedFromList');
    }

    getRandomString() {
        return new Sugar.Date().iso().raw + Math.random().toString();
    }

    triggerDynamicReplacements($s) {
        for (const o in $s?.dynamicObjs) {
            $s.dynamicObjs[o].updated = this.getRandomString();
        }
    }

    getTriggerDirectiveApplications(fieldMap) {
        let hasAppReference;
        let targetMap;
        const serverCallApps = {};
        if (AppsConstants.triggerDirective.none !== fieldMap.directive) {
            for (let i = 0; i < fieldMap.targetFields.length; i++) {
                hasAppReference = this.isFieldAppReferenced(fieldMap.targetFields[i]);
                if (hasAppReference) {
                    targetMap = this.getAppReferenced(fieldMap.targetFields[i]);
                    serverCallApps[targetMap.appName] = true;
                }
            }
        }
        return Object.keys(serverCallApps);
    }

    getDataFromUrlContext(urlContext, appName) {
        return JSON.stringify(urlContext.context[appName]);
    }

    getDxOverlayInfo($e) {
        let classAttr = $e.attr("class");
        classAttr = classAttr.split(' ');
        if (classAttr.length > 1) {
            classAttr = classAttr[1];
            classAttr = classAttr.split('-');
            classAttr = classAttr[1];
        } else {
            classAttr = 'unknown';
        }
        const aux = {
            dxType: classAttr,
            zIndex: +$e.css('z-index'),
            element: $e
        };
        return aux;
    }

    getDxOverlays() {
        const overlays = [],
            $overlays = $(".dx-overlay-wrapper");
        $.each($overlays, (it, el) => {
            const aux = this.getDxOverlayInfo($(el));
            overlays.push(aux);
        });
        return overlays;
    }

    setZIndex(overlay) {
        overlay.element.css('z-index', overlay.zIndex);
    }

    shouldBeSwap(prev, curr) {
        return prev.zIndex > curr.zIndex && prev.dxType !== curr.dxType;
    }

    swapElementZIndex(prev, curr) {
        const aux = curr.zIndex;
        curr.zIndex = prev.zIndex;
        prev.zIndex = aux;
        this.setZIndex(prev);
        this.setZIndex(curr);
    }

    fixDxOverlaysZIndex(overlays) {
        if (overlays.length < 2)
            return;
        let prev, curr;
        for (let i = 1; i < overlays.length; i++) {
            prev = overlays[i - 1];
            curr = overlays[i];
            if (this.shouldBeSwap(prev, curr)) {
                this.swapElementZIndex(prev, curr);
                continue;
            }
            break;
        }
    }

    fixOverlappingLoadings() {
        const overlays = this.getDxOverlays();
        this.fixDxOverlaysZIndex(overlays);
    }

    triggerWindowResize(): void {
        const resizeEvent = new Event('resize');
        window.dispatchEvent(resizeEvent);
    }

    getDynamicTerminateSessionData() {
        ////debugger("retriveing _getDynamicTerminateSessionData");
        if (iXing && iXing.IX_appForSignOut) {
            this.apiClientService.makeEcdRequest({
                "ServerCallType": "LST",
                "ApplicationVersion": "v4",
                "ApplicationName": iXing.IX_appForSignOut,
                "ContainerApplication": $("[data-workflow]").attr("data-workflow")
            }).then((data) => {
                //console.log("data came back for _getDynamicTerminateSessionData: ", data, data.Status);
                if (data && data.Status === "SUCCESS" && data.ListData && data.ListData.length > 1) {
                    iXing.IX_dynamicSignoutData = data;
                }
            })
        }
    }

    getDynamicSignoutData() {
        //console.log("_getSignoutURL: Calling getsignout URL");
        const config = {
            url: "/Membership/ExtPages/ilg.ashx?IX_DURL=Y",
            type: 'GET'
        };
        IX_Ajax(config).then((data) => {
            //console.log("data came back for _getDynamicSignoutData: ", data)
            if (!_.isNil(data.url)) {
                iXing.IX_urlForLogout = data.url;
                iXing.IX_urlForSignout = data.url;
            }
        });
    }

    getFormat(formatName) {
        return this.fieldFormatService.getFormat(formatName);
    }

    getAppCanvasReference(applicationName) {
        const canvasClass = ".IXCanvasApplication-";

        if (applicationName)
            applicationName = applicationName.split('.').join('-');

        return canvasClass + applicationName;
    }

    showLoadingFieldIndicator(appName, fieldName) {
        const fieldEle = this.getFieldElement(appName, fieldName);

        if (!fieldEle.length) {
            return;
        }

        const loadingIndicatorId = this.getLoadingIndicatorId(appName, fieldName);

        if ($('#' + loadingIndicatorId).length) {
            return;
        }

        fieldEle.prepend('<div id="' + loadingIndicatorId + '"></div>');

        $('#' + loadingIndicatorId).dxLoadIndicator({
            elementAttr: {
                style: 'position: absolute; z-index: 1;',
            },
            visible: true,
        });

    }

    hideLoadingFieldIndicator(appName, fieldName) {
        const loadingIndicatorId = this.getLoadingIndicatorId(appName, fieldName);

        const target = $('#' + loadingIndicatorId);
        if (!target.length) {
            return;
        }

        target.dxLoadIndicator('dispose');
        target.remove();
    }

    getFieldElement(appName, fieldName) {
        return $('[data-app="' + appName + '"]').find('.' + this.getFieldClassName(fieldName));
    }

    getLoadingIndicatorId(appName, fieldName) {
        return this.getEscapedName(appName) + '_' + this.getEscapedName(fieldName) + '_LoadingIndicator';
    }

    getFieldClassName(fieldName) {
        return 'CL_' + this.getEscapedName(fieldName);
    }

    getEscapedName(name) {
        return name.replace(/#/g, '').replace(/\./g, '');
    }

    getSanitizedValue(value) {
        if (UtilityFunctions.getThemeProperty('SkipSanitizingGridCells')) {
            return value;
        }

        if (value) {
            const trustedHtml = this.domSanitizer.sanitize(SecurityContext.HTML, value);
            return $('<div/>').html(trustedHtml).text();
        }
        return value;
    }

    getTrustedHtml(value) {
        if (UtilityFunctions.getThemeProperty('SkipSanitizingGridCells')) {
            return value;
        }

        if (value) {
            value = this.domSanitizer.sanitize(SecurityContext.HTML, value)
        }
        return value;
    }

    stripAllHtmlContent(value) {
        if (UtilityFunctions.getThemeProperty('SkipSanitizingGridCells')) {
            return value;
        }

        if (value) {
            value = filterXSS(value, {
                whiteList: [],        // empty, means filter out all tags
                stripIgnoreTag: true,      // filter out all HTML not in the whilelist
                stripIgnoreTagBody: ['script'] // the script tag is a special case, we need
                // to filter out its content
            });
        }

        return value;
    }

    checkWorkflowWizard($scope) {

        if (!$scope.context._wizards) return;

        const wizards = $scope.context._wizards;
        let reload = false;
        let isBrowserBackButton = false;
        $scope.currentWizard = sessionStorage.getItem("wizard");

        window.addEventListener('beforeunload', (e) => {
            if (sessionStorage.getItem("wizard")) {
                reload = true;
            }
            sessionStorage.setItem('reload', reload.toString());

            return false;
        });

        document.onmouseover = () => {
            //User's mouse is inside the page.
            isBrowserBackButton = false;
        }

        document.onmouseleave = () => {
            //User's mouse has left the page.
            isBrowserBackButton = true;
        }

        _.forEach(wizards, (value, key) => {
            wizards[key] = JSON.parse(value);

            const screens = wizards[key].Screens;
            for (let i = 0; i < screens.length; i++) {
                screens[i] = screens[i].replace(/\./g, '').toLowerCase();
            }

            // current step is first step on first page load
            if (screens[0] === $scope.step) {
                $scope.currentWizard = key;
                sessionStorage.setItem('wizard', key);
            }
        });

        // This gets triggered only on page load
        $scope.$on('$routeChangeSuccess', () => {
            if ($scope.currentWizard && sessionStorage.getItem('reload') === "true") {
                const wizardObj = wizards[$scope.currentWizard];
                const currentStep = $scope.step;
                const firstStep = wizardObj.Screens[0];

                if (currentStep !== firstStep) {
                    this.redirectDialog(wizardObj);
                }

                sessionStorage.removeItem('reload');
            }
        });

        // This gets triggered upon navigation
        $scope.$on('$locationChangeSuccess', (event, next, prev) => {
            const currentStep = $scope.step;
            let currentStepAWizardStep = false;

            if (prev) {
                let step = prev.split('s=');
                step = step.length > 1 && step[1].indexOf('&') > -1 ? step[1].split('&')[0] : step[1];
                $scope.previousStep = step;
            }

            _.forEach(wizards, (value, key) => {
                // current step is first step of wizard upon navigation
                if (value.Screens[0] === currentStep) {
                    $scope.currentWizard = key;
                    sessionStorage.setItem('wizard', key);
                }

                if (value.Screens.includes(currentStep)) {
                    currentStepAWizardStep = true;
                }
            });

            if (!currentStepAWizardStep) {
                sessionStorage.removeItem('wizard');
            }

            if ($scope.currentWizard) {
                const wizardObj = wizards[$scope.currentWizard];
                const lastStepInWizard = wizardObj.Screens[wizardObj.Screens.length - 1];

                if (sessionStorage.getItem('reload') === "true" ||
                    (currentStep === lastStepInWizard && !wizardObj.Screens.includes($scope.previousStep))) {

                    this.redirectDialog(wizardObj);

                    sessionStorage.removeItem('reload');
                    sessionStorage.removeItem('wizard');
                }
                else if (isBrowserBackButton) {
                    window.location.reload();
                }
            }
        });
    }

    redirectDialog(wizardObj) {
        this.alertService.enhanceAlert(wizardObj.Message, false, (result) => {
            if (result === true) {
                let url = window.location.href;
                const index = url.indexOf("s=");
                url = url.substring(0, index + 2) + wizardObj.Screens[0];

                //Typescript is inferring WorkerLocation and calling url readonly in unit tests
                //TODO: figure out why
                (window as Window).location.href = url;
            }
        });
    }

    translateShortListDataSource($scope, fieldName) {
        if ($scope.dataSources && $scope.dataSources[fieldName]) {
            _.forEach($scope.dataSources[fieldName], (item) => {
                item.Value = this.translateOrDefault(item.Value, item.Value);
                item.Key = this.translateOrDefault(item.Key, item.Key);
            });
        }

        return;
    }

    public updateAppWrapperHtmlAttributes(applet: Applet, ele: Element): void {
        const appName = applet.name ? applet.name : (applet.rid || "CL_WrongAppletConfig_0").split('_')[1];
        const appClass = this.applicationInformation.getAppClassName(appName);
        const element = $(ele);

        element.find(".dx-datagrid div[role='menu']").attr("aria-hidden", "true");
        element.find(".dx-treelist div[role='menu']").attr("aria-hidden", "true");
        element.addClass(appClass);
    }

    public decorateElementWithDataAttributes(element: any, config: any): void {
        const { appName, dataAttributes } = config;
        if (!dataAttributes) return;
        const interpolateText = '{LabelLowercase}';
        const text = element.innerText?.toLowerCase();
        const attributes = dataAttributes.split(',');
        attributes.forEach((attributePair: string) => {
            if (attributePair.indexOf('|') == -1) return;
            const keyValue = attributePair.split('|');
            const attributeName = 'data-' + _.first(keyValue);
            let attributeValue = _.last(keyValue);
            if (text) {
                attributeValue = attributeValue.replace(interpolateText, text);
            }
            attributeValue = this.dynamicReplacementService.getDynamicFieldValue(appName, attributeValue);
            if (_.isFunction(element.attr))
                element.attr(attributeName, attributeValue);
            else
                element.setAttribute(attributeName, attributeValue);
        });
    }
}
