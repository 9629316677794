/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { filter, tap } from 'rxjs/operators';
import { AppsConstantsFacade } from './apps-constants.facade';
import { WorkflowApplicationTree } from './workflow-application-tree.service';
import { WorkflowConfigurationService } from './workflow-configuration.service';
import { AppsConstants } from '../state/apps.constants';

@Injectable()
export class ApplicationInformation {

    private currentStepName: string;
    private applicationsMap: Record<string, AppInfo>;
    private defaultAppletLayout: AppletLayout;

    constructor(private appsConstantsFacade: AppsConstantsFacade,
        private workflowConfigurationService: WorkflowConfigurationService,
        private workflowApplicationTree: WorkflowApplicationTree) {
        this.defaultAppletLayout = {
            "x": 0,
            "y": 0,
            "width": 24,
            "height": 0,
            "auto": true,
            "manualResize": false,
            "innerSelector": ".applet-innercontainer > *",
            "adjustWidth": true,
            "adjustHeight": true,
            "canResize": true,
            "canMove": true
        };
        this.appsConstantsFacade.step$.subscribe(stepName => this.currentStepName = stepName);
        this.appsConstantsFacade.configuration$
            .pipe(
                filter(action => action?.contains("reload-service-configuration")),
                tap(() => this.reloadConfiguration())
            )
            .subscribe();
    }

    getMapKeys() {
        return Object.keys(this.applicationsMap);
    }

    getAppClassName(appName: string): string {
        return appName && !appName.isBlank() ? appName.replace(/\./g, '_') + 'Ctrl' : null;
    }

    private log(appName: string, appInfo: AppInfo): void {
        if (!IX_DEBUG_SETTINGS.appGrph.debug) return;
        if (IX_DEBUG_SETTINGS.appGrph.verbose)
            console.log("Getting info for app ", appName, " info ", appInfo);
        else
            console.log("Getting info for app ", appName, " found ", !_.isNil(appInfo));
    }

    getAppInfo(appName: string): AppInfo {
        const appInfo = this.applicationsMap[appName];
        if (!_.isNil(appInfo?.canvasApplet))
            appInfo.canvasApplet.layout = _.merge({}, this.defaultAppletLayout);
        this.log(appName, appInfo);
        return appInfo;
    }

    getStepApplets(appName: string, otherApps: string[]): AppInfo[] {
        const applets = this.workflowApplicationTree.getStepApplets(appName, otherApps);
        const apps = [];
        applets.forEach(applet => {
            const info = this.getAppInfo(applet.name);
            if (_.isNil(info)) return;
            apps.push({
                id: applet.name,
                name: applet.name,
                containerName: applet.containerName,
                template: info.canvasApplet.template,
                validationGroups: info.validationGroups,
                specialEffects: info.specialEffects,
                renderDelay: info.canvasApplet.renderDelay,
                // layout: info.canvasApplet.layout,
            });
        });
        return apps;
    }

    getFormats(appName) {
        return this.getAppInfo(appName)?.formats || {};
    }

    getFieldNamesToUppercase(appName) {
        return this.getAppInfo(appName)?.fieldNamesToUppercase || {};
    }

    getFilterCasings(appName) {
        return this.getAppInfo(appName)?.filterCasing || {};
    }

    getEcdRequestForTriggerDirective(appName: string, containerName: string, directive: string, force?: boolean): any {
        const appInfo = this.getAppInfo(appName);
        if (!_.isNil(appInfo) && this.isInputApp(appName)) {
            if (AppsConstants.triggerDirective[directive] !== "NONE") {
                const request = {
                    "ServerCallType": AppsConstants.triggerDirective[directive],
                    "ApplicationName": appName,
                    "ContainerApplication": containerName,
                    "ApplicationVersion": "v4"
                };
                if (appInfo.hasServer) {
                    if (_.isNil(containerName)) {
                        const applet = this.workflowApplicationTree.getStepApplet(this.currentStepName, appName);
                        request.ContainerApplication = applet.containerName;
                    }
                } else if (!force)
                    return;
                return request;
            }
        }
    }

    getFolder(appName): string {
        const appInfo = this.getAppInfo(appName);
        return appInfo.folder || "";
    }

    isBinaryRelay(appName) {
        const appInfo = this.getAppInfo(appName);
        return appInfo.componentType.toLowerCase() == "binaryrelay";
    }

    isAppWithinWorkflow(appName) {
        const appInfo = this.getAppInfo(appName);
        return !_.isNil(appInfo);
    }

    isComponent(appName) {
        const appInfo = this.getAppInfo(appName);
        if (_.isNil(appInfo)) {
            return false;
        }
        return appInfo.component;
    }

    private isListApplication(appInfo: AppInfo): boolean {
        return !_.isNil(appInfo) && appInfo.screenType == "List";
    }

    isListApp(appName: string): boolean {
        const appInfo = this.getAppInfo(appName);
        return this.isListApplication(appInfo);
    }

    isPillsList(appName: string): boolean {
        const appInfo = this.getAppInfo(appName);
        if (this.isListApplication(appInfo)) {
            return appInfo.componentType === "PillsList";
        }
        return false;
    }

    isPivotedList(appName: string): boolean {
        const appInfo = this.getAppInfo(appName);
        if (this.isListApplication(appInfo)) {
            return appInfo.componentType == "Pivoted";
        }
        return false;
    }

    isTreeList(appName: string): boolean {
        const appInfo = this.getAppInfo(appName);
        if (this.isListApplication(appInfo)) {
            return appInfo.componentType == "TreeList";
        }
        return false;
    }

    isInputApp(targetApp: string): boolean {
        const appInfo = this.getAppInfo(targetApp);
        return !_.isNil(appInfo) && appInfo.screenType == "Input";
    }

    isHolderApp(targetApp: string): boolean {
        const appInfo = this.getAppInfo(targetApp);
        if (_.isNil(appInfo)) return false;
        return appInfo.screenType == "Input" && !_.isEmpty(appInfo.mainApps);
    }

    supportsKeyCaching(appName) {
        const appInfo = this.getAppInfo(appName);
        return !_.isNil(appInfo) && !!appInfo.keyCache;
    }

    isV4App(appName: string): boolean {
        const appInfo = this.getAppInfo(appName);
        return !_.isNil(appInfo);
    }

    shouldApplyFilterOnShowOpenModalPopUp(targetApp: string): boolean {
        const appInfo = this.getAppInfo(targetApp);
        return this.isListApplication(appInfo) ? true : false;
    }

    getMainApplicationForSetFocus(canvas) {
        const appInfo = this.getAppInfo(canvas.appName);
        if (!_.isNil(appInfo) && !_.isEmpty(appInfo.mainApps)) {
            for (const key in appInfo.mainApps) {
                const layouts = appInfo.mainApps[key];
                if (layouts === "" || _.isNil(canvas.layoutSize) || layouts.indexOf(canvas.layoutSize) != -1)
                    return key;
            }
        }
        return "";
    }

    getAppletStringsToReplace(appName: string): Record<string, any> {
        const appInfo = this.getAppInfo(appName);
        return appInfo?.dynamicReplacements || {};
    }

    getPageLoadRequests(appName: string): any {
        const appInfo = this.getAppInfo(appName);
        return appInfo?.pageLoadRequests || {};
    }

    getServiceMaps() {
        const _serviceMaps = {
            serviceApp: {},
            appService: {},
        };
        for (const app in this.applicationsMap) {
            if (this.applicationsMap[app].hasServer) {
                const service = this.applicationsMap[app].server;
                _serviceMaps.appService[app] = service;
                if (!_serviceMaps.serviceApp[service]) {
                    _serviceMaps.serviceApp[service] = {};
                }
                _serviceMaps.serviceApp[service][app] = this.applicationsMap[app].serverParameters;
            }
        }
        return _serviceMaps;
    }

    private reloadConfiguration() {
        this.applicationsMap = this.workflowConfigurationService.getAppInfo();
    }

}
