/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-prototype-builtins */
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import '../libraries/sticky-kit.jquery';
import dxDataGrid from 'devextreme/ui/data_grid';
import { first } from 'rxjs/operators';
import { TranslateFacadeService } from './translate-facade.service';
import { ApplicationInformation } from './application-information.service';
import { UtilService } from './util.service';
import { FieldFormatService } from './field-format.service';
import { ValidationEngineService } from './validation-engine.service';
import { DynamicReplacementService } from './dynamic-replacement.service';
import { DeviceService } from './device-information.service';
import { ThemeService } from './theme.service';
import { FocusService } from './focus.service';
import { AccessibilityService } from './accessibility.service';
import { DataSourceService } from './datasource.service';
import { AppsConstantsFacade } from './apps-constants.facade';
import { PersonalizationService } from './personalization.service';
import { HelpersService } from './helpers.service';
import UtilityFunctions from '../utility.functions';
import { AppsConstants } from '../state/apps.constants';
import { DomComponentRetrievalService } from './dom-component-retrieval.service';
import { ComponentService } from './component.service';
import { AppEvent } from '../state/app-events.enum';
import { AppsEntity } from '../state/apps.models';
import { ListApplication } from '../components/list-application';
import { StoreService } from './store-service';

const STICKY_PARENT_ATTR = 'data-stickyparent';
const STICKY_HEADER_SCROLL_CORRECTION_ATTR = 'data-stickyheader-scroll-correction';
const SERIALIZED_INITIAL_GRID_STATE = '{}';

@Injectable()
export class UtilListService {

    buttonAriaLabels = {
        'dx-column-chooser-button': 'Choose columns'
    };
    optionChanges = {
        excluded: 'excluded',
        columnChooserDropdownClose: 'columnChooserDropdownClose',
        columnSortOrderChange: 'columnSortOrderChange',
        columnWidthChange: 'columnWidthChange',
        columnVisibilityChange: 'columnVisibilityChange',
        columnVisibilityIndexChange: 'columnVisibilityIndexChange',
    };

    enums = AppsConstants;
    _customSortColumnMap = {};
    currentBreakPoint: string;
    serializedAppStates: Map<string, any>;

    constructor(private translate: TranslateFacadeService,
        private personalizationService: PersonalizationService,
        private fieldFormatService: FieldFormatService,
        private applicationInformation: ApplicationInformation,
        private utilService: UtilService,
        private validationEngineService: ValidationEngineService,
        private dynamicReplacementService: DynamicReplacementService,
        private focusService: FocusService,
        private deviceService: DeviceService,
        private themeService: ThemeService,
        private accessibilityService: AccessibilityService,
        private dataSourceService: DataSourceService,
        private appsConstantsFacade: AppsConstantsFacade,
        private helpersService: HelpersService,
        private domComponentRetrievalService: DomComponentRetrievalService,
        private componentService: ComponentService,
        private storeService: StoreService
    ) {
        this.serializedAppStates = new Map<string, any>();
        this.appsConstantsFacade.breakPoint$.subscribe((breakPoint) => this.currentBreakPoint = breakPoint);
    }

    private getAppState(appName: string): Promise<any> {
        return this.storeService
            .getCurrentAppState(appName)
            .pipe(first())
            .toPromise();
    }

    private getAppComponent(appName: string): ListApplication {
        return this.domComponentRetrievalService.getAppComponent(appName) as ListApplication;
    }

    setColumnChooserImage($scope, image) {
        if (!_.isEmpty($scope.gridProperties.columnChooserLabel)) return;
        $scope.gridProperties.columnChooserImage = this.utilService.setASPNETThemePath(image);
    }

    disableRowSelection($scope, e) {
        const disableParams = $scope.disableRowSelection;
        if (disableParams === null)
            return;

        const component = e.component,
            visibleRows = component.getVisibleRows(),
            disableSelectionForRows = [];

        visibleRows.forEach((row) => {
            const data = row.data;
            let value = data[disableParams.fieldName];
            value = _.isNull(value) ? "" : value.toString();
            const compareTo = disableParams.value;
            let disable = false;
            switch (disableParams.criteria) {
                case "WhenValuesAreEqual":
                    disable = value === compareTo;
                    break;
                case "WhenValuesAreDifferent":
                    disable = value !== compareTo;
                    break;
            }
            if (disable) {
                disableSelectionForRows.push(row);
            }
        });

        let selectionCellIndex = null;
        disableSelectionForRows.forEach((row) => {
            if (selectionCellIndex === null) {
                for (let i = 0; i < row.cells.length; i++) {
                    const cell = row.cells[i];
                    if (cell.column.type === "selection") {
                        selectionCellIndex = i;
                        break;
                    }
                }
            }

            const selectionCell = row.cells[selectionCellIndex];
            const checkBoxInstance = selectionCell.cellElement.find(".dx-checkbox").dxCheckBox("instance");
            if (checkBoxInstance !== null) {
                checkBoxInstance.option("disabled", true);
                selectionCell.cellElement.off();
            }
        });

        const options = component.option();
        options.ic.disableSelectionForRows = disableSelectionForRows;
    }

    // TODO: This might be dead code. Could not find any reference
    onSelectionChanged(e) {
        const $scope = e.model;
        //adding a flag to gridProperties to know if the user selected a row in the grid
        if (!$scope.gridProperties.defaultFirstRowSelection && !_.isEmpty(e.component.getSelectedRowKeys())) {
            $scope.gridProperties.itemIsSelectedByUser = true;
        } else {
            $scope.gridProperties.itemIsSelectedByUser = false;
        };
        const options = e.component.option();
        const grid = e.component;
        const multiRowSelectValidationGroupName = grid.multiRowSelectValidationGroupName;
        if ($scope.limitSelectionRowCount === true) {
            const selectedItems = grid.getSelectedRowsData();
            const maxRowCount = $scope.maxRowCount;

            // Sets the footer text.
            $scope.dynamicObjs.footerText.updated = new Sugar.Date().iso().raw;

            if (selectedItems.length >= maxRowCount) {
                if (selectedItems.length > maxRowCount) {
                    grid.deselectRows([selectedItems[selectedItems.length - 1]]);
                }
            }
        }

        if (options.ic && !_.isUndefined(options.ic.disableSelectionForRows)) {
            const rowKeys = options.ic.disableSelectionForRows.map((current) => current.key);

            grid.deselectRows(rowKeys);
        }

        if (!_.isNil(multiRowSelectValidationGroupName)) {
            this.helpersService.setAppStateDirty($scope.applet.name);
            this.helpersService.refreshValidationGroupButtons($scope.$parent.context._events, multiRowSelectValidationGroupName);
        }
    }

    addMultiRowValidator(editor, validationGroupName: string): void {
        editor.component.multiRowSelectValidationGroupName = validationGroupName.toLowerCase();
        const validator = {
            validationGroup: validationGroupName.toLowerCase(),
            validationRules: [{
                enabled: true,
                type: 'custom',
                validationCallback: () => editor.component.getSelectedRowsData().length > 0,
            }],
        };

        editor.component.option('value', 'true'); // Validation adapter is expecting option value to be available on the editor component, it is not for a grid so we stub it

        this.validationEngineService.createNewValidator(editor, validator);
    }

    applyColumnOverrides($scope, defaultConfiguration, defaultConfigColumnsMap, uniqueBreakPointOverrides, breakPointOverrideMap) {
        if ($scope.gridInstance) {
            this.setColumnsFromState($scope.gridInstance, defaultConfigColumnsMap);
        }
        const currentOverride = uniqueBreakPointOverrides[breakPointOverrideMap[this.currentBreakPoint]];
        const currentConfiguration = _.merge([], defaultConfiguration, currentOverride);
        const columns = this.getGridColumnsDefaultConfiguration($scope, currentConfiguration);
        if ($scope.gridInstance) {
            $scope.gridInstance.option("columns", columns);
        }
        return columns;
    }

    setColumnsFromState(instance, defaultConfigColumnsMap) {
        const state = instance.state();
        if (_.isNil(state.columns)) return null;
        const props = 'visible,visibleIndex,sortOrder,sortIndex,width'.split(',');
        const columnsState = {};
        state.columns.forEach((column) => {
            const config = {};
            props.forEach((prop) => {
                if (!_.isNil(column[prop])) {
                    config[prop] = column[prop];
                }
            });
            columnsState[column.dataField] = _.merge({}, config);
        });
        for (const field in columnsState) {
            _.defaults(defaultConfigColumnsMap[field], columnsState[field]);
        }
    }

    getGridColumnsDefaultConfiguration($scope, defaultConfiguration) {
        const visibleColumns = defaultConfiguration.filter((column) => _.isNil(column.condition) || column.condition !== 'HiddenDoNotReserveSpace');
        visibleColumns.forEach((column) => {
            if (!_.isNil(column.condition) && column.condition.startsWith('Hidden')) {
                column.visible = false;
            }
            if (column.allowFiltering && _.isNil(column.calculateFilterExpression)) {
                column.calculateFilterExpression = (filterValue, selectedFilterOperation) => {
                    return this.calculateFilterExpressionWrapper(this, filterValue, selectedFilterOperation, $scope.applet, false);
                }
            }
            if (!_.isNil(column.format) && !_.isNil(column.format.name)) {
                const formatSettings = this.fieldFormatService.getFormat(column.format.name) || {};
                column.format.type = formatSettings.f;
            }
        });
        return visibleColumns;
    }

    getElementToAppend(ele, text, o) {
        if (ele) return ele;
        const span = document.createElement('span');

        if (_.isNil(text)) {
            text = ' ';
        }

        // Optimization because Column.isText gives us false negatives for our purpose here
        // and Element.textContent is faster than Element.innerHTML
        if (!text || o.isText || text === ' ' || this.isTextValue(text)) {
            span.textContent = text;
        } else {
            span.innerHTML = text;
        }

        return $(span);
    }

    getCellValueToDisplay(o, a, dr) {
        let value = dr ? this.dynamicReplacementService.getDynamicFieldValueForListCell(a, dr) : o.data[a.fieldName];

        if (typeof value === "string"
            && _.startsWith(value, "{Date:")
            && _.endsWith(value, "}")) {
            value = this.dynamicReplacementService.getDateReplacement(value);
        }

        if (_.isString(value) && value.toLowerCase() === 'null') {
            value = null;
        }

        if (!dr) {
            let conditionalFormat = null;
            if (typeof a.conditionalFormats !== 'undefined' && !$.isEmptyObject(a.conditionalFormats)) {
                const rowIndex = o.row ? o.row.rowIndex : null;
                conditionalFormat = this.utilService.getConditionalFormatRulesFieldMask(null, a.conditionalFormats, o.data, a.fieldName, rowIndex);
            }
            if (!!conditionalFormat || !!a.formats[a.fieldName]) {
                value = this.fieldFormatService.format(conditionalFormat || a.formats[a.fieldName], value);
            }
        }

        if (!o.isText) {
            if (o.column.stripHtmlTags)
                value = this.utilService.stripAllHtmlContent(value);
            else
                value = this.utilService.getTrustedHtml(value);
        }

        return value;
    }

    addAriaLabelToColumn(columnElement, columnName, columnNameText) {
        if (columnElement.attr('role') === 'gridcell') {
            // Don't add aria-label to dxDataGrid cells;
            // it is inappropriate for our implementation.
            // We'd just have to remove it.
            return;
        }

        //TODO: Determine if this code ever runs. If not, remove this function for performance improvement.
        let ariaLabel;
        if (!_.isNil(columnNameText)) {
            ariaLabel = 'Column ' + columnName + ', Value ' + columnNameText;
        } else {
            ariaLabel = 'Column ' + columnName;
        }
        columnElement.attr('aria-label', ariaLabel);
    }

    private isTextValue(value, dataType?) {
        // Testing if string contains HTML
        // https://regex101.com/r/GIzRty/21
        if (!value) return false;
        // eslint-disable-next-line no-useless-escape
        return !RegExp("<\/?\s*[a-z-][^>]*\s*>|(\&(?:[\w\d]+|#\d+|#x[a-f\d]+);)", "g").test(value);
        // return ! this.dynamicReplacementService.hasReplacementValue(value) &&
        //     (dataType === "number" ||
        //     dataType === "date" ||
        //     value === null ||
        //     value === undefined);
    }

    cellTemplateInner(c, o, a, ele, dr, inplace, applet) {
        // Optimization to not use HTML when not necessary
        const value = o.data[a.fieldName];
        o.isText = this.isTextValue(value, o.column.dataType);

        let cntnt = this.getCellValueToDisplay(o, a, dr);

        //checking the the column is a dynamic column with superscripts
        if (_.indexOf(applet.superscriptFields, a.fieldName) > -1) {
            //adding ADA support for the superscript texts in the summary cells abd to hide the 'sup' tag from the screen reader (VSTS 63305)
            if (_.isString(cntnt)) {
                const supStartInd = cntnt.indexOf("<sup>");
                const supEndInd = cntnt.indexOf("</sup>");
                const emptySupInd = cntnt.indexOf("<sup></sup>");
                if (supStartInd > -1 && supEndInd > -1 && emptySupInd === -1) {
                    cntnt = this.helpersService.makeSuperscriptAdaCompliant(cntnt, supStartInd, supEndInd);
                };
            }
        };

        const dre = this.getElementToAppend(ele, cntnt, o);
        if (o.column) {
            if (o.column.addTabIndexToGridNonButtonFields) {
                dre.attr({ 'tabindex': '0' });
            }
            this.addAriaLabelToColumn(c, o.column.caption, cntnt);
        }

        //TODO: Evaluate: is there a better place from which to set the edit-format?
        // This gets called many times, at least once per gridcell. We only need
        // to do this once *per column*.
        const fieldFormatName = a.formats[a.fieldName];
        if (o.column && o.component && fieldFormatName) {
            this.setColumnCellEditFormat(o.column, o.component, fieldFormatName);
        }

        if (typeof a.conditionalFormats !== 'undefined' && !$.isEmptyObject(a.conditionalFormats)) {
            a.fieldName = a.fieldName.startsWith("CL_") ? a.fieldName : ("CL_" + a.fieldName);
            (function (element, data, field, conditionalFormats) {
                IX_ConditionalFormatExecuteRules(element, conditionalFormats, data, IX_ConditionalFormatApplyFormat, field, o.row ? o.row.rowIndex : null);
            }(dre, o.data, a.fieldName, a.conditionalFormats));

        }

        c.attr("role", (o.column && o.column.isRowHeader === true) ? "rowheader" : "cell");
        if (c.length > 0)
            c.get(0).appendChild(dre.get(0));
    }

    setColumnCellEditFormat(column, component, formatName) {

        if (!column || !component || !formatName) {
            throw new Error('must have column object, component object and format name');
        }

        if (
            column.allowEditing &&
            !component.columnOption(column.dataField, 'editorOptions.format')
        ) {
            if (formatName.toLocaleLowerCase().indexOf('percent') !== -1) {
                const format = this.fieldFormatService.getFormat(formatName);

                if (format && format.f) {
                    // Strip out parens
                    const devExFormat = '##' + format.f.replace(/([()])/g, '');
                    component.columnOption(column.dataField, 'editorOptions.format', devExFormat);
                }
            }
        }
    }

    createCellTemplate(ce, ci, ele, dr, fd, applet, conditionalFormats, inplace?) {

        const a = {
            data: ci.data,
            appName: applet.name,
            formats: applet.config.formats || {},
            fieldName: fd ? fd : ci.column.dataField,
            conditionalFormats: conditionalFormats
        }
        //(function (_ce, _ci, _a, _ele, _dr, _inplace) {

        this.cellTemplateInner(ce, ci, a, ele, dr, inplace, applet);
        //}(ce, ci, a, ele, dr, inplace));
    }

    createOnEventHandlerSettings(dst, src) {
        dst._options = {
            setListContext: src.setListContext,
            eventHandler: src.eventHandler,
            cmdLstName: src.cmdLstName
        };
        return dst;
    }

    getAppletForDetailAppContext(tmpId, instanceId, isUnifiedTranslation, detailApp) {
        const applet = {
            rid: tmpId,
            name: instanceId,
            translationIdPrefix: null
        };
        if (isUnifiedTranslation) {
            applet.translationIdPrefix = detailApp;
        }

        return applet;
    }

    createDxGridMasterDetailTemplate(parentListApp, container, options, detailApp, masterDetailAriaDescription) {
        this.componentService.createDxGridMasterDetailTemplate(parentListApp, container, options, detailApp, masterDetailAriaDescription);
    }

    getCustomizeText(appName, fieldName, value) {
        const formats = this.applicationInformation.getFormats(appName)
        return this.fieldFormatService.format(formats[fieldName], value);
    }

    getColumnChooserOptions(gridComponent) {
        const outerContainer = _.get(gridComponent.getView('columnChooserView'), '_popupContainer._popup._$wrapper');
        if (!outerContainer) {
            return;
        }

        return outerContainer.find('.dx-treeview-node');
    }

    setCustomColumnChooserForGridState(applicationName, $scope, appElement, gridElement, gridComponent): void {

        const compStyle = UtilityFunctions.getDxComponentClass(gridElement);
        const columnChooserOptions = gridComponent.option('columnChooser');

        gridElement.find(".dx-toolbar-item").removeClass("dx-toolbar-item-invisible");
        gridElement.find(".dx-toolbar-menu-container").hide();

        let mutationObserver;
        if (columnChooserOptions && columnChooserOptions.mode !== 'select' && columnChooserOptions.mode !== 'dragAndDrop') {

            const dropdownBox = gridElement.find('.dx-dropdownbox');
            // Set up a mutation observer for the drop down column chooser, to await instantiation of the elements
            const debouncedPopulateAndTranslate = _.debounce((dxToolbar, dxTreeViewNodes) => {
                this.populateColumnChooserButtonContainer(dxToolbar, gridComponent, applicationName, $scope.gridId);
                this.updateColumnChooserList(dxTreeViewNodes, gridComponent, $scope);
            }, 75, { leading: true, trailing: false });


            dropdownBox.click(() => {
                mutationObserver?.disconnect();
                const mutationFunction = (mutations, observer) => {
                    const outerContainer = _.get(gridComponent.getView('columnChooserView'), '_popupContainer._popup._$wrapper');
                    if (!outerContainer) {
                        return;
                    }

                    const dxToolbar = outerContainer.find('.dx-toolbar-center');
                    const dxTreeViewNodes = this.getColumnChooserOptions(gridComponent);

                    // If the dxToolbar and dxTreeView aren't present yet,
                    // we must await their appearance before handling translation
                    if (!dxToolbar.length || !dxTreeViewNodes.length) {
                        return;
                    }

                    observer.disconnect();
                    debouncedPopulateAndTranslate(dxToolbar, dxTreeViewNodes);

                    // Fix max-height for column chooser once rendered
                    const $element = outerContainer.find('.dx-overlay-content.dx-popup-normal');
                    const parentHeight = $element.height();
                    $element.children().css('max-height', parentHeight);
                };
                mutationObserver = new MutationObserver(mutationFunction);
                mutationObserver.observe(appElement[0], { subtree: true, childList: true });
            });

        } else {

            const columnChooserButtonClass = '.' + compStyle + '-column-chooser-button';
            const columnChooserOverlayClass = '.dx-overlay-wrapper.' + compStyle + '-column-chooser';
            const columnChooserTitleClass = '.dx-item-content.dx-toolbar-item-content:empty';
            const ccBtn = gridElement.find(columnChooserButtonClass);

            // Set upp a mutation observer for the default column chooser button
            ccBtn.click(() => {
                mutationObserver?.disconnect();
                const mutationFunction = (mutations, observer) => {
                    const columnChooserPopup = ".dx-popup-normal";

                    let container = document.querySelector(columnChooserOverlayClass);
                    if (container) {

                        container = $(columnChooserPopup).find(columnChooserTitleClass).first();
                        if (!_.isNil(container)) {
                            observer.disconnect();
                            this.populateColumnChooserButtonContainer(container, gridComponent, applicationName, $scope.gridId);
                        }
                    }
                };
                mutationObserver = new MutationObserver(mutationFunction);
                mutationObserver.observe(appElement[0], { subtree: true, childList: true });
            });
        }

        $scope.onColumnChooserLabel = _.noop;
    }

    populateColumnChooserButtonContainer(buttonContainer, gridComponent, applicationName, gridId): void {
        buttonContainer.html('');
        this.setResetDxDataGridStateBtn(buttonContainer, gridComponent, applicationName, gridId);
        this.setSaveDxDataGridStateBtn(buttonContainer, gridComponent, applicationName, gridId);
        buttonContainer.css('display', 'flex');
    }

    private updateColumnChooserList(dxTreeViewNodes, gridComponent, $scope) {

        const baseTranslateId = this.getListBaseTranslationId($scope);
        const colMap = new Map<string, any>();
        gridComponent.option('columns').forEach((column) => {
            if (!column.showInColumnChooser) return;
            colMap.set(column.dataField, column);
        });

        dxTreeViewNodes.each((index, columnNameDomEle) => {
            const $treeNode = $(columnNameDomEle);
            const dataField = $treeNode.data('itemId');

            if (!colMap.has(dataField)) return;

            const column = colMap.get(dataField);
            const translatedText = this.getTranslatedFieldTextForColumn(column.dataField, baseTranslateId, $scope.buttons);
            $treeNode.find('div.dx-item-content span')[0].textContent = translatedText;
            this.accessibilityService.addColumnChooserCheckboxAria($treeNode);

            // Checked state does not auto-sync when the column order is manually adjusted
            const checked = columnNameDomEle.classList.contains('dx-state-selected');
            const shouldBeChecked = !!gridComponent.columnOption(column.dataField, 'visible');
            if (checked == shouldBeChecked) return;
            $treeNode.find('.dx-checkbox-icon')[0].click();
        });
    }

    getUniqCommaDelimList(inputs) {
        if (!Array.isArray(inputs)) {
            return null;
        }

        const arrays = [];

        inputs.forEach((input) => {
            if (this.isCommaDelimitedList(input)) {
                arrays.push(this.getArrayFromCommaDelimitedList(input));
            }
        });

        const values = _.flatten(arrays);
        return _.uniq(values).sort().join(',');
    }

    isCommaDelimitedList(input) {
        return !!this.getArrayFromCommaDelimitedList(input);
    }

    getArrayFromCommaDelimitedList(input) {
        if (typeof input !== 'string') {
            return null;
        }

        let toParse = input;
        if (toParse.charAt(0) !== '[') {
            toParse = '[' + toParse + ']';
        }

        try {
            const csv = JSON.parse(toParse);
            if (Array.isArray(csv)) {
                return csv;
            }
        } catch (e) {
            return null;
        }

        return null;
    }

    getDxComponentInstanceOrDefault(gridId, instance?) {
        if (_.isNil(gridId) && _.isNil(instance)) return null;
        if (!instance) {
            const appName = $('#' + gridId).data("appname");
            instance = this.domComponentRetrievalService.getDxComponentInstance({}, appName);
        }
        return instance;
    }

    resetDataGridState(gridComponent, appName, gridId) {
        gridComponent = this.getDxComponentInstanceOrDefault(gridId, gridComponent);
        gridComponent.hideColumnChooser();

        const key = this.createGridPersonalizationKey(gridId, appName);
        this.personalizationService.resetPersonalization(key);

        gridComponent.beginCustomLoading('Resetting');
        return this.loadDxDataGridState(appName, gridId).then((newState) => {
            newState = newState === null ? {} : newState;
            this.serializedAppStates.set(appName, SERIALIZED_INITIAL_GRID_STATE);
            gridComponent.state(newState);
            const scope = this.getAppComponent(appName);
            if (scope.icCustomGroupExpand)
                gridComponent.option('icCustomGroupExpand', scope.icCustomGroupExpand);
            this.updateColumnChooserSequence(gridComponent);
            this.recalculateHidingPriorities(gridComponent);
            const dataSource = gridComponent.getController('data')._dataSource;
            if (dataSource.hasOwnProperty('_isReload')) {
                dataSource._isReload = true;
            }
            gridComponent.endCustomLoading();
            return newState;
        });
    }

    setResetDxDataGridStateBtn(container, gridComponent, applicationName, gridId) {
        $('<div />').dxButton({
            text: gridComponent.option('columnChooser').resetText,
            onClick: (e) => {
                this.resetDataGridState(gridComponent, applicationName, gridId);
            },
            elementAttr: {
                class: 'ic-data-grid-column-chooser-button-reset-state',
            },
        }).appendTo(container);
    }

    getGridState(gridComponent) {
        return this.customizeGridState(gridComponent.state());
    }

    getFilterBuilder(gridId) {
        const filter = $("#FB_" + gridId);

        if (filter.length === 0)
            return null;

        return filter;
    }

    setFilterBuilderKey(gridId, data) {

        const filter = this.getFilterBuilder(gridId);
        if (!_.isNil(filter)) {
            const selectBox = filter.find('.dx-selectbox');

            if (selectBox.length > 0) {
                const filterKey = selectBox.dxSelectBox("instance").option("value");
                if (!_.isNil(filterKey)) {
                    data['filterKey'] = filterKey;
                }
            }
        }

    }

    getActionFromChangedProperty(fullName: string): string {
        fullName = fullName.replace(/(\[\d+\])/g, '');
        const validChangedProperties = {
            "CalmDropDown": {
                "hiding": this.optionChanges.columnChooserDropdownClose
            },
            "columns": {
                "hidingPriority": this.optionChanges.excluded,
                "sortOrder": this.optionChanges.columnSortOrderChange,
                "visible": this.optionChanges.columnVisibilityChange,
                "visibleIndex": this.optionChanges.columnVisibilityIndexChange,
                "width": this.optionChanges.columnWidthChange,
            },
        };
        const fnNo = _.at(validChangedProperties, fullName);
        return typeof fnNo[0] === "string" ? fnNo[0] : '';
    }

    getColumnDataFieldFromChangedProperty(fullName, component) {
        const matches = fullName.match(/(columns\[[0-9]*\])/);
        const columnRef = matches && matches.length && matches[0];
        return columnRef ? component.option(columnRef + '.dataField') : '';
    }

    onOptionChanged(scope, e) {
        const action = this.getActionFromChangedProperty(e.fullName);

        if (action === this.optionChanges.excluded) {
            // Avoid looping; if option might have been changed here, bail.
            return;
        }

        const saveOnChooserClosed = action === this.optionChanges.columnChooserDropdownClose && e.component.option('icAutoSaveOnColumnChooserClose');
        const saveOnSorted = action === this.optionChanges.columnSortOrderChange && e.component.option('icAutoSaveOnSort');
        const saveOnVisibilityIndexChange = action === this.optionChanges.columnVisibilityIndexChange && e.component.option('icAutoSaveOnColumnReposition');
        const saveOnWidthChanged = action === this.optionChanges.columnWidthChange && e.component.option('icAutoSaveOnColumnWidthChange');

        if (saveOnChooserClosed || saveOnSorted || saveOnVisibilityIndexChange || saveOnWidthChanged) {
            const gridComponent = this.getDxComponentInstanceOrDefault(scope.gridId, e?.component);
            const prepStateOnly = true;
            // timeout prevents column chooser hiding error
            setTimeout(() => this.saveDxDataGridState(gridComponent, scope.applet.name, scope.gridId, prepStateOnly));
        }

        if (action === this.optionChanges.columnVisibilityIndexChange || action === this.optionChanges.columnChooserDropdownClose) {
            setTimeout(() => {
                this.updateColumnChooserSequence(e.component);
                this.recalculateHidingPriorities(e.component);
            });
        }
    }

    recalculateHidingPriorities(component) {
        if (!component.option('icDynamicHidingPriority')) {
            return;
        }

        // Pause rendering grid to reduce performance impact
        component.beginUpdate();

        // Give highest priority by ordinal position, left-to-right
        // (.hidingPriority is the inverse of .visibleIndex)
        component.option('columns').map((column, idx, array) => {
            const visibleIndex = component.columnOption(column.dataField, 'visibleIndex');
            const hidingPriority = array.length - visibleIndex - 1;
            component.columnOption(column.dataField, 'hidingPriority', hidingPriority);
        });

        // Resume rendering
        component.endUpdate();
    }

    updateColumnChooserSequence(component) {
        if (!component.option('icDynamicColumnChooserSequence')) {
            return;
        }

        const columns = component.option('columns');
        columns.forEach((col, index) => col.originalIndex = index);

        columns.sort((a, b) => {
            // Fixed columns should be at the top in original order.
            if (a.fixed && b.fixed) {
                return a.originalIndex - b.originalIndex;
            } else if (a.fixed) {
                return -1;
            } else if (b.fixed) {
                return 1;
            }
            // Must use .columnOption getter. Column array objects still contain old values
            const aVisible = component.columnOption(a.dataField, 'visible');
            const bVisible = component.columnOption(b.dataField, 'visible');
            if (aVisible === bVisible) {
                const aIndex = component.columnOption(a.dataField, 'visibleIndex');
                const bIndex = component.columnOption(b.dataField, 'visibleIndex');
                return aIndex - bIndex;
            } else if (aVisible) {
                return -1;
            } else {
                return 1;
            }
        });

        component.beginUpdate();
        columns.forEach((col, index) => {
            delete col.originalIndex;
            if (component.columnOption(col.dataField, 'visibleIndex') !== index) {
                component.columnOption(col.dataField, 'visibleIndex', index);
            }
            if (col.fixed && col.visible && !component.columnOption(col.dataField, 'visible')) {
                component.columnOption(col.dataField, 'visible', true);
            }
        });
        component.endUpdate();
    }

    createGridPersonalizationKey(gridId, appName) {
        const data = {};
        const appId = appName.replace(/\./g, '');
        this.setFilterBuilderKey(gridId, data);
        const filterKey = data["filterKey"] || '';
        let key = 'grids.' + appId;
        if (filterKey) {
            key += '.FB_' + filterKey;
        } else {
            key += '.GV';
        }
        return key;
    }

    customSave(gridState, appName, gridId) {
        gridState = this.customizeGridState(gridState);
        if (this.stateHasChanged(gridState, appName)) {
            const key = this.createGridPersonalizationKey(gridId, appName);
            this.personalizationService.setPersonalization(key, gridState);
        }
    }

    saveGridState(gridComponent, appName, gridId) {
        if (gridComponent) {
            gridComponent.hideColumnChooser();
            const gridState = this.getGridState(gridComponent);
            if (this.stateHasChanged(gridState, appName)) {
                const key = this.createGridPersonalizationKey(gridId, appName);
                this.personalizationService.setPersonalization(key, gridState);
            }
        }
    }

    stateHasChanged(gridState, appName) {
        const alreadyPersisted = this.serializedAppStates.get(appName);
        const isInitialState = alreadyPersisted === SERIALIZED_INITIAL_GRID_STATE;
        const serializedState = JSON.stringify(gridState);

        if (serializedState === alreadyPersisted) {
            return false;
        }

        this.serializedAppStates.set(appName, serializedState);

        // The grid will call customSave() when we reset state to p-tier configuration,
        // which we can detect via SERIALIZED_INITIAL_GRID_STATE.
        // We don't need to update the server *this* time.
        // The server was *just* updated by resetPersonalization.
        return !isInitialState;
    }

    setSaveDxDataGridStateBtn(container, gridComponent, applicationName, gridId) {
        if (gridComponent.option('icAutoSaveOnColumnChooserClose')) {
            return;
        }

        $('<div />').dxButton({
            text: gridComponent.option('columnChooser').saveText,
            onClick: (e) => {
                this.saveDxDataGridState(gridComponent, applicationName, gridId);
            },
            elementAttr: {
                class: 'ic-data-grid-column-chooser-button-save-state',
            },
        }).appendTo(container);
    }

    saveDxDataGridState(gridComponent: dxDataGrid, applicationName: string, gridId: string, prepStateOnly?: boolean): void {
        // Pause rendering grid and column chooser to reduce performance impact
        this.beginColumnChooserUpdate(gridComponent);
        gridComponent.beginUpdate();
        if (this.helpersService.getThemeProperty('CalmColumnChooser', IX_Theme)) {
            const dxTreeViewNodes = this.getColumnChooserOptions(gridComponent);
            const columns = gridComponent.option('columns');
            const columnLookup = _.invertBy(columns, (c) => c.caption);
            if (dxTreeViewNodes && dxTreeViewNodes.length && columns && columns.length) {
                const state = gridComponent.state();
                let stateUpdated;
                const stateCols = state.columns;
                const stateColsLookup = _.invertBy(stateCols, (c) => c.dataField);
                dxTreeViewNodes.each((index, node) => {
                    const columnName = $(node).text();
                    const column = columnLookup[columnName] ? columns[columnLookup[columnName][0]] : null;
                    if (column) {
                        const visible = $(node).attr('aria-selected')?.toLowerCase() === 'true';
                        if (gridComponent.columnOption(columnName, 'visible') !== visible) {
                            gridComponent.columnOption(columnName, 'visible', visible);
                        }
                        if (stateCols) {
                            const stateCol = stateCols[stateColsLookup[column.dataField][0]];
                            if (stateCol && stateCol.visible !== visible) {
                                stateUpdated = true;
                                stateCol.visible = visible;
                            }
                        }
                    }
                });
                if (stateUpdated) {
                    gridComponent.state(state);
                }
            }
        }

        const filter = this.getFilterBuilder(gridId);
        if (!prepStateOnly) {
            if (_.isNil(filter)) {
                this.saveGridState(gridComponent, applicationName, gridId);
            } else {
                const s = filter.scope();
                s.postSave = () => {
                    this.saveGridState(gridComponent, applicationName, gridId);
                    //s.$applyAsync(function () {
                    s.postSave = null;
                    //}, 0);
                };
                s.performSave(s);
            }
        }
        gridComponent.endUpdate();
        this.endColumnChooserUpdate(gridComponent);
    }

    beginColumnChooserUpdate(gridComponent) {
        const columnChooser = gridComponent.getController('columnChooser');
        if (columnChooser && columnChooser.component) {
            columnChooser.component.beginUpdate();
        }
    }

    endColumnChooserUpdate(gridComponent) {
        const columnChooser = gridComponent.getController('columnChooser');
        if (columnChooser && columnChooser.component) {
            columnChooser.component.endUpdate();
        }
    }

    setCustomImageForGridExportExcel(gridId, exportImgSrc, $scope) {
        if ($scope && $scope.gridProperties) {
            $scope.gridProperties.excelExportImage = this.utilService.setASPNETThemePath(exportImgSrc);
        }
        $(document).ready(() => {
            setTimeout(() => {
                const gridElement = $('#' + gridId);
                const compStyle = UtilityFunctions.getDxComponentClass(gridElement);
                const exportButtonClass = '.' + compStyle + '-export-button';
                const eBtn = gridElement.find(exportButtonClass);
                this.setCustomImageForDxGrid(eBtn, exportImgSrc);
            }, 200);
        });
    }

    setCustomImageForDxGrid(container, columnImgSrc) {
        const appendToHere = container.find('div');
        for (let i = 0; i < appendToHere.length; i++) {
            const tmp = $(appendToHere[i]);
            if (tmp.find("img").length > 0)
                continue;
            if (columnImgSrc) {
                $('<img />').attr({
                    'src': columnImgSrc,
                    'role': 'presentation'
                }).appendTo(tmp);
            }
            tmp.find('i').hide();
        }
    }

    setCustomLabelForGridColumnChooser(gridId, $scope) {
        const gridElement = $('#' + gridId);
        const compStyle = UtilityFunctions.getDxComponentClass(gridElement);
        const columnChooserButtonClass = '.' + compStyle + '-column-chooser-button';
        const eBtn = gridElement.find(columnChooserButtonClass);
        if (eBtn) {
            const baseTranslateId = this.getListBaseTranslationId($scope, true);
            const columnChooserTitleTranslated = this.translateOrDefault(baseTranslateId + "columnChooserLabel.ariaLabel", $scope.gridProperties.columnChooserLabel);
            eBtn.text(columnChooserTitleTranslated);
        }
    }

    setCustomLabelForGridExportExcel(gridId, $scope) {
        const gridElement = $('#' + gridId);
        const compStyle = UtilityFunctions.getDxComponentClass(gridElement);
        const exportButtonClass = '.' + compStyle + '-export-button';
        const eBtn = gridElement.find(exportButtonClass);
        this.setCustomLabelForDxGrid(eBtn, this.utilService.getTranslation($scope.gridProperties.exportLabel));
    }

    setCustomLabelForDxGrid(container, exportLabel) {
        const appendToHere = container.find('div');
        for (let i = 0; i < appendToHere.length; i++) {
            const tmp = $(appendToHere[i]);
            if (container.text())
                continue;
            if (exportLabel) {
                container.text(exportLabel);
            }
            tmp.find('i').hide();
        }
    }

    setCustomRowsToExportToExcel(dxDataGrid) {

        if (dxDataGrid) {

            const remoteOperations = dxDataGrid.option("remoteOperations");
            if (typeof remoteOperations !== "string" && !!remoteOperations) {

                dxDataGrid.beginCustomLoading('Exporting');
                setTimeout(() => {
                    let currentlySelected = dxDataGrid.getSelectedRowKeys();
                    currentlySelected = currentlySelected.map((val) => dxDataGrid.getRowIndexByKey(val));
                    const totalRows = dxDataGrid._$element.find(".dx-datagrid-content:last table tbody tr").length;
                    dxDataGrid.beginUpdate();
                    const indexes = new Array(totalRows);
                    let toHide = dxDataGrid.option("columns");
                    let i: number;
                    toHide = toHide.filter((item) => item.dataField[0] === '#').map((item) => item.dataField);
                    for (i = 0; i < indexes.length - 1; indexes[i] = i++);
                    for (i = 0; i < toHide.length; i++)
                        dxDataGrid.columnOption(toHide[i], "visible", false);
                    dxDataGrid.selectRowsByIndexes(indexes);
                    dxDataGrid.exportToExcel(true);
                    dxDataGrid.clearSelection();
                    dxDataGrid.selectRowsByIndexes(currentlySelected);
                    for (i = 0; i < toHide.length; i++)
                        dxDataGrid.columnOption(toHide[i], "visible", true);
                    dxDataGrid.endUpdate();
                    dxDataGrid.endCustomLoading();

                }, 50);

                return true;
            }
        }
        return false;
    }

    customizeGridState(gridState) {
        const doNotSave = ["pageIndex", "pageSize", "selectedRowKeys", "allowedPageSizes"];
        for (const i in doNotSave) {
            delete gridState[doNotSave[i]];
        }
        if (gridState.columns) {
            gridState.columns.forEach((column) => {
                // If the width is a number, it's in pixels,
                // and should be stored as such so that it will
                // round-trip to the grid correctly
                if (!!column && typeof column.width === "number") {
                    column.width += 'px';
                }
            });
        }
        return gridState;
    }

    loadDxDataGridState(application, gridId) {
        const key = this.createGridPersonalizationKey(gridId, application);
        let gridState = this.personalizationService.getPersonalization(key);
        gridState = gridState ? gridState : {};
        if (this.hasCustomSort(application) && !gridState.icCustomSort && !gridState.sortByGroupSummaryInfo) {
            const gridComponent = this.getDxComponentInstanceOrDefault(gridId, null);
            const sortingState = this.callCustomGroupSorting(gridComponent, application);
            _.extend(gridState, sortingState);
            this.personalizationService.setPersonalization(key, gridState);
        }
        return Promise.resolve(this.customizeGridState(gridState || {}));
    }

    hasCustomSort(appName: string): boolean {
        const gridScope = this.getAppComponent(appName);
        const autoGroupingSummarySortProperty = this.themeService.getThemeProperty("#List.AutoGroupingSummarySort");
        let autoGroupingSummarySort = autoGroupingSummarySortProperty && autoGroupingSummarySortProperty.Value1;
        autoGroupingSummarySort = gridScope.autoGroupingSummarySort || autoGroupingSummarySort;
        return (autoGroupingSummarySort + "").toLowerCase() === "true" ||
            (gridScope.gridProperties.icCustomGroupSorting && gridScope.gridProperties.icCustomGroupSorting.length > 0);
    }

    private findTopMostSortColIndex(columns: any, depth: number): number {
        const sortIndex0 = 0;
        const sortIndex1 = 1;
        let columnIdx = _.findIndex(columns, function (col: any) {
            return !!col.sortOrder && col.sortIndex === depth;
        });
        if (columnIdx === -1 && depth == sortIndex0) {
            columnIdx = this.findTopMostSortColIndex(columns, sortIndex1);
        }
        return columnIdx;
    }

    private callCustomGroupSorting(gridComponent, appName) {
        // call customgroupsorting based on which column sort was set by ptier config
        const columns = gridComponent.option('columns');
        const columnIdx = this.findTopMostSortColIndex(columns, 0);
        if (columnIdx === -1) {
            return;
        }
        const column = columns[columnIdx];
        column.index = column.index || columnIdx;
        const controllerState = {
            gridScope: this.getAppComponent(appName),
            option: () => this.themeService,
            _columnsController: gridComponent.getController('columns'),
            component: gridComponent,
        };
        const visibleColumns = gridComponent.getVisibleColumns() || controllerState._columnsController._visibleColumns[0];
        const keyName = 'asc';
        return this.customGroupSorting(column, visibleColumns, controllerState, keyName, true);
    }

    getListBaseTranslationId($s, excludeBreakPoint?) {
        const namespace = this.utilService.getTranslationNameSpace($s.applet.name, excludeBreakPoint);
        return namespace + '.fields.';
    }

    getTranslatedTextForColumn(dataField, baseTranslateId, buttonConfigs, postfix) {
        const labelPostfix = postfix ? postfix : '.ariaLabel';
        let translateId;
        if (dataField[0] !== '#') {
            translateId = baseTranslateId + dataField + labelPostfix;
        } else if (/#button/i.test(dataField)) {
            const configMatch = _.findLast(buttonConfigs, (config) => config.fieldName === dataField);
            translateId = configMatch ? configMatch.translationId + labelPostfix : null;
        } else if ('#RowAction' === dataField) {
            translateId = baseTranslateId + 'RowAction' + labelPostfix;
        }
        if (_.isNil(translateId)) return null;
        const translationMatch = this.translate.instant(translateId);
        // Sometimes this.translate.instant can return an object, due to this.translateProvider.useSanitizeValueStrategy('sce');
        const translatedText = _.isNil(translationMatch) ? null : translationMatch.toString();
        if (translateId === translatedText) {
            return null;
        }
        return translatedText;
    }

    getTranslatedFieldTextForColumn(dataField, baseTranslateId, buttonConfigs): string {
        return this.getTranslatedTextForColumn(dataField, baseTranslateId, buttonConfigs, '.ixLabel');
    }

    private translateOrDefault(translateId: string, defaultText: string): string {
        return this.utilService.translateOrDefault(translateId, defaultText);
    }

    updateDxListComponentTranslationsInPlace($s, gridConfig): void {

        const altTranslateId = this.getListBaseTranslationId($s, true);

        gridConfig.columnChooser.saveText = this.translateOrDefault(altTranslateId + "columnChooser.saveText", gridConfig.columnChooser.saveText);
        gridConfig.columnChooser.resetText = this.translateOrDefault(altTranslateId + "columnChooser.resetText", gridConfig.columnChooser.resetText);
        gridConfig.columnChooser.dropDownLabel = this.translateOrDefault(altTranslateId + 'columnChooser.dropDownLabel', gridConfig.columnChooser.dropDownLabel);
        gridConfig.columnChooser.dropDownPlaceholder = this.translateOrDefault(altTranslateId + 'columnChooser.dropDownPlaceholder', gridConfig.columnChooser.dropDownPlaceholder);
        if (gridConfig.exportLabel)
            gridConfig.exportLabel = this.translateOrDefault(altTranslateId + "exportLabel.ariaLabel", gridConfig.exportLabel);
        gridConfig.csvExportLabel = this.translateOrDefault(altTranslateId + "csvExportLabel.ariaLabel", gridConfig.csvExportLabel);
        gridConfig.csvFlatExportLabel = this.translateOrDefault(altTranslateId + "csvFlatExportLabel.ariaLabel", gridConfig.csvFlatExportLabel);
        if (gridConfig.groupPanel && gridConfig.groupPanel.allowColumnDragging)
            gridConfig.groupPanel.emptyPanelText = this.translateOrDefault(altTranslateId + "groupPanelEmptyPanelText", gridConfig.groupPanel.emptyPanelText);

        if (!Array.isArray(gridConfig.columns)) return;

        const baseTranslateId = this.getListBaseTranslationId($s);
        gridConfig.columns.forEach((column, index) => {
            const translatedText = this.getTranslatedFieldTextForColumn(column.dataField, baseTranslateId, $s.buttons);
            column.caption = translatedText || column.caption;
            if (!Array.isArray(column.validationRules)) return;
            _.forEach(column.validationRules, (rule) => {
                if (!rule.message) return;
                rule.message = this.translateOrDefault(baseTranslateId + rule.fieldName + '.validation', rule.message);
            });
        });
    }

    updateDxListComponentTranslations($s): void {

        const instance = this.getDxComponentInstanceOrDefault($s.gridId);
        if (!instance) return;

        const baseTranslateId = this.getListBaseTranslationId($s);
        const altTranslateId = this.getListBaseTranslationId($s, true);
        const shownColumns = instance.option('columns').filter((column) => column.showInColumnChooser);
        const columns = instance.state().columns || instance.option('columns');
        const countableColumns = columns.filter((item) => shownColumns.filter((shownItem) => item.dataField === shownItem.dataField).length > 0);
        const columnChooser = instance.option('columnChooser');

        instance.beginUpdate();
        columnChooser.saveText = this.translateOrDefault(altTranslateId + "columnChooser.saveText", columnChooser.saveText);
        columnChooser.resetText = this.translateOrDefault(altTranslateId + "columnChooser.resetText", columnChooser.resetText);
        columnChooser.dropDownLabel = this.translateOrDefault(altTranslateId + "columnChooser.dropDownLabel", columnChooser.dropDownLabel);
        columnChooser.dropDownPlaceholder = this.translateOrDefault(altTranslateId + "columnChooser.dropDownPlaceholder", columnChooser.dropDownPlaceholder);

        // Update DOM directly if component is already rendered
        if (instance.$element()) {
            const columnChooserPlaceholder = instance.$element().find('.dx-dropdownbox .dx-placeholder');
            if (columnChooserPlaceholder.length) {
                const dropDownPlaceholder = {
                    text: columnChooser.dropDownPlaceholder,
                    select: columnChooser.placeholderSelect,
                    total: columnChooser.placeholderTotal,
                };

                // Update the DevExtreme config with the translated, interpolated placeholder
                columnChooser.dropDownPlaceholder = this.interpolateDropdownPlaceholder(countableColumns, dropDownPlaceholder);
                columnChooserPlaceholder.attr('data-dx_placeholder', columnChooser.dropDownPlaceholder);
            }

            const columnChooserLabel = instance.$element().find('[id$="Column_Chooser_Label"].dx-toolbar-widget-label');
            if (columnChooserLabel.length) {
                columnChooserLabel.text(columnChooser.dropDownLabel);
            }
        }

        // Translate column headers
        columns.forEach((column, index) => {
            const translatedText = this.getTranslatedFieldTextForColumn(column.dataField, baseTranslateId, $s.buttons);
            if (translatedText) {
                instance.columnOption(index, "caption", translatedText);
            }
        });

        _.forEach(this.buttonAriaLabels, (str, btnClass) => {
            $('.' + btnClass).attr('aria-label', this.translate.instant(str).toString());
        });

        instance.endUpdate();

    }

    interpolateDropdownPlaceholder(items, placeholder) {
        if (!placeholder.text) {
            return;
        }

        let selected = 0,
            total = 0;

        selected = items.filter((item) => {
            return item.visible;
        }).length;
        total = items.length;

        placeholder.text = placeholder.text.replace(placeholder.select, selected.toString());
        placeholder.text = placeholder.text.replace(placeholder.total, total.toString());

        return placeholder.text;
    }

    isCatapultApplet($s) {
        return $s.applet && $s.applet && $s.applet.name && this.applicationInformation.isV4App($s.applet.name);
    }

    updateListTranslations($s) {
        this.updateExportOptionsTranslations($s.gridProperties);

        if (this.isCatapultApplet($s)) {
            if (this.applicationInformation.isPivotedList($s.applet.name)) {
                this.updateDxListComponentTranslationsInPlace($s, $s.gridProperties);
            } else if (this.applicationInformation.isListApp($s.applet.name)) {
                this.updateDxListComponentTranslations($s);
            }
        }
    }

    private updateExportOptionsTranslations(gridProperties) {
        this.utilService.getTranslationWhenReady('CSV').then((translation) => { gridProperties.csvExportLabel = translation });
        this.utilService.getTranslationWhenReady('CSV (flat)').then((translation) => { gridProperties.csvFlatExportLabel = translation });
        this.utilService.getTranslationWhenReady('Excel').then((translation) => { gridProperties.excelExportLabel = translation });
        this.utilService.getTranslationWhenReady(gridProperties.exportLabel).then((translation) => { gridProperties.exportLabel = translation });
    }

    //TODO: test other components when apply filter is involved
    updateListComponentTranslations(listComponent) {

        if (!this.isCatapultApplet(listComponent)
            || !this.applicationInformation.isComponent(listComponent.applet.name)) return;

        let dataSource;
        if (!_.isNil(listComponent.config.dataSource))
            dataSource = listComponent.config.dataSource;
        else if (!_.isNil(listComponent.componentProperties) && !_.isNil(listComponent.componentProperties.dataSource))
            dataSource = listComponent.componentProperties.dataSource;
        else
            dataSource = listComponent.context._dataSources[listComponent.applet.name.normalize()];

        if (_.isNil(dataSource)) {
            IX_Log("component", "The datasource for the applet " + listComponent.applet.name + " is not defined.");
            return;
        }

        if (!dataSource.isLoading()) return;

        this.dataSourceService.getAppFilter(listComponent.applet.name)
            .then(filter => {
                dataSource.filter(filter);
                dataSource.reload();
            });
    }

    hasValidComponentItemValueParameters(valuesMap, field, currentItem) {
        const inValid = (!_.isPlainObject(valuesMap) || !_.isPlainObject(currentItem) || _.isNil(field) || _.isNil(valuesMap[field]) || _.isNil(currentItem[field]))
        return !inValid;
    }

    hasValidComponentTranslationParameters(appName, textMap, field, objectInfo) {
        const inValid = (_.isNil(appName) || _.isNil(field) || !_.isPlainObject(objectInfo) || !_.isPlainObject(textMap) || _.isNil(textMap[field]) || _.isNil(objectInfo[field]))
        return !inValid;
    }

    setComponentItemValue(valuesMap, field, currentItem) {
        if (this.hasValidComponentItemValueParameters(valuesMap, field, currentItem)) {
            currentItem[valuesMap[field]] = currentItem[field];
        }
    }

    setComponentItemTranslation(appName, itemTextMap, field, currentItem) {
        if (this.hasValidComponentTranslationParameters(appName, itemTextMap, field, currentItem)) {
            const translationId = appName + ".comp." + itemTextMap[field],
                translation = this.translate.instant(translationId).toString(),
                drConfig = {
                    currentItem: currentItem
                };
            if (translation !== translationId) {
                currentItem[field] = this.dynamicReplacementService.getComponentReplacement(translation, drConfig);
            }
        }
    }

    setComponentTranslation(appName, textMap, field, config) {
        if (this.hasValidComponentTranslationParameters(appName, textMap, field, config)) {
            const translationId = appName + ".comp." + textMap[field],
                translation = this.translate.instant(translationId).toString();
            // drConfig = {
            //     scope: config
            // };
            if (translation !== translationId) {
                config[field] = translation;
                // this.dynamicReplacementService.getComponentReplacement(translation, drConfig);
            }
        }
    }

    updateComponentPropertiesForTranslations(appName, componentItems, config) {
        let pageInfo, fieldMap, i = 0;
        for (; i < componentItems.length; i++) {
            pageInfo = componentItems[i];
            for (const field in config.itemFieldMap) {
                fieldMap = config.itemFieldMap[field];
                this.setComponentItemValue(config.itemValueMap, fieldMap, pageInfo);
            }
            for (const field in config.itemTextMap) {
                fieldMap = config.itemTextMap[field];
                pageInfo[fieldMap] = config[field];
                this.setComponentItemTranslation(appName, config.itemTextMap, fieldMap, pageInfo);
            }
        }
        for (const field in config.textMap) {
            fieldMap = config.textMap[field];
            this.setComponentItemTranslation(appName, config.textMap, fieldMap, config);
        }
    }

    getCustomSortThemeOverride(customSort) {
        if (this.dynamicReplacementService.hasReplacementValue(customSort)) {
            customSort = customSort.replace("{ThemeProperty:", "").replace("}", "");
            customSort = this.dynamicReplacementService.getThemePropertyReplacement(customSort);
        }
        return customSort;
    }

    createColumnCustomSortMap(customSort) {
        const map = {};
        customSort = this.getCustomSortThemeOverride(customSort);
        if (_.isString(customSort) && customSort.length > 0) {
            const elements = customSort.trim().split('|');
            elements.forEach((it, i) => {
                if (typeof it === "string" && it.length > 0) {
                    map[it] = i;
                }
            });
        }
        return map;
    }

    createCustomSortColumnMaps(columns) {
        const sortMap = {};
        if (Array.isArray(columns)) {
            columns.forEach((col) => {
                if (!_.isNil(col._customSort)) {
                    sortMap[col.dataField] = this.createColumnCustomSortMap(col._customSort);
                    if (!$.isEmptyObject(sortMap[col.dataField])) {
                        col.calculateSortValue = "_customSortFieldName" + col.dataField;
                        col.sortOrder = "asc"; // Without sortOrder property, calculateSortValue callback method is not called.
                    }
                }
            });
        }
        return sortMap;
    }

    customizeGroupTemplate(column, customSortMap) {

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;

        //TODO: support allowGrouping
        //if (_.isNil(col.groupIndex) || (_.isNil(col.allowGrouping)) && !col.allowGrouping) return;
        // Disable grouping for cell with button
        if (column.dataField[0] === '#') {
            column.allowGrouping = false;
            return;
        }

        const customizeDxGroupCellTemplate = (groupCell, info) => {

            groupCell = $(groupCell);

            const isText = self.isTextValue(info.text, info.column.dataType);
            const items = _.isNil(info.data.items) ? info.data.collapsedItems : info.data.items;
            if (info.column.icGroupingUnaffectedBySuperScript && !_.isNil(items)) {
                // sorts '<sup>4</sup>' before '<sup></sup>'
                const sortedRow = _.sortBy(items, [info.column.dataField])[0];
                let textWithSuperScript = _.isNil(sortedRow) ? '' : sortedRow[info.column.dataField];
                textWithSuperScript = _.isNil(textWithSuperScript) ? '' : textWithSuperScript;
                const infoText = self.utilService.getTrustedHtml(textWithSuperScript);
                $(document.createElement('div')).html(infoText).appendTo(groupCell);
            } else
                if (isText) {
                    const div = document.createElement('div');
                    div.textContent = info.text;
                    groupCell.get(0).appendChild(div);
                } else {
                    const infoText = self.utilService.getTrustedHtml(info.text);
                    $(document.createElement('div')).html(infoText).appendTo(groupCell);
                }
        }

        if (column.groupCellTemplate === undefined) {
            column.groupCellTemplate = customizeDxGroupCellTemplate;
        }

        const customizeDxCalculateGroupValue = function (data) {
            if (_.isNil(self._customSortColumnMap[this._namespace]))
                self._customSortColumnMap[this._namespace] = {};
            if (_.isNil(self._customSortColumnMap[this._namespace][this.dataField]))
                self._customSortColumnMap[this._namespace][this.dataField] = {};
            self._customSortColumnMap[this._namespace][this.dataField][data[this.calculateSortValue]] = data[this.dataField];
            return data[this.calculateSortValue];
        }


        const customizeDxCustomizeText = function (e) {
            const aux = ((self._customSortColumnMap[this._namespace] || {})[this.dataField]) || {};
            return aux[e.valueText] || e.valueText;
        }

        if (!$.isEmptyObject(customSortMap[column.dataField])) {
            column.calculateGroupValue = customizeDxCalculateGroupValue;
            column.customizeText = customizeDxCustomizeText;
        }
    }



    generateFileNameForExport(fileName) {
        return fileName + '_' + Sugar.Date.format(new Date(), '{yyyy}{MM}{dd}{HH}{mm}{ss}');
    }

    mapAggregatesToSummaryItems(aggregates, summaryItems) {
        return _.map(aggregates, function (aggregate, index) {
            return {
                column: summaryItems[index].column,
                format: summaryItems[index].format,
                value: aggregate
            }
        });
    }

    createAggregatesRow(headers, aggregates, title) {
        const ret = {};

        _.forEach(headers, function (o, index) {
            const headerDataField = headers[index].dataField;
            const matchingAggregate = aggregates.length ?
                _.find(aggregates, function (aggregate) {
                    return aggregate.column === headerDataField;
                }) :
                null;

            if (index.toString() === "0") {
                ret[headerDataField] = title || null;
            } else if (matchingAggregate) {
                ret[headerDataField] = matchingAggregate.value;
            } else {
                ret[headerDataField] = null;
            }
        });

        return ret;
    }

    updateColumnConfiguration(columns, customSortMap) {
        if (Array.isArray(columns)) {
            columns.forEach((col) => {
                this.customizeGroupTemplate(col, customSortMap);
            });
        }
    }

    createOnEditorPreparingOverrides(dxOptions): void {
        if (_.isPlainObject(dxOptions)) {
            dxOptions.onEditorPreparing = (e) => {
                if (e.parentType === "filterRow" && e.dataType === "number") {
                    e.editorOptions.mode = 'number';
                }
            }
        }
    }

    shouldResetDxMobileComponentProperties(dxOptions) {
        const customizeEnabled = dxOptions.stateStoring && dxOptions.stateStoring.enabled;
        return !customizeEnabled && dxOptions.columnHidingEnabled;
    }

    resetDxMobileComponentProperties(dxOptions) {
        const canReset = this.shouldResetDxMobileComponentProperties(dxOptions);
        if (canReset) {
            dxOptions.columns.forEach((col) => {
                delete col.hidingPriority;
            });
            dxOptions.columnHidingEnabled = false;
            dxOptions.columnAutoWidth = false;
            dxOptions.columnMinWidth = 70;
        }
    }

    shouldEnableMobileColumnSettings() {
        return this.helpersService.shouldEnableMobileColumnSettings();
    }

    setDxMobileComponentProperties(dxOptions) {
        if (this.deviceService.isPhone() && this.shouldEnableMobileColumnSettings()) {
            this.resetDxMobileComponentProperties(dxOptions);
        }
    }

    changeExpandCollapseInGridState($scope, isExpandAll) {
        // NOTE: dx-datagrid state does not save expand all grouping state
        const gridState = $scope.gridInstance.state();
        gridState.isExpandAll = isExpandAll;
        $scope.gridInstance.state(gridState);
    }

    setExpandCollapseButtonState($scope, expanded) {
        // Show/hide expand button
        let button = $("#" + $scope.gridId + "_ExpandButton");
        button.dxButton("instance").option("visible", !expanded);
        // Mark parent so it can be adjusted with css.
        if (!expanded) {
            button.parent().parent().removeClass('ic-gridbutton-state-invisible');
        } else {
            button.parent().parent().addClass('ic-gridbutton-state-invisible');
        }
        // Show/hide collapse button
        button = $("#" + $scope.gridId + "_CollapseButton");
        button.dxButton("instance").option("visible", !!expanded);
        // Mark parent so it can be adjusted with css.
        if (expanded) {
            button.parent().parent().removeClass('ic-gridbutton-state-invisible');
        } else {
            button.parent().parent().addClass('ic-gridbutton-state-invisible');
        }
    }

    loadExpandCollapseAllFromGridState($scope: ListApplication): void {
        if (!$scope.showOneExpandCollapseButton) {
            // Remove from config and manage with state
            $scope.gridInstance.option('grouping.autoExpandAll', null);
            $scope.gridInstance.option('autoExpandAll', null);
        }

        // NOTE: dx-datagrid state does not save expand all grouping state
        setTimeout(() => {
            const gridState = $scope.gridInstance.state();
            if (_.has(gridState, 'isExpandAll')) {
                if (gridState.isExpandAll) {
                    $scope.gridInstance.expandAll();
                    if ($scope.showOneExpandCollapseButton) {
                        // Make buttons match
                        this.setExpandCollapseButtonState($scope, true);
                    }
                } else {
                    $scope.gridInstance.collapseAll();
                    if ($scope.showOneExpandCollapseButton) {
                        // Make buttons match
                        this.setExpandCollapseButtonState($scope, false);
                    }
                }
            } else if ($scope.showOneExpandCollapseButton) {
                // State not persisted, set the default initial state
                this.setExpandCollapseButtonState($scope, $scope.gridInstance.option('grouping.autoExpandAll'));
            }
        }, 0);
    }

    /**
     * Currently returns 0 or undefined (meaning, all groups). Any other group level would require iterating
     * over its parent group levels as well, making the data grid unresponsive for tens of seconds on large data set.
     */
    private shouldLimitExpansion(config) {
        if (_.isNil(config)) {
            return undefined;
        }
        return _.includes(['false', 'no'], config.toString().toLowerCase()) ? 0 : undefined;
    }

    private getGroupingLevelToExpand(appName, gridProperties, gridToolbar) {
        const allGroupingLevelsOnExpandAction = gridProperties.icAllGroupingLevelsOnExpand;
        const allGroupingLevelsOnExpandParam = gridProperties.icAllGroupingLevelsOnExpandFromField;
        if (!_.isNil(allGroupingLevelsOnExpandParam)) {
            // Get the dynamic config value from a field.
            this.getAppState(appName).then(appState => {
                const expandFieldValue = appState[allGroupingLevelsOnExpandParam];
                const groupingLevelToExpand = this.shouldLimitExpansion(expandFieldValue);
                gridToolbar.component.expandAll(groupingLevelToExpand);
            });
        } else {
            const groupingLevelToExpand = this.shouldLimitExpansion(allGroupingLevelsOnExpandAction);
            gridToolbar.component.expandAll(groupingLevelToExpand);
        }
    }

    setExpandCollapsebtnOnHeaderPanel(dxOptions, $scope, gridToolbar) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;

        if (!$scope.gridProperties.showExpandCollapseBtnOnHeaderPanel ||
            ($scope.gridProperties.showExpandCollapseBtnOnHeaderPanel &&
                $scope.gridProperties.showExpandCollapseBtnOnHeaderPanel !== true)) {
            if (_.isPlainObject(dxOptions.groupPanel) && dxOptions.groupPanel.visible) {
                if ($scope.gridProperties.showExpandCollapseAllAdaptiveRows) {
                    gridToolbar.toolbarOptions.items.unshift({
                        locateInMenu: "auto",
                        location: "before",
                        name: "showHiddenRows",
                        options: {
                            icon: "chevrondown",
                            onClick: function (e) {
                                self.toggleHiddenApdativeRows($scope.gridId, $scope.gridInstance, true);
                            },
                            hint: "ShowHiddenRows",
                            text: "More"
                        },
                        showText: "inMenu",
                        sortIndex: 40,
                        widget: "dxButton"
                    },
                        {
                            locateInMenu: "auto",
                            location: "before",
                            nam: "hideHiddenRows",
                            options: {
                                icon: "chevronup",
                                onClick: (e) => {
                                    self.toggleHiddenApdativeRows($scope.gridId, $scope.gridInstance, false);
                                },
                                hint: "HideHiddenRows",
                                text: "Less"
                            },
                            showText: "inMenu",
                            sortIndex: 40,
                            widget: "dxButton"
                        });
                }

                const showOneExpandCollapseButtonProperty = self.themeService.getThemeProperty('ShowOneExpandCollapseButton');
                $scope.showOneExpandCollapseButton = !!showOneExpandCollapseButtonProperty
                    && showOneExpandCollapseButtonProperty.Value1
                    && showOneExpandCollapseButtonProperty.Value1.toUpperCase() === "TRUE";
                const autoExpand = $scope.gridInstance.option('grouping.autoExpandAll');

                gridToolbar.toolbarOptions.items.unshift({
                    locateInMenu: "auto",
                    location: "before",
                    name: "expandAllButton",
                    options: {
                        elementAttr: {
                            id: $scope.gridId + "_ExpandButton"
                        },
                        icon: "chevrondown",
                        onInitialized: (e) => {
                            if (e.element) {
                                $(e.element).keypress((e) => {
                                    const key = e.which;
                                    if (key == 13) self.onExpandCollapseAllClick(self, gridToolbar, $scope, true);
                                });
                            }
                        },
                        onClick: (e) => self.onExpandCollapseAllClick(self, gridToolbar, $scope, true),
                        hint: "ExpandAll",
                        text: "Expand All",
                        // If showing both buttons, show
                        // If state is persisted, hide for now to avoid visible button change
                        // Otherwise, set to default state for grid
                        visible: !$scope.showOneExpandCollapseButton || (!$scope.persistExpandCollapse && !autoExpand)

                    },
                    showText: "inMenu",
                    sortIndex: 40,
                    widget: "dxButton"
                }, {
                    locateInMenu: "auto",
                    location: "before",
                    name: "collapseAllButton",
                    options: {
                        elementAttr: {
                            id: $scope.gridId + "_CollapseButton"
                        },
                        icon: "chevronup",
                        onInitialized: (e) => {
                            if (e.element) {
                                $(e.element).keypress((e) => {
                                    const key = e.which;
                                    if (key == 13) self.onExpandCollapseAllClick(self, gridToolbar, $scope, false);
                                });
                            }
                        },
                        onClick: (e) => self.onExpandCollapseAllClick(self, gridToolbar, $scope, false),
                        hint: "CollapseAll",
                        text: "Collapse All",
                        // If showing both buttons, show
                        // If state is persisted, hide for now to avoid visible button change
                        // Otherwise, set to default state for grid
                        visible: !$scope.showOneExpandCollapseButton || (!$scope.persistExpandCollapse && autoExpand)

                    },
                    showText: "inMenu",
                    sortIndex: 40,
                    widget: "dxButton"
                });
            };
        };
    }

    onExpandCollapseAllClick(self, gridToolbar, $scope: ListApplication, isExpandAll) {
        if (isExpandAll) {
            this.getGroupingLevelToExpand($scope.applet.name, $scope.gridProperties, gridToolbar);
        } else {
            gridToolbar.component.collapseAll();
        }

        if ($scope.showOneExpandCollapseButton) {
            // Make buttons match new state
            self.setExpandCollapseButtonState($scope, isExpandAll);
        }

        if ($scope.persistExpandCollapse) {
            //to save the gridState if the grid is not already in collapse state
            if ($scope.gridInstance.state() && $scope.gridInstance.state().isExpandAll) {
                self.changeExpandCollapseInGridState($scope, isExpandAll);
                self.saveGridState($scope.gridInstance, $scope.applet.name, $scope.gridId);
            };
        }

        const event: AppsEntity = {
            id: AppEvent.ResetHorizontalScrollHandles,
            state: { appName: $scope.applet.name },
        };
        this.appsConstantsFacade.publishEvent(event);
    }

    toggleHiddenApdativeRows(gridId, gridComponent, expandAll) {
        const allAdaptiveRowButtons = $('#' + gridId).find('.dx-datagrid-adaptive-more');

        const adaptiveController = gridComponent.getController('adaptiveColumns');
        const dataController = adaptiveController.getController('data');
        dataController._icAllHiddenColumnsExpanded = expandAll;
        dataController._icExpandCollapseAdaptiveRows = void 0;

        gridComponent.beginCustomLoading();
        allAdaptiveRowButtons.each(function (key, button) {
            // Timeout is necessary as it crashes the page if there are too many records
            setTimeout(function () {
                button.click();
            }, 0);
        });
        gridComponent.endCustomLoading();
    }

    findExportBtnIndex(items) {
        for (let i = 0; i < items.length; i += 1) {
            if (items[i].name === "exportButton") {
                return i;
            }
        }
        return -1;
    }

    setDxComponentToolBarProperties(dxOptions: any, $scope) {

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;

        if (_.isPlainObject(dxOptions)) {
            const existingOnToolbarPreparing = dxOptions.onToolbarPreparing;


            dxOptions.onToolbarPreparing = function (gridToolbar) {
                self.setExpandCollapsebtnOnHeaderPanel(dxOptions, $scope, gridToolbar);

                const toolbarItems = gridToolbar.toolbarOptions.items;

                //ensures export button is the last item in the toolbar
                if (toolbarItems.length > 0 && dxOptions.export.enabled) {
                    toolbarItems.push(toolbarItems.splice((self.findExportBtnIndex(toolbarItems)), 1)[0]);
                }

                const gridId = $scope.gridId;
                const appSelector = '[data-app="' + $scope.applet.name + '"]';
                const appNode = document.querySelector(appSelector);

                self.updateExportOptionsTranslations($scope.gridProperties);

                const exportOptions = {
                    excel: {
                        clickCallback: gridToolbar.component.exportToExcel,
                        cssClass: '-excel-export',
                        enabled: dxOptions.export ? !!dxOptions.export.enabled : false,
                        format: 'xlsx',
                        hint: dxOptions.excelExportLabel,
                        image: dxOptions.excelExportImage,
                        order: 0,
                        text: dxOptions.excelExportLabel
                    },
                    csv: {
                        clickCallback: this.exportToCsv,
                        cssClass: '-csv-export',
                        enabled: !!dxOptions.csvExportEnabled,
                        flat: false,
                        format: 'csv',
                        hint: dxOptions.csvExportLabel,
                        image: dxOptions.csvExportImage,
                        text: dxOptions.csvExportLabel
                    },
                    csvFlat: {
                        clickCallback: this.exportToCsv,
                        cssClass: '-csv-flat-export',
                        enabled: !!dxOptions.csvFlatExportEnabled,
                        flat: true,
                        format: 'csv',
                        hint: dxOptions.csvFlatExportLabel,
                        image: dxOptions.csvFlatExportImage,
                        text: dxOptions.csvFlatExportLabel
                    }
                };

                const numEnabledOptions = _.filter(exportOptions, function (option) {
                    return !!option.enabled;
                }).length;

                if (numEnabledOptions > 1) {
                    const exportMenuId = gridId + '-export-menu-' + Guid.create();

                    const exportMenuButtonSuffix = '-export-menu-button';
                    const exportMenuButtonClass = 'dx' + exportMenuButtonSuffix;

                    const exportMenuItemClass = 'dx-export-menu-item';

                    const menuItems = [];

                    if (exportOptions.excel.enabled) {
                        const exportButton = _.find(gridToolbar.toolbarOptions.items, function (item) {
                            return item.name === 'exportButton';
                        });

                        gridToolbar.toolbarOptions.items.splice(gridToolbar.toolbarOptions.items.indexOf(exportButton), 1);

                        menuItems.push(exportOptions.excel);
                    }

                    if (exportOptions.csv.enabled) {
                        menuItems.push(exportOptions.csv);
                    }

                    if (exportOptions.csvFlat.enabled) {
                        menuItems.push(exportOptions.csvFlat);
                    }

                    $('body').append('<div id="' + exportMenuId + '"></div>');

                    gridToolbar.toolbarOptions.items.push({
                        widget: 'dxButton',
                        options: {
                            hint: dxOptions.exportLabel,
                            template: function () {
                                return $('<img />')
                                    .attr({
                                        alt: dxOptions.exportLabel,
                                        role: 'presentation',
                                        src: self.utilService.setASPNETThemePath(dxOptions.exportMenuButtonImage),
                                        title: dxOptions.exportLabel
                                    });
                            },
                            text: dxOptions.exportLabel,
                            elementAttr: {
                                class: exportMenuButtonClass // Add a class by to target this later in the MutationObserver
                            },
                            onInitialized: function (args) {
                                $('#' + exportMenuId).dxContextMenu({
                                    showEvent: 'dxclick',
                                    items: menuItems,
                                    itemTemplate: function (itemData, itemIndex, itemElement) {
                                        itemElement.addClass('dx' + itemData.cssClass + ' ' + exportMenuItemClass);
                                        return $('<img />')
                                            .attr({
                                                alt: itemData.hint,
                                                role: 'presentation',
                                                src: itemData.image,
                                                title: itemData.hint
                                            });
                                    },
                                    onItemClick: function (item) {
                                        const debounced = _.debounce(function () {
                                            if (item.itemData.format === 'csv') {
                                                item.itemData.clickCallback(gridToolbar, item.itemData.flat, $scope);
                                            } else if (item.itemData.format === 'xlsx') {
                                                item.itemData.clickCallback(false);
                                            }
                                        });

                                        debounced();
                                    },
                                    onShown: function (evt) {
                                        // This hides the menu when navigating via *dropdown* click. Otherwise outside click is not fired.
                                        const routeUpdate = $scope.$on('$routeUpdate', function () {
                                            evt.component.hide();
                                            routeUpdate();
                                        });
                                    },
                                    target: args.element,
                                    position: {
                                        at: 'left bottom',
                                        my: 'left top',
                                        offset: '0 3',
                                        collision: 'fit',
                                        boundary: gridToolbar.element,
                                        boundaryOffset: '1 1'
                                    }
                                })
                            }
                        },
                        location: 'after'
                    });

                    self.exportOptionsMutationObserver(appNode, gridId, '.' + exportMenuButtonClass, exportMenuButtonSuffix);
                } else if (numEnabledOptions === 1 && !exportOptions.excel.enabled) {
                    const exportOption = exportOptions.csvFlat.enabled ? exportOptions.csvFlat : exportOptions.csv;
                    const exportButtonClass = 'dx' + exportOption.cssClass + '-button';

                    gridToolbar.toolbarOptions.items.push({
                        location: 'after',
                        options: {
                            elementAttr: {
                                class: exportButtonClass
                            },
                            onClick: function () {
                                const debounced = _.debounce(function () {
                                    exportOption.clickCallback(gridToolbar, exportOption.flat, $scope);
                                });

                                debounced();
                            },
                            template: function () {
                                return $('<img />').attr({
                                    alt: exportOption.hint,
                                    role: 'presentation',
                                    src: exportOption.image,
                                    title: exportOption.hint
                                });
                            },
                            text: exportOption.text
                        },
                        widget: 'dxButton'
                    });

                    self.exportOptionsMutationObserver(appNode, gridId, exportButtonClass, exportOption.cssClass);
                }

                if (existingOnToolbarPreparing && _.isFunction(existingOnToolbarPreparing)) {
                    existingOnToolbarPreparing(gridToolbar);
                }
            }
        }
    }

    exportOptionsMutationObserver(appNode, gridId, buttonClass, buttonSuffix) {
        if (appNode === null) return;
        // Add custom CSS classes to context menu button and items that reference the type of grid component used.
        // Must be in a MutationObserver, so that the grid is ready when we check for its component class.
        return new MutationObserver(function (mutations, observer) {
            const grid = document.querySelector('#' + gridId);

            if (grid) {
                observer.disconnect();

                const gridElement = $('#' + gridId);

                gridElement
                    .find(buttonClass)
                    .addClass(UtilityFunctions.getDxComponentClass(gridElement) + buttonSuffix);
            }
        }).observe(appNode, {
            childList: true,
            subtree: true
        });
    }

    setCustomSummaryCalculation(o, calculation, fields) {
        switch (o.summaryProcess) {
            case 'start':
                o.totalValue = {};

                _.forEach(fields, function (field) {
                    let result;
                    if (field.calculateOption === 'avg') {
                        result = {
                            count: 0,
                            total: 0
                        };
                    } else if (field.calculateOption === 'min') {
                        result = Number.MAX_VALUE;
                    } else if (field.calculateOption === 'max') {
                        result = Number.MIN_VALUE;
                    } else {
                        result = 0;
                    }

                    if (typeof (field.forGroupIndex) === "undefined") {
                        o.totalValue[field.name] = result;
                    }
                    else {
                        o.totalValue[field.name] = o.totalValue[field.name] || {};
                        o.totalValue[field.name][field.forGroupIndex] = result;
                    }
                });

                break;
            case 'calculate':
                _.forEach(fields, (field) => {
                    // Exactly one calculate operation is required
                    const val = !_.isPlainObject(o.value) ? o.value : o.value[field.name];
                    let objectToManipulate;

                    if (typeof (field.forGroupIndex) === "undefined") {
                        objectToManipulate = o.totalValue[field.name];
                    }
                    else {
                        objectToManipulate = o.totalValue[field.name][field.forGroupIndex];
                    }

                    if (field.calculateOption === 'sum') {
                        objectToManipulate += val || 0;
                    } else if (field.calculateOption === 'max') {
                        objectToManipulate = Math.max(val, objectToManipulate);
                    } else if (field.calculateOption === 'min') {
                        objectToManipulate = Math.min(val, objectToManipulate);
                    } else if (field.calculateOption === 'avg') {
                        objectToManipulate.count++;
                        objectToManipulate.total += val || 0;
                    } else if (field.calculateOption === 'count') {
                        objectToManipulate++;
                    }

                    if (typeof (field.forGroupIndex) === "undefined") {
                        o.totalValue[field.name] = objectToManipulate;
                    }
                    else {
                        o.totalValue[field.name][field.forGroupIndex] = objectToManipulate;
                    }
                });

                break;
            case 'finalize': {
                let replacedCalculation = calculation;

                if (Array.isArray(calculation)) {
                    _.forEach(calculation, (calc) => {
                        if (o.groupIndex === (calc.forGroupIndex - 1)) {
                            replacedCalculation = calc.summaryCalculation;
                        }
                    });
                }

                _.forEach(fields, (field) => {
                    if (typeof (field.forGroupIndex) === "undefined") {
                        replacedCalculation = replacedCalculation.replace(
                            field.withOptions,
                            field.calculateOption === "avg" ? o.totalValue[field.name].total / o.totalValue[field.name].count : o.totalValue[field.name]
                        );
                    }
                    else {
                        if (o.groupIndex === (field.forGroupIndex - 1)) {
                            replacedCalculation = replacedCalculation.replace(
                                field.withOptions,
                                field.calculateOption === "avg" ? o.totalValue[field.name][field.forGroupIndex].total / o.totalValue[field.name][field.forGroupIndex].count : o.totalValue[field.name][field.forGroupIndex]
                            );
                        }
                    }
                });

                o.totalValue = this.runEvalCode(replacedCalculation);
                o.totalValue = !_.isFinite(o.totalValue) || !_.isNumber(o.totalValue) ? 0 : o.totalValue;

                break;
            }
        }
    }

    private runEvalCode(replacedCalculation: string): any {
        let result = null;
        try {
            // tslint:disable-next-line: no-eval
            result = eval(replacedCalculation);
        } catch (e) {
            const error = { e, replacedCalculation };
            IX_Log('component', error);
            throw (e);
        }
        return result;
    }

    setNullableCustomSummaryCalculation(o, calculation, fields) {

        switch (o.summaryProcess) {
            case 'start':
                o.totalValue = {};

                _.forEach(fields, (field) => {
                    if (typeof (field.forGroupIndex) === 'undefined') {
                        o.totalValue[field.name] = null;
                    }
                    else {
                        o.totalValue[field.name] = o.totalValue[field.name] || {};
                        o.totalValue[field.name][field.forGroupIndex] = null;
                    }
                });

                break;
            case 'calculate':
                _.forEach(fields, (field) => {
                    // Exactly one calculate operation is required
                    const val = !_.isPlainObject(o.value) ? o.value : o.value[field.name];

                    if (val === null) {
                        return;
                    }

                    let objectToManipulate;

                    if (typeof (field.forGroupIndex) === "undefined") {
                        objectToManipulate = o.totalValue[field.name];
                    }
                    else {
                        objectToManipulate = o.totalValue[field.name][field.forGroupIndex];
                    }

                    if (objectToManipulate === null) {
                        if (field.calculateOption === 'avg') {
                            objectToManipulate = {
                                count: 0,
                                total: 0
                            };
                        } else if (field.calculateOption === 'min' || field.calculateOption === 'max') {
                            objectToManipulate = val;
                        } else {
                            objectToManipulate = 0;
                        }
                    }

                    if (field.calculateOption === 'sum') {
                        objectToManipulate += val;
                    } else if (field.calculateOption === 'max') {
                        objectToManipulate = Math.max(val, objectToManipulate);
                    } else if (field.calculateOption === 'min') {
                        objectToManipulate = Math.min(val, objectToManipulate);
                    } else if (field.calculateOption === 'avg') {
                        objectToManipulate.count++;
                        objectToManipulate.total += val;
                    } else if (field.calculateOption === 'count') {
                        objectToManipulate++;
                    } else if (field.calculateOption === 'distinctCsv') {
                        objectToManipulate = this.getUniqCommaDelimList([objectToManipulate, val]);
                    }

                    if (typeof (field.forGroupIndex) === "undefined") {
                        o.totalValue[field.name] = objectToManipulate;
                    }
                    else {
                        o.totalValue[field.name][field.forGroupIndex] = objectToManipulate;
                    }
                });

                break;
            case 'finalize': {
                let replacedCalculation = calculation;

                if (Array.isArray(calculation)) {
                    _.forEach(calculation, (calc) => {
                        if (o.groupIndex === (calc.forGroupIndex - 1)) {
                            replacedCalculation = calc.summaryCalculation;
                        }
                    });
                }

                _.forEach(fields, (field) => {
                    if (replacedCalculation === null) {
                        return;
                    }

                    if (typeof (field.forGroupIndex) === "undefined") {
                        if (o.totalValue[field.name] === null) {
                            replacedCalculation = null;
                        } else if (field.calculateOption === 'avg') {
                            replacedCalculation = replacedCalculation.replace(
                                field.withOptions,
                                o.totalValue[field.name].total / o.totalValue[field.name].count
                            );
                        } else if (field.calculateOption === 'distinctCsv') {
                            replacedCalculation = replacedCalculation.replace(
                                field.withOptions,
                                '"' + o.totalValue[field.name] + '"'
                            );
                        } else {
                            replacedCalculation = replacedCalculation.replace(
                                field.withOptions,
                                o.totalValue[field.name]
                            );
                        }
                    }
                    else {
                        if (o.groupIndex === (field.forGroupIndex - 1)) {
                            if (o.totalValue[field.name][field.forGroupIndex] === null) {
                                replacedCalculation = null;
                            } else if (field.calculateOption === 'avg') {
                                replacedCalculation = replacedCalculation.replace(
                                    field.withOptions,
                                    o.totalValue[field.name][field.forGroupIndex].total / o.totalValue[field.name][field.forGroupIndex].count
                                );
                            } else if (field.calculateOption === 'distinctCsv') {
                                replacedCalculation = replacedCalculation.replace(
                                    field.withOptions,
                                    '"' + o.totalValue[field.name][field.forGroupIndex] + '"'
                                );
                            } else {
                                replacedCalculation = replacedCalculation.replace(
                                    field.withOptions,
                                    o.totalValue[field.name][field.forGroupIndex]
                                );
                            }
                        }
                    }
                });

                o.totalValue = this.runEvalCode(replacedCalculation);
                o.totalValue = !_.isFinite(o.totalValue) && _.isNumber(o.totalValue) ? null : o.totalValue;

                break;
            }
        }
    }

    setupOnRowPrepared($scope): void {
        const existingHandler = $scope.gridProperties.onRowPrepared;
        $scope.gridProperties['onRowPrepared'] = (e) => {
            e.rowElement = $(e.rowElement);
            if ($scope.rowReorderSequenceField) {
                if (e.rowType !== 'data')
                    return;
                e.rowElement.addClass('rowDraggable').data('rowKey', e.key.Id);
            }
            this.checkIfGroupingRowAndAddCorrectAria($scope, e);
            if (!_.isNil(existingHandler))
                existingHandler(e);
            this.stopAggregationOnRowPrepared($scope, e);
            this.setupCustomGroupingClasses($scope, e);
            this.setupGroupBlockingOnRowPrepared($scope, e);
            this.applyDeleteStateClasses($scope, e);
            this.showFixedGroupingHeaders($scope, e);
        };
        $scope.gridProperties["ariaDescriptionFieldForCheckBox"] = $scope.ariaDescriptionFieldForCheckBox;
    }

    showFixedGroupingHeaders($scope, e) {
        if (!$scope.gridProperties.columnFixing?.enabled || !$scope.gridProperties.summary?.groupItems || e.rowType !== 'group') return;
        const fixedCols = $(e.element).find('.dx-datagrid-headers .dx-datagrid-content-fixed col.dx-col-fixed').length;
        const groupingRow = e.rowElement[0];
        const groupCols = $(groupingRow).find('td:not(.dx-last-cell)').length;
        const badNode = groupingRow.childNodes[1];
        const badNode2 = groupingRow.childNodes[2];
        if ($(badNode).css('visibility') === 'hidden') {
            badNode.colSpan = 1 + (fixedCols - groupCols);
            $(badNode).css('visibility', '');
        } else if ($(badNode2).css('visibility') === 'hidden') {
            badNode2.colSpan = 1 + (fixedCols - groupCols);
            $(badNode2).css('visibility', '');
        }
    }

    applyDeleteStateClasses($scope, e) {
        const gridEditingConfig = ($scope.gridProperties && $scope.gridProperties.editing) || {};
        if (!gridEditingConfig.allowDeleting || gridEditingConfig.mode !== 'batch') {
            return;
        }

        if (e.rowType !== 'data' || e.level < 1) {
            return;
        }

        // TODO deregister editable rows
        // const $deregister = $scope.$watch(() => {
        //     return this.editableRowMupService.isDeleted(e.node);
        //     },
        //     () => {
        //         e.rowElement.toggleClass('etl-child-row-removed', this.editableRowMupService.isDeleted(e.node));
        //     }
        // );

        // e.rowElement.on('remove', function () {
        //     $deregister();
        // });
    }

    stopAggregationOnRowPrepared($scope, e): void {
        const { groupingStopAggregationLevels, groupingStopAggregationTargetFields } = $scope.applet;
        const stopGrouping = !_.isEmpty(groupingStopAggregationLevels) && !_.isEmpty(groupingStopAggregationTargetFields);
        if (!stopGrouping) return;

        const stopAggLevels = groupingStopAggregationLevels;
        const stopAggTargets = groupingStopAggregationTargetFields;
        const groupingStopAggregationForceBlank = $scope.applet.groupingStopAggregationForceBlank;
        if (stopAggLevels && stopAggLevels.length > 0 && e.rowType === 'group') {

            let targetGroupLevel = -1;
            const groupedColumns = _.chain($scope.gridProperties.columns)
                .filter((item) => item.groupIndex !== undefined)
                .sortBy(['groupIndex'])
                .map((item) => ({ selector: item.dataField }))
                .value();

            for (let i = 0; i < stopAggLevels.length; i++) {
                for (let j = 0; j < groupedColumns.length; j++) {
                    if (groupedColumns[j].selector === stopAggLevels[i]) {
                        targetGroupLevel = j;
                        break;
                    }
                }
                if (targetGroupLevel > -1) {
                    break;
                }
            }

            if (targetGroupLevel === -1) {
                return;
            }

            if (e.groupIndex < targetGroupLevel) {
                if (stopAggTargets && stopAggTargets.length > 0) {
                    for (let i = 0; i < e.cells.length; i++) {
                        const cell = e.cells[i];
                        const cellField = cell.column.dataField;
                        for (let j = 0; j < stopAggTargets.length; j++) {
                            if (cellField === stopAggTargets[j]
                                && cell.summaryItems?.length > 0) {
                                cell.summaryItems[0].value = null;
                                cell.summaryItems[0].stopAggregationForceBlank = groupingStopAggregationForceBlank;
                            }
                        }
                    }
                } else {
                    for (let i = 0; i < e.cells.length; i++) {
                        const cell = e.cells[i];
                        if (cell.summaryItems?.length > 0) {
                            cell.summaryItems[0].value = null;
                            cell.summaryItems[0].stopAggregationForceBlank = groupingStopAggregationForceBlank;
                        }
                    }
                }
            }
        }
    }

    setupGroupBlockingOnRowPrepared($scope, e) {
        if ($scope.allowGroupBlocking && $scope.groupBlockingLevel) {
            let target;

            if (e.rowType === 'group' && e.key.length === $scope.groupBlockingLevel) {
                target = e.rowElement.find('td.dx-command-expand');
                target.each(function (t) {
                    target[t].removeAttribute('role');
                    target[t].removeAttribute('aria-expanded');
                    target[t].removeAttribute('aria-label');
                });
                target.empty();
            };

            if (e.rowType === 'data') {
                e.rowElement.hide();
            };
        }
    }

    checkIfGroupingRowAndAddCorrectAria($scope, e) {
        if (e.rowType === 'group') {
            const target = e.rowElement.find('td.dx-command-expand');
            if (target.length > 0) {
                target.hasClass("dx-selection-disabled") ?
                    target.removeAttr("tabindex").attr("aria-hidden", true) : target.attr("tabindex", "0");
                target.attr("role", "button");
                target.removeAttr("aria-colindex");
                target.attr("aria-expanded", e.rowElement.attr("aria-expanded"));
                e.rowElement.removeAttr("aria-expanded");
            }
        };
    };

    setupCustomGroupingClasses($scope, e) {
        if ($scope.groupingRowClasses) {
            if (e.rowType === 'group' && e.key.length) {
                e.rowElement.addClass($scope.groupingRowClasses[e.key.length - 1]);
            }
        }

        if ($scope.groupingDataClass) {
            if (e.rowType === 'data') {
                e.rowElement.addClass($scope.groupingDataClass);
            }
        }
    }

    generateValidationGroups($scope, validationEngine) {
        _.forEach($scope.gridProperties.columns, function (column) {
            if (column.validationGroupName) {
                validationEngine.addGroup(column.validationGroupName);
            }
        });
    }

    setUpDxComponentProperties($scope) {
        this.setDxComponentToolBarProperties($scope.gridProperties, $scope);
        this.setDxMobileComponentProperties($scope.gridProperties);
        this.createOnEditorPreparingOverrides($scope.gridProperties);
        const customSortColumnMaps = this.createCustomSortColumnMaps($scope.gridProperties.columns);
        this.updateColumnConfiguration($scope.gridProperties.columns, customSortColumnMaps);
        this.updateDxListComponentTranslationsInPlace($scope, $scope.gridProperties);
        $scope.gridProperties.gridScope = $scope;
        return customSortColumnMaps;
    }

    setDropdownEditorOptions(e, $scope, onValueChangedCallback) {
        if (_.isNil(e.lookup)) {
            return;
        }

        if (e.parentType === 'dataRow' && e.showEditorAlways) {
            e.editorName = 'dxSelectBox';
            e.editorOptions.onFocusOut = (arg) => {
                const formattedVal = this.fieldFormatService.getFormattedValue($scope.applet.config.formats[e.dataField], arg.element.find('input').val());
                e.editorElement.dxSelectBox('instance').option('value', formattedVal);
            };
        }

        if (typeof onValueChangedCallback === "function") {
            e.editorOptions.onValueChanged = (args) => {
                e.setValue(args.value);
                onValueChangedCallback($scope, e, e.dataField, args.value, args.previousValue);
            };
        }
    }

    getEditableCheckboxCellTemplate(cellElement, cellInfo, ariaLabel, lookupValues?) {
        let yesValue = 'Y';
        let noValue = 'N';

        if (!_.isNil(lookupValues)) {
            yesValue = lookupValues.split(',')[0];
            noValue = lookupValues.split(',')[1];
        }
        $('<div/>').dxCheckBox({
            onInitialized: function (e) {
                const setValue = cellInfo.value === yesValue ? true : false;
                $(e.element).dxCheckBox({
                    value: setValue,
                    elementAttr: {
                        'aria-label': ariaLabel ? ariaLabel : null
                    }
                });
            },
            onValueChanged: function (e) {
                const setValue = e.value ? yesValue : noValue;
                // Read only prroperty, cannot assign, setValue updates the data model
                // cellInfo.data[cellInfo.column.dataField] = setValue;
                if (cellInfo.setValue !== null)
                    cellInfo.setValue(setValue);
            },
            ic: {
                yesValue: yesValue,
                noValue: noValue
            }
        }).appendTo(cellElement);

        cellElement.data("field", cellInfo.column.dataField);
    }

    // getEditableCellTemplate(cellElement, cellInfo, $gridScope, onValueChangedCallback) {
    //     let boxName = "";
    //     const column = cellInfo.column;
    //     const dataField = column.dataField;

    //     if (column.editorConfig.numberBoxModel) {
    //         column.numberBoxModel = column.editorConfig.numberBoxModel;
    //         boxName = "ic-number-box";
    //         if (column.editorOptions && column.editorOptions.showClearButton) {
    //             column.numberBoxModel.showClearButton = true;
    //         }
    //     } else if (column.editorConfig.dateBoxModel) {
    //         column.dateBoxModel = column.editorConfig.dateBoxModel
    //         boxName = "ic-date-box";
    //         if (column.editorOptions && column.editorOptions.showClearButton) {
    //             column.dateBoxModel.showClearButton = true;
    //         }
    //         if (cellInfo.value) {
    //             cellInfo.value = cellInfo.value.toISOString().substr(0,10);
    //         }
    //     } else {
    //         column.textBoxModel = column.editorConfig.textBoxModel;
    //         boxName = "ic-text-box";
    //         if (column.editorOptions && column.editorOptions.showClearButton) {
    //             column.textBoxModel.showClearButton = true;
    //         }
    //     }

    //     if (column.editorConfig.numberProperties) {
    //         column.numberProperties = column.editorConfig.numberProperties;
    //         column.numberProperties.EagerValidate = "Yes";
    //     }

    //     const $scope = $gridScope.$new();
    //     $scope.model = {
    //         text: cellInfo.text,
    //         value: cellInfo.value
    //     };
    //     $scope.config = column;
    //     $scope.applet = $gridScope.applet;
    //     $scope.context = $gridScope.context;
    //     $scope.checkSize = $gridScope.checkSize;
    //     $scope.updateState = function (field, value) {
    //         const previousValue = cellInfo.value;
    //         const unchanged = value === previousValue;
    //         if (unchanged) {
    //             // Avoid incorrectly highlighting unchanged cell in batch edit mode.
    //             return;
    //         }

    //         // Notify grid of input component value change
    //         // see https://js.devexpress.com/Documentation/18_2/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/#editCellTemplate
    //         if (cellInfo && cellInfo.setValue)
    //             cellInfo.setValue(value);

    //         if (angular.isFunction(onValueChangedCallback)) {
    //             onValueChangedCallback($gridScope, cellInfo, dataField, value, previousValue);
    //         }
    //     };

    //     const icEditorControl = "<" + boxName + " model='model' class='" + cellInfo.column.cssClass + "' config='config' update-state='updateState' check-size='checkSize' context='context' ></" + boxName + ">";
    //     const itemToAppend = $compile(icEditorControl)($scope);
    //     itemToAppend.appendTo(cellElement);
    //     cellElement.data("field", column.dataField);

    //     if ($gridScope.gridProperties.ignoreForcedFocus || !column.showEditorAlways) {
    //         return;
    //     }

    //     // Investigated: intent of this setTimeout block is to allow tab-navigation to bring focus to the *same*
    //     // field in the *next* row. As an alternative, can opt-out with screen property #List.IgnoreForcedFocus
    //     // and instead use screen property #List.HandleTabKey.
    //     setTimeout(function () {
    //         const validationErrors = cellInfo.cellElement.find('.dx-invalid-message');
    //         if (!validationErrors || validationErrors.length < 1) {
    //             if (cellInfo.component.getVisibleRows().length > cellInfo.rowIndex + 1) {
    //                 const nextCell = cellInfo.component.getCellElement(cellInfo.rowIndex + 1, dataField);
    //                 if (nextCell === null)
    //                         return;

    //                 const nextElementToFocus = nextCell.find('[dx-text-box]').dxTextBox("instance");
    //                 if (nextElementToFocus) {
    //                     nextElementToFocus.focus();
    //                 }
    //             }
    //         }
    //     }, 100);
    // }

    validationCallbackMandatoryForChild(options, $scope) {
        if (!options || !options.data) {
            return false;
        }

        const isParent = options.data[$scope.relationshipFieldName] === $scope.relationshipFieldValueIfParent;
        return isParent || (!_.isNil(options.value) && options.value !== '');
    }

    validationCallbackMandatoryForParent(options, $scope) {
        if (!options || !options.data) {
            return false;
        }

        const isChild = options.data[$scope.relationshipFieldName] === $scope.relationshipFieldValueIfChild;
        return isChild || (!_.isNil(options.value) && options.value !== '');
    }

    calculateFilterExpressionWrapper(_this, filterValue, selectedFilterOperation, scopeApplet, isListWithODCEnabled) {

        const calculateFilterExpression = function (filterValue, selectedFilterOperation, format, isListWithODCEnabled, fieldNamesToUppercaseRules) {
            const dataField = this.dataField;
            const dataType = this.dataType;

            if (
                selectedFilterOperation !== "=" || isListWithODCEnabled || !format
            ) {
                const expression = this.defaultCalculateFilterExpression.apply(this, [filterValue, selectedFilterOperation]);
                if (
                    !Array.isArray(expression) ||
                    !_this.mustUppercaseFieldName(dataField, selectedFilterOperation, fieldNamesToUppercaseRules)
                ) {
                    return expression;
                }

                if (expression[0] === dataField) {
                    expression[0] = dataField.toUpperCase();
                } else {
                    expression.forEach(function (expr) {
                        if (Array.isArray(expr) && expr[0] === dataField) {
                            expr[0] = dataField.toUpperCase();
                        }
                    });
                }

                return expression;
            }

            return function (itemData) {
                const formattedValue = IX_GetFormattedField(format, itemData[dataField]);
                let numeric = _this.fieldFormatService.unformat(format, formattedValue, itemData[dataField]);

                if (filterValue < 0) {
                    numeric = Math.abs(numeric) * -1;
                }

                if (filterValue instanceof Date) {
                    if (dataType === "date") {
                        return (
                            new Date(numeric).toDateString() ===
                            filterValue.toDateString()
                        );
                    } else {
                        return numeric === filterValue.toISOString();
                    }
                } else {
                    return numeric === filterValue;
                }
            };
        }


        const fieldCasing = scopeApplet.config.filterCasing ? scopeApplet.config.filterCasing[_this.dataField] : null;
        if (filterValue && typeof filterValue === "string" && fieldCasing) {
            filterValue = fieldCasing.toLowerCase() === "uppercase" ? filterValue.toUpperCase() : filterValue.toLowerCase();
        }

        const fieldFormat = scopeApplet.config.formats[_this.dataField];
        const format = this.fieldFormatService.getFormat(fieldFormat);
        const fieldNamesToUppercaseRules = scopeApplet.config.fieldNamesToUppercase;
        return calculateFilterExpression.apply(_this, [filterValue, selectedFilterOperation, format, isListWithODCEnabled, fieldNamesToUppercaseRules]);
    };



    mustUppercaseFieldName(fieldName, selectedFilterOperation, rules) {
        if (!rules || !rules.hasOwnProperty(fieldName)) {
            return false;
        }

        const dict = {
            "<": ["datelessthan"],
            "<=": ["datelessthanorequalto"],
            "<>": ["datedoesnotequal", "doesnotequal"],
            "=": ["dateequals", "equals"],
            ">": ["dategreaterthan"],
            ">=": ["dategreaterthanorequalto"],
            "between": ["datebetween"],
            "contains": ["contains"],
            "endswith": ["endswith"],
            "notcontains": ["doesnotcontain", "notcontains"],
            "startswith": ["startswith"],
        };

        if (!dict[selectedFilterOperation]) {
            return false;
        }

        if (!rules[fieldName]) {
            // Empty ScreenProp.Parameters interpreted as "apply to all filter operations"
            return true;
        }

        const operationsInRule = rules[fieldName].toLowerCase().split(',');
        const affectedFilterOps = dict[selectedFilterOperation];
        return !!_.intersection(operationsInRule, affectedFilterOps).length;
    }

    customizeDxExportData(cols, rows, isGroupingCustomTemplate, sourceColumnGroupIndex, targetColumnName, scope) {
        rows.forEach(function (row) {

            // grouping values
            if (row.rowType !== 'data') {
                const rowValues = row.values;
                if (
                    row.rowType === 'group' && !!isGroupingCustomTemplate &&
                    !!sourceColumnGroupIndex && !!targetColumnName &&
                    sourceColumnGroupIndex === row.groupIndex &&
                    typeof (rowValues[0]) !== 'object' && !!row.data &&
                    !!row.data.items && row.data.items.length > 0 &&
                    _.has(row.data.items[0], targetColumnName)
                ) {
                    rowValues[0] = row.data.items[0][targetColumnName];
                }

                for (let i = 0; i < rowValues.length; i++) {
                    if (!!rowValues[i] && typeof (rowValues[i]) === 'object') {
                        const r = Array.isArray(rowValues[i]) ? rowValues[i][0] : rowValues[i];
                        const format = _.isNil(r.valueFormat) ? r.format : r.valueFormat;
                        if (format && format.type) {
                            r.value = this.fieldFormatService.format(scope.applet.config.formats[r.column], r.value);
                        }
                    }
                }
            }
        });
    }

    /**
     * Conditionally filter the dropdown in an editable cell of a datagrid/treelist
     * Add the new filter logic to this.getDxSelectBoxFilter
     * @param {object} linkAppFilters hashmap of LinkAppName: {
     *   {string} type: a filter type, defined in this.getDxSelectBoxFilter,
     *   {string} dataFieldName: the grid field the control of which is being filtered
     *   {expr1}  any
     * }
     * @param {object} $scope
     */
    filterDxSelectBox(e, linkAppFilters, $scope) {
        if (!e.row || e.editorName !== 'dxSelectBox') {
            return;
        }

        Object.keys(linkAppFilters).forEach((linkAppName) => {
            if (e.dataField === linkAppFilters[linkAppName].dataFieldName) {
                const dataSource = e.editorOptions.dataSource;

                dataSource.filter = this.getDxSelectBoxFilter(
                    e,
                    linkAppFilters[linkAppName],
                    $scope
                );
            }
        });
    }

    getDxSelectBoxFilter(e, linkAppFilter, $scope) {
        let filterFunction;

        switch (linkAppFilter.type) {
            case 'ParentChild': {
                const parentIdExpr = linkAppFilter.expr1;

                const showParents = function (itemData) {
                    return _.isNil(itemData[parentIdExpr]) || itemData[parentIdExpr] === '';
                };

                const showChildren = function (itemData) {
                    return !_.isNil(itemData[parentIdExpr]) && itemData[parentIdExpr] !== '';
                };

                if (e.row.data[$scope.gridProperties.parentIdExpr]) {
                    filterFunction = showChildren;
                } else {
                    filterFunction = showParents;
                }
                break;
            }
            default:
                filterFunction = function () { return true; };
        }

        return filterFunction;
    }

    onCellClick($scope, cellEvent) {
        if ($scope.gridProperties.icRestoreFocusToClickedCell) {
            this.focusService.storeGridCell(cellEvent.component, cellEvent.rowIndex, cellEvent.columnIndex);
        }
    }

    /**
     * Sort grouping header rows in addition to normal data rows
     * Currently single column sort only
     * @param {Array} data grid dataSource nested array of data items and aggregates
     */
    sortGroupingSummaries(data, $scope, sortLevels, nullsLast, retainSummarySort) {
        if (!data.length) {
            return data;
        }

        const groupItems = $scope.gridInstance.option('summary').groupItems;

        if (!groupItems) {
            return data;
        }

        const levels = sortLevels ? sortLevels.split(',').map(Number) : [];
        data.thisLevel = 1;

        const gridColumns = $scope.gridInstance.option().columns;
        const getOption = function (dataField, prop) {
            if (retainSummarySort) {
                const col = _.find(gridColumns, { dataField: dataField });
                return col[prop];
            }
            return $scope.gridInstance.columnOption(dataField, prop);
        }

        const sortableGroups = $scope.gridInstance.option('columns')
            .filter(function (col) {
                // Column has a sortIndex and a summary row calcuation to sort by
                const sortIndexSet = !_.isNil(getOption(col.dataField, 'sortIndex'));
                const isCustomSummary = groupItems.filter(function (i) {
                    return i.name === "customSummary" + col.dataField + "_grouping";
                }).length;
                return sortIndexSet && isCustomSummary;
            })
            .map(function (m) {
                const sortable = {
                    name: "customSummary" + m.dataField + "_grouping",
                    sortOrder: getOption(m.dataField, 'sortOrder'),
                    sortIndex: getOption(m.dataField, 'sortIndex')
                };
                return sortable;
            })
            .sort(function (a, b) {

                if (nullsLast) {
                    if (a === null && b !== null) {
                        return -1;
                    }
                    if (b === null && a !== null) {
                        return 1;
                    }
                } else {
                    if (a === null && b !== null) {
                        return 1;
                    }
                    if (b === null && a !== null) {
                        return -1;
                    }
                }

                if (a < b) {
                    return -1;
                }

                if (a === b) {
                    return 0;
                }

                return 1;
            });

        if (sortableGroups.length) {

            const summaryIndex = groupItems.map(function (e) { return e.name; }).indexOf(sortableGroups[0].name);
            const sortOrder = sortableGroups[0].sortOrder;

            const sortFn = function (data, summaryIndex, sortOrder) {

                if (!sortLevels || levels.indexOf(data.thisLevel) > -1) {
                    data.sort(function (a, b) {

                        const valueA = !_.isNil(a.aggregates) ? a.aggregates[summaryIndex] : -1;
                        const valueB = !_.isNil(b.aggregates) ? b.aggregates[summaryIndex] : -1;

                        const ascDirection = sortOrder.toUpperCase() === 'DESC' ? false : true;
                        /*
                        if (sortOrder === 'desc') {
                            return b - a;
                        }
                        return a - b;
                        */

                        if (nullsLast) {
                            if (valueA === null && valueB !== null) {
                                return ascDirection ? 1 : -1;
                            }
                            if (valueB === null && valueA !== null) {
                                return ascDirection ? -1 : 1;
                            }
                        } else {
                            if (valueA === null && valueB !== null) {
                                return ascDirection ? -1 : 1;
                            }
                            if (valueB === null && valueA !== null) {
                                return ascDirection ? 1 : -1;
                            }
                        }

                        if (valueA < valueB) {
                            return ascDirection ? -1 : 1;
                        }

                        if (valueA === valueB) {
                            return 0;
                        }

                        return ascDirection ? 1 : -1;
                    });
                }

                data.forEach(function (di) {
                    if (di && di.items) {
                        di.items.thisLevel = data.thisLevel + 1;
                        sortFn(di.items, summaryIndex, sortOrder);
                    }
                });
            };

            sortFn(data, summaryIndex, sortOrder);
        }

        return data;
    };

    initRowDragging(e, $scope) {
        e.element.find('.dx-datagrid-rowsview tbody').sortable({
            helper: 'clone',
            start: function (event, ui) {
                const $originalRow = ui.item.next("tr"),
                    $clonedRow = ui.helper;
                const $originalRowCells = $originalRow.children(),
                    $clonedRowCells = $clonedRow.children();
                for (let i = 0; i < $originalRowCells.length; i++)
                    $($clonedRowCells.get(i)).css('min-width', $($originalRowCells.get(i)).outerWidth());

                $clonedRow.find("td").css("max-width", "100%");
            },
            update: function (event, ui) {
                let draggedToLastPos;
                let datasourceItems = e.component.getDataSource()._items;
                $scope.reorderedItems = [];

                //to identify the dragged row and the dropped row
                const draggedRowKey = ui.item.data('rowKey');
                let targetRowKey = ui.item.next("tr").data('rowKey');

                //to check if the row was moved to the last position
                //in this case get the sequence number from the previous item
                if (!targetRowKey) {
                    targetRowKey = ui.item.prev("tr").data('rowKey');
                    draggedToLastPos = true;
                }

                //to find the  sequence of the dragged row
                const draggingRowSequence = _.find(datasourceItems, function (itm) {
                    return itm.Id === draggedRowKey;
                })[$scope.rowReorderSequenceField];

                //to find the  sequence of the dropped row
                const droppedRowSequence = _.find(datasourceItems, function (itm) {
                    return itm.Id === targetRowKey;
                })[$scope.rowReorderSequenceField];

                const draggingDirection = (droppedRowSequence < draggingRowSequence) ? 1 : -1;

                for (let dataIndex = 0; dataIndex < datasourceItems.length; dataIndex++) {
                    if ((datasourceItems[dataIndex][$scope.rowReorderSequenceField] > Math.min(droppedRowSequence, draggingRowSequence)) && (datasourceItems[dataIndex][$scope.rowReorderSequenceField] < Math.max(droppedRowSequence, draggingRowSequence))) {
                        datasourceItems[dataIndex][$scope.rowReorderSequenceField] += draggingDirection;
                        $scope.reorderedItems.push(datasourceItems[dataIndex]);
                    }
                    if (datasourceItems[dataIndex]["Id"] === draggedRowKey) {
                        datasourceItems[dataIndex][$scope.rowReorderSequenceField] = droppedRowSequence;
                        $scope.reorderedItems.push(datasourceItems[dataIndex]);
                    };
                    if (datasourceItems[dataIndex]["Id"] === targetRowKey) {
                        datasourceItems[dataIndex][$scope.rowReorderSequenceField] = droppedRowSequence + draggingDirection;
                        $scope.reorderedItems.push(datasourceItems[dataIndex]);
                    };
                };

                //to make sure the data controller items also have the sequence number correctly synced
                _.forEach(e.component.getController("data")._items, function (itm, dataIndex) {
                    if (itm.data.Id === datasourceItems[dataIndex]["Id"]) {
                        itm.data[$scope.rowReorderSequenceField] = datasourceItems[dataIndex][$scope.rowReorderSequenceField];
                    }
                });

                //to make sure the edit controller has the new sequence number synced
                //so that the cell value does not return to original value after reordering and editing
                datasourceItems = _.sortBy(datasourceItems, function (itm) { return itm.SequenceOrder });
                e.component.getDataSource()._items = datasourceItems;
                e.component.getController("data")._items = _.sortBy(e.component.getController("data")._items, function (itm) { return itm.data.SequenceOrder; });
                e.component.getController("editing")._dataController._dataSource._items = _.sortBy(e.component.getController("editing")._dataController._dataSource._items, function (itm) { return itm.SequenceOrder; });
                e.component.getController("editing")._dataController._items = _.sortBy(e.component.getController("editing")._dataController._items, function (itm) { return itm.SequenceOrder; });
                e.component.repaintRows();

                //to check if the datagrid is editable
                //for editable grids - we only want to update the row sequence in a-tier when the save button is clicked
                //that is handled in the '_saveListEditingChanges' function
                //else we want to update the sequence right away for non-editable grids
                if (!$scope.gridProperties.editing) {
                    _.each($scope.reorderedItems, function (itm) {
                        e.component.getDataSource().store().update(itm, {});
                    });
                }
            }
        });
    };

    recalcHorizontalScrollHandles($scope): void {
        function markScrollArrowHidden(el, hidden) {
            if (hidden) {
                el.classList.add('ic-horizontal-arrow-hidden');
            } else {
                el.classList.remove('ic-horizontal-arrow-hidden');
            }
        }

        function markParentScrollable(el, scrollable) {
            if ($scope.horizontalScrollbarScrollable !== scrollable) {
                $scope.horizontalScrollbarScrollable = scrollable;
                if (scrollable) {
                    el.classList.remove('ic-horizontal-not-scrollable');
                    el.classList.add('ic-horizontal-scrollable');
                } else {
                    el.classList.remove('ic-horizontal-scrollable');
                    el.classList.add('ic-horizontal-not-scrollable');
                }
            }
        }

        function markScrollArrowsClickable(clickable, reason?) {
            if (clickable) {
                if ($scope.horizontalArrowSettings && $scope.horizontalArrowSettings.display === "show") {
                    return;
                }
                $scope.horizontalArrowSettings.display = "show"
                $scope.leftArrowElement.disabled = false;
                $scope.rightArrowElement.disabled = false;
                $scope.leftArrowElement.classList.remove('ic-horizontal-arrow-at-extremity');
                $scope.rightArrowElement.classList.remove('ic-horizontal-arrow-at-extremity');
                $scope.leftArrowElement.classList.remove('ic-horizontal-arrow-no-overflow');
                $scope.rightArrowElement.classList.remove('ic-horizontal-arrow-no-overflow');
            } else {
                if ($scope.horizontalArrowSettings && $scope.horizontalArrowSettings.display === reason) {
                    return;
                }
                $scope.horizontalArrowSettings.display = reason;
                $scope.leftArrowElement.disabled = true;
                $scope.rightArrowElement.disabled = true;
                switch (reason) {
                    case "nooverflow":
                        $scope.leftArrowElement.classList.remove('ic-horizontal-arrow-at-extremity');
                        $scope.rightArrowElement.classList.remove('ic-horizontal-arrow-at-extremity');
                        $scope.leftArrowElement.classList.add('ic-horizontal-arrow-no-overflow');
                        $scope.rightArrowElement.classList.add('ic-horizontal-arrow-no-overflow');
                        break;
                    case "atextremity":
                        $scope.leftArrowElement.classList.add('ic-horizontal-arrow-at-extremity');
                        $scope.rightArrowElement.classList.add('ic-horizontal-arrow-at-extremity');
                        $scope.leftArrowElement.classList.remove('ic-horizontal-arrow-no-overflow');
                        $scope.rightArrowElement.classList.remove('ic-horizontal-arrow-no-overflow');
                        break;
                }
            }
        }

        if (!$scope.horizontalArrowsInstalled) {
            return;
        }

        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
        const gridScrollable = $scope.gridScrollable;
        const gridScrollableElement = $scope.gridScrollableElement;
        let clickable = true;

        if (!gridScrollableElement.offsetParent) {
            return;
        }

        const hasPositionSticky = window.CSS && window.CSS.supports('position', 'sticky');
        const isWithinModal = $(".dx-overlay-modal").hasClass("dx-overlay-wrapper dx-popup-wrapper");
        const scrollWidth = isWithinModal ? gridScrollableElement.scrollWidth - gridScrollable.clientWidth() : gridScrollable.scrollWidth() - gridScrollable.clientWidth();
        let topPos, leftPos, rightPos;
        const outsideRect = gridScrollableElement.offsetParent.getBoundingClientRect();
        if (outsideRect.bottom - outsideRect.top < $scope.scrollableHeight && scrollWidth > 0) {
            markScrollArrowsClickable(false, "atextremity");
            clickable = false;
        }
        const scrollPosition = gridScrollable.scrollLeft();

        if ($scope.windowInnerWidth
            && $scope.windowInnerWidth === window.innerWidth
            && $scope.gridWidth
            && $scope.gridWidth !== outsideRect.width) {
            $scope.forceResize();
        }

        $scope.windowInnerWidth = window.innerWidth;
        $scope.gridWidth = outsideRect.width;

        const leftHidden = scrollPosition === 0;
        const rightHidden = scrollWidth - scrollPosition < 1;

        let cssPosition, topOffsetFromHeader = 0;

        if (hasPositionSticky) {
            // Handles should be static on the page, "position: sticky"
            cssPosition = "sticky";

            const $element = $scope.gridInstance.element();
            const $scrollbarElement = $element.find('.ic-horizontal-scrollbars');
            const $headerElement = $element.find('.dx-datagrid-headers');
            if ($headerElement.length > 0) topOffsetFromHeader = $headerElement[0].offsetHeight;
            topPos = viewportHeight / 2;
            leftPos = 0;
            rightPos = outsideRect.right - $scope.rightArrowElement.clientWidth;

            setTimeout(() => {
                $scrollbarElement.css('height', ($scope.scrollableElement.offsetHeight - $scope.scrollableHeight));
                $scrollbarElement.css('top', ($scope.scrollableElement.offsetTop + topOffsetFromHeader));
                $($scope.leftArrowElement).css('position', cssPosition).css('top', topPos + 'px').css('left', leftPos + 'px').css('z-index', $scope.zindex).css('visibility', 'visible');
                $($scope.rightArrowElement).css('position', cssPosition).css('top', topPos + 'px').css('left', rightPos + 'px').css('z-index', $scope.zindex).css('visibility', 'visible');
            });
        }

        if (scrollWidth <= 0) {
            markScrollArrowsClickable(false, "nooverflow");
            markParentScrollable($scope.scrollableElement, false);
            clickable = false;
        } else {
            markParentScrollable($scope.scrollableElement, true);
        }

        const settings = $scope.horizontalArrowSettings;
        if (!!settings &&
            Math.abs(topPos - settings.top) <= 1 &&
            Math.abs(leftPos - settings.left) <= 1 &&
            Math.abs(rightPos - settings.right) <= 1 &&
            cssPosition === settings.position &&
            leftHidden === settings.leftHidden &&
            rightHidden === settings.rightHidden) {
            if (clickable) {
                markScrollArrowsClickable(true);
            }
            return;
        }

        let repositioned = false;
        if ($scope.horizontalArrowSettings.top !== topPos) {
            $scope.horizontalArrowSettings.top = topPos;
            repositioned = true;
        }

        $scope.horizontalArrowSettings.left = leftPos;
        $scope.horizontalArrowSettings.right = rightPos;

        if ($scope.horizontalArrowSettings.position !== cssPosition) {
            $scope.horizontalArrowSettings.position = cssPosition;
            repositioned = true;
        }
        if (clickable) {
            markScrollArrowsClickable(true);
        }

        let visibilityChanged = false;
        if ($scope.horizontalArrowSettings.leftHidden !== leftHidden) {
            $scope.horizontalArrowSettings.leftHidden = leftHidden;
            markScrollArrowHidden($scope.leftArrowElement, leftHidden);
            visibilityChanged = true;
        }

        if ($scope.horizontalArrowSettings.rightHidden !== rightHidden) {
            $scope.horizontalArrowSettings.rightHidden = rightHidden;
            markScrollArrowHidden($scope.rightArrowElement, rightHidden);
            visibilityChanged = true;
        }

        if (repositioned || visibilityChanged) {
            const event: AppsEntity = {
                id: AppEvent.HorizontalScrollButtonReadingOrderChanged,
                state: { appName: $scope.applet.name },
            };
            this.appsConstantsFacade.publishEvent(event);
        }
    }

    initHorizontalScrollHandles($scope: ListApplication, placeholderTextLeft: string, placeholderTextRight: string, clickDistance: number, holdDistance: number, holdPause: number): void {
        let timerID;
        let counter = 0;
        let pressAndHoldTarget;
        let pressAndHoldTriggerTime;

        function isHorizontalScrollArrow(e) {
            return (e.target === leftAr || e.target === rightAr);
        }

        const windowScrollEvent = (e): void => {
            this.recalcHorizontalScrollHandles($scope);
        }
        const scrollLeft = (e: Event): void => {
            e.preventDefault();
            if (!isHorizontalScrollArrow(e)) {
                return;
            }
            const now = new Date().getTime();
            if (now - pressAndHoldTriggerTime < 100) {
                // Already handled as a press and hold
                return;
            }
            forceScrollLeft();
        }
        const forceScrollLeft = (): void => {
            $scope.gridInstance.getScrollable().scrollBy({ left: -clickDistance });
        }
        const scrollRight = (e: Event): void => {
            e.preventDefault();
            if (!isHorizontalScrollArrow(e)) {
                return;
            }
            const now = new Date().getTime();
            if (now - pressAndHoldTriggerTime < 100) {
                // Already handled as a press and hold
                return;
            }
            forceScrollRight();
        }
        const forceScrollRight = (): void => {
            $scope.gridInstance.getScrollable().scrollBy({ left: clickDistance });
        }
        function pressingDown(e) {
            e.preventDefault();
            if (!isHorizontalScrollArrow(e)) {
                return;
            }
            // Start the timer
            pressAndHoldTarget = e.currentTarget;
            if (timerID !== undefined) {
                cancelAnimationFrame(timerID);
            }
            timerID = requestAnimationFrame(timer);
            e.preventDefault();
            IX_Log("component", "Press and hold activation started");
        }
        function notPressingDown(e) {
            e.preventDefault();
            if (!isHorizontalScrollArrow(e)) {
                return;
            }
            // Stop the timer
            if (timerID !== undefined) {
                cancelAnimationFrame(timerID);
            }
            timerID = undefined;
            pressAndHoldTriggerTime = undefined;
            if (e.type === "touchend" && counter < pressHoldDuration) {
                if (pressAndHoldTarget === leftAr) {
                    forceScrollLeft();
                } else {
                    forceScrollRight();
                }
            }
            counter = 0;
            IX_Log("component", "Press and hold activation ended");
        }

        //
        // Runs at 60fps when you are pressing down
        //
        function timer() {
            if (counter < pressHoldDuration) {
                timerID = requestAnimationFrame(timer);
                counter++;
            } else {
                pressAndHoldTriggerTime = new Date().getTime();
                timerID = requestAnimationFrame(timer);
                pressAndHoldTarget.dispatchEvent(pressHoldEvent);
            }
        }

        function pressHold(e) {
            $scope.gridInstance.getScrollable().scrollBy({ left: pressAndHoldTarget === leftAr ? -holdDistance : holdDistance });
            pressAndHoldTriggerTime = new Date().getTime();
        }

        const pressHoldEvent = new CustomEvent("pressHold");
        const pressHoldDuration = holdPause;
        const leftAr = document.createElement("button");
        const rightAr = document.createElement("button");
        leftAr.style.visibility = 'hidden';
        rightAr.style.visibility = 'hidden';
        leftAr.id = $scope.gridId + "-ic-horizontal-left-arrow";
        rightAr.id = $scope.gridId + "-ic-horizontal-right-arrow";
        leftAr.classList.add("ic-horizontal-arrow");
        rightAr.classList.add("ic-horizontal-arrow");
        leftAr.classList.add("ic-horizontal-left-arrow");
        rightAr.classList.add("ic-horizontal-right-arrow");
        leftAr.setAttribute('aria-label', 'Scroll Left');
        rightAr.setAttribute('aria-label', 'Scroll Right');
        if (placeholderTextLeft) {
            leftAr.innerText = placeholderTextLeft;
        }
        if (placeholderTextRight) {
            rightAr.innerText = placeholderTextRight;
        }
        $scope.leftArrowElement = leftAr;
        $scope.rightArrowElement = rightAr;

        $scope.gridScrollable = $scope.gridInstance.getScrollable();
        const gridScrollableElement = $scope.gridScrollable.content()[0];
        $scope.gridScrollableElement = gridScrollableElement as HTMLDivElement;

        const arrowParent = $($scope.gridInstance.element()).find('.dx-datagrid-rowsview')[0];
        if (!arrowParent) {
            return;
        }
        $scope.scrollableElement = arrowParent as HTMLDivElement;

        const horizontalArrowDiv = document.createElement('div');
        horizontalArrowDiv.classList.add('ic-horizontal-scrollbars');
        horizontalArrowDiv.style.position = 'absolute';
        horizontalArrowDiv.style.width = '100%';
        horizontalArrowDiv.appendChild(leftAr);
        horizontalArrowDiv.appendChild(rightAr);
        arrowParent.after(horizontalArrowDiv);
        $scope.horizontalArrowsInstalled = true;

        leftAr.addEventListener("click", scrollLeft);
        // Listening for the mouse and touch events
        leftAr.addEventListener("mousedown", pressingDown, false);
        leftAr.addEventListener("mouseup", notPressingDown, false);
        leftAr.addEventListener("mouseleave", notPressingDown, false);
        leftAr.addEventListener("touchstart", pressingDown, false);
        leftAr.addEventListener("touchend", notPressingDown, false);
        // Listening for our custom pressHold event
        leftAr.addEventListener("pressHold", pressHold, false);

        rightAr.addEventListener("click", scrollRight);
        // Listening for the mouse and touch events
        rightAr.addEventListener("mousedown", pressingDown, false);
        rightAr.addEventListener("mouseup", notPressingDown, false);
        rightAr.addEventListener("mouseleave", notPressingDown, false);
        rightAr.addEventListener("touchstart", pressingDown, false);
        rightAr.addEventListener("touchend", notPressingDown, false);
        // Listening for our custom pressHold event
        rightAr.addEventListener("pressHold", pressHold, false);

        window.addEventListener("mouseup", notPressingDown, false);
        window.addEventListener("resize", windowScrollEvent, false);
        $scope.gridScrollable.on('scroll', windowScrollEvent);

        $(leftAr).css('display', 'inline');
        $(rightAr).css('display', 'inline');

        $scope.horizontalArrowSettings = {};

        $scope.gridInstance.beginUpdate();
        const cols = $scope.gridInstance.option('columns');
        cols.forEach(function (col) {
            const currentWidth = $scope.gridInstance.columnOption(col.dataField, 'width');
            const lastChar = typeof currentWidth === 'string' && currentWidth[currentWidth.length - 1];
            if (lastChar === '%') {
                // Reset any persisted percentage widths to the default pixel-width as configured in p-tier
                $scope.gridInstance.columnOption(col.dataField, 'width', col.width);
            }
        });
        $scope.gridInstance.endUpdate();
    }

    loadHorizontalScrollHandles($scope: ListApplication, screenSettings, skipLaunch?): void {
        let placeholderTextLeft = '', placeholderTextRight = '';
        let clickDistance = 50;
        let holdDistance = 6;
        let holdPause = 20;
        let scrollableHeight = 100;
        let zindex = 3;
        function setPropertiesFromSettings(setting) {
            const parts = setting.split(':');
            if (parts.length > 1) {
                switch (parts[0]) {
                    case 'clickDistance':
                        clickDistance = parseInt(parts[1], 10);
                        break;
                    case 'holdDistance':
                        holdDistance = parseInt(parts[1], 10);
                        break;
                    case 'holdPause':
                        holdPause = parseInt(parts[1], 10);
                        break;
                    case 'scrollableHeight':
                        scrollableHeight = parseInt(parts[1], 10);
                        break;
                    case 'zindex':
                        zindex = parseInt(parts[1], 10);
                        break;
                    case 'placeholderTextLeft':
                        placeholderTextLeft = parts[1];
                        break;
                    case 'placeholderTextRight':
                        placeholderTextRight = parts[1];
                        break;
                }
            }
        }
        const scrollable = $scope.gridInstance.getScrollable();
        const scrollableContent = scrollable?.content();
        const gridScrollableElement = !!scrollableContent && scrollableContent[0];
        if (gridScrollableElement && $scope.gridScrollableElement !== gridScrollableElement) {
            // First, initialize any global settings which may be in the theme setting
            const themeProperty = this.themeService.getThemeProperty('HorizontalScrollHandles');
            let themePropertyValue = themeProperty && themeProperty.Value1;
            themePropertyValue = themePropertyValue ? themePropertyValue : "";
            let values = themePropertyValue.split('|');
            values.forEach(setPropertiesFromSettings);
            // Next, use screen settings to override global settings if needed
            screenSettings = screenSettings ? screenSettings : '';
            values = screenSettings.split('|');
            values.forEach(setPropertiesFromSettings);
            $scope.scrollableHeight = scrollableHeight;
            $scope.zindex = zindex;
            this.initHorizontalScrollHandles($scope, placeholderTextLeft, placeholderTextRight, clickDistance, holdDistance, holdPause);
            if (!skipLaunch) {
                this.recalcHorizontalScrollHandles($scope);
            }
        } else {
            this.recalcHorizontalScrollHandles($scope);
        }
    }

    generateGroupingActionButtons($scope: any, buttonText: string, ariaText: string): void {
        if ($scope.groupingActionButtonsRetryCount > 50) {
            // Avoid runaway code if the grid never shows up in the DOM
            clearInterval($scope.groupingActionButtonsRetryId);
            IX_Log('component', 'timer id="' + $scope.groupingActionButtonsRetryId + '" ended in FAILURE. ' + $scope.gridId + ' group rows did not render.');
            $scope.groupingActionButtonsRetryId = undefined;
            $scope.groupingActionButtonsRetryCount = undefined;
            return;
        }
        const $grid = $('#' + $scope.gridId);
        if ($grid.find('.dx-group-row td').length === 0) {
            // In some mobile devices, this is sometimes called seconds before the grid is actually visible in the DOM
            if ($scope.groupingActionButtonsRetryId) {
                $scope.groupingActionButtonsRetryCount++;
            } else {
                $scope.groupingActionButtonsRetryCount = 0;
                $scope.groupingActionButtonsRetryId = setInterval(() => this.generateGroupingActionButtons($scope, buttonText, ariaText), 100);
                IX_Log('component', 'timer id="' + $scope.groupingActionButtonsRetryId + '" started, awaiting ' + $scope.gridId + ' group rows to render.');
            }
            return;
        }

        if ($scope.groupingActionButtonsRetryId) {
            clearInterval($scope.groupingActionButtonsRetryId);
            IX_Log('component', 'timer id="' + $scope.groupingActionButtonsRetryId + '" ended. ' + $scope.gridId + ' group rows rendered.');
            $scope.groupingActionButtonsRetryId = undefined;
            $scope.groupingActionButtonsRetryCount = undefined;
        }

        if ($grid.find('.ic-grouping-header-button-fixed,.ic-grouping-header-button-scrollable').length > 0) {
            // buttons already added, onContentReady may have fired again
            return;
        }

        buttonText = this.translate.instant(buttonText || "");
        ariaText = this.translate.instant(ariaText || "");

        const createButton = (cssClasses: string): HTMLButtonElement => {
            const button = document.createElement('button');
            button.innerHTML = buttonText;
            button.setAttribute('display', 'none');
            button.setAttribute('aria-label', ariaText);
            button.setAttribute('class', cssClasses);
            $(button).on('click', (e) => $scope.groupingEventHandler(e));
            return button;
        };

        // Put in last column if it's not marked with the hidden "dx-last-cell" class
        const $rowsView = $grid.find('.dx-datagrid-rowsview');
        let added = $rowsView.find('.dx-datagrid-content:not(.dx-datagrid-content-fixed) .dx-group-row td:not(.dx-last-cell):nth-last-child(1)')
            .append(createButton('ic-grouping-header-button-scrollable'));
        if (added.length === 0) {
            // If last column was "dx-last-cell", then we need the next-to-the-last
            $rowsView.find('.dx-datagrid-content:not(.dx-datagrid-content-fixed) .dx-group-row td:nth-last-child(2)')
                .append(createButton('ic-grouping-header-button-scrollable'));
        }

        // Put in last column if it's not marked with the hidden "dx-last-cell" class
        added = $rowsView.find('.dx-datagrid-content.dx-datagrid-content-fixed .dx-group-row td:not(.dx-last-cell):nth-last-child(1)')
            .append(createButton('ic-grouping-header-button-fixed'));
        if (added.length === 0) {
            // If last column was "dx-last-cell", then we need the next-to-the-last
            $rowsView.find('.dx-datagrid-content.dx-datagrid-content-fixed .dx-group-row td:nth-last-child(2)')
                .append(createButton('ic-grouping-header-button-fixed'));
        }

    }

    private _GetThemeProperty(property, that) {
        const themeSvc = that.option('ic.$themeSvc');
        return (!themeSvc || !themeSvc.getThemeProperty) ? null : themeSvc.getThemeProperty(property);
    }

    private useAutoGroupingSummarySort(scope, that) {
        const autoGroupingSummarySortProperty = this._GetThemeProperty("#List.AutoGroupingSummarySort", that);
        let autoGroupingSummarySort = autoGroupingSummarySortProperty && autoGroupingSummarySortProperty.Value1;
        autoGroupingSummarySort = scope.autoGroupingSummarySort || autoGroupingSummarySort;
        return (autoGroupingSummarySort + "").toLowerCase() === "true";
    }

    private autoGroupingSummarySortExceptions(scope, that) {
        const autoGroupingSummarySortProperty = this._GetThemeProperty("#List.AutoGroupingSummarySort", that);
        let autoGroupingSummarySortExceptions = autoGroupingSummarySortProperty && autoGroupingSummarySortProperty.Value2;
        autoGroupingSummarySortExceptions = scope.autoGroupingSummarySortExceptions || autoGroupingSummarySortExceptions;
        return !autoGroupingSummarySortExceptions ? [] :
            autoGroupingSummarySortExceptions
                .split(',')
                .map(function (val) { return val.trim().toLowerCase(); })
                .filter(function (val) { return !!val; });
    }

    private autoGroupingSummarySortGroupLevels(scope) {
        return !scope.autoGroupingSummarySortGroupLevels ? [] :
            scope.autoGroupingSummarySortGroupLevels
                .split(',')
                .map(function (val) { return val.trim().toLowerCase(); })
                .filter(function (val) { return !!val; });
    }

    customGroupSorting(column, visibleColumns, that, keyName, setDefaults) {
        const gridScope = that.component.option('gridScope');
        let sortColumnIndex = column.index;
        setDefaults = setDefaults || false;
        let groupItems = null;
        let icCustomState = {};
        const useAutoGroupingSummary = this.useAutoGroupingSummarySort(gridScope, that) &&
            (groupItems = gridScope.gridInstance.option('summary.groupItems')) &&
            groupItems.some(function (c) { return c.name === 'customSummary' + column.dataField + '_grouping' || c.column === column.dataField; }) &&
            !this.autoGroupingSummarySortExceptions(gridScope, that).includes((column.dataField + '').toLowerCase());

        if (!useAutoGroupingSummary && !column.forceAutoGroupingSummarySort) {
            const customSort = gridScope.gridProperties.icCustomGroupSorting ? gridScope.gridProperties.icCustomGroupSorting : [];
            if (customSort.length > 0) {
                // find out if item.click column was clicked and set item.group column's icCalculateGroupValue set to item.sort
                const clickedColumn = _.find(customSort, { clickField: column.dataField });
                if (clickedColumn) {
                    gridScope.columnMap[clickedColumn.groupField].icCalculateGroupValue = { useField: clickedColumn.sortField, isCustom: true };
                    const groupItem = _.find(visibleColumns, { dataField: clickedColumn.groupField }) || { index: -1 };
                    sortColumnIndex = groupItem.index;
                } else {
                    const groupFieldName = _.uniqBy(customSort, 'group')[0]['groupField'];
                    gridScope.columnMap[groupFieldName].icCalculateGroupValue = { useField: column.dataField, isCustom: false };
                    const blockGroupLevel3 = gridScope.allowGroupBlocking;
                    if (blockGroupLevel3) {
                        const groupItem = _.find(visibleColumns, { dataField: groupFieldName }) || { index: -1 };
                        sortColumnIndex = groupItem.index;
                    }
                }

                // record sort order in case we need to override sort indicators
                icCustomState = column.index === sortColumnIndex ? undefined :
                    {
                        clickedColumnDataField: column.dataField,
                        sortedColumnIndex: sortColumnIndex,
                        sortOrder: setDefaults ? "asc" : "",
                    };
                if (!setDefaults) {
                    const sorting = that.component.option('sorting');
                    sorting.icCustomSort = icCustomState;
                }
            }
            if (setDefaults) {
                return {
                    icCustomSort: icCustomState,
                    sortByGroupSummaryInfo: [],
                };
            } else {
                that._columnsController.beginUpdate();
                gridScope.gridInstance.beginUpdate();
                gridScope.gridInstance.option("sortByGroupSummaryInfo", []);
                that._columnsController.changeSortOrder(sortColumnIndex, keyName);
                gridScope.gridInstance.option("icCustomGroupExpand", gridScope.icCustomGroupExpand);
                gridScope.gridInstance.endUpdate();
                that._columnsController.endUpdate();
            }
        } else {
            const sorting = gridScope.gridInstance.option('sorting')
            if (sorting && !setDefaults) {
                // need to set value this way because DX doesn't traverse the object correctly if set via option('sorting.icCustomSort', undefined)
                _.set(sorting, 'icCustomSort', undefined);
            }
            if (setDefaults && groupItems) {
                const groupItemChevron = _.find(groupItems, function (item) { return item.column === column.dataField; });
                if (groupItemChevron) {
                    icCustomState = { clickedColumnDataField: groupItemChevron.showInColumn, sortOrder: "asc" };
                }
            } else {
                icCustomState = { clickedColumnDataField: column.dataField, sortOrder: "asc" };
            }
            if (!setDefaults)
                that._columnsController.changeSortOrder(sortColumnIndex, keyName);
            const groupLevels = this.autoGroupingSummarySortGroupLevels(gridScope);
            const groupings = _.chain(gridScope.gridInstance.option('columns'))
                .filter(function (c) {
                    return c.groupIndex !== undefined && (
                        groupLevels.length === 0 ||
                        _.includes(groupLevels, c.dataField.trim().toLowerCase())
                    );
                })
                .sortBy(["groupIndex"])
                .map(function (c) { return c.dataField; })
                .value()
                .reverse();
            // The groupings need to end up in descending order (reverse), since we stop aggregation in that order
            let stopAggLevels = gridScope.applet.groupingStopAggregationLevels || [];
            const stopAggTargets = gridScope.applet.groupingStopAggregationTargetFields || [];
            if (stopAggTargets.length > 0 && !stopAggTargets.includes(column.dataField) && !column.forceAutoGroupingSummarySort) {
                stopAggLevels = [];
            }
            const groupCustomSortings = [];
            groupings.every(function (val) {
                // Not all summary entries have a distinct name, identify by index
                let summaryIndex;
                groupItems.every(function (s, sIdx) {
                    if (s.showInColumn === column.dataField) {
                        summaryIndex = sIdx;
                        return false;
                    };
                    if (s.column == column.dataField && summaryIndex === undefined) {
                        summaryIndex = sIdx;
                    }
                    return true;
                });
                const order = setDefaults ? "asc" : gridScope.gridInstance.option('sorting.icCustomSort.sortOrder');
                groupCustomSortings.push({ summaryItem: summaryIndex, groupColumn: val, sortOrder: order || column.sortOrder });
                return !stopAggLevels.includes(val);
            });
            if (setDefaults) {
                return {
                    icCustomSort: icCustomState,
                    sortByGroupSummaryInfo: groupCustomSortings,
                };
            } else {
                gridScope.gridInstance.beginUpdate();
                gridScope.gridInstance.option("sortByGroupSummaryInfo", groupCustomSortings);
                gridScope.gridInstance.endUpdate();
            }
        }

    };

}
