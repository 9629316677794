import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import Sugar from 'sugar/date';
import { ListApplication } from '../components/list-application';
import { RouterService } from './router.service';
import { TranslateFacadeService } from './translate-facade.service';
import { StoreService } from './store-service';
import { ApplicationInformation } from './application-information.service';
import { DomComponentRetrievalService } from './dom-component-retrieval.service';
import { FieldFormatService } from './field-format.service';
import { CacheManagerService } from './cachemanager.service';
import UtilityFunctions from '../utility.functions';
import { AdvancedFilterExpressionParser } from './clientservices/advanced-filter-expression-parser';

type Tokens = {
    transform: string[];
    tokens: string[];
    newStr: string,
};

@Injectable()
export class DynamicReplacementService {

    constructor(private storeService: StoreService,
        private routerService: RouterService,
        private translateFacade: TranslateFacadeService,
        private fieldFormatService: FieldFormatService,
        private applicationInformation: ApplicationInformation,
        private domComponentRetrievalService: DomComponentRetrievalService,
        private cacheManagerService: CacheManagerService) { }

    private getCurrentAppsState(): Promise<any> {
        return this.storeService
            .getCurrentAppsState()
            .pipe(first())
            .toPromise();
    }

    private getAppState(appName: string): Promise<any> {
        return this.storeService
            .getCurrentAppState(appName)
            .pipe(first())
            .toPromise();
    }

    evaluateGlobalsConditional(dynamicStr: string) {
        const globalsToken = '{Globals:';
        const tokenLength = globalsToken.length;
        const allCached = this.cacheManagerService.getAll();
        const globalsKeys = Object.keys(allCached).filter((cachedKey) => {
            return cachedKey.substr(0, tokenLength).toLowerCase() === globalsToken.toLowerCase();
        });

        const objectInfo = {};
        globalsKeys.forEach((key) => {
            const keyName = key.substr(tokenLength, key.indexOf('}') - tokenLength).replace(/:/g, '');
            objectInfo[keyName] = allCached[key];
        });

        dynamicStr = dynamicStr.replace(globalsToken, '').replace(/:/g, '').replace('}', '');
        dynamicStr = '::' + dynamicStr + '_';
        const result = this.dynamicConditionMet(dynamicStr, objectInfo, null);
        return result.conditionMet;
    }

    getQueryStringReplacement(strParts) {
        strParts = strParts.split(":");
        if (strParts.length <= 1) {
            IX_Log("dynamic", "'" + strParts + "' is an invalid replacement value.");
            return;
        }

        const param = strParts[1].substring(0, strParts[1].length - 1);
        return IX_getQueryStringParameter(param, true);
    }

    getDynamicConfig(dynamicStr, tokenStr) {
        let fieldName = null;
        if (_.isString(dynamicStr) && dynamicStr.length) {
            if (dynamicStr.indexOf(tokenStr) != -1) {
                const dFLen = tokenStr.length + dynamicStr.indexOf(tokenStr);
                const iT = dynamicStr.length - 1;
                fieldName = dynamicStr.substr(dFLen, Math.abs(dFLen - iT));
            }
        }
        return fieldName;
    }

    // To use from app state effect while migrating code.
    getDynamicField(dynamicStr: string): string {
        const fieldName = this.getDynamicConfig(dynamicStr, "{Field:");
        return fieldName;
    }

    getDynamicFieldValueForListCell(config, dynamicStr) {
        const replacementType = "{Field:";
        return this.getObjectReplacement(dynamicStr, replacementType, config.data, config.formats, config.appName);
    }

    getComponentReplacement(dynamicStr, config) {
        const formats = {},
            replacementType = "{ComponentItem:";
        return this.getObjectReplacement(dynamicStr, replacementType, config.currentItem, formats);
    }

    getObjectReplacement(dynamicStr, replacementType, objectInfo, formats, appName?) {
        const replacement = this.getTokens(dynamicStr, objectInfo);
        for (let tokenRef = 0; tokenRef < replacement.tokens.length; tokenRef++) {
            const property = this.getDynamicConfig(replacement.tokens[tokenRef], replacementType);
            const token = this.getFormattedValueOrDefaultFromObject(property, replacementType, objectInfo, formats, appName, "");
            replacement.newStr = replacement.newStr.replace(this.getToken(tokenRef), token);
        }
        return replacement.newStr;
    }

    getFormattedValueOrDefaultFromObject(propertyName, replacementType, objectInfo, formats, appName, defaultValue) {
        let value = defaultValue;
        if (!_.isNil(propertyName) && !_.isNil(objectInfo[propertyName])) {
            value = objectInfo[propertyName];
            if (!_.isNil(formats[propertyName])) {
                value = this.fieldFormatService.format(formats[propertyName], value);
                value = this.getDynamicValueOrFallback(value, defaultValue);
            }
        } else if (replacementType === '{Field:') {
            value = this.getFieldReplacement(propertyName, { appName: appName });
        }
        return value;
    }

    getConstantReplacement(str) {
        const parts = str.split(':');
        if (parts.length > 1) {
            const constant = parts[1];
            return constant.substring(0, constant.length - 1);
        }
        return null;
    }

    parseMessageWithinBrackets(str) {
        const matchedWithinBrackets = (str || '').match(/\[(.*)\]/i);

        if (!matchedWithinBrackets || !matchedWithinBrackets[1]) {
            return str;
        }

        const textItems = matchedWithinBrackets[1].split(',');

        if (textItems.length === 1) {
            return textItems[0];
        }

        const last = textItems.pop();
        const result = textItems.join(', ') + ' and ' + last;
        return result;
    }

    deserializeErrorOrSuccessMessage(message) {
        const messageParts = message.split('|');

        return {
            Message: messageParts[3],
            MessageListWithinBrackets: this.parseMessageWithinBrackets(messageParts[3]),
            Number: messageParts[2],
            Code: messageParts[1],
            Details: messageParts[0]
        };
    }

    /***
     * Replaces a dynamic expression using {Error:} and {Success
     */
    getErrorOrSuccessReplacement(str) {
        /* Parses the dynamic expression to get the command. */
        const strParts = str.split(":");
        if (strParts.length <= 1) {
            IX_Log("dynamic", "'" + str + "' is an invalid replacement value.");
            return;
        }
        let command = strParts[1].toLowerCase().trim();

        /* Parses and removes the last "}". */
        command = command.substring(0, command.length - 1);

        /* Gets the replacement using the "lastError" object in "window.lastError". */
        const errorMessage = window.lastError ? window.lastError.MainMessage : '';

        if (_.isNil(errorMessage)) {
            return "";
        }

        const errorMessageParts = errorMessage.split('|');

        /* If the error Message doesn't contain any pipes (|) then ignore the parts and just use the message,
            else assume a properly formed error message and use the forth item in the error Message string.*/
        const messageSection = errorMessageParts.length == 1 ? errorMessageParts[0] : errorMessageParts[3];
        /* For these, assume properly structured error messages. */
        const numberSection = errorMessageParts.length >= 3 ? errorMessageParts[2] : "[Number not available]";
        const codeSection = errorMessageParts.length >= 2 ? errorMessageParts[1] : "[Code not available]";
        const detailsSection = errorMessageParts[0];


        /* The errors are comprised of three parts separated by "|" the last part containing the user
        * friendly message.*/
        switch (command) {
            case "message":
                return messageSection;
            case "messagelistwithinbrackets":
                return this.parseMessageWithinBrackets(messageSection);
            case "number":
                return numberSection;
            case "code":
                return codeSection;
            case "details":
                return detailsSection;
            default:
                IX_Log("dynamic", "Unrecognized error dynamic replacement argument: " + command);
                return "";
        }
    }

    private parseUrl(url) {
        const parser = document.createElement('a');
        const searchObject = {};
        // Let the browser do the work
        parser.href = url;
        // Convert query string to object
        const queries = parser.search.replace(/^\?/, '').split('&');
        for (let i = 0; i < queries.length; i++) {
            const split = queries[i].split('=');
            searchObject[split[0]] = split[1];
        }
        return {
            protocol: parser.protocol,
            host: parser.host,
            hostname: parser.hostname,
            port: parser.port,
            pathname: parser.pathname,
            search: parser.search,
            searchObject: searchObject,
            hash: parser.hash
        };
    }

    private getCurrentUrl() {
        return this.routerService.absUrl();
    }

    private cleanForAnchor(currentUrl: string): string {
        const parsed = this.parseUrl(currentUrl);
        const endIndex = parsed.hash ? currentUrl.indexOf(parsed.hash) : currentUrl.length;
        const base = currentUrl.substring(0, endIndex);
        // replace base and any existing anchor #id
        return currentUrl.replace(base, '').replace(/(#[^!].*)/gi, '');
    }

    getUrlReplacement(str: string): string {
        const strParts = str.replace('}', '').split(":");
        let currentUrl = this.getCurrentUrl();
        if (strParts.length > 1) {
            switch (strParts[1].toLowerCase()) {
                case "foranchorhref":
                    currentUrl = '#' + this.cleanForAnchor(currentUrl);
                    // beacause of https://angular.io/api/router/ExtraOptions#scrollPositionRestoration = 'enabled'
                    // it is required to programatically scroll to element
                    currentUrl = currentUrl + '" onclick="IC_UrlDynamicReplacementGoTo(this);return false;" data-href="';
                    break;
                case "foranchorid":
                    currentUrl = this.cleanForAnchor(currentUrl);
                    // replace first #
                    currentUrl = currentUrl[0] === '#' ? currentUrl.substring(1) : currentUrl;
                    break;
            }
        }
        str = str.replace(/{url:*\w*}/gi, currentUrl);
        return str;
    }

    getSSOParamReplacement(str: string): string {
        const strParts = str.split(":");
        if (strParts.length <= 1) {
            IX_Log("dynamic", "'" + str + "' is an invalid replacement value.");
            return;
        }
        let param = strParts[1];
        param = param.substring(0, param.length - 1);
        return window.IX_SSOParams[param];
    }

    getThemeSettingReplacement(str) {
        let i = str.indexOf("|");
        i = (i > -1) ? i : str.length;
        const toTest = str.substr(0, i),
            value = str.substr(i + 1, str.length);
        if (typeof window.IX_Theme != 'undefined' && typeof IX_Theme.details != 'undefined' && typeof IX_Theme.details[toTest] != 'undefined') {
            return IX_Theme.details[toTest];
        }
        return value;
    }

    getCookieReplacement(str) {
        const subject = this.getSubjectFromToken(str),
            fallback = this.getFallbackValueFromToken(str),
            name = subject + "=",
            ca = document.cookie.split(";");
        let cookieVal;
        for (let i = 0; i < ca.length; i++) {
            const c = ca[i].trim();
            if (c.indexOf(name) == 0) {
                cookieVal = c.substring(name.length, c.length);
                break;
            }
        }
        return cookieVal ? cookieVal : fallback;
    }

    getThemePropertyReplacement(str) {
        let j = str.indexOf("|");
        j = (j > -1) ? j : str.length;
        const toTest = str.substr(0, j);
        const aux = toTest.split(":");
        const matchVal1 = aux.length > 1;
        let value = str.substr(j + 1, str.length);
        for (let i = 0; i < IX_Theme.properties.length; i++) {
            const property = IX_Theme.properties[i];
            if (matchVal1) {
                if (property.PropertyName.EqualsIgnoreCase(aux[0])
                    && property.Value1.EqualsIgnoreCase(aux[1])) {
                    value = property.Value2;
                    break;
                }
            } else {
                if (property.PropertyName.EqualsIgnoreCase(toTest)) {
                    value = property.Value1;
                    break;
                }
            }
        }
        return value;
    }

    getDateForDxValidator(value, appletName, unFormatted, now?) {
        return new Promise((resolve, reject) => {
            const parsed = value.match(/({Field.*?})/);

            let forDynamicReplacement = null;
            if (parsed && parsed.length === 2)
                forDynamicReplacement = parsed[1];

            if (!forDynamicReplacement) {
                const str = value.substr(6, value.length - 1);
                if (unFormatted) {
                    resolve(this.getDateReplacementUnformatted(str, undefined, now));
                } else {
                    resolve(this.getDateReplacement(str, undefined, now));
                }
                return;
            }

            this.getAppState(appletName).then(appState => {
                const isoDate = this.getDynamicFieldValue(appState, forDynamicReplacement);
                if (!isoDate) {
                    resolve(null);
                    return;
                }

                const date = this.getUTCMidnightAsSugar(new Date(isoDate));
                const expression = value.match(/}([+-])(.*)/);
                if (expression && expression.length === 3) {
                    const operator = expression[1];
                    const operand = expression[2];
                    date.addDays(Number(operator + operand));
                }

                if (unFormatted) {
                    resolve(date.iso().raw.substr(0, 10));
                    return;
                }

                const dateOnlyFormat = 'iXingFieldFormat.Date';
                resolve(this.fieldFormatService.format(dateOnlyFormat, date.raw));
            })
        })
    }

    getDateForReplacement(now) {
        const d = now;
        let offset = +IX_GetCookieValue('IXSBaseUtcOffset');
        if (!isNaN(offset)) {
            // This is suspicious for introducing off-by-one date errors in positive-offset timezones.
            // This needs to be addressed, but is beyond the scope of the current changeset.
            offset = d.getTimezoneOffset() + offset;
            offset = offset * 60000;
            d.setTime(d.getTime() + offset);
        }
        return new Sugar.Date(d);
    }

    /**
     * @param {Date} jsDate
     * @returns Sugar.Date
     */
    getLocalMidnightAsSugar(jsDate) {
        const startOfDay = this.getLocalMidnightOf(jsDate);
        return new Sugar.Date(startOfDay);
    }

    /**
     *
     * @param {Date} jsDate
     * @returns Date
     */
    getLocalMidnightOf(jsDate) {
        const startOfDay = new Date(
            jsDate.getFullYear(),
            jsDate.getMonth(),
            jsDate.getDate()
        );
        return startOfDay;
    }

    /**
     * @param {Date} jsDate
     * @returns Sugar.Date
     */
    getUTCMidnightAsSugar(jsDate) {
        const startOfDay = this.getUTCMidnightOf(jsDate);
        return new Sugar.Date(startOfDay);
    }

    /**
     *
     * @param {Date} jsDate
     * @returns Date
     */
    getUTCMidnightOf(jsDate) {
        const startOfDay = new Date(
            Date.UTC(
                jsDate.getUTCFullYear(),
                jsDate.getUTCMonth(),
                jsDate.getUTCDate()
            )
        );
        return startOfDay;
    }

    /**
     * @param {*} str replacement expression from p-tier
     * 		{Date:Today}
     * 		{Date:Today-30}
     * 		{Date:Today:AsDateTime}
     * 		{Date:Today-30:AsDateTime}
     * 		{Date:Today:Year}
     * 		{Date:Today-30:Year}
     * @param {*} config passed in from dynamic replacement adapter, but not used here.
     * @param {*} now Date object replacing call to new Date() (optional, for testing)
     */
    getDateReplacement(str, config?, now?) {
        const rawDate = this.getDateReplacementRaw(str, false, now);
        return rawDate;
    }

    /**
     * @param {*} str
     * @param {*} config passed in from dynamic replacement adapter, but not used here.
     * @param {*} now Date object replacing call to new Date() (optional, for testing)
     */
    getDateReplacementUnformatted(str, config?, now?) {
        const rawDate = this.getDateReplacementRaw(str, true, now);
        return _.isObject(rawDate) && rawDate instanceof Date ? rawDate.toISOString() : rawDate;
    }

    getDateReplacementRaw(str, unformatted, now) {
        now = now || new Date();
        const tokens = str.split(":");
        const regex = /[+-]/;
        let indexOfOperator = tokens[0].regexIndexOf(regex);
        indexOfOperator = (indexOfOperator > -1) ? indexOfOperator : str.length;
        let rightSide = +tokens[0].substr(indexOfOperator + 1, str.length);
        const leftSide = tokens[0].substr(0, indexOfOperator),
            operator = tokens[0][indexOfOperator],
            returnDateWithoutTime = tokens.length > 1 && (window['string'].Compare('AsDate', tokens[1], true) == 0 || window['string'].Compare('AsDate}', tokens[1], true) == 0),
            useTimeStamp = tokens.length > 1 && window['string'].Compare('AsDateTime', tokens[1], true) == 0,
            returnYear = tokens.length > 1 && window['string'].Compare('Year', tokens[1], true) == 0,
            result = returnDateWithoutTime ? this.getLocalMidnightAsSugar(now) : this.getDateForReplacement(now),
            dateOnlyFormat = 'iXingFieldFormat.Date';

        switch (leftSide) {
            case "Yesterday":
                result.addDays(-1);
                break;
            //case "LatestBeAsOf": not supported yet
            //	break;
        }

        if (!isNaN(rightSide) && regex.test(operator)) {
            rightSide = operator + rightSide;
            result.addDays(+rightSide);
        }

        if (useTimeStamp) {
            return result.iso().raw;
        }

        if (returnYear) {
            return result.raw.getFullYear();
        }

        if (returnDateWithoutTime) {
            // Using Sugar.iso() here will fail (off-by-one day) when UTC date is one behind local date.
            // Example: positive-offset timezone just after local midnight
            const year = result.raw.getFullYear();
            const month = result.raw.getMonth() + 1;
            const day = result.raw.getDate();
            return year + '-' + _.padStart(month.toString(), 2, '0') + '-' + _.padStart(day.toString(), 2, '0');
        }

        if (unformatted) {
            return result.raw;
        }

        return this.fieldFormatService.format(dateOnlyFormat, result.raw);
    }

    getDynamicValueOrFallback(value, fallback) {
        return (value != null && value.toString().length > 0) ? value : fallback;
    }

    getEventReplacement(str, config) {
        const subject = this.getSubjectFromToken(str);
        const fallback = this.getFallbackValueFromToken(str);
        const info = this.getAppReferenced(subject); //Here is always a referenced app
        const event = config.getAppState(info.appName);
        let value;
        if (subject.indexOf('_') == -1)
            IX_Log("dynamic", "Event '", subject, "' is not in the right format. e.g.: SelectAccount_Event.AccountId");
        if (!_.isNil(event) && !_.isNil(event[info.fieldName])) {
            value = UtilityFunctions.getValue(event[info.fieldName]);
            value = this.getDynamicValueOrFallback(value, fallback);
        } else {
            value = fallback;
        }
        return value;
    }

    getDynamicFieldValue(state: AppState, dynamicStr: string): string {
        if (_.isEmpty(dynamicStr)) return "";
        const obj = this.getTokens(dynamicStr);
        for (let tokenRef = 0; tokenRef < obj.tokens.length; tokenRef++) {
            const replacement = obj.tokens[tokenRef];
            const fieldName = this.getDynamicField(replacement);
            if (_.isNil(fieldName)) continue;
            const aux = this.getFieldValueFromAppContext(state, fieldName) || "";
            obj.newStr = obj.newStr.replace(this.getToken(tokenRef), aux);
        }
        const result = obj.newStr.trim().length > 0 ? obj.newStr : void (0);
        return result;
    }

    getModel(state) {
        if (!_.isPlainObject(state)) return {};
        return state.model ? state.model : state;
    }

    getListReplacementSum(str, selectedData, formats) {
        let sum = 0, result;
        str = str.split(/(:)/g);
        const fieldToSum = str.slice(4, 5).join("");
        for (let i = 0; i < selectedData.length; i++) {
            sum += selectedData[i][fieldToSum];
        }
        if (str.length >= 7) {
            result = sum.toFixed(str.slice(6, 7).join(""));
        }

        if (formats) {
            const fieldFormatUsed = formats[fieldToSum];
            const input = result ? result : sum;
            return this.fieldFormatService.format(fieldFormatUsed, input);
        } else {
            return result ? result : sum.toString();
        }
    }

    getFieldValue(state, field, format) {
        const model = this.getModel(state);
        let value = UtilityFunctions.getValue(model[field]);
        if (!value) {
            value = this.getFieldValueIfDataIsGrouped(model, field);
        }
        if (format)
            value = this.fieldFormatService.format(format, value);
        return value;
    }

    getFieldValueIfDataIsGrouped(appState, field) {
        const records = [];
        records.push(appState);
        const data = UtilityFunctions.getFirstRowFromDxDataGrid(records);
        return data[field];
    }

    hasValidState(state) {
        return _.isPlainObject(state);
    }

    getFieldValueFromAppContext(state, field, format?) {
        let tmp = null;
        if (this.hasValidState(state)) {
            tmp = this.getFieldValue(state, field, format);
        }
        return tmp;
    }

    getFallbackValueFromToken(tokenStr) {
        let i = tokenStr.indexOf("|");
        i = i < 0 ? tokenStr.length : i;
        const value = tokenStr.substr(i + 1, tokenStr.length);
        return value;
    }

    getSubjectFromToken(tokenStr) {
        let i = tokenStr.indexOf("|");
        i = i < 0 ? tokenStr.length : i;
        const field = tokenStr.substr(0, i);
        return field;
    }

    getFieldReplacement(str, config) {
        const subject = this.getSubjectFromToken(str);
        const fallback = this.getFallbackValueFromToken(str);
        let info = {
            appName: config.appName || config.options.appName || (config.options.applet ? (config.options.applet as Applet).name : undefined),
            fieldName: subject,
            originalName: ''
        };
        // In the case of icAppTemplates, originalName contains the template app name, and appName contains
        // the templated app name. eg: originalName: Foo.Input.App appName: fooParentListApp_FooInputApp_0
        info.originalName = config.originalName ||
            (config.options ?
                (config.options.originalName ||
                    (config.options.applet ?
                        config.options.applet.originalName :
                        info.appName)
                ) :
                info.appName);

        if (this.isFieldAppReferenced(subject)) {
            info = this.getAppReferenced(subject);
        }

        // $appInfoSvc is only loaded with the base apps, and not all the templates to avoid unnecessary duplication.
        // We prefer to use originalName as in the case of template apps, that is what exists in the infoSvc map.
        const formats = this.applicationInformation.getFormats(info.originalName);
        const format = formats[info.fieldName];
        const state = config.getAppState(info.appName); // App state is unique to the apps, so this doesn't get originalName
        let value = this.getFieldValueFromAppContext(state, info.fieldName, format);
        value = this.getDynamicValueOrFallback(value, fallback);
        return value;
    }

    updateComponentTranslationProperties(options, translateId) {
        options.ariaLabelText = this.translateFacade.getTranslation(translateId + '.ariaLabel');
        options.ariaDescribedBy = this.translateFacade.translateOrDefault(translateId + '.ariaDescribedBy', null);
        if (options.appState) {
            options.ariaLabelText = this.getDynamicFieldValue(options.appState, options.ariaLabelText);
            options.ariaDescribedBy = this.getDynamicFieldValue(options.appState, options.ariaDescribedBy);
        }
    }

    getFileExtensionReplacement(str, config) {
        const value = this.getFieldReplacement(str, config);
        if (_.isNil(value))
            return value;
        const s = value.split('.');
        if (s.length > 1) {
            return '.' + s[s.length - 1];
        }
        return "";
    }

    getListReplacement(str, config) {
        const strOrig = str;
        let appName = null;
        let dxDataGrid = null;
        let strToConsider = null;
        if (!_.isNil(config.options) && !_.isNil(config.options.appName)) {
            appName = config.options.appName;
        }
        if (!_.isNil(appName)) {
            dxDataGrid = this.domComponentRetrievalService.getListDxDataGrid(appName);
            if (_.isNil(dxDataGrid))
                return "0";
        }
        str = str.split(/(:)/g);
        if (str.length >= 3)
            strToConsider = str[1] + str[2];
        else
            strToConsider = str;

        switch (strToConsider) {
            case ":MaxRowCount": {
                const gridRowInfo = this.domComponentRetrievalService.getAppComponent(appName) as ListApplication;
                return gridRowInfo.maxRowCount ? gridRowInfo.maxRowCount.toString() : "";
            }
            case ":SelectedRowCount":
                return dxDataGrid.getSelectedRowsData().length.toString();
            case ":PageSize":
                return (dxDataGrid.pageSize() || 0).toString();
            case ":TotalItems":
                return (dxDataGrid.totalCount() < 0 ? 0 : dxDataGrid.totalCount()).toString();
            case ":SelectedRowSum":
                return this.getListReplacementSum(strOrig, dxDataGrid.getSelectedRowsData(), null);
        }
        return "0"; //dxDataGrid.maxRowCount.toString();
    }

    getReplacementFromServer(str, options) {
        str = options.str || str;
        return this.cacheManagerService.getRequestPromise(str);
    }

    noParser(str) {
        return str;
    }

    getScreenContextReplacement(str, config) {
        let i = str.indexOf("|");
        i = i < 0 ? str.length : i;
        const field = str.substr(0, i);
        let value = str.substr(i + 1, str.length);
        if (this.applicationInformation.isListApp(config.options.appName)) {
            const component = this.domComponentRetrievalService.getAppComponent(config.options.appName) as ListApplication;
            if (_.isNil(component) || _.isEmpty(component?.screenContextFields)) return value;
            const formats = this.applicationInformation.getFormats(config.options.appName);
            if (component.screenContextFields[field] && component.screenContextFields[field].valid) {
                const format = formats[field];
                value = this.fieldFormatService.format(format, component.screenContextFields[field].value);
            }
        }
        return value;
    }

    getColumnReplacement(str, config) {
        const subject = this.getSubjectFromToken(str),
            fallback = this.getFallbackValueFromToken(str),
            column = config.options && config.options.column || [];
        let value = "";
        if (!_.isNil(column[subject])) {
            value = column[subject];
        } else {
            value = fallback;
        }
        return value;
    }

    passThrough(str) {
        let value = "";
        if (!str || str.length < "{PassThrough:}".length) {
            return value;
        }

        value = str.substring("{PassThrough:".length, str.length - 1);

        return value;
    }

    splitOnColonsOutsideBraces(str) {
        str = str || "";
        let depth = 0;
        const splitIndices = [];

        for (let index = 0; index < str.length; index++) {
            if (str[index] === ":" && depth === 0) {
                splitIndices.unshift(index);
            } else if (str[index] === "{") {
                depth++;
            } else if (str[index] === "}") {
                depth--;
            }
        }

        const placeholder = "~~!!SPLIT!!~~";
        splitIndices.forEach((index) => {
            str = str.substring(0, index) + placeholder + str.substring(index + 1);
        });

        return str.split(placeholder);
    }

    private getSupportedDynamicReplacements(unformatted) {
        const dateReplacement = unformatted ? this.getDateReplacementUnformatted : this.getDateReplacement;

        //TODO: Refactor into a shared object to reduce memory pressure
        const objs = {
            "{SSOParam:": { token: "SSOParam:", adapt: this.getSSOParamReplacement.bind(this), parser: this.noParser },
            "{ThemeDetails:": { token: "{ThemeDetails:", adapt: this.getThemeSettingReplacement.bind(this), parser: this.getDynamicConfig },
            "{ThemeProperty:": { token: "{ThemeProperty:", adapt: this.getThemePropertyReplacement.bind(this), parser: this.getDynamicConfig },
            "{Event:": { token: "{Event:", adapt: this.getEventReplacement.bind(this), parser: this.getDynamicConfig },
            "{Field:": { token: "{Field:", adapt: this.getFieldReplacement.bind(this), parser: this.getDynamicConfig },
            "{List:": { token: "{List", adapt: this.getListReplacement.bind(this), parser: this.getDynamicConfig },
            "{PersistedProfile:": { token: "{PersistedProfile:", adapt: this.getReplacementFromServer.bind(this), parser: this.noParser },
            "{Globals:": { token: "{Globals", adapt: this.getReplacementFromServer.bind(this), parser: this.noParser },
            "{TotalSummaryValue:": { token: "{TotalSummaryValue:", adapt: this.getReplacementFromServer.bind(this), parser: this.noParser },
            "{ScreenContext:": { token: "{ScreenContext:", adapt: this.getScreenContextReplacement.bind(this), parser: this.getDynamicConfig },
            "{MenuRedirects:": { token: "{MenuRedirects:", adapt: this.getReplacementFromServer.bind(this), parser: this.noParser },
            "{Date:": { token: "{Date:", adapt: dateReplacement.bind(this), parser: this.getDynamicConfig },
            "{Persisted": { token: "{Persisted", adapt: this.getReplacementFromServer.bind(this), parser: this.noParser },
            "{Constant:": { token: "{Constant:", adapt: this.getConstantReplacement.bind(this), parser: this.noParser },
            "{Success:": { token: "{Success:", adapt: this.getErrorOrSuccessReplacement.bind(this), parser: this.noParser },
            "{Error:": { token: "{Error:", adapt: this.getErrorOrSuccessReplacement.bind(this), parser: this.noParser },
            "{FileExtension:": { token: "{FileExtension:", adapt: this.getFileExtensionReplacement.bind(this), parser: this.getDynamicConfig },
            "{Cookie:": { token: "{Cookie:", adapt: this.getCookieReplacement.bind(this), parser: this.getDynamicConfig },
            "{SecureCookie:": { token: "{SecureCookie:", adapt: this.getReplacementFromServer.bind(this), parser: this.noParser },
            "{Column:": { token: "{Column:", adapt: this.getColumnReplacement.bind(this), parser: this.getDynamicConfig },
            "{UserContextDefault:": { token: "{UserContextDefault:", adapt: this.getReplacementFromServer.bind(this), parser: this.noParser },
            "{PassThrough:": { token: "{PassThrough:", adapt: this.passThrough, parser: this.noParser },
            "{Url:": { token: "{Url:", adapt: this.getUrlReplacement.bind(this), parser: this.noParser },
            "{QueryString:": { token: "{QueryString:", adapt: this.getQueryStringReplacement.bind(this), parser: this.noParser }
        };
        return objs;
    }

    getTokens(str: string, objectInfo?: Record<string, unknown>, listData?: string[]): Tokens {
        const res: Tokens = {
            transform: [],
            tokens: [],
            newStr: ''
        },
            newStr = [],
            tokens: string[] = [],
            stack = [],
            transform = [];
        let buffer = [];
        for (let i = 0; i < str.length; i++) {
            const c = str[i];
            newStr.push(c);
            if (c == '{') {
                stack.push(c);
            }
            if (stack.length) {
                buffer.push(c);
                newStr.pop();
            }
            if (c == '}') {
                stack.pop();
            }
            if (!stack.length && buffer.length) {
                const result = this.dynamicConditionMet(buffer.join(""), objectInfo, listData);
                if (result.conditionMet) {
                    const value: string[] = result.dynamicStr.split('|');
                    const regexFunc: string = value[1] ? value[1].slice(0, -1) : ''; // to remove } from the string

                    // If there is a transform function as pipe separator along with dynamic replacement
                    if (regexFunc && regexFunc.match(/^(\w+)\((.*)\)$/)) {
                        const dynamicStr = value[0] + value[1].slice(value[1].length - 1); // to add } to the string
                        transform.push(regexFunc);
                        tokens.push(dynamicStr);
                    } else {
                        transform.push("");
                        tokens.push(result.dynamicStr);
                    }
                    const tokenRef = tokens.length - 1;
                    newStr.push(this.getToken(tokenRef));
                }
                buffer = [];
            }
        }
        res.transform = transform;
        res.tokens = tokens;
        res.newStr = newStr.join("");
        return res;
    }

    dynamicConditionMet(dynamicStr: string, objectInfo: Record<string, unknown>, listData: string[]): { conditionMet: boolean, dynamicStr: string } {
        if (!objectInfo) {
            return { conditionMet: true, dynamicStr };
        }

        let condition;
        const isColumnReplaceAll = dynamicStr.substring(0, dynamicStr.indexOf(":")).toLowerCase() === "{columnreplaceall";

        if (isColumnReplaceAll) {
            condition = dynamicStr.substring(dynamicStr.indexOf(":") + 1, dynamicStr.length - 1); // without type or wrapping braces
        } else {
            dynamicStr.split('').some((char, idx, arr) => {
                if (
                    char === ':' &&
                    arr.lastIndexOf(':') != idx &&
                    !dynamicStr.substring(idx, arr.lastIndexOf(':')).contains('{')
                ) {
                    condition = dynamicStr.slice(arr.lastIndexOf(':') + 1, arr.length - 1);
                    dynamicStr = dynamicStr.substring(0, arr.lastIndexOf(':')) + '}';
                    return true;
                }
            });
        }

        if (!condition) {
            return { conditionMet: true, dynamicStr: dynamicStr };
        }

        const conditionMetFn = () => {
            if (isColumnReplaceAll) {
                listData = listData || [];
                const tokens = this.splitOnColonsOutsideBraces(condition);

                if (tokens.length !== 3) {
                    IX_Log('dynamic', 'error parsing ColumnReplaceAll replacement conditional; must include (a) name of field to inspect, (b) value/field of comparer and (c) value/field to replace with');
                    return false;
                }

                const [field, compareTo, replaceWith] = tokens;
                const compareToField = this.getDynamicField(compareTo);

                for (let i = 0; i < listData.length; i++) {
                    const comparer = compareToField ? listData[i][compareToField] : compareTo;

                    // Intentionally using abstract equality so that "7.0" matches 7
                    if (listData[i][field] == comparer) {
                        const replaceWithField = this.getDynamicField(replaceWith);
                        const value = replaceWithField ? listData[i][replaceWithField] : replaceWith;
                        dynamicStr = '{PassThrough:' + value + '}';
                        return true;
                    }
                }

                return false;
            }

            try {
                const parsed = new AdvancedFilterExpressionParser().parse(condition);

                const expressionConfig = parsed[0];
                if (expressionConfig.length !== 3) {
                    throw ('Invalid comparison expression; use "{FieldName}{operator}{comparisonString} format without whitespace padding.');
                }

                const [fieldName, operator, comparisonString] = expressionConfig;
                if (operator === '=') {
                    throw ('Invalid comparison expression; use "==", not "=".');
                }

                let expression = 'this.' + fieldName + operator + '"' + comparisonString + '"';
                if (comparisonString === null) {
                    // Inequality comparison? In this special case, != null AND !="null"
                    const logicalOp = operator === '!=' ? ' && ' : ' || ';
                    expression = 'this.' + fieldName + operator + 'null' + logicalOp + 'this.' + fieldName + operator + '"null"';
                }

                let result = false;
                if (_.isPlainObject(objectInfo)) {
                    const runnerContext = { ...objectInfo };
                    const runnerFunction = 'runnerContext.run = function() { return ' + expression + '; }; return runnerContext.run()';
                    const runner = new Function('runnerContext', runnerFunction);
                    result = runner(runnerContext);
                }

                return result;
            } catch (e) {
                IX_Log('dynamic', 'error while executing replacement conditional', e.message, e.stack);
                return false;
            }
        };
        const conditionMet = conditionMetFn();
        return { conditionMet, dynamicStr };
    }

    private getDynamicReplacementAdapter(str, dynamicReplacements) {
        const tmp = str.substr(0, str.indexOf(':') + 1);
        return dynamicReplacements[tmp];
    }

    private runReplacement(config, dynamicReplacements): Promise<any> {

        let currentReplacement = config.str;
        let i = 0;

        do {

            i++;

            const adapter = this.getDynamicReplacementAdapter(currentReplacement, dynamicReplacements);
            if (adapter) {
                currentReplacement = adapter.parser(currentReplacement, adapter.token);
                currentReplacement = adapter.adapt(currentReplacement, config);
                if (UtilityFunctions.isPromise(currentReplacement)) break;
                currentReplacement = currentReplacement ? currentReplacement.toString() : "";
            } else {
                currentReplacement = "";
                break;
            }
            if (i > 50) {
                currentReplacement = "";
                break;
            }
        } while (currentReplacement && currentReplacement.indexOf("{") != -1);

        return currentReplacement;
    }

    private createTokensCommands(tokens: string[], options: any): any[] {
        return tokens.map(str => ({ str, options }));
    }

    // Version of _getDynamicValue that's optimized to be used for grid list data by reducing the number of promises it will create.
    // This is necessary as IE11 doesn't have native promises and uses MutationObservers in the bluebird library to imitate it.
    // With large grids 668 rows * 5 replacement per row = 3340 promises get generated.
    // This causes IE to run garbage collection before the grid can actually render significantly slowing doing performance.
    // Current version reduces number of promises to 0 all scenarios that are not async,
    // and minimal number of promises for scenarios that are truly async.
    getDynamicValueBatched(str, options) {

        if (!this.hasReplacementValue(str))
            return str;

        const fields = options?.data || options?.column || {};
        const listData = options?.listData || [];
        const config = this.getTokens(str, fields, listData);

        if (_.isEmpty(config.tokens) && _.isEmpty(config.newStr))
            return config.newStr;

        const dynamicReplacements = this.getSupportedDynamicReplacements(options?.unformatted);
        const commandFns = this.createTokensCommands(config.tokens, options);
        const promises = _.map(commandFns, (commandOptions, i) => {
            const replacedResult = this.runReplacement(commandOptions, dynamicReplacements);
            if (UtilityFunctions.isPromise(replacedResult)) {
                return replacedResult.then((result) => this.getTransformedValue(config, result, i))
                    .catch(e => IX_Log("dynamic", "error while executing replacements", e.message, e.stack));
            }
            return this.getTransformedValue(config, replacedResult, i);
        }).filter((x) => UtilityFunctions.isPromise(x));
        if (promises.length > 0) {
            return promises.reduce(() => config.newStr, '');
        }
        return config.newStr;
    }

    getDynamicValue(str: string, options?: any): Promise<string> {

        if (!this.hasReplacementValue(str))
            return Promise.resolve(str);

        const fields = options?.data || options?.column || null;
        const listData = options?.listData || [];
        const config = this.getTokens(str, fields, listData);

        if (_.isEmpty(config.tokens) && _.isEmpty(config.newStr))
            return Promise.resolve(config.newStr);

        const unformatted = options && options.unformatted;
        const dynamicReplacements = this.getSupportedDynamicReplacements(unformatted);
        const commandFns = this.createTokensCommands(config.tokens, options);

        const promise = this.getCurrentAppsState()
            .then((appsStore) => {
                const getAppState = (appName: string) => appsStore.entities[appName]?.state || (options.entities && options.entities[appName]?.state) || {};
                return commandFns.reduce((ignore, commandOptions, currentIndex) => {
                    commandOptions.getAppState = getAppState;
                    const replacedResult = this.runReplacement(commandOptions, dynamicReplacements);
                    const innerPromise = UtilityFunctions.isPromise(replacedResult) ? replacedResult : Promise.resolve(replacedResult);
                    return innerPromise.then((replacedValue) => this.getTransformedValue(config, replacedValue, currentIndex));
                }, '').catch(e => IX_Log("dynamic", "error while executing runReplacement", e.message, e.stack));
            }).catch(e => IX_Log("dynamic", "error while executing getDynamicValue reduce", e.message, e.stack));
        return promise.then(replacedValue => replacedValue)
            .catch(e => IX_Log("dynamic", "error while executing getDynamicValue", e.message, e.stack));
    }

    private getTransformedValue(config: any, result: string | any, tokenRef: number): string {
        result = this.transform(config, result, tokenRef);
        result = result ? result : "";
        const tokenName = this.getToken(tokenRef);
        config.newStr = config.newStr.replace(tokenName, result);
        return config.newStr;
    }

    transform(config, data, idx) {
        if (config.transform && config.transform.length > 0 && data) {
            const value = config.transform[idx];
            if (!value) return data;

            const matches = value.match("(Replace)\\((.*)\\)");
            switch (matches[1]) {
                case 'Replace': {
                    const args = matches[2];
                    const parts = args.match(/\/?(.*)\/\/?(.*)?,\s?'(.*)'/);
                    if (!parts) break;
                    const pattern = parts[1];
                    const flags = parts[2];
                    const replacement = parts[3];
                    data = data.replace(new RegExp(pattern, flags), replacement);
                    break;
                }
            }
        }
        return data;
    }

    getFallbackValue(str) {
        const info = this.getTokens(str);
        let fallBackValue: string = null;
        let aux: string[];
        if (info.tokens.length == 1) {
            aux = info.tokens[0].split("|");
            if (aux.length > 1) {
                fallBackValue = aux[aux.length - 1].replace(/\}/g, "");
            }
        }
        return {
            isValid: info.tokens.length == 1,
            fallBack: fallBackValue
        };
    }

    isClientSideReplacement(parameter) {

        if (typeof parameter == 'undefined' || typeof parameter == "number" ||
            typeof parameter != 'string' || parameter === null || parameter === "")
            return false;

        parameter = parameter.toLowerCase();
        const dynamic = parameter.indexOf("{field:") > -1 ||
            parameter.indexOf("{date:") > -1 ||
            parameter.indexOf("{list:") > -1 ||
            parameter.indexOf("{event:") > -1 ||
            parameter.indexOf("{themeproperty:") > -1 ||
            parameter.indexOf("{themedetails:") > -1 ||
            parameter.indexOf("{constant:") > -1 ||
            parameter.indexOf("{screencontext:") > -1 ||
            parameter.indexOf("{error:") > -1 ||
            parameter.indexOf("{cookie:") > -1 ||
            parameter.indexOf("{ssoparam:") > -1 ||
            parameter.indexOf("{column:") > -1 ||
            parameter.indexOf("{columnreplaceall:") > -1 ||
            parameter.indexOf("{url:") > -1 ||
            parameter.indexOf("{querystring:") > -1;
        return dynamic;
    }

    isServerSideReplacement(parameter) {

        if (typeof parameter == 'undefined' || typeof parameter == "number" ||
            typeof parameter != 'string' || parameter === null || parameter === "")
            return false;

        parameter = parameter.toLowerCase();
        const dynamic = parameter.indexOf("{menuredirects:") > -1 ||
            parameter.indexOf("{totalsummaryvalue:") > -1 ||
            parameter.indexOf("{globals:") > -1 ||
            parameter.indexOf("{usercontextdefault:") > -1 ||
            parameter.indexOf("{persisted") > -1 ||
            parameter.indexOf("{securecookie") > -1;
        return dynamic;
    }

    hasReplacementValue(parameter) {
        return this.isClientSideReplacement(parameter) || this.isServerSideReplacement(parameter);
    }

    private isFieldAppReferenced(fieldName) {
        return fieldName.indexOf('.') != -1;
    }

    getToken(index: number): string {
        return `##TOKEN${index}##`
    }

    private getAppReferenced(fieldNameStr): any {
        const parts = fieldNameStr.split(".");
        return {
            appName: parts[0].replace(/_/g, '.'),
            fieldName: parts[1]
        };
    }
}
