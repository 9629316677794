// tslint:disable: member-ordering
import { Injectable } from '@angular/core';
import { DataPersistence } from '@nrwl/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import * as AppsFeature from './apps.reducer';
import * as AppsActions from './apps.actions';
import * as StateActions from './apps.tokens';
import { ListDataEntity } from './apps.models';
import { EcdService } from '../services/clientservices/ecd.service';
import { ApplicationInformation } from '../services/application-information.service';
import { DomComponentRetrievalService } from '../services/dom-component-retrieval.service';
import { DynamicReplacementService } from '../services/dynamic-replacement.service';
import { ApiClientService } from '../services/api-client.service';
import { FieldFormatService } from '../services/field-format.service';
import { ListApplication } from '../components/list-application';
import UtilityFunctions from '../utility.functions';

@Injectable()
export class AppsEffects {

    constructor(private actions$: Actions,
        private dataPersistence: DataPersistence<AppsFeature.AppsPartialState>,
        private applicationInformation: ApplicationInformation,
        private ecdService: EcdService,
        private domComponentRetrievalService: DomComponentRetrievalService,
        private dynamicReplacementService: DynamicReplacementService,
        private fieldFormatService: FieldFormatService,
        private apiClientService: ApiClientService) { }

    //#region loadListData$ methods

    // TODO: Update to implement all logic from dataSourceService.createCustomDataSource
    loadListData$ = createEffect(() =>
        this.dataPersistence.fetch(AppsActions.loadListData, {
            id: (action: ReturnType<typeof AppsActions.loadListData>, state) => action.ecdRequest.ApplicationName,
            run: (
                action: ReturnType<typeof AppsActions.loadListData>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const ecdRequest = { ...action.ecdRequest };
                const dynamicColumnItems = action.ecdRequest.DynamicColumnItems;
                delete ecdRequest.DynamicColumnItems;
                const appName = ecdRequest.ApplicationName;

                const filters = appsStore.listFilter.entities[appName]?.listFilterDetails?.filters || [];
                this.setRequestFilter(filters, ecdRequest);
                const listComponent = this.domComponentRetrievalService.getAppComponent(appName) as ListApplication;

                if (_.isNil(ecdRequest.Context))
                    ecdRequest.Context = {};

                IX_PerfStart(appName, 'list-data-load');
                const promise = this.ecdService.request(ecdRequest);
                return from(promise)
                    .pipe(
                        switchMap((response: any) => {
                            const listData = this.convertDataSetToArray(response.ListData);
                            const listDataPromise = this.createDynamicColumns(appName, listData, dynamicColumnItems);
                            return from(listDataPromise);
                        }),
                        switchMap((listDataWithDynamicColumns: any[]) => {
                            const maps = listComponent?.gridProperties?.customSortSortColumnMaps || {};
                            this.createCustomSortProperties(listDataWithDynamicColumns, maps);
                            const listDataEntity: ListDataEntity = {
                                id: appName,
                                data: listDataWithDynamicColumns,
                                requestId: ecdRequest.requestId
                            };
                            return of({
                                type: `[${appName}] ${StateActions.LOAD_LIST_DATA_SUCCESS}`,
                                appName,
                                listDataEntity
                            });
                        }),
                    );
            },
            onError: (action, error) => {
                const appName = action.ecdRequest.ApplicationName;
                const errorMessage = error?.responseJSON?.MainMessage || `Could not retrieve list data for ${appName}, no error message was provided`;
                const onErrorAction = AppsActions.onError({ error: errorMessage, info: action });
                const ecdRequest = { ...action.ecdRequest };
                const listDataEntity: ListDataEntity = {
                    id: appName,
                    data: [],
                    requestId: ecdRequest.requestId
                }
                const listDataAction = {
                    type: `[${appName}] ${StateActions.LOAD_LIST_DATA_ERROR}`,
                    appName,
                    listDataEntity
                };
                return from([listDataAction, onErrorAction]);
            }
        })
    );

    mupListData$ = createEffect(() =>
        this.dataPersistence.fetch(AppsActions.updateServerListData, {
            run: (
                action: ReturnType<typeof AppsActions.updateServerListData>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {

                const ecdRequest = { ...action.ecdRequest };
                const promise = this.ecdService.request(ecdRequest);
                const appName = ecdRequest.ApplicationName;

                return from(promise)
                    .pipe(
                        switchMap((response: any) => {

                            _.forEach(action.deferrers, (deferred) => {
                                if ("SUCCESS" === response.Status) {
                                    deferred.resolve(true);
                                } else {
                                    deferred.reject(response.Status);
                                }
                            });

                            return of({
                                type: `[${appName}] ${StateActions.MUP_LIST_DATA_SUCCESS}`,
                                appName,
                                listDataEntity: {
                                    data: [] // TODO get listData onto response object
                                }
                            });
                        }),
                    );
            },
            onError: (route, error) => {
                console.error('Failed to mup list data', error);

                // TODO Use error for last error
                //Used by "{Error:} Dynamic Replacement Value. "
                // window.lastError = jqXHR.responseJSON;
                // IX_Log('component', jqXHR.responseJSON);

                _.forEach(route.deferrers, (deferred) => {
                    //deferred.reject(jqXHR.responseJSON.MainMessage);
                    deferred.reject(error);
                });

                return AppsActions.mupApplicationFailure({ error });
            },
        })
    );

    updateListKeyboardFocus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppsActions.updateListKeyboardFocus),
            switchMap((action) => {
                typeof action.callback === 'function' && action.callback();
                return [];
            }),
        ));

    private setRequestFilter(filter: any[], ecdRequest: EcdRequest) {
        ecdRequest.Data = {};
        if (!Array.isArray(filter)) return;
        for (let i = 0; i < filter.length; i++) {
            const filterElement = filter[i];
            if (!Array.isArray(filterElement)) continue;
            const fValue = UtilityFunctions.getValue(filterElement[filterElement.length - 1]);
            if (_.isNil(fValue)) continue;
            ecdRequest.Data[filterElement[0]] = fValue;
        }
    }

    private convertDataSetToArray(listData: any[]): any[] {
        const dataArray = [];
        for (let i = 1; i < listData.length; i++) {
            const singleObject = listData[i];
            const index = i - 1;
            dataArray[index] = {};
            for (let j = 0; j < singleObject.length; j++) {
                const indexName = listData[0][j];
                dataArray[index][indexName] = singleObject[j];
            }
        }
        return dataArray;
    }

    private createDynamicColumns(appName: string, listData: any, dynamicColumns: any): Promise<any> {
        if (!Array.isArray(dynamicColumns)) {
            return Promise.resolve(listData);
        }

        //to determine which columns have the superscript values
        let clientSideFieldsOnly = [];
        if (dynamicColumns && dynamicColumns.length > 0) {
            const gridScope = this.domComponentRetrievalService.getAppComponent(appName) as ListApplication;

            if (gridScope) {
                const gridApplet = gridScope.applet;
                if (gridApplet && gridApplet.config && gridApplet.config.clientSideFields) {
                    clientSideFieldsOnly = gridApplet.config.clientSideFields;
                }

                dynamicColumns.forEach((itm) => {
                    // eslint-disable-next-line no-prototype-builtins
                    if (!gridApplet.hasOwnProperty("superscriptFields")) {
                        gridApplet.superscriptFields = [];
                    }
                    if (itm.SourceValue.contains("<sup>")) {
                        if (_.indexOf(gridApplet.superscriptFields, itm.TargetField) === -1) {
                            gridApplet.superscriptFields.push(itm.TargetField);
                        }
                    }
                });
            }
        }

        const allDynamicReplacements = [];
        try {
            const replaceFn = this.getReplaceFn();
            listData.forEach((currentItem) => {
                dynamicColumns.forEach((column) => {
                    replaceFn(currentItem, column, clientSideFieldsOnly, listData, appName, allDynamicReplacements);
                });
            });
        } catch (e) {
            console.log(e);
        }

        return Promise.all(allDynamicReplacements).then(() => listData);
    }

    private getReplacementOptions(appName, clientSideFieldsOnly, listData, column, currentItem) {
        let options: any = {
            appName,
            listData,
            column: currentItem,
        };
        if (!_.isEmpty(clientSideFieldsOnly)
            && _.includes(clientSideFieldsOnly, column.TargetField)) {
            options = {};
        }
        return options;
    }

    private getReplaceFn() {
        let promise;
        return (currentItem, column, clientSideFieldsOnly, listData, appName, allDynamicReplacements) => {
            const options = this.getReplacementOptions(appName, clientSideFieldsOnly, listData, column, currentItem);
            promise = this.dynamicReplacementService.getDynamicValue(column.SourceValue, options);
            allDynamicReplacements.push(promise);
            promise.then((resultValue) => currentItem[column.TargetField] = resultValue);
        };
    }

    private createCustomSortProperties(listData: any, customSortMap: any): void {
        if (!Array.isArray(listData) || _.isNil(customSortMap) || !_.isPlainObject(customSortMap)) return;
        const gapMap = {},
            uniqueMap = {},
            uniqueMapValues = {};
        for (let i = 0; i < listData.length; i++) {
            for (const field in customSortMap) {
                if (!_.isNil(listData[i][field])) {
                    if (!uniqueMap[field]) uniqueMap[field] = {};
                    uniqueMap[field][listData[i][field]] = 0;
                }
                gapMap[field] = Object.keys(customSortMap[field]).length;
            }
        }
        for (const field in uniqueMap) {
            const auxArray = Object.keys(uniqueMap[field]);
            auxArray.sort();
            uniqueMapValues[field] = {};
            auxArray.forEach(function (it, i) {
                uniqueMapValues[field][it] = i + gapMap[field];
                if (!_.isNil(customSortMap[field][it])) {
                    uniqueMapValues[field][it] = customSortMap[field][it];
                }
            });
        }
        for (let i = 0; i < listData.length; i++) {
            for (const field in uniqueMapValues) {
                const item = listData[i];
                if (!_.isNil(item[field]) && !_.isNil(uniqueMapValues[field][item[field]])) {
                    item["_customSortFieldName" + field] = uniqueMapValues[field][item[field]];
                }
            }
        }
    }

    //#endregion

    //#region loadApplication$ methods

    loadApplication$ = createEffect(() =>
        this.dataPersistence.fetch(AppsActions.loadApplication, {
            id: (action: ReturnType<typeof AppsActions.loadApplication>, state) => action.ecdRequest.ApplicationName,
            run: (
                action: ReturnType<typeof AppsActions.loadApplication>,
                { [AppsFeature.APPS_FEATURE_KEY]: appsStore }
            ) => {
                const request = action.ecdRequest;
                const dynamicReplacements = action.dynamicReplacements;
                const appName = request.ApplicationName;
                const promise = this.performEcdRequest(appsStore, request);
                return from(promise)
                    .pipe(
                        map(response => {
                            let appState = this.processResponseData(appsStore, appName, response);
                            appState = this.updateStateWithReplacementValues(appState, appName, dynamicReplacements);
                            return {
                                type: `[${appName}] ${StateActions.LOAD_SUCCESS}`,
                                appName,
                                appState
                            };
                        })
                    )
            },
            onError: (route, error) => {
                console.error('Failed to load application', route.ecdRequest?.ApplicationName, error);
                return AppsActions.loadApplicationFailure({ error });
            },
        })
    );

    private performEcdRequest(appsStore: any, request: EcdRequest): Promise<any> {
        const dataIn = {};
        const ecdContext = {};
        const parentAppId = this.normalizeAppName(request.ContainerApplication);
        const ctxApps = this.getRequestContext(appsStore, request.ApplicationName, request.ContainerApplication);
        const ecdRequest = this.apiClientService.createECDHeader(request.ApplicationName, parentAppId, request.ServerCallType, request.CommandPath);
        this.apiClientService.setRequestData(ctxApps[request.ApplicationName], dataIn);
        this.apiClientService.setECDContext(ecdContext, ctxApps);
        ecdRequest.Data = dataIn;
        this.apiClientService.setEcdRequestContext(ecdRequest, ecdContext);
        return this.ecdService.request(ecdRequest);
    }

    private normalizeAppName(appName: string): string {
        return appName.split("[")[0];
    }

    private getComponentData(appsStore: any, appName: string): Record<string, any> {
        return appsStore.components ? appsStore.components[appName] : null;// Update 'store' to contain 'component data'
    }

    private getAppState(appsStore: AppsFeature.State, appName: string): Record<string, any> {
        return appsStore.apps.entities[appName] ? { ...appsStore.apps.entities[appName].state } : {};
    }

    private getRequestContext(appsStore: any, appId: string, parentAppId: string, additionalApps?: any)
        : Record<string, any> {
        const ctxApps = {};
        ctxApps[appId] = this.getAppState(appsStore, appId);
        if (appId !== parentAppId) {
            const parentAppIdWithoutRowIndex = this.normalizeAppName(parentAppId);
            ctxApps[parentAppIdWithoutRowIndex] = this.getAppState(appsStore, parentAppId);
        }
        if (Array.isArray(additionalApps)) {
            additionalApps.forEach(otherAppName => {
                if (ctxApps[otherAppName]) return;
                ctxApps[otherAppName] = this.getAppState(appsStore, otherAppName);
            });
        }
        if (_.isPlainObject(additionalApps)) {
            for (const otherAppName in additionalApps) {
                if (ctxApps[otherAppName]) continue;
                ctxApps[otherAppName] = this.getAppState(appsStore, otherAppName);
            }
        }
        const componentData = this.getComponentData(appsStore, appId);
        if (componentData) {
            _.extend(ctxApps[appId], componentData);
        }
        return ctxApps;
    }

    private shouldUpdateField(currentValue: any, value: any, force: boolean): boolean {
        return ((!_.isNil(value) && value !== "") || force) && (_.isNil(currentValue) || force);
    }

    private processResponseData(appsStore: AppsFeature.State, appName: string, response: any): AppState {
        const singletonData = response.SingletonData || {};
        const appState = this.getAppState(appsStore, appName);
        for (const field in singletonData) {
            if (!this.shouldUpdateField(appState[field], singletonData[field], true)) continue;
            appState[field] = singletonData[field];
        }
        return appState;
    }

    private isFieldDynamicReplacement(dynamicReplacements: Record<string, unknown>, dynamicValue: string): boolean {
        const fieldType = "{field:";
        const preIndex = dynamicValue.indexOf(fieldType);
        const auxType = preIndex > -1 ? fieldType : "";
        const replacementEndIndex = dynamicValue.indexOf("}");
        const auxFieldName = dynamicValue.substring(preIndex + fieldType.length, replacementEndIndex);
        return auxType === fieldType && !!dynamicReplacements[auxFieldName];
    }

    private getFieldsDynamicReplacements(dynamicReplacements: Record<string, any>)
        : Record<string, string>[] {
        const fields = [];
        const dateType = "{date:";
        _.forEach(dynamicReplacements, (field, fieldName) => {
            let dynamicValue = field.value;
            if (!_.isString(dynamicValue)) return;
            dynamicValue = dynamicValue.trim().toLowerCase();
            if (dynamicValue.length <= 0) return;
            //to check for the passed dynamicReplacement value type
            //also to check replacement value regardless if its position in the replacement string
            if (this.isFieldDynamicReplacement(dynamicReplacements, dynamicValue)) {
                fields.push({ fieldName, dynamicValue, fieldType: true });
            } else {
                const preIndex = dynamicValue.indexOf(dateType);
                const auxType = preIndex > -1 ? dateType : "";
                if (auxType === dateType) {
                    fields.unshift({ fieldName, dynamicValue, dateType: true });
                }
            }
        });
        return fields;
    }

    private updateStateWithReplacementValues(appState: AppState, appName: string,
        dynamicReplacements: Record<string, any>): AppState {
        const replacements = this.getFieldsDynamicReplacements(dynamicReplacements);
        const appStateClone = { ...appState };
        if (!Array.isArray(replacements) || replacements.length < 1) return appStateClone;
        const formats = this.applicationInformation.getFormats(appName);
        replacements.forEach(replacement => {
            let dynamicValue = replacement.dynamicValue;
            if (replacement.fieldType)
                dynamicValue = this.dynamicReplacementService.getDynamicFieldValue(appStateClone, dynamicValue);
            else if (replacement.dateType)
                dynamicValue = this.dynamicReplacementService.getDateReplacementUnformatted(dynamicValue);
            const fieldName = replacement.fieldName;
            const fieldFormat = formats[fieldName];
            appStateClone[fieldName] = this.fieldFormatService.getFormattedValue(fieldFormat, dynamicValue);
        });
        return appStateClone;
    }

    //#endregion

    onError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppsActions.onError),
                tap((action) => console.error('onError handling ', action))
            ),
        { dispatch: false }
    );

}
