/* eslint-disable @typescript-eslint/no-use-before-define */
import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as AppsActions from './apps.actions';
import * as StateActions from './apps.tokens';
import { AppsEntity, HolderAppEntity, AppInHolderEntity, FieldsInAppEntity, ListDataEntity, ListFilterEntity } from './apps.models';
import { AppEvent } from './app-events.enum';

export const APPS_FEATURE_KEY = 'apps';

export interface State {
  apps: EntityState<AppsEntity>;
  listData: EntityState<ListDataEntity>;
  listFilter: EntityState<ListFilterEntity>;
  holderApps: EntityState<HolderAppEntity>;
  appsInHolder: EntityState<AppInHolderEntity>;
  fieldsInApp: EntityState<FieldsInAppEntity>;
  error: string;
  step?: string;
  workflow?: string;
  breakPoint?: string;
  contextName?: string;
  themeName?: string;
  stateMode?: string;
  isPlatformMobile: boolean;
}

export interface AppsPartialState {
  readonly [APPS_FEATURE_KEY]: State;
}

export const appsAdapter: EntityAdapter<AppsEntity> = createEntityAdapter<AppsEntity>();
export const listDataAdapter: EntityAdapter<ListDataEntity> = createEntityAdapter<ListDataEntity>();
export const listFilterAdapter: EntityAdapter<ListFilterEntity> = createEntityAdapter<ListFilterEntity>();
export const holderAppAdapter: EntityAdapter<HolderAppEntity> = createEntityAdapter<HolderAppEntity>();
export const appInHolderAdapter: EntityAdapter<AppInHolderEntity> = createEntityAdapter<AppInHolderEntity>();
export const fieldsInAppAdapter: EntityAdapter<FieldsInAppEntity> = createEntityAdapter<FieldsInAppEntity>();

export const appsInitialState: State = {
  error: null,
  breakPoint: "ExtraSmall",
  apps: appsAdapter.getInitialState({}),
  listData: listDataAdapter.getInitialState(),
  listFilter: listFilterAdapter.getInitialState(),
  holderApps: holderAppAdapter.getInitialState(),
  fieldsInApp: fieldsInAppAdapter.getInitialState(),
  appsInHolder: appInHolderAdapter.getInitialState(),
  isPlatformMobile: false,
};

interface StateAction extends Action {
  appName: string;
  appState: AppState;
  forceDelete?: boolean;
  listDataEntity?: ListDataEntity;
  listFilterDetails?: DataSourceFilterDetails;
  commandState: Record<string, AppsEntity>;
}

const updatePristineField = (entity: FieldsInAppEntity, field: string, value: string | number | boolean): void => {
  const pristineRecordIndex = entity.pristineFieldValues.findIndex(pv => pv[field]);
  const newRecord = { [field]: value };
  if (pristineRecordIndex > -1) {
    entity.pristineFieldValues.splice(pristineRecordIndex, 1, newRecord);
  } else {
    entity.pristineFieldValues.push(newRecord);
  }
}

const appsReducer = createReducer(
  appsInitialState,
  on(AppsActions.onError, (state, { error }) => ({ ...state, error })),
  on(AppsActions.setStep, (state, { step }) => ({ ...state, step })),
  on(AppsActions.setWorkflow, (state, { workflow }) => ({ ...state, workflow })),
  on(AppsActions.setBreakPoint, (state, { breakPoint }) => ({ ...state, breakPoint })),
  on(AppsActions.setContextName, (state, { contextName }) => ({ ...state, contextName })),
  on(AppsActions.setThemeName, (state, { themeName }) => ({ ...state, themeName })),
  on(AppsActions.setStateMode, (state, { stateMode }) => ({ ...state, stateMode })),
  on(AppsActions.loadApplicationFailure, (state, { error }) => ({ ...state, error })),
  on(AppsActions.resetContext, (state, { resetOptions }) => ({
    ...state,
    step: null,
    apps: clearAppsContext(state, resetOptions),
    listData: listDataAdapter.removeAll(state.listData),
    listFilter: listFilterAdapter.removeAll(state.listFilter),
    holderApps: holderAppAdapter.removeAll(state.holderApps),
    fieldsInApp: fieldsInAppAdapter.removeAll(state.fieldsInApp),
    appsInHolder: appInHolderAdapter.removeAll(state.appsInHolder)
  })),
  on(AppsActions.updateCommandState, (state, { commandState }) => ({ ...state, apps: appsAdapter.upsertMany(commandState, state.apps) })),
  on(AppsActions.setDataDisplayBehaviorOnEventOnly, (state, { holderAppName, appNames }) => {
    const onEventOnly = true;
    const appEntities: AppInHolderEntity[] = appNames.map(app => ({ id: app, onEventOnly }));
    const appsInHolder = appInHolderAdapter.upsertMany(appEntities, state.appsInHolder);
    const inHolder = _.merge(appNames, state.holderApps.entities[holderAppName]?.apps);
    const holderApps = holderAppAdapter.upsertOne({ id: holderAppName, apps: inHolder }, state.holderApps);
    return {
      ...state,
      appsInHolder,
      holderApps,
    };
  }),
  on(AppsActions.disableDisplayBehaviorOnEventOnly, (state, { holderAppName, appName }) => {
    const onEventOnly = false;
    const appEntity: AppInHolderEntity = { id: appName, onEventOnly };
    const appsInHolder = appInHolderAdapter.upsertOne(appEntity, state.appsInHolder);
    const inHolder = _.merge([appName], state.holderApps.entities[holderAppName]?.apps);
    const holderApps = holderAppAdapter.upsertOne({ id: holderAppName, apps: inHolder }, state.holderApps);
    return {
      ...state,
      appsInHolder,
      holderApps,
    };
  }),
  on(AppsActions.disableDisplayBehaviorOnEventOnlyAllAppInstances, (state, { appName }) => {
    const appEntity: AppInHolderEntity = { id: appName, onEventOnly: false };
    const appsInHolder = appInHolderAdapter.upsertOne(appEntity, state.appsInHolder);
    return {
      ...state,
      appsInHolder,
    };
  }),
  on(AppsActions.setIsPlatformMobile, (state) => {
    return {
      ...state,
      isPlatformMobile: true,
    }
  }),
  on(AppsActions.setAppDirtyField, (state, { appName, fieldName, isDirty }) => {
    const toUpdate = getAppFields(state, appName);
    const fieldIndex = toUpdate.dirtyFields.indexOf(fieldName);

    if (fieldIndex > -1) {
      if (!isDirty)
        toUpdate.dirtyFields.splice(fieldIndex, 1);
    } else {
      if (isDirty)
        toUpdate.dirtyFields.push(fieldName);
    }

    const fieldsInApp = fieldsInAppAdapter.upsertOne(toUpdate, state.fieldsInApp);
    return {
      ...state,
      fieldsInApp,
    };
  }),
  on(AppsActions.setAppStateDirty, (state, { appName }) => {
    const appEntity = getAppStateOrDefault(state, appName);
    appEntity.isDirty = true;
    const apps = appsAdapter.upsertOne(appEntity, state.apps);
    return {
      ...state,
      apps,
    };
  }),
  on(AppsActions.setAppPristineFieldValue, (state, { appName, fieldName, value }) => {
    const toUpdate = getAppFields(state, appName);
    updatePristineField(toUpdate, fieldName, value);
    const fieldsInApp = fieldsInAppAdapter.upsertOne(toUpdate, state.fieldsInApp);
    return {
      ...state,
      fieldsInApp,
    };
  }),
  on(AppsActions.setCurrentAsPristine, (state, { appName }) => {
    const fieldsInAppToUpdate = getAppFields(state, appName);

    if (fieldsInAppToUpdate.dirtyFields?.length) {
      fieldsInAppToUpdate.dirtyFields.forEach(dirtyField => {
        const value = getAppStateOrDefault(state, appName).state[dirtyField];
        updatePristineField(fieldsInAppToUpdate, dirtyField, value);
      });
    }

    fieldsInAppToUpdate.dirtyFields = [];

    const fieldsInApp = fieldsInAppAdapter.upsertOne(fieldsInAppToUpdate, state.fieldsInApp);
    return {
      ...state,
      fieldsInApp,
    }
  }),
  on(AppsActions.clearDirtyFields, (state, { appName }) => {
    const toUpdate = getAppFields(state, appName);
    toUpdate.dirtyFields = [];
    const fieldsInApp = fieldsInAppAdapter.upsertOne(toUpdate, state.fieldsInApp);
    return {
      ...state,
      fieldsInApp,
    };
  }),
  on(AppsActions.resetDirtyFieldsToPristine, (state, { appName }) => {
    const fieldsInAppToUpdate = getAppFields(state, appName);
    const appState = getAppStateOrDefault(state, appName);
    const stateToUpdate = {
      ...appState,
      state: {
        ...appState.state,
      },
    };

    fieldsInAppToUpdate.dirtyFields.forEach(field => {
      const value = fieldsInAppToUpdate.pristineFieldValues.find(pv => pv[field])?.[field];
      stateToUpdate.state[field] = value;
    });

    fieldsInAppToUpdate.dirtyFields = [];

    const apps = appsAdapter.upsertOne(stateToUpdate, state.apps);
    const fieldsInApp = fieldsInAppAdapter.upsertOne(fieldsInAppToUpdate, state.fieldsInApp);
    return {
      ...state,
      apps,
      fieldsInApp,
    }
  }),
  on(AppsActions.pillListPillClicked, (state, { id }) => {
    const appEntity = getAppStateOrDefault(state, AppEvent.PillListPillClicked);
    const event = { ...appEntity, state: { ...appEntity.state, Id: id }, }
    const apps = appsAdapter.upsertOne(event, state.apps);
    return {
      ...state,
      apps,
    };
  }),
  on(AppsActions.pillListPillCloseClicked, (state, { id }) => {
    const appEntity = getAppStateOrDefault(state, AppEvent.PillListPillCloseClicked);
    const event = { ...appEntity, state: { ...appEntity.state, Id: id }, }
    const apps = appsAdapter.upsertOne(event, state.apps);
    return {
      ...state,
      apps,
    };
  }),
);

function getDefaultAppState(appName: string): AppsEntity {
  return {
    id: appName,
    state: {},
    appName,
    isDirty: false
  };
}

function getAppStateOrDefault(state: State, appName: string): AppsEntity {
  if (state.apps.entities[appName]) return { ...state.apps.entities[appName] };
  return getDefaultAppState(appName);
}

function getAppFields(state: State, appName: string): FieldsInAppEntity {
  const defaultState = { id: appName, dirtyFields: [], pristineFieldValues: [] };
  const appFields = state.fieldsInApp.entities[appName] || defaultState;
  const appFieldEntity = _.cloneDeep(appFields);
  appFieldEntity.dirtyFields = _.isArray(appFieldEntity.dirtyFields) ? appFieldEntity.dirtyFields : [];
  return appFieldEntity;
}

function loadSuccess(state: State, appName: string, action: StateAction): State {
  const appEntity = getAppStateOrDefault(state, appName);
  appEntity.state = { ...appEntity.state, ...action.appState };
  const apps = appsAdapter.upsertOne(appEntity, state.apps)
  return {
    ...state,
    apps
  };
}

function updateState(state: State, appName: string, action: StateAction): State {
  const appEntity = getAppStateOrDefault(state, appName);
  appEntity.state = { ...appEntity.state, ...action.appState };
  const apps = appsAdapter.upsertOne(appEntity, state.apps);
  return {
    ...state,
    apps
  };
}

function resetState(state: State, appName: string, action: StateAction): State {
  let apps;
  if (action.forceDelete) {
    apps = appsAdapter.removeOne(appName, state.apps);
  } else {
    const appEntity = getDefaultAppState(appName);
    apps = appsAdapter.setOne(appEntity, state.apps);
  }
  const listData = listDataAdapter.removeOne(appName, state.listData);
  const listFilter = listFilterAdapter.removeOne(appName, state.listFilter);
  return {
    ...state,
    apps,
    listData,
    listFilter
  };
}

function loadListDataSuccess(state: State, appName: string, action: StateAction): State {
  const { data, requestId } = action.listDataEntity;
  const listDataEntity = {
    id: appName,
    data,
    requestId,
  };
  const listData = listDataAdapter.upsertOne(listDataEntity, state.listData);
  return {
    ...state,
    listData
  };
}

function loadListDataBypass(state: State, appName: string, action: StateAction): State {
  return state;
}

function setListFilters(state: State, appName: string, action: StateAction): State {

  let listFilter = state.listFilter;
  if (action.listFilterDetails) {
    const listFilterDetails = { ...action.listFilterDetails };
    listFilter = listFilterAdapter.upsertOne({ id: appName, listFilterDetails }, state.listFilter);
  }

  return {
    ...state,
    listFilter
  };
}

function isKeepOnlyInputAppsInContextEnabled(mode: string): boolean {
  return 'KeepOnlyInputApplications'.EqualsIgnoreCase(mode);
}

function isKeepNothingInContextEnabled(mode: string): boolean {
  return 'KeepNothing'.EqualsIgnoreCase(mode);
}

function isKeepOnlyInputAppsAndEventsInContextEnabled(mode: string): boolean {
  return 'KeepInputApplicationsAndEvents'.EqualsIgnoreCase(mode);
}

function shouldClearAppState(appName: string, mode: string, resetOptions: ResetOptions): boolean {
  let result = false;
  const keepOnlyInputApps = isKeepOnlyInputAppsInContextEnabled(mode);
  const keepOnlyInputAppsAndEvents = isKeepOnlyInputAppsAndEventsInContextEnabled(mode);
  const isEvent = appName.toLowerCase().contains(".event");
  if (keepOnlyInputApps && (isEvent || !resetOptions.isInput(appName))) {
    result = true;
  }
  else if (keepOnlyInputAppsAndEvents && resetOptions.isList(appName)) {
    result = true;
  }
  return result;
}

function clearAppsContext(state: State, resetOptions: ResetOptions): EntityState<AppsEntity> {
  if (resetOptions.resetAllContext)
    return appsAdapter.removeAll(state.apps);
  const skipApps = resetOptions.skipApps ? resetOptions.skipApps : {};
  let appKeys: string[] = [];
  state.apps.ids.forEach((app) => {
    const appName = app.toString();
    if (appName[0] === '_') return;
    if (appName === state.contextName) return;
    if (skipApps[appName]) return;
    appKeys.push(appName);
  });
  const keepNothing = isKeepNothingInContextEnabled(state.stateMode);
  if (!keepNothing)
    appKeys = appKeys.filter(appName => shouldClearAppState(appName, state.stateMode, resetOptions));
  return appsAdapter.removeMany(appKeys, state.apps);
}

const appStateReducer = (state: State | undefined, action: StateAction) => {
  if (action.appName) {
    const actionType = _.last(action.type.split(" "));
    switch (actionType) {
      case StateActions.LOAD_SUCCESS:
        return loadSuccess(state, action.appName, action);
      case StateActions.UPDATE_STATE:
        return updateState(state, action.appName, action);
      case StateActions.RESET_STATE:
        return resetState(state, action.appName, action);
      case StateActions.SET_FILTERS:
        return setListFilters(state, action.appName, action);
      case StateActions.LOAD_LIST_DATA_SUCCESS:
      case StateActions.LOAD_LIST_DATA_ERROR:
        return loadListDataSuccess(state, action.appName, action);
      case StateActions.LOAD_LIST_DATA_ONEVENTONLY_BYPASS:
        return loadListDataBypass(state, action.appName, action);
      case StateActions.REPLACE_LIST_DATA:
        return loadListDataSuccess(state, action.appName, action);
      case StateActions.MUP_LIST_DATA_SUCCESS:
        return loadListDataSuccess(state, action.appName, action);
    }
  }
  return appsReducer(state, action);
}

export function dynamicReducer(state: State | undefined, action: Action): State {
  return appStateReducer(state, action as StateAction);
}
