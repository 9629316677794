/* eslint-disable @typescript-eslint/no-this-alias */
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import dxPromise, { dxDeferred } from 'devextreme/core/utils/deferred';
import CustomStore from 'devextreme/data/custom_store';
import Sugar from 'sugar/date';
import { StoreService } from './store-service';
import { UtilService } from './util.service';
import { DataService } from './data.service';
import { EcdService } from './clientservices/ecd.service';
import { ApiClientService } from './api-client.service';
import { DynamicReplacementService } from './dynamic-replacement.service';
import { HelpersService } from './helpers.service';
import { WorkflowApplicationTree } from './workflow-application-tree.service';
import { AppsConstantsFacade } from './apps-constants.facade';
import { DomComponentRetrievalService } from './dom-component-retrieval.service';
import UtilityFunctions from '../utility.functions';
import { LoadOptions } from 'devextreme/data/load_options';
import { AppsFacade } from '../state/apps.facade';
import { ListApplication } from '../components/list-application';

@Injectable()
export class DataSourceService {

    currentStepName: string;

    constructor(
        private storeService: StoreService,
        private dataService: DataService,
        private ecdService: EcdService,
        private apiClientService: ApiClientService,
        private dynamicReplacementService: DynamicReplacementService,
        private helpersService: HelpersService,
        private workflowApplicationTreeService: WorkflowApplicationTree,
        private utilService: UtilService,
        private appsFacade: AppsFacade,
        private appsConstantsFacade: AppsConstantsFacade,
        private domComponentRetrievalService: DomComponentRetrievalService) {
        this.appsConstantsFacade.step$.subscribe(stepName => this.currentStepName = stepName);
    }

    formatters = {
        "=": this.createBinaryOperationFormatter("eq"),
        "<>": this.createBinaryOperationFormatter("ne"),
        ">": this.createBinaryOperationFormatter("gt"),
        ">=": this.createBinaryOperationFormatter("ge"),
        "<": this.createBinaryOperationFormatter("lt"),
        "<=": this.createBinaryOperationFormatter("le"),
        startswith: this.createStringFuncFormatter("startswith"),
        endswith: this.createStringFuncFormatter("endswith")
    };
    formattersV2 = $.extend({}, this.formatters, {
        contains: this.createStringFuncFormatter("substringof", true),
        notcontains: this.createStringFuncFormatter("not substringof", true)
    });
    formattersV4 = $.extend({}, this.formatters, {
        contains: this.createStringFuncFormatter("contains"),
        notcontains: this.createStringFuncFormatter("not contains")
    });

    private getAppState(appName: string): Promise<any> {
        return this.storeService
            .getCurrentAppState(appName)
            .pipe(first())
            .toPromise();
    }

    private checkDataSourceKeyProperty(appName: string, store: any): void {
        if (_.isEmpty(store.key()))
            console.warn(appName, "is not setting key property for datasource. Potential performance issue.");
    }

    getAppFilter(appName: string): Promise<any> {
        return this.storeService
            .getCurrentListFilters(appName)
            .pipe(first())
            .toPromise();
    }

    setDataDisplayBehaviorOnEventOnly(holderAppName: string, appNames: string[]): void {
        return this.dataService.setDataDisplayBehaviorOnEventOnly(holderAppName, appNames);
    }

    isDisplayBehaviorOnEventOnlyEnabled(appName: string): boolean {
        return this.dataService.isDisplayBehaviorOnEventOnlyEnabled(appName);
    }

    private disableDisplayBehaviorOnEventOnly(containerName: string, appName: string): void {
        return this.dataService.disableDisplayBehaviorOnEventOnly(containerName, appName);
    }

    createCustomSortProperties(listData, customSortMap) {
        if (!Array.isArray(listData) || _.isNil(customSortMap) || !_.isPlainObject(customSortMap)) return;
        const gapMap = {};
        let uniqueMap = {};
        const uniqueMapValues = {};
        let auxArray;
        let item;
        for (let i = 0; i < listData.length; i++) {
            for (const field in customSortMap) {
                if (!_.isNil(listData[i][field])) {
                    if (!uniqueMap[field]) uniqueMap[field] = {};
                    uniqueMap[field][listData[i][field]] = 0;
                }
                gapMap[field] = Object.keys(customSortMap[field]).length;
            }
        }
        for (const field in uniqueMap) {
            auxArray = Object.keys(uniqueMap[field]);
            auxArray.sort();
            uniqueMapValues[field] = {};
            auxArray.forEach(function (it, i) {
                uniqueMapValues[field][it] = i + gapMap[field];
                if (!_.isNil(customSortMap[field][it])) {
                    uniqueMapValues[field][it] = customSortMap[field][it];
                }
            });
        }
        uniqueMap = null;
        for (let i = 0; i < listData.length; i++) {
            for (const field in uniqueMapValues) {
                item = listData[i];
                if (!_.isNil(item[field]) && !_.isNil(uniqueMapValues[field][item[field]])) {
                    item["_customSortFieldName" + field] = uniqueMapValues[field][item[field]];
                }
            }
        }
    }

    createDynamicColumns(gridApplet, listData, dynamicColumns) {
        if (!Array.isArray(dynamicColumns)) {
            return Promise.resolve(listData);
        }

        //to determine which columns have the superscript values
        let clientSideFieldsOnly = [];
        if (dynamicColumns && dynamicColumns.length > 0) {

            if (gridApplet && gridApplet.config && gridApplet.config.clientSideFields) {
                clientSideFieldsOnly = gridApplet.config.clientSideFields;
            }

            _.forEach(dynamicColumns, (itm, ind) => {

                // eslint-disable-next-line no-prototype-builtins
                if (!gridApplet.hasOwnProperty("superscriptFields")) {
                    gridApplet.superscriptFields = [];
                }

                if (itm.SourceValue.contains("<sup>")) {
                    if (_.indexOf(gridApplet.superscriptFields, itm.TargetField) === -1) {
                        gridApplet.superscriptFields.push(itm.TargetField);
                    }
                }
            });
        }

        const allDynamicReplacements = [];
        try {
            const getReplacementOptions = (appName, clientSideFieldsOnly, listData, column, currentItem) => {
                let options: any = {
                    appName,
                    listData,
                    column: currentItem,
                };
                if (!_.isEmpty(clientSideFieldsOnly)
                    && _.includes(clientSideFieldsOnly, column.TargetField)) {
                    options = {};
                }
                return options;
            };
            const getReplaceFn = () => {
                return (currentItem, column) => {
                    const options = getReplacementOptions(gridApplet.name, clientSideFieldsOnly, listData, column, currentItem);
                    const promise = this.dynamicReplacementService.getDynamicValue(column.SourceValue, options);
                    allDynamicReplacements.push(promise);
                    promise.then((resultValue) => currentItem[column.TargetField] = resultValue);
                };
            };

            const replaceFn = getReplaceFn();
            listData.forEach((currentItem) => dynamicColumns.forEach((column) => replaceFn(currentItem, column)));

        } catch (e) {
            console.log(e);
        }

        return Promise.all(allDynamicReplacements).then(() => listData);
    }

    convertDataSetListToObjectList(listData: any[]): Record<string, unknown>[] {
        const dataObjectArr = [];
        let singleObject; let i; let j;
        for (i = 1; i < listData.length; i++) {
            singleObject = listData[i];
            dataObjectArr[i - 1] = {};
            for (j = 0; j < singleObject.length; j++) {
                dataObjectArr[i - 1][listData[0][j]] = singleObject[j];
            }
        }
        return dataObjectArr;
    }

    sortAndOrGroupListData(listData, groupBy, sortColumns) {
        const newDataSource: DxDataSource = { store: listData };
        if (groupBy) {
            newDataSource.group = groupBy;
        }
        if (sortColumns) {
            const sorts = [];
            const columns = sortColumns.length > 0 ? sortColumns.split(',') : [];
            for (let i = 0; i < columns.length; i++) {
                const field = columns[i];
                const sort = { selector: '', desc: false };
                const sortAndDir = field.split('=');
                sort.selector = sortAndDir[0];
                sort.desc = sortAndDir.length > 0 && sortAndDir[1].toLowerCase().indexOf('desc') >= 0;
                sorts.push(sort);
            }
            if (sorts.length > 0) {
                newDataSource.sort = sorts;
            }
        }
        const groupedDropdownSource = new DevExpress.data.DataSource(newDataSource);
        return groupedDropdownSource.load();
    }

    createGridDataSource(
        appName: string,
        ecdRequestOriginal: EcdRequest,
        keyExpr: string,
        setActiveDataRequest: (request: EcdRequest) => { promise: dxDeferred<unknown> },
        getItemByKey: (keyExpr: string, keyValue: string | number) => Record<string, unknown>,
        listApp: ListApplication
    ): any {
        let ecdRequests = [];
        let deferreds = [];

        const makeEcdRequest = _.debounce((deferredPromises, requests) => {
            let ecdRequest: EcdRequest;

            if (!requests) return;

            if (requests.length === 1 && requests[0].ServerCallType !== "MUP-M") {
                // If only one request came through then go ahead and perform a
                // TUP-Update or TUP-M based on the server call type
                ecdRequest = requests[0];
            } else {
                // If multiple requests came through then perform a MUP-Update
                // and shove the data into the Data.$ComponentDataToMUP field in
                // the proper format.
                const data = [];
                for (let i = 0; i < requests.length; i++) {
                    data.push(requests[i].Context[requests[i].ApplicationName]);
                }

                ecdRequest = {
                    ApplicationName: requests[0].ApplicationName,
                    ApplicationVersion: requests[0].ApplicationVersion,
                    ContainerApplication: requests[0].ContainerApplication,
                    Context: null,
                    Data: {
                        $ComponentDataToMUP: IX_ConvertSelectedRowsToDSFormat(data)
                    },
                    ServerCallType: requests[0].ServerCallType === "MUP-M" ? "MUP-M" : "MUP-U"
                };
            }

            this.appsFacade.updateServerListData(ecdRequest, deferreds);
            ecdRequests = [];
            deferreds = [];

            return deferredPromises[0].promise();
        }, 10);

        const bufferedUpdate = function (ecdRequest) {
            const deferred = $.Deferred();
            ecdRequests.push(ecdRequest);
            deferreds.push(deferred);
            makeEcdRequest(deferreds, ecdRequests);
            return deferred.promise();
        };

        const store = this.createCustomStoreForGrid(keyExpr, ecdRequestOriginal, setActiveDataRequest, bufferedUpdate, getItemByKey, listApp);

        if (_.isEmpty(store.key()))
            console.warn(appName, "is not setting key property for datasource. Potential performance issue.");
        return new DevExpress.data.DataSource({ store });
    }

    createCustomStoreForGrid(
        keyExpr: string,
        ecdRequest: EcdRequest,
        setActiveDataRequest: (request: EcdRequest) => { promise: dxDeferred<unknown> },
        bufferedUpdate: (request: EcdRequest) => Promise<unknown>,
        getItemByKey: (keyExpr: string, keyValue: string | number) => Record<string, unknown>,
        listApp: ListApplication,
    ): CustomStore {
        return new DevExpress.data.CustomStore({
            key: keyExpr,
            load: (loadOptions: LoadOptions): dxPromise.dxPromise<unknown> | Promise<unknown> => {
                const stepApplet = this.getRuntimeInfo(ecdRequest.ApplicationName, ecdRequest.ContainerApplication);
                const loadOnEventOnly = this.isDisplayBehaviorOnEventOnlyEnabled(stepApplet.name);

                // Do not initiate a load or set deferred promise if this won't be doing anything
                if (loadOnEventOnly) {
                    return Promise.resolve();
                }

                const activeDataRequest = setActiveDataRequest.call(listApp, ecdRequest);
                this.appsFacade.loadListData(ecdRequest, loadOptions);
                return activeDataRequest.promise.promise();
            },
            update: (keyValue: string, newValues: Record<string, unknown>): Promise<unknown> => {
                const updated = { ...getItemByKey.call(listApp, keyExpr, keyValue), ...newValues };
                const data = { Data: {} };
                const updateEcdRequest = { ...data, ...ecdRequest }
                const ecdContext = {};

                ecdContext[updateEcdRequest.ApplicationName] = updated;
                this.apiClientService.setEcdRequestContext(updateEcdRequest, ecdContext);
                updateEcdRequest.ServerCallType = 'TUP-U';

                return bufferedUpdate(updateEcdRequest);
            }
        });
    }

    private completeLoadRequest(deferred, listData, appName?) {
        const result = { data: listData, totalCount: listData.length };
        IX_PerfStart(appName, 'grid-render');
        deferred.resolve(result).then(() => IX_PerfEnd(appName, 'grid-render'));
    }

    private _completeLoadRequest(defrdPromise, listData, appName?) {
        const totalCountObj = { totalCount: listData.length };
        if (appName) {
            IX_PerfStart(appName, 'grid-render');
        }
        defrdPromise.resolve(listData, totalCountObj).then(() => {
            if (appName) IX_PerfEnd(appName, 'grid-render');
        });
    }

    createLookupDataSource(ecdRequestOriginal, keyField, childIdField, parentIdField) {

        const self = this;

        const store = new DevExpress.data.CustomStore({
            key: keyField,
            loadMode: 'raw',
            load: function (loadOptions) {

                const d = $.Deferred();

                self.getAppState(ecdRequestOriginal.ApplicationName)
                    .then(requestData => {

                        const ecdRequest: EcdRequest = { ...ecdRequestOriginal };
                        ecdRequest.Data = requestData;
                        const ecdContext = {};
                        self.apiClientService.setEcdRequestContext(ecdRequest, ecdContext);
                        delete ecdRequest.DynamicColumnItems;

                        self.ecdService.request(ecdRequest)
                            .then((data) => {

                                if (data === null)
                                    data = {};
                                if (data.ListData === null)
                                    data.ListData = [];

                                let listData = self.convertDataSetListToObjectList(data.ListData);
                                if (childIdField && parentIdField) {
                                    listData = self.convertRawDataFromImplicitHierarchyToAdjacencyList(listData, childIdField, parentIdField);
                                }
                                self._completeLoadRequest(d, listData);
                            })
                            .catch(function (jqXHR, type, statusMessage) {
                                // Used by "{Error:} Dynamic Replacement Value. "
                                window.lastError = jqXHR.responseJSON;
                                IX_Log('component', jqXHR);
                                d.resolve([], { totalCount: 0 });
                            })
                    });

                return d.promise();
            },
            byKey: function (key) {
                const keyField = this.key();
                let result = _.filter(this.getRawData(), (item) => item[keyField] === key);
                result = result.length ? _.first(result) : {};
                return result;
            }
        });
        this.checkDataSourceKeyProperty(ecdRequestOriginal.ApplicationName, store);
        return store;
    }

    private _shouldGroupBy(groupBy) {
        return !_.isEmpty(groupBy);
    }

    private _shouldSort(sortColumns) {
        return !_.isEmpty(sortColumns);
    }

    createDataSource(ecdRequestOriginal, clientSideFields, returnAsRawList, paginate, loadMode, groupBy, skipFirstTime, sortBy, sortColumns, applet) {
        const self = this;
        let firstTime = true;
        const dsKey = ecdRequestOriginal.dxDsKey,
            store = new DevExpress.data.CustomStore({
                loadMode: loadMode || 'processed',
                key: !_.isNil(dsKey) ? dsKey : void (0),
                load: function (loadOptions) {

                    const d = $.Deferred();
                    if (firstTime && skipFirstTime) {
                        firstTime = false;
                        return d.resolve(true);
                    }

                    setTimeout(() => {
                        const appStatePromise = self.getAppState(ecdRequestOriginal.ApplicationName);
                        const listFilterPromise = self.getAppFilter(ecdRequestOriginal.ApplicationName);

                        Promise.all([appStatePromise, listFilterPromise])
                            .then(([requestData, filter]) => {

                                const ecdRequest: EcdRequest = { ...ecdRequestOriginal };
                                const ecdContext = {};

                                loadOptions.filter = filter;
                                if (Array.isArray(loadOptions.filter)) {
                                    const arrayLength = loadOptions.filter.length;
                                    for (let i = 0; i < arrayLength; i++) {
                                        const filterElement = loadOptions.filter[i];
                                        if (!Array.isArray(filterElement)) continue;
                                        const fValue = UtilityFunctions.getValue(filterElement[filterElement.length - 1]);
                                        if (_.isNil(fValue)) continue;
                                        requestData[filterElement[0]] = fValue;
                                    }
                                }

                                if (clientSideFields) {
                                    for (let i = 0; i < clientSideFields.length; i++) {
                                        const fieldToRemove = clientSideFields[i];
                                        if (_.isNil(requestData[fieldToRemove])) continue;
                                        delete requestData[fieldToRemove];
                                    }
                                }

                                ecdRequest.Data = requestData;
                                self.apiClientService.setEcdRequestContext(ecdRequest, ecdContext);
                                delete ecdRequest.DynamicColumnItems;

                                self.ecdService.request(ecdRequest)
                                    .then((data) => {
                                        if (data === null) data = {};
                                        if (data.ListData === null) data.ListData = [];
                                        if (returnAsRawList) {
                                            d.resolve(data.ListData);
                                        }
                                        else {
                                            const listData = self.convertDataSetListToObjectList(data.ListData);
                                            self.createDynamicColumns(applet, listData, ecdRequestOriginal.DynamicColumnItems)
                                                .then((listDataWithDynamicColumns) => {
                                                    if (self._shouldGroupBy(groupBy) || self._shouldSort(sortColumns)) {
                                                        self.sortAndOrGroupListData(listDataWithDynamicColumns, groupBy, sortColumns)
                                                            .done((groupedListData) => {
                                                                self._completeLoadRequest(d, groupedListData, ecdRequestOriginal.ApplicationName);
                                                            });
                                                    } else {
                                                        self._completeLoadRequest(d, listDataWithDynamicColumns, ecdRequestOriginal.ApplicationName);
                                                    }
                                                });
                                        }
                                    })
                                    .catch(function (jqXHR) {
                                        // Used by "{Error:} Dynamic Replacement Value. "
                                        window.lastError = jqXHR.responseJSON;
                                        IX_Log('component', jqXHR);
                                        d.resolve([], { totalCount: 0 });
                                    });
                            });

                    }, 0);
                    return d.promise();
                },
                byKey: function (keyValue) {
                    const keyField = this.key();
                    let result = _.filter(this.getRawData(), (item) => item[keyField] === keyValue);
                    result = result.length ? _.first(result) : {};
                    return result;
                }
            });
        this.checkDataSourceKeyProperty(ecdRequestOriginal.ApplicationName, store);
        delete ecdRequestOriginal.dxDsKey;
        const _store = { store, paginate };
        const newDataSource = new DevExpress.data.DataSource(_store);
        if (sortBy) {
            newDataSource.sort({ getter: sortBy.sortColumn, desc: sortBy.descending });
        }

        newDataSource.ic = {
            forceServerSideFiltering: (ecdRequestOriginal.forceServerSideFiltering === true)
        };

        delete ecdRequestOriginal.forceServerSideFiltering;

        return newDataSource;
    }

    createCustomDataSourceForMenu() {
        const store = new DevExpress.data.CustomStore({
            load: () => this.apiClientService.makeMenuRequest()
        });
        this.checkDataSourceKeyProperty('Menu', store);
        return new DevExpress.data.DataSource({ store });
    }

    createCustomDataSource(ecdRequestOriginal, map, doNotLoad, onLoaded?, keyExpr?, isMupM?, fixOnEventOnly?) {

        const self = this;
        let ecdRequests = [];
        const deferreds = [];

        const makeEcdRequest = _.debounce((deferreds, requests) => {

            if (!requests) return;

            let theEcdRequest;
            // If only one request came through then go ahead and perform a
            // TUP-Update or TUP-M based on the server call type
            if (requests.length === 1 && requests[0].ServerCallType !== "MUP-M") {
                theEcdRequest = requests[0];
            }
            // If multiple requests came through then perform a MUP-Update
            // and shove the data into the Data.$ComponentDataToMUP field in
            // the proper format.
            else {
                theEcdRequest = {
                    ApplicationName: requests[0].ApplicationName,
                    ApplicationVersion: requests[0].ApplicationVersion,
                    ContainerApplication: requests[0].ContainerApplication,
                    Context: null,
                    Data: {
                        $ComponentDataToMUP: null
                    },
                    ServerCallType: requests[0].ServerCallType === "MUP-M" ? "MUP-M" : "MUP-U"
                };

                const data = [];

                for (let i = 0; i < requests.length; i++) {
                    data.push(requests[i].Context[requests[i].ApplicationName]);
                }

                theEcdRequest.Data.$ComponentDataToMUP = IX_ConvertSelectedRowsToDSFormat(data);
            }

            self.ecdService.request(theEcdRequest)
                .then((response) => {
                    _.forEach(deferreds, (deferred) => deferred.resolve(true));
                    ecdRequests = [];
                    deferreds = [];
                })
                .catch(function (jqXHR, type, statusMessage) {
                    ecdRequests = [];
                    //Used by "{Error:} Dynamic Replacement Value. "
                    window.lastError = jqXHR.responseJSON;
                    IX_Log('component', jqXHR.responseJSON);
                    _.forEach(deferreds, function (deferred) {
                        deferred.reject(jqXHR.responseJSON.MainMessage);
                    });
                });

            return deferreds[0].promise();
        }, 10);

        const bufferedUpdate = function (deferred, ecdRequest) {
            ecdRequests.push(ecdRequest);
            deferreds.push(deferred);
            makeEcdRequest(deferreds, ecdRequests);
            return deferred.promise();
        };

        const store = new DevExpress.data.CustomStore({
            load: function (loadOptions) {

                const deferred = dxPromise.Deferred();
                const requestData = {};
                const ecdRequest: EcdRequest = { ...ecdRequestOriginal };

                self.getAppFilter(ecdRequestOriginal.ApplicationName)
                    .then(filter => {
                        loadOptions.filter = filter.filters;

                        if (Array.isArray(loadOptions.filter)) {
                            const arrayLength = loadOptions.filter.length;
                            let filterElement; let fValue;
                            for (let i = 0; i < arrayLength; i++) {
                                filterElement = loadOptions.filter[i];
                                if (Array.isArray(filterElement)) {
                                    fValue = UtilityFunctions.getValue(filterElement[filterElement.length - 1]);
                                    if (fValue !== void (0) && fValue !== null) {
                                        requestData[filterElement[0]] = fValue;
                                    }
                                }
                            }
                        }
                        const runtimeInfo = self.getRuntimeInfo(ecdRequest.ApplicationName, ecdRequest.ContainerApplication);
                        const loadOnEventOnly = self.isDisplayBehaviorOnEventOnlyEnabled(runtimeInfo.name);

                        // Scoping fix to screen prop by preserving bug behavior when absent
                        //   (setting doNotLoad to true, which has enclosing scope, prevents
                        //   load() from *ever* firing the request)
                        if (!fixOnEventOnly) {
                            doNotLoad = doNotLoad || loadOnEventOnly;
                        }

                        if (_.isNil(loadOptions.forceLoad) || !loadOptions.forceLoad) {
                            if (doNotLoad || loadOnEventOnly) {
                                loadOptions.forceLoad = true;
                                self.disableDisplayBehaviorOnEventOnly(runtimeInfo.containerName, runtimeInfo.name);
                                if (_.isEmpty(requestData)) {
                                    deferred.resolve({ data: [], totalCount: 0 });
                                    return deferred.promise();
                                }
                            }
                        }

                        const ecdContext = {};
                        ecdRequest.Data = requestData;
                        self.apiClientService.setEcdRequestContext(ecdRequest, ecdContext);
                        delete ecdRequest.DynamicColumnItems;

                        IX_PerfStart(ecdRequestOriginal.ApplicationName, 'dataSource-request');
                        self.ecdService.request(ecdRequest)
                            .then((data) => {
                                IX_PerfEnd(ecdRequestOriginal.ApplicationName, 'dataSource-request');
                                IX_PerfStart(ecdRequestOriginal.ApplicationName, 'convert-data-onloaded');
                                const listData = self.convertDataSetListToObjectList(data.ListData);
                                if (onLoaded) {
                                    onLoaded(listData);
                                }
                                IX_PerfEnd(ecdRequestOriginal.ApplicationName, 'convert-data-onloaded');
                                IX_PerfStart(ecdRequestOriginal.ApplicationName, 'create-columns');
                                self.createDynamicColumns(ecdRequestOriginal.applet, listData, ecdRequestOriginal.DynamicColumnItems)
                                    .then((listDataWithDynamicColumns) => {
                                        IX_PerfEnd(ecdRequestOriginal.ApplicationName, 'create-columns');
                                        self.createCustomSortProperties(listDataWithDynamicColumns, map || {});
                                        self.completeLoadRequest(deferred, listDataWithDynamicColumns, ecdRequestOriginal.ApplicationName);
                                    });
                            })
                            .catch(function (jqXHR) {
                                if (onLoaded) {
                                    onLoaded([]);
                                }
                                // Used by "{Error:} Dynamic Replacement Value. "
                                window.lastError = jqXHR.responseJSON;
                                IX_Log('component', jqXHR.responseJSON);

                                const scope = self.domComponentRetrievalService.getAppComponent(ecdRequestOriginal.ApplicationName);
                                if (_.get(scope, "gridProperties.ic.onErrorButtons", []).length > 0)
                                    self.utilService.runConditionalErrorButton(scope, jqXHR.responseJSON);

                                if (self.helpersService.getThemeProperty('DisplayLSTErrorsInGrid', IX_Theme)) {
                                    deferred.reject(jqXHR.responseJSON.MainMessage);
                                } else {
                                    deferred.resolve({ data: [], totalCount: 0 });
                                }
                            });

                    });

                return deferred.promise();
            },
            update: function (key, values) {

                const updated = { ...key, ...values };

                const ecdRequest: EcdRequest = { ...ecdRequestOriginal, Data: {} };
                const d = $.Deferred();
                const ecdContext = {};


                ecdContext[ecdRequest.ApplicationName] = updated;
                self.apiClientService.setEcdRequestContext(ecdRequest, ecdContext);

                //to check if the server call type is MUP-M
                if (this.options.serverCallType && this.options.serverCallType === "MUP-M") {
                    ecdRequest.ServerCallType = 'MUP-M';
                } else {
                    ecdRequest.ServerCallType = 'TUP-U';
                }

                return bufferedUpdate(d, ecdRequest);
            }
        });
        this.checkDataSourceKeyProperty(ecdRequestOriginal.ApplicationName, store);
        return new DevExpress.data.DataSource({ store, filter: null });
    }

    getRuntimeInfo(applicationName: string, containerApplication: string): Applet {
        let stepApplet = null;
        if (this.currentStepName)
            stepApplet = this.workflowApplicationTreeService.getStepApplet(this.currentStepName, applicationName);
        if (_.isNil(stepApplet)) {
            stepApplet = {
                name: applicationName,
                containerName: containerApplication
            };
        }
        return stepApplet;
    }

    private _getDataGridColumnType(scope, columnName) {
        if (_.isEmpty(scope.columnMap))
            return null;

        const column = scope.columnMap[columnName];
        if (_.isEmpty(column))
            return null;

        return column.dataType;
    }

    createDataSourceForODataProvider(ecdRequest, scope?, doNotLoad?, isExportCurrentPageOnly?, fixOnEventOnly?) {

        const self = this;

        const store = new DevExpress.data.CustomStore({
            load: function (loadOptions) {

                const d = $.Deferred(); // for OData jquery deferred only
                const dsOptions: DataSourceOptions = {};
                const requestData = {};

                self.getAppFilter(ecdRequest.ApplicationName)
                    .then(applyFilter => {

                        if (Array.isArray(applyFilter)) {
                            const arrayLength = applyFilter.length;
                            for (let i = 0; i < arrayLength; i++) {
                                const filterElement = applyFilter[i];
                                if (!Array.isArray(filterElement)) continue;
                                const fValue = UtilityFunctions.getValue(filterElement[filterElement.length - 1]);
                                if (_.isNil(fValue)) continue;
                                requestData[filterElement[0]] = fValue;
                            }
                        }

                        const runtimeInfo = self.getRuntimeInfo(ecdRequest.ApplicationName, ecdRequest.ContainerApplication);
                        const loadOnEventOnly = self.isDisplayBehaviorOnEventOnlyEnabled(runtimeInfo.name);

                        // Scoping fix to screen prop by preserving bug behavior when absent
                        //   (setting doNotLoad to true, which has enclosing scope, prevents
                        //   load() from *ever* firing the request)
                        if (!fixOnEventOnly) {
                            doNotLoad = doNotLoad || loadOnEventOnly;
                        }

                        if (_.isNil(loadOptions.forceLoad) || !loadOptions.forceLoad) {
                            if (doNotLoad || loadOnEventOnly) {
                                loadOptions.forceLoad = true;
                                self.disableDisplayBehaviorOnEventOnly(runtimeInfo.containerName, runtimeInfo.name);
                                if (_.isEmpty(requestData)) {
                                    d.resolve([], { totalCount: 0 });
                                    return d.promise();
                                }
                            }
                        }

                        const ecdContext = {};
                        ecdRequest.Data = requestData;
                        self.apiClientService.setEcdRequestContext(ecdRequest, ecdContext);

                        if (loadOptions.searchExpr && loadOptions.searchOperation && loadOptions.searchValue) {
                            let searchText = loadOptions.searchValue;
                            if (_.isString(searchText) && ecdRequest.ConvertUserInputToUppercase) {
                                searchText = searchText.toUpperCase();
                            }
                            const newFilter = [loadOptions.searchExpr, loadOptions.searchOperation, searchText];
                            if (loadOptions.filter)
                                loadOptions.filter.push("and");
                            else
                                loadOptions.filter = [];
                            loadOptions.filter.push(newFilter);
                        }

                        const groupFields = [];
                        if (loadOptions.group) {
                            const sortFieldsByLevel = [];
                            for (const g in loadOptions.group) {
                                if (!_.isNil(loadOptions.group[g].selector)) {
                                    groupFields.push(loadOptions.group[g].selector);
                                    sortFieldsByLevel.push(loadOptions.group[g].desc);
                                }
                            }
                            dsOptions.$apply = "groupby((" + groupFields.join(",") + ")";
                            dsOptions.$sortby = sortFieldsByLevel.join(",");
                        }

                        const groupSummaries = [];
                        if (loadOptions.groupSummary && loadOptions.groupSummary.length > 0 && dsOptions.$apply) {
                            const summaryODataTypeMapper = {
                                "sum": "sum",
                                "min": "min",
                                "max": "max",
                                "avg": "average",
                                "count": "countdistinct"
                            };

                            dsOptions.$apply += ",";
                            for (let i = 0; i < loadOptions.groupSummary.length; i++) {
                                const expObj = loadOptions.groupSummary[i];
                                if (expObj.summaryType === "custom") continue;
                                const aggregateMethod = summaryODataTypeMapper[expObj.summaryType];
                                groupSummaries.push(expObj.selector + " with " + aggregateMethod + " as " + expObj.selector + "_" + expObj.summaryType);
                            }
                            dsOptions.$apply += "aggregate(" + groupSummaries.join(",") + ")";
                        }

                        if (groupFields.length > 0)
                            dsOptions.$apply += ")";

                        if (loadOptions.sort) {
                            dsOptions.$orderby = self.compileSortCriteria(loadOptions.sort);
                            const splitFields = dsOptions.$orderby.split(","); // Split multiple fields
                            const arr = [];

                            if (groupFields.length > 0) {
                                delete dsOptions.$orderby;
                            } else {
                                $.each(splitFields, function (idx) {
                                    let tmp2;
                                    const tmp = splitFields[idx].split(" "); // Separate fieldName and sortOrder
                                    const dataType = self._getDataGridColumnType(scope, tmp[0]);

                                    if (dataType !== "number" && !dataType.match(/^date(time)?$/))
                                        tmp2 = "tolower(" + tmp[0] + ") ";
                                    else
                                        tmp2 = tmp[0] + " ";

                                    if (!_.isNil(tmp[1]))
                                        tmp2 += tmp[1];

                                    arr.push(tmp2);
                                });
                                dsOptions.$orderby = arr.join(',');
                            }
                        }

                        if (loadOptions.filter) {
                            let columnMap;
                            if (scope && scope.columnMap) {
                                columnMap = scope.columnMap;
                            }
                            dsOptions.$filter = self.compileFilterCriteria(loadOptions.filter, 4, columnMap, ecdRequest);
                        }

                        if (loadOptions.skip && loadOptions.skip > 0) {
                            dsOptions.$skip = loadOptions.skip;
                        }

                        if (loadOptions.take) {
                            dsOptions.$top = loadOptions.take;
                        }
                        // when take is not provided is on export to excel mode
                        else {
                            self.setupSkipAndTop(dsOptions, loadOptions.pageIndex, loadOptions.pageSize, isExportCurrentPageOnly);
                        }

                        self.apiClientService.makeOdcRequest(ecdRequest, dsOptions)
                            .then((result) => {
                                const extraObj = { totalCount: result.totalCount, summary: null };
                                if (result.summaries)
                                    extraObj.summary = result.summaries;

                                d.resolve(result.data, extraObj);

                                if (!_.isNil(result.ApplicationName)) {
                                    result.ApplicationName = result.ApplicationName.replace(/_/g, '.');
                                    const dataGridInstance = self.domComponentRetrievalService.getDxComponentInstance({}, result.ApplicationName);
                                    dataGridInstance?.updatePager(result.totalCount);
                                }
                            })
                            .catch(function () {
                                d.reject("Data Loading Error");
                            });
                    });

                return d.promise();
            },
            totalCount: function (options) {
                const d = $.Deferred();
                const dsOptions: DataSourceOptions = {};
                const ecdContext = {};
                ecdRequest.Data = {};
                self.apiClientService.setEcdRequestContext(ecdRequest, ecdContext);
                // Just getting count. Data is already cached?
                dsOptions.$top = 1;
                self.apiClientService.makeOdcRequest(ecdRequest, dsOptions)
                    .then(function (result) {
                        d.resolve(result.totalCount);
                    })
                    .catch(function () {
                        d.reject("Data Loading Error");
                    });
                return d.promise;
            }
        });
        this.checkDataSourceKeyProperty(ecdRequest.ApplicationName, store);
        return new DevExpress.data.DataSource({ store, filter: null });
    }

    createDataSourceForODataProviderForAngular(ecdRequest, scope?, doNotLoad?, isExportCurrentPageOnly?, fixOnEventOnly?) {

        const self = this;

        const store = {
            load: function (loadOptions) {

                const d = $.Deferred(); // for OData jquery deferred only

                self.getAppFilter(ecdRequest.ApplicationName)
                    .then(applyFilter => {

                        const dsOptions: DataSourceOptions = {};
                        const requestData = {};

                        if (Array.isArray(applyFilter)) {
                            const arrayLength = applyFilter.length;
                            for (let i = 0; i < arrayLength; i++) {
                                const filterElement = applyFilter[i];
                                if (!Array.isArray(filterElement)) continue;
                                const fValue = UtilityFunctions.getValue(filterElement[filterElement.length - 1]);
                                if (_.isNil(fValue)) continue;
                                requestData[filterElement[0]] = fValue;
                            }
                        }
                        const runtimeInfo = self.getRuntimeInfo(ecdRequest.ApplicationName, ecdRequest.ContainerApplication);
                        const loadOnEventOnly = self.isDisplayBehaviorOnEventOnlyEnabled(runtimeInfo.name);

                        // Scoping fix to screen prop by preserving bug behavior when absent
                        //   (setting doNotLoad to true, which has enclosing scope, prevents
                        //   load() from *ever* firing the request)
                        if (!fixOnEventOnly) {
                            doNotLoad = doNotLoad || loadOnEventOnly;
                        }

                        if (_.isNil(loadOptions.forceLoad) || !loadOptions.forceLoad) {
                            if (doNotLoad || loadOnEventOnly) {
                                loadOptions.forceLoad = true;
                                self.disableDisplayBehaviorOnEventOnly(runtimeInfo.containerName, runtimeInfo.name);
                                if (_.isEmpty(requestData)) {
                                    d.resolve([], { totalCount: 0 });
                                    return d.promise();
                                }
                            }
                        }

                        const ecdContext = {};
                        ecdRequest.Data = requestData;
                        self.apiClientService.setEcdRequestContext(ecdRequest, ecdContext);

                        if (loadOptions.searchExpr && loadOptions.searchOperation && loadOptions.searchValue) {
                            let searchText = loadOptions.searchValue;
                            if (typeof (searchText) === 'string' && ecdRequest.ConvertUserInputToUppercase) {
                                searchText = searchText.toUpperCase();
                            }
                            const newFilter = [loadOptions.searchExpr, loadOptions.searchOperation, searchText];
                            if (loadOptions.filter)
                                loadOptions.filter.push("and");
                            else
                                loadOptions.filter = [];
                            loadOptions.filter.push(newFilter);
                        }

                        const groupFields = [];
                        if (loadOptions.group) {
                            const sortFieldsByLevel = [];
                            for (const g in loadOptions.group) {
                                if (!_.isNil(loadOptions.group[g].selector)) {
                                    groupFields.push(loadOptions.group[g].selector);
                                    sortFieldsByLevel.push(loadOptions.group[g].desc);
                                }
                            }
                            dsOptions.$apply = "groupby((" + groupFields.join(",") + ")";
                            dsOptions.$sortby = sortFieldsByLevel.join(",");
                        }

                        const groupSummaries = [];
                        if (loadOptions.groupSummary && loadOptions.groupSummary.length > 0 && dsOptions.$apply) {
                            const summaryODataTypeMapper = {
                                "sum": "sum",
                                "min": "min",
                                "max": "max",
                                "avg": "average",
                                "count": "countdistinct"
                            };

                            dsOptions.$apply += ",";
                            for (let i = 0; i < loadOptions.groupSummary.length; i++) {
                                const expObj = loadOptions.groupSummary[i];
                                if (expObj.summaryType === "custom") continue;
                                const aggregateMethod = summaryODataTypeMapper[expObj.summaryType];
                                groupSummaries.push(expObj.selector + " with " + aggregateMethod + " as " + expObj.selector + "_" + expObj.summaryType);
                            }
                            dsOptions.$apply += "aggregate(" + groupSummaries.join(",") + ")";
                        }

                        if (groupFields.length > 0)
                            dsOptions.$apply += ")";


                        if (loadOptions.sort) {
                            dsOptions.$orderby = self.compileSortCriteria(loadOptions.sort);
                            const splitFields = dsOptions.$orderby.split(","); // Split multiple fields
                            const arr = [];

                            if (groupFields.length > 0) {
                                delete dsOptions.$orderby;
                            } else {
                                $.each(splitFields, function (idx) {
                                    let tmp2;
                                    const tmp = splitFields[idx].split(" "); // Separate fieldName and sortOrder
                                    const dataType = self._getDataGridColumnType(scope, tmp[0]);

                                    if (dataType !== "number" && !dataType.match(/^date(time)?$/))
                                        tmp2 = "tolower(" + tmp[0] + ") ";
                                    else
                                        tmp2 = tmp[0] + " ";

                                    if (!_.isNil(tmp[1]))
                                        tmp2 += tmp[1];

                                    arr.push(tmp2);
                                });
                                dsOptions.$orderby = arr.join(',');
                            }
                        }

                        if (loadOptions.filter) {
                            let columnMap;
                            if (scope && scope.columnMap) {
                                columnMap = scope.columnMap;
                            }
                            dsOptions.$filter = self.compileFilterCriteria(loadOptions.filter, 4, columnMap, ecdRequest);
                        }

                        if (loadOptions.skip && loadOptions.skip > 0) {
                            dsOptions.$skip = loadOptions.skip;
                        }

                        if (loadOptions.take) {
                            dsOptions.$top = loadOptions.take;
                        }
                        // when take is not provided is on export to excel mode
                        else {
                            // TODO pass pageIndex and pageSize in loadOptions.
                            self.setupSkipAndTop(dsOptions, loadOptions.pageIndex, loadOptions.pageSize, isExportCurrentPageOnly);
                        }

                        self.apiClientService.makeOdcRequest(ecdRequest, dsOptions)
                            .then(function (result) {
                                const extraObj = { totalCount: result.totalCount, summary: null };
                                if (result.summaries)
                                    extraObj.summary = result.summaries;

                                d.resolve(result.data, extraObj);

                                if (!_.isNil(result.ApplicationName)) {
                                    result.ApplicationName = result.ApplicationName.replace(/_/g, '.');
                                    const dataGridInstance = self.domComponentRetrievalService.getDxComponentInstance({}, result.ApplicationName);
                                    dataGridInstance?.updatePager(result.totalCount);
                                }
                            })
                            .catch(function () {
                                d.reject("Data Loading Error");
                            });
                    });

                return d.promise();
            },
            totalCount: function (options) {
                const d = $.Deferred();
                const dsOptions: DataSourceOptions = {};
                const ecdContext = {};
                ecdRequest.Data = {};
                self.apiClientService.setEcdRequestContext(ecdRequest, ecdContext);
                // Just getting count. Data is already cached?
                dsOptions.$top = 1;
                self.apiClientService.makeOdcRequest(ecdRequest, dsOptions)
                    .then(function (result) {
                        d.resolve(result.totalCount);
                    })
                    .catch(function () {
                        d.reject("Data Loading Error");
                    });
                return d.promise;
            }
        };

        return store;
    }

    /**
     * Convert from implied hierarchy by adding a row for each parent with null for parentField
     * (one level deep)
     * @param {Array} rawData
     * @param {string} identityFieldName
     * @param {string} parentFieldName
     */
    convertRawDataFromImplicitHierarchyToAdjacencyList(rawData, identityFieldName, parentFieldName) {
        const categories = {};
        const distinct = [];

        rawData.forEach(function (row) {
            if (!categories[row[parentFieldName]]) {
                distinct.push(row[parentFieldName]);
            }
            categories[row[parentFieldName]] = true;
        });

        const output = rawData;

        distinct.forEach(function (category) {
            const newRow = {};
            newRow[parentFieldName] = null;
            newRow[identityFieldName] = category;
            output.push(newRow);
        });

        return output;
    }

    setupSkipAndTop(options: DataSourceOptions, pageIndex: number, pageSize: number, isExportCurrentPageOnly: boolean) {
        if (isExportCurrentPageOnly) {
            options.$skip = pageIndex * pageSize;
            options.$top = pageSize;
        }
        else {
            options.$skip = 0;
            options.$top = 5000;
        }
    }

    private compileSortCriteria(sort) {

        sort = DevExpress.data.utils.normalizeSortingInfo(sort || []);
        sort = DevExpress.data.utils.arrangeSortingInfo([], sort);
        const q = [];
        $.each(sort, function (index) {
            q.push(this.selector + (this.desc ? " desc" : ""));
        });

        return q.join(",");
    }

    private compileFilterCriteria(criteria, protocolVersion, formats, ecdRequest) {

        if (_.isArray(criteria[0])) {
            $.each(criteria, (index, indCriterion) => {
                const newCriterion = this.checkIfDateAndCreateNewCriterion(indCriterion, formats, ecdRequest);

                if (newCriterion.length > 0) {
                    if (criteria.indexOf("or") > 0) {
                        criteria.splice(index, 0, "or");
                    } else {
                        criteria.splice(index, 0, "and");
                    }
                    criteria.splice(index, 0, newCriterion);
                } else if (indCriterion[1] && this.formattersV4[indCriterion[1].toLowerCase()]) {
                    const searchExpr = indCriterion[0]; // A field name, or an array of field names
                    const searchMode = indCriterion[1]; // DevExtreme enum, accepts 'Contains' and 'StartsWith'
                    const searchString = indCriterion[2]; // String entered by user

                    if ($.isArray(searchExpr)) {
                        const multipleCriteria = [];
                        $.each(searchExpr, function (_, fieldName) {
                            if (multipleCriteria.length > 0) {
                                multipleCriteria.push('or');
                            }

                            multipleCriteria.push([fieldName, searchMode, searchString]);
                        });
                        criteria.splice(index, 1);
                        multipleCriteria.reverse();
                        _.map(multipleCriteria, function (criterion) {
                            criteria.splice(index, 0, criterion);
                        });
                    }
                }
            });
            return this.compileGroup(criteria, protocolVersion, formats, ecdRequest);
        }
        else {
            const newCriterion = this.checkIfDateAndCreateNewCriterion(criteria, formats, ecdRequest);
            if (newCriterion.length > 1) {
                const newCriteria = [];
                newCriteria.push(criteria);
                newCriteria.push(newCriterion);
                return this.compileGroup(newCriteria, protocolVersion, formats, ecdRequest);
            }
            return this.compileBinary(criteria, protocolVersion);
        }

    }

    private createBinaryOperationFormatter(op) {
        return function (prop, val) {
            if (val === "''") val = 'null';
            return prop + " " + op + " " + val;
        };
    }

    private createStringFuncFormatter(op, reverse?) {
        return function (prop, val) {
            const bag = [op, "("];
            if (reverse)
                bag.push(val, ",", prop);
            else
                bag.push(prop, ",", val);
            bag.push(")");
            return bag.join("");
        };
    }

    checkIfDateAndCreateNewCriterion(indCriterions, formats, ecdRequest) {

        if (formats) {
            //to determine if the field is of type 'Date' or related
            const column = formats[indCriterions[0]];
            const isDateField = (column && (column.dataType === "date" || column.dataType === "datetime"));
            if (isDateField) {

                //to convert the value for the "dateField" in the list of filter fields from 'string' to 'date' format
                //also to check the operator value and convert it to adjust the date query to compoensate for the timeStamp value difference

                indCriterions[2] = Sugar.Date.create(indCriterions[2]);

                if (indCriterions[1] === "=") {

                    //to create a new condition for the 'less than next day' phrase in case of '=' day filtering
                    const nextDayObj = JSON.parse(JSON.stringify(indCriterions));
                    const nextDay = new Sugar.Date(nextDayObj[2]).addDays(1).raw;

                    //to change the 'equals' operator to 'equal or greater'
                    indCriterions[1] = ">=";
                    const nextDayCriterion = [indCriterions[0], "<", nextDay];

                    return nextDayCriterion;
                }
            }
        }
        return [];
    }

    compileBinary(criteria, protocolVersion) {
        criteria = DevExpress.data.utils.normalizeBinaryCriterion(criteria);

        const op = criteria[1],
            formatters = protocolVersion === 4 ? this.formattersV4 : this.formattersV2,
            formatter = formatters[op.toLowerCase()];
        if (!formatter)
            throw Error("E4003 " + op);

        return formatter(DevExpress.ic.data.odata.utils.serializePropName(criteria[0]), DevExpress.ic.data.odata.utils.serializeValue(criteria[2], protocolVersion));
    }

    compileGroup(criteria, protocolVersion, formats, ecdRequest) {
        const bag = [];
        let groupOperator;
        let nextGroupOperator;
        $.each(criteria, (index, criterion) => {
            if ($.isArray(criterion)) {

                if (bag.length > 1 && groupOperator !== nextGroupOperator)
                    throw new Error("E4019- devextreme error");

                bag.push("(" + this.compileFilterCriteria(criterion, protocolVersion, formats, ecdRequest) + ")");
                groupOperator = nextGroupOperator;
                nextGroupOperator = "and";
            }
            else
                nextGroupOperator = DevExpress.data.utils.isConjunctiveOperator(this) ? "and" : "or";
        });
        return bag.join(" " + groupOperator + " ");
    }

}
