import { Directive, Inject, Input, OnChanges, OnInit, Renderer2, RendererFactory2, SecurityContext, SimpleChanges, ViewContainerRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { HelpersService } from '../services/helpers.service';
import { UtilService } from '../services/util.service';
import { DynamicReplacementService } from '../services/dynamic-replacement.service';

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IFrameNodeAttrs {
    id?: string;
    [x: string]: string | boolean;
}

@Directive({
    selector: "[replaceValue]"
})
export class ReplaceValueDirective implements OnInit, OnChanges {

    elementNode: HTMLElement;
    renderer: Renderer2;
    iFrameAttrs: string[];
    options: IFrameNodeAttrs;
    promise: Promise<string>;

    @Input("replaceValue")
    toReplace: Replacement;

    @Input("replaceValueUpdated")
    updated: string;

    constructor(rendererFactory: RendererFactory2,
        private domSanitizer: DomSanitizer,
        private viewContainerRef: ViewContainerRef,
        @Inject(DOCUMENT)
        private document: Document,
        private utilService: UtilService,
        private dynamicReplacementService: DynamicReplacementService,
        private helpersService: HelpersService) {
        this.renderer = rendererFactory.createRenderer(null, null);
        this.elementNode = this.viewContainerRef.element.nativeElement;
        this.iFrameAttrs = "id,src,frameborder,border,width,height,scrolling,style".split(',');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.toReplace)
            this.updateView();
    }

    ngOnInit(): void {
        this.updateView();
    }

    private updateView(): void {
        if (_.isEmpty(this.toReplace?.config)) {
            this.setContent(null);
        } else {
            this.options = {
                rid: this.toReplace.rid,
                originalName: this.toReplace.originalName,
                appName: this.toReplace.appName,
                force: true
            };
            const replacement = this.utilService.translateOrDefault(this.toReplace.translationId, this.toReplace.config);
            this.setGetReplaceValue(replacement);
        }
    }

    private setContent(content: string): void {
        const replacement = content ? content : "";
        let securityContext = SecurityContext.HTML;
        if (this.isFrameStr(replacement)) securityContext = SecurityContext.URL;
        else if (this.elementNode.hasAttribute('ic-replace-value-trusted')) securityContext = SecurityContext.NONE;

        this.elementNode.innerHTML = this.domSanitizer.sanitize(securityContext, replacement);
    }

    private setGetReplaceValue(replacement: string): void {
        if (this.promise) this.promise.cancel();

        this.promise = this.dynamicReplacementService
            .getDynamicValue(replacement, this.options);

        this.promise.then((result: string) => {
            let existing = this.elementNode.outerHTML;
            const frameNode = this.elementNode.getElementsByTagName('iframe');
            const isFrameHtml = this.isFrameStr(result);
            const isFrameOnDOM = isFrameHtml && frameNode?.length;

            if (isFrameOnDOM) {
              // If iframe exists in DOM, just update src attribute so we don't lose attached events
                existing = frameNode[0].outerHTML;
                this.updateIframeSrc(result, existing);
            } else if (isFrameHtml && result !== existing) {
              const iframe = this.convertToNode<HTMLIFrameElement>(result);
              if (this.isIframeSrcValid(iframe)) {
                this.setContent(result);
              }
            } else if (result !== existing) {
                this.setContent(result);
            }
        });
    }

    private setNodeAttrs(iFrameNode: HTMLElement, attrObj: IFrameNodeAttrs): void {

        if (!iFrameNode) {
            console.warn('replace-value: attempting to setAttribute on undefined node');
            return;
        }

        this.iFrameAttrs.forEach((attr) => {
            const attribute = attrObj[attr];
            if (_.isNil(attribute) || !_.isString(attribute)) return;
            iFrameNode.setAttribute(attr, attribute as string);
        });
    }

    private getIFrameNodeAttrs(iFrameNode: Element): Record<string, string | boolean> {
        return this.iFrameAttrs.reduce((obj, attr) => {
            return obj[attr] = iFrameNode.getAttribute(attr), obj;
        }, {});
    }

    private convertToNode<T>(element: string): T {
        const root = this.renderer.createElement("div");
        root.innerHTML = element;
        return root.childNodes[0];
    }

    private getIFrameAttrs(iFrame: string | Element): IFrameNodeAttrs {
        if (_.isString(iFrame) && _.isEmpty(iFrame)) return {};
        iFrame = _.isString(iFrame) ? this.convertToNode(iFrame as string) : iFrame;
        return this.getIFrameNodeAttrs(iFrame as Element);
    }

    private isIFrameQueryStringValid(queryStr: string): boolean {
        let isValid = (!queryStr) ? false : true;
        const queryStrArr = queryStr.split('&');
        for (let i = 0; i < queryStrArr.length; i++) {
            const keyValue = queryStrArr[i].split('=');
            if (!keyValue[1])
                isValid = false;
        }
        return isValid;
    }

    private updateIframeSrc(result: string, currentFrame: string): void {
        const resultFrame = this.convertToNode<HTMLIFrameElement>(result);
        const resultFrameAttrs = this.getIFrameAttrs(resultFrame);
        const currentFrameAttrs = this.getIFrameAttrs(currentFrame);
        const newSrc = resultFrameAttrs.src.toString();
        const oldSrc = this.isFrameStr(currentFrame) ? currentFrameAttrs.src : "";

        if (newSrc == oldSrc) return;

        if (this.isIframeSrcValid(resultFrame)) {
            const elementHere = this.convertToNode(currentFrame);
            if (elementHere) {
                const iFrame = this.document.getElementById(resultFrameAttrs.id);
                this.setNodeAttrs(iFrame, resultFrameAttrs);
            } else {
                this.setContent(result);
            }
        }
    }

    private isFrameStr(iFrameStr: string): boolean {
        let isValid = iFrameStr?.includes("iframe");
        if (isValid) {
            const iFrame = this.convertToNode<HTMLIFrameElement>(iFrameStr);
            isValid = iFrame?.hasAttribute("src");
        }
        return isValid;
    }

    private isIframeSrcValid(iframe: HTMLIFrameElement): boolean {
        if (!iframe || !iframe.src || !this.helpersService.isUrl(iframe.src)) {
            return false;
        }
        const url = this.utilService.parseUrl(iframe.src);
        const queryStr = url.search.substring(1); //get rid of "?" in querystring
        return this.isIFrameQueryStringValid(queryStr);
    }
}
