/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Input, ElementRef, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import DxTabs from 'devextreme/ui/tabs';
import { TranslateFacadeService } from '../../services/translate-facade.service';
import { from, of, Subscription } from 'rxjs';
import { concatMap, delay, filter, tap } from 'rxjs/operators';
import { AppsFacade } from '../../state/apps.facade';
import { ThemeService } from '../../services/theme.service';
import { ApplicationInformation } from '../../services/application-information.service';
import { UtilService } from '../../services/util.service';
import { AccessibilityService } from '../../services/accessibility.service';
import { PersonalizationService } from '../../services/personalization.service';
import { AppEvent } from '../../state/app-events.enum';

type TabCanvas = {
  id?: string,
  applets?: {},
  name?: string,
  config?: {
    layout?: string,
    layoutSize?: string,
  },
  layout?: {
    responsive?: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onResize?: (width: number) => string,
    },
  },
  parentName?: string,
  source?: string,
};

type TabItem = {
  id: string,
  visible: boolean,
  tabCanvas?: TabCanvas,
  tabId?: string,
  tabName?: string,
  onTabHeaderClick?: Function
};

type TabsConfig = {
  appName?: string;
  dataAttributes?: string,
  onTabToActivate?: string,
  personalize?: boolean;
  rid?: string,
  personalizationConfig?: {},
  preventSelectionOnScroll?: boolean,
  preventTabSelectionOnArrowKeys?: true,
  responsiveValues?: {},
  tabCanvas: TabCanvas[],
  tabIndex?: number,
  tabItems: TabItem[],
  translationId?: string,
};

@Component({
  selector: 'ic-tabs',
  templateUrl: './tabs.component.html',
  host: { '[class]': 'cssClass' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements OnInit, OnDestroy {

  @Input()
  public config: TabsConfig;
  @Input()
  public context: any;
  @Input()
  public checkSize: any;
  @Input()
  public cssClass: string;

  selectedIndex: number;
  canvasConfig: any;
  dxOptions: any;
  tabComponent: DxTabs;
  onTabActivateFired: boolean;
  recalcHorizontalHandle: boolean = false;

  private eventsSubscription: Subscription;
  private replayableEventsSubscription: Subscription;
  private tabIndexSubscription: Subscription;
  private translateSubscription: Subscription;
  private peloadTabSubscription: Subscription;

  get visibleItems(): any[] {
    return this.config.tabItems.filter(item => item.visible);
  }

  get maxIndex(): number {
    return this.visibleItems.length;
  }

  get $element(): any {
    return $(this.element);
  }

  get $parent(): any {
    return this;
  }

  constructor(
    public element: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private appsFacade: AppsFacade,
    private translate: TranslateFacadeService,
    private applicationInformation: ApplicationInformation,
    private themeService: ThemeService,
    private utilService: UtilService,
    private accessibilityService: AccessibilityService,
    private personalizationService: PersonalizationService) { }

  ngOnInit(): void {

    this.controller();

    // The following line resolves. https://investcloud.visualstudio.com/Portfolio/_workitems/edit/44393
    if (this.config.preventSelectionOnScroll === true) {
      this.$element.find('.dx-tabs-wrapper').off('dxpointerdown', '**');
    }

    this.updateTranslations();
    this.translateSubscription = this.translate.onLangChange.subscribe(() => this.updateTranslations());

    const eventName = this.config.appName + ":ActivateTab";
    this.eventsSubscription = this.appsFacade.events$
      .pipe(
        filter(event => eventName.EqualsIgnoreCase(event?.id)),
        tap(event => this.onActivateTab(event.state?.tabName as string))
      ).subscribe();

    this.replayableEventsSubscription = this.appsFacade.replayableEvents$
      .pipe(
        filter(event => eventName.EqualsIgnoreCase(event?.id)),
        tap(event => this.onActivateTab(event.state?.tabName as string))
      ).subscribe();

    this.tabIndexSubscription = this.appsFacade.events$
      .pipe(
        filter(event => AppEvent.TabToActivate.EqualsIgnoreCase(event?.id)
          && this.config.appName.EqualsIgnoreCase(event?.state?.appName as string)),
        tap((event) => this.tabComponent.option('selectedIndex', event.state.tabIndex))
      ).subscribe();
  }

  ngOnDestroy(): void {
    this.eventsSubscription?.unsubscribe();
    this.replayableEventsSubscription?.unsubscribe();
    this.tabIndexSubscription?.unsubscribe();
    this.translateSubscription?.unsubscribe();
    this.peloadTabSubscription?.unsubscribe();
  }

  onResize(width: number): string {
    const r = this.config.tabCanvas[this.selectedIndex].layout.responsive;
    const b = this.config.responsiveValues;
    let s = 'ExtraSmall';
    if (width >= +b['ExtraLarge'] && !_.isNil(r['ExtraLarge'])) {
      s = 'ExtraLarge';
    } else if (width >= +b['Large'] && !_.isNil(r['Large'])) {
      s = 'Large';
    } else if (width >= +b['Medium'] && !_.isNil(r['Medium'])) {
      s = 'Medium';
    } else if (width >= +b['Small'] && !_.isNil(r['Small'])) {
      s = 'Small';
    }
    return s;
  }

  private publishEvent(eventName: string, state: any): void {
    const eventState = { id: eventName, state };
    this.appsFacade.publishEvent(eventState);
  }

  private publishAppEvent(eventName: string, eventState: any): void {
    this.appsFacade.publishAppEvent(eventName, eventState);
  }

  private onActivateTab(tabName: string): void {

    const visibleTabs = this.visibleItems;
    const tab = _.find(visibleTabs, (tab) => {
      if (!_.isString(tab.id)) return false;
      const apps = tab.id.split('|');
      return _.includes(apps, tabName);
    });

    if (!tab) return;

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    const visibleIndex = _.indexOf(visibleTabs, tab);
    this.tabComponent.option('selectedIndex', visibleIndex);

    const index = _.indexOf(this.config.tabItems, tab);
    this.onTabClicked(index, tab.tabName);

    this.onTabActivateFired = true;
  }

  private closeAllColumnChooser(e): void {
    $(e.element)
      .find('div.icGrid')
      .filter('[dx-data-grid],[dx-tree-list]')
      .each(function (i) {
        const it = $(this);
        const grid = this.helpersService.getDxComponentInstance(it);
        grid && grid.hideColumnChooser();
      });
  }

  private setDataAttributes(tabPanelEle: any, dataAttributes: string): void {
    if (!dataAttributes)
      return;
    const interpolateLabel = '{LabelLowercase}';
    const pairs = dataAttributes.split(',');
    tabPanelEle.find('.dx-tab').each((index: number, element: any) => {
      pairs.forEach((pair) => {
        if (pair.indexOf('|') !== -1) {
          const keyValue = pair.split('|');
          const key = 'data-' + keyValue[0];
          let value = keyValue[1];
          const tabItem = this.dxOptions.items[index]
          const translatedLabel = tabItem && tabItem.tabName && tabItem.tabName.toLowerCase();
          if (translatedLabel) {
            value = value.replace(interpolateLabel, translatedLabel);
          }
          $(element).attr(key, value);
        }
      });
    });
  }

  private preventAutomaticOpeningOnSelectionChanged(e: any): void {
    const element = $(e.element);
    element.attr('tabindex', '');
    element.find('.dx-tabs').attr('tabindex', '');
    const tabSelector = element.find('.dx-item.dx-tab');
    tabSelector.addClass('ic-tabpage');
    tabSelector.attr('tabindex', '0');
    tabSelector.attr('aria-live', 'polite');
    tabSelector.each(function (index) {
      $(this).attr('data-tabpageindex', index);
    });
    tabSelector.keyup(function () {
      const current = $(this),
        tabIndex = parseInt(current.attr('data-tabpageindex')),
        enterKey = 13,
        spaceKey = 32,
        leftKey = 39,
        rightKey = 37;

      if (_.isNaN(tabIndex)) return;

      const keyEvent: KeyboardEvent = window.event as KeyboardEvent;

      if (keyEvent.keyCode == enterKey || keyEvent.keyCode == spaceKey) {
        e.component.option('selectedIndex', tabIndex);
      }
      if (keyEvent.keyCode == leftKey) {
        const items = e.component.itemElements();
        if (tabIndex < items.length - 1) {
          element.find('[data-tabpageindex=' + (tabIndex + 1) + ']').focus();
        }
      }
      if (keyEvent.keyCode == rightKey) {
        if (tabIndex > 0) {
          element.find('[data-tabpageindex=' + (tabIndex - 1) + ']').focus();
        }
      }
    });
  }

  private isPersonalizeEnabled(): boolean {
    return this.config.personalize && !!this.config.personalizationConfig;
  }

  private getPersonalizationKey(): string {
    const screen = this.config.personalizationConfig['ScreenName'] || '';
    const component = this.config.personalizationConfig['ComponentName'] || '';
    const key = 'tabs.' + screen + '_' + component;
    return key;
  }

  private moveTabTo(direction: any): void {
    let curIndex = this.tabComponent.option('selectedIndex');
    if (curIndex === this.maxIndex && direction > 0) {
      curIndex = -1;
    }
    if (curIndex === 0 && direction < 0) {
      curIndex = this.maxIndex + 1;
    }
    this.tabComponent.option('selectedIndex', curIndex + direction);
  }

  private bindNavigationButtonsEvents(e: any): void {
    const THE_LEFT = -1;
    const THE_RIGHT = 1;

    const tabHeader = $(e.element).find('.dx-tabpanel-tabs');

    tabHeader.find('[role=button].dx-tabs-nav-button-left').click((e: any) => {
      e.preventDefault();
      this.moveTabTo(THE_LEFT);
    });

    tabHeader.find('[role=button].dx-tabs-nav-button-right').click((e) => {
      e.preventDefault();
      this.moveTabTo(THE_RIGHT);
    });
  }

  private onTabClicked(itemIndex: number, tabName: string): void {
    // Figure out how many levels deeps this tab is and execute that event.
    const activeTabs = $('.dx-tabpanel-tabs .dx-tab-selected');

    for (let i = 0; i < activeTabs.length; i++) {
      const currentTabName = $(activeTabs[i]).text().trim();
      if (currentTabName != tabName) continue;
      const tabLevel = $(activeTabs[i]).parents("[data-app$='.Tabs.App']").length; // TODO: Not all tab apps have this suffix

      if (this.isPersonalizeEnabled()) {
        const index = this.tabComponent.option('selectedIndex');
        this.selectedIndex = index;
        this.personalizationService.setPersonalization(this.getPersonalizationKey(), { index });
      }

      const eventName = 'Level' + tabLevel + 'TabClickedEvent.Event';
      const data = {
        TabIndex: itemIndex,
      };
      this.publishAppEvent(eventName, data);
      return;
    }
  }

  private preloadTabs(selectedIndex: any, tabAppName: string, e: any): void {
    // Note: this is used to preload inactive tabs after delay specified by PTier.
    // The difference between deferRendering and this approach is that with deferRendering it happens right away.
    const themeProperty: any = this.themeService.getThemeProperty('AutoTabPreloadDelay');
    if (!themeProperty) return;
    this.recalcHorizontalHandle = true;
    selectedIndex = parseInt(selectedIndex);
    let tabDelay = parseInt(themeProperty.Value1);
    tabDelay = isNaN(tabDelay) ? 5000 : tabDelay;
    this.peloadTabSubscription = from(this.config.tabItems)
      .pipe(
        delay(100),
        concatMap((tabItem, idx) => {
          const observable$ = of(tabItem);
          if (idx == selectedIndex || !e.component._deferredItems[idx])
            return observable$;
          return observable$
            .pipe(
              delay(tabDelay),
              tap(() => e.component._renderSpecificItem(idx))
            );
        })
      ).subscribe();
  }

  private controller(): void {
    if (this.isPersonalizeEnabled()) {
      const key = this.getPersonalizationKey();
      const p = this.personalizationService.getPersonalization(key);
      this.selectedIndex = _.get(p, 'index', this.config.tabIndex);
    } else {
      this.selectedIndex = this.config.tabIndex;
    }

    this.config.responsiveValues = this.themeService.getResponsiveBreakpoints();
    this.canvasConfig = {
      float: true,
      hideTitle: true,
      headerMode: 'hover',
      showMove: false,
      isDisabled: true,
      horizontalMargin: 0,
      verticalMargin: 0,
      layout: 'column',
      hideChrome: true,
      cellHeight: 5,
    };

    const firstCanvas = _.first(this.config.tabCanvas);
    const tabAppName = firstCanvas.parentName;
    this.themeService.setTabVisibility(tabAppName, this.config.tabItems);

    this.setupTabCanvas();

    let dataAttributesSet = false;
    this.dxOptions = {
      focusStateEnabled: true,
      focusOnSelectedItem: false,
      swipeEnabled: false,
      showNavButtons: true,
      height: 'auto',
      items: this.visibleItems,
      selectedIndex: this.selectedIndex,
      onInitialized: (e) => {
        this.tabComponent = e.component;
        this.tabComponent.option('selectedIndex', this.dxOptions.selectedIndex);
        // load data for non selected tabs
        this.preloadTabs(this.dxOptions.selectedIndex, tabAppName, e);
      },
      onContentReady: (e) => {
        const noSelectionOnArrowKey =
          this.config.preventTabSelectionOnArrowKeys === true;

        if (!noSelectionOnArrowKey) {
          this.bindNavigationButtonsEvents(e);
        }

        setTimeout(() => {
          e.component._tabs._renderScrolling();

          if (noSelectionOnArrowKey) {
            this.preventAutomaticOpeningOnSelectionChanged(e);
          }
        }, 75);

        if (this.dxOptions.items) {
          const selector = '.dx-tabpanel-container > .dx-multiview-wrapper > .dx-multiview-item-container > .dx-multiview-item';
          const tabElements = this.$element.find(selector);

          for (let i = 0; i < tabElements.length; i++) {
            const target = tabElements[i];
            const options = {
              attributes: true,
              attributeOldValue: true,
              attributeFilter: ['style'],
            };

            const observer = new MutationObserver((mutations, observer) => {
              let triggerStickyHeaderFix = false;
              let selectedTabElement = null;

              mutations.forEach((mutation) => {
                const element = mutation.target as any;
                const oldValue = mutation.oldValue as any;

                if (
                  (!oldValue || !oldValue.contains('translate')) &&
                  element.style &&
                  element.style.transform &&
                  element.style.transform.contains('translate')
                ) {
                  triggerStickyHeaderFix = true;
                  selectedTabElement = element;
                }
              });

              if (triggerStickyHeaderFix) {
                $(document.body).trigger('sticky_kit:recalc');
                $(selectedTabElement)
                  .parents('.dx-multiview-item-container, .dx-item')
                  .css('transform', '');
                $(selectedTabElement).css('transform', '');
              }

              observer.disconnect();
            });

            observer.observe(target, options);
          }
        }

        if (!dataAttributesSet) {
          this.setDataAttributes($(e.element), this.config.dataAttributes);
          dataAttributesSet = true;
        }

        this.accessibilityService.addAccessibilityToTabs({
          element: $(e.element)
        });
      },
      onSelectionChanged: (e) => {
        this.accessibilityService.addAccessibilityToTabsOnSelected({
          element: $(e.element)
        });

        $(document.body).trigger('sticky_kit:recalc');
        this.closeAllColumnChooser(e);

        // Added items contains the new tab that became visible
        const item = e.addedItems[0];
        const index = e.component.option('selectedIndex');

        if (_.isFunction(item.onTabHeaderClick)) {
          item.onTabHeaderClick(this.$parent, e);
        }

        this.onTabClicked(index, item.tabName);
        this.preloadTabRecalcHorizontalHandles(item.tabCanvas)
      },
      onItemRendered: (e) => {
        /* Fixes scrollbar issue in DevExtreme
         * https://gitlab.investcloud.com/ICPTier/IC.SDK.PWP/issues/1306
         */

        setTimeout(() => {
          const multiviewWrapper = $(e.element).find('.dx-multiview-wrapper');

          multiviewWrapper.css('touchAction', 'manipulation');

          if (this.context.activateTab) {
            const eventName = 'v4:' + this.context.activateTab.targetApp + ':Tab1:ActivateTab';
            this.publishEvent(eventName, this.context.activateTab.params);
          }
        }, 100);
      },
      onTitleClick: (e) => {
        this.closeAllColumnChooser(e);
      },
      onAccessibility: (e) => {
        this.accessibilityService.addAccessibilityToTabs({
          element: $(e.element)
        });
      },
    };

    if (this.checkSize) {
      this.checkSize({
        adjustWidth: true,
        adjustHeight: true,
      });
    }
  }

  // Preload tabs does not compute the  horizontal scrolll handles as they are not visible in DOM
  private preloadTabRecalcHorizontalHandles(canvas) {
    if (!this.recalcHorizontalHandle) return;
    this.recalcHorizontalHandle = false;
    canvas.applets.forEach((applet) => {
      if (this.applicationInformation.isListApp(applet.name)) {
        const eventState = {
          appName: applet.name,
        };
        this.publishAppEvent(AppEvent.ResetHorizontalScrollHandles, eventState);
      }
    })
  }

  private setupTabCanvas() {
    for (let i = 0; i < this.config.tabItems.length; i++) {

      const tabItem = this.config.tabItems[i];
      const tabCanvas = this.config.tabCanvas[i];

      if (!tabItem.visible || !tabItem.id)
        continue;

      tabItem.tabCanvas = tabCanvas;

      const apps = tabItem.id.split('|');
      const mainApp = apps.shift();

      tabCanvas.source = 'tab';
      tabCanvas.applets = this.applicationInformation.getStepApplets(mainApp, apps);

      if (_.isNil(tabCanvas.layout.responsive)) continue;

      tabCanvas.layout.responsive.onResize = this.onResize;
      tabCanvas.config.layoutSize = this.onResize(window.outerWidth);
    }
  }

  private updateTranslations(): void {
    const tabItems: any[] = this.config.tabItems;
    tabItems.forEach(tab => {
      const translationId = this.config.translationId + '.' + tab.tabId + '.tabName';
      const translation = this.utilService.translateOrDefault(translationId, tab.tabName);
      if (tab.hasDynamicReplacement) {
        tab.dynamicObj.config = translation;
      }
      tab.tabName = translation;
    });
    this.detectChanges();
  }

  private detectChanges() {
    this.changeDetectorRef.detectChanges();
  }

}
